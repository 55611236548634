import { Field, FieldProps, Formik, FormikProps } from 'formik';
import React, { FunctionComponent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  ITextareaMentionsProps,
  ITextareaMentionsRef,
  MentionUser,
  TextareaMentions
} from '@ryan/components';

import ApiService from '../../../services/ApiService';
import { yup } from '../../../utils/forms';
import { ICodeNoteFieldProps, TFormikValues } from './utils';

const CodeNoteField: FunctionComponent<ICodeNoteFieldProps> = ({
  codeNoteGuid,
  codeNoteText,
  engagementGuid,
  isEditable,
  isLoading,
  onCancel,
  onSave
}) => {
  const { t: getTextToDisplay } = useTranslation();

  const makeOnSuggestionsRequested =
    (engagementGuid: string) =>
    (query: string): Promise<MentionUser[]> =>
      ApiService.engagementMembersAutocomplete(engagementGuid, query).then(
        response =>
          response.data.map(user => ({
            display: user.fullName,
            id: user.userGuid
          }))
      );

  const onSuggestionsRequested: ITextareaMentionsProps['onSuggestionsRequested'] =
    React.useMemo(
      () => makeOnSuggestionsRequested(engagementGuid),
      [engagementGuid]
    );

  const inputRef = useRef<ITextareaMentionsRef | null>(null);

  return (
    <div className="new-comment">
      <Formik<TFormikValues>
        initialValues={{
          codeNote: {
            text: '',
            users: []
          }
        }}
        onSubmit={(formikValues: any) => {
          const { text } = formikValues.codeNote.toJSON();
          if (inputRef.current && !isEditable) {
            inputRef.current.clearContent && inputRef.current.clearContent();
          }
          onSave(text);
        }}
        validationSchema={yup.object({
          codeNote: yup.object().shape({
            text: yup.string().max(250).required()
          })
        })}
      >
        {(formik: FormikProps<TFormikValues>) => (
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Field name="codeNote">
              {({ field }: FieldProps) => (
                <TextareaMentions
                  {...field}
                  onChange={event => {
                    formik.setFieldValue('codeNote', event);
                  }}
                  onSuggestionsRequested={onSuggestionsRequested}
                  placeholder={getTextToDisplay('codeNotes.placeholder')}
                  placement="top"
                  ref={inputRef}
                  value={codeNoteText}
                />
              )}
            </Field>
            <ButtonGroup>
              {codeNoteGuid && (
                <Button onClick={onCancel} text={getTextToDisplay('Cancel')} />
              )}
              <Button
                disabled={!formik.dirty || !formik.isValid}
                loading={isLoading as Promise<any>}
                text={codeNoteGuid ? 'Save' : 'Send'}
                type="submit"
                variant="primary"
              />
            </ButtonGroup>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CodeNoteField;
