import { useUser } from 'contexts/UserContext';
import { IActivity } from 'interfaces';

import classnames from 'classnames';
import React from 'react';
import { isWindows } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import { Button, Icon } from '@ryan/components';

import { RedirectToDecisionUrlEnum } from '../../utils/enums/RedirectToDecisionsEnum';
import redirectToDecisionsApp from '../../utils/redirectToDecisions';

import './AbstractActivity.scss';

export enum ActivityColor {
  Blue = 'blue',
  DarkPurple = 'darkpurple',
  Gray = 'gray',
  Green = 'green',
  LightBlue = 'lightblue',
  LightOrange = 'lightorange',
  LightPurple = 'lightpurple',
  LightTeal = 'lightteal',
  Lime = 'lime',
  MediumBlue = 'mediumblue',
  Orange = 'orange',
  Pink = 'pink',
  Purple = 'purple',
  Red = 'red'
}

export interface IAbstractActivityProps
  extends React.HTMLAttributes<HTMLDivElement> {
  activity: IActivity;
  color?: ActivityColor;
  ctaText?: string;
  icon?: string;
  isNotification?: boolean;
  isRedirectToDecisions?: boolean;
  showAccountName?: boolean;
  showEngagementName?: boolean;
  to?: string;
  onCloseNotificationDrawer?: () => void;
}

export const AbstractActivity: React.FC<IAbstractActivityProps> = ({
  activity,
  children,
  className: classNameProp,
  color = ActivityColor.Blue,
  ctaText,
  icon = 'information',
  isNotification,
  isRedirectToDecisions,
  showAccountName,
  showEngagementName,
  title,
  to,
  onCloseNotificationDrawer,
  ...rest
}) => {
  const history = useHistory();
  const {
    activeView,
    getAccountByGuid,
    setActiveAccount,
    setActiveAccountForEngagement
  } = useUser();
  const className = classnames('activity', `activity--${color}`, classNameProp);

  /**
   * If this activity is from a notification AND we are currently in an
   * executive view, then we should not expect the link to work because
   * notifications are always from your non-executive access.
   */
  const handleClick = async (e: React.MouseEvent) => {
    const { ctrlKey, metaKey } = e;
    const exitExecutiveView = !!isNotification && activeView.isExecutiveView;
    const openInNewTab = metaKey || (ctrlKey && isWindows);
    e.stopPropagation();

    // if there is a CTA
    if (to) {
      // get out of executive view
      if (exitExecutiveView) {
        const { engagementGuid, accountGuid } = activity;

        // if opening in current tab, navigate to home before updating view
        // to avoid undesired interactions on pages that react to both URL path
        // and view changes; should be a quick transition in real-time
        if (!openInNewTab) {
          history.push('/app');
        }

        if (engagementGuid && accountGuid) {
          await setActiveAccountForEngagement({ accountGuid, engagementGuid });
        } else if (accountGuid) {
          const match = await getAccountByGuid(accountGuid);

          if (match) {
            await setActiveAccount(match.account, true, false);
          }
        }
      }

      // navigate
      if (openInNewTab) {
        // when the card/CTA is ctrl/cmd + clicked, a new tab should open
        window.open(to, '_blank');
      } else if (exitExecutiveView) {
        // use replace if clicked on notification in executive view as we
        // redirected to home page first
        history.replace(to);
      } else {
        history.push(to);
      }

      onCloseNotificationDrawer?.();
    }
  };

  const renderCta = () => {
    if (to || isRedirectToDecisions) {
      const decisionsRedirectUrl = `${RedirectToDecisionUrlEnum.REVIEW_DATA_REQUEST}${activity.decisionsEntityId}`;

      return (
        <Button
          className={classnames({
            'ry-link--is-notification': isNotification
          })}
          onClick={
            isRedirectToDecisions
              ? () => redirectToDecisionsApp(decisionsRedirectUrl)
              : handleClick
          }
          role="link"
          variant="link"
        >
          {ctaText}
        </Button>
      );
    }
    return null;
  };

  return (
    <div
      className={className}
      // if activity is a notification, the entire card should be clickable
      {...(to && isNotification
        ? {
            'aria-label': ctaText,
            role: 'link',
            onClick: handleClick
          }
        : {})}
      {...rest}
    >
      <Icon className="activity__icon" name={icon} />
      {showAccountName && (
        <div className="activity__accountName">
          {activity.accountName}
          <hr />
        </div>
      )}
      {showEngagementName && activity.engagementDisplayNameShort && (
        <div className="activity__engagement">
          {activity.engagementDisplayNameShort}
        </div>
      )}
      {title && <div className="activity__entity">{title}</div>}
      <div className="activity__content">
        {children}
        {renderCta()}
      </div>
    </div>
  );
};
