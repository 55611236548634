import { UserAutocomplete } from 'components/AutocompleteAjax';
import CommentInput from 'components/Comments/CommentInput';
import { makeOnSuggestionsRequested } from 'components/Comments/CommentInput/CommentInput';
import DSSFileUpload from 'components/DSSFileUpload/DSSFileUpload';
import Modal from 'components/Modal';
import UploadModalWarning from 'components/UploadModalWarning/UploadModalWarning';
import {
  IDataRequest,
  IDataRequestUploadFilesOnBehalfOfUserRequest,
  IEngagement,
  IUserSummary
} from 'interfaces';
import ApiService from 'services/ApiService';
import { DSSManager } from 'utils/DSS';
import {
  Formik,
  formikAutocompleteAjaxProps,
  formikCommentProps,
  yup
} from 'utils/forms';

import React, { PureComponent, createRef } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  MentionsValue,
  Message,
  autocompleteHighlight,
  pushToast
} from '@ryan/components';

import './DataRequestRyanUploadModal.scss';

interface IDataRequestRyanUploadModalValues {
  user: IUserSummary | null;
  comment: MentionsValue;
}

interface IDataRequestRyanUploadModalProps extends WithTranslation {
  engagement: IEngagement | null;
  dataRequest: IDataRequest | null;
  open: boolean;
  onClose: (uploadedFiles: boolean) => void;
}

interface IDataRequestRyanUploadModalState {
  submitPromise: Promise<any> | null;
  message: React.ReactNode | null;
}

const initialValues: IDataRequestRyanUploadModalValues = {
  user: null,
  comment: new MentionsValue()
};

const maxLengthComment = 250;

export class DataRequestRyanUploadModal extends PureComponent<
  IDataRequestRyanUploadModalProps,
  IDataRequestRyanUploadModalState
> {
  private dss = new DSSManager({ onChange: () => this.forceUpdate() });

  private errorContainerRef = createRef<HTMLDivElement>();
  private formRef = createRef<HTMLFormElement>();

  private schema: yup.ObjectSchema<IDataRequestRyanUploadModalValues>;

  constructor(props: IDataRequestRyanUploadModalProps) {
    super(props);

    const { t } = props;

    this.schema = yup.object({
      user: yup
        .object<IUserSummary>()
        .nullable()
        .required(t('dataRequest.ryanUploadModal.userRequired')),
      comment: yup.mixed().validateCommentLength(
        maxLengthComment,
        t('dataRequest.ryanUploadModal.commentMaxLength', {
          length: maxLengthComment
        })
      )
    });

    this.state = {
      submitPromise: null,
      message: null
    };
  }

  /**
   * User clicked "Complete" button.
   */
  handleSubmit = (values: any) => {
    const { dataRequest } = this.props;
    const documents = this.dss.getUploadedDocuments();
    if (dataRequest && documents.length > 0) {
      this.setState({
        message: null,
        submitPromise: this.uploadFiles(dataRequest, {
          userGuid: values.user.userGuid,
          comment: values.comment.toJSON().text,
          documents
        })
      });
    }
  };

  async uploadFiles(
    dataRequest: IDataRequest,
    request: IDataRequestUploadFilesOnBehalfOfUserRequest
  ) {
    const { t } = this.props;

    try {
      await ApiService.uploadDataRequestFilesOnBehalfOfUser(
        dataRequest.engagementGuid,
        dataRequest.queueItemGuid,
        request
      );

      pushToast({
        type: 'success',
        title: t('uploadFilesSuccess.title'),
        content: t('uploadFilesSuccess.content', {
          count: request.documents.length,
          filename: request.documents[0].documentName
        })
      });

      // Close (after clearing submitPromise).
      this.setState({ submitPromise: null }, () => {
        this.close(true);
      });
    } catch (error) {
      this.setState({
        submitPromise: null,
        message: (
          <Message title={t('serverError.title')} type="error">
            {t('serverError.content')}
          </Message>
        )
      });

      // Throw so Button knows to stop spinning without the succes check.
      throw error;
    }
  }

  /**
   * Close the modal (if not currently completing request).
   */
  close(withChanges: boolean) {
    if (this.state.submitPromise === null) {
      this.setState({ message: null }, () => {
        this.dss.clearUploads();
        this.props.onClose(withChanges);
      });
    }
  }

  renderUser(user: IUserSummary, { query }: { query: string }) {
    return autocompleteHighlight(user.fullName, query);
  }

  render() {
    const { t, dataRequest, open } = this.props;
    const { submitPromise, message } = this.state;

    if (dataRequest) {
      return (
        <UploadModalWarning dss={this.dss} onClose={() => this.close(false)}>
          {({ dss, warning, onEscape, onCancel }) => (
            <Modal
              className="data-request-ryan-upload-modal"
              onClose={onEscape}
              open={open}
              title={t('Upload Files')}
            >
              {warning}
              {message}
              <div ref={this.errorContainerRef} />
              <div className="data-request-ryan-upload-modal__flex">
                <DSSFileUpload
                  border
                  dssManager={dss}
                  errorContainerRef={this.errorContainerRef}
                  multiple
                  title={t('Select Files')}
                />
                <div className="data-request-ryan-upload-modal__form">
                  {dataRequest === null ? (
                    <>
                      <div className="ry-skeleton" />
                      <div className="ry-skeleton" />
                    </>
                  ) : (
                    <>
                      <h4 className="ry-h4">
                        {dataRequest.engagementDisplayNameShort}
                      </h4>
                      <h3 className="ry-h3">{dataRequest.title}</h3>
                    </>
                  )}

                  <Formik<IDataRequestRyanUploadModalValues>
                    initialValues={initialValues}
                    onSubmit={this.handleSubmit}
                    validationSchema={this.schema}
                  >
                    {formik => (
                      <form
                        autoComplete="off"
                        onSubmit={formik.handleSubmit}
                        ref={this.formRef}
                      >
                        <UserAutocomplete
                          {...formikAutocompleteAjaxProps('user', formik)}
                          engagementGuid={dataRequest.engagementGuid}
                          label={t('dataRequest.ryanUploadModal.userLabel')}
                        />
                        <CommentInput
                          {...formikCommentProps('comment', formik)}
                          boundingParentRef={this.formRef}
                          label={t('dataRequest.ryanUploadModal.commentLabel')}
                          makeOnSuggestionsRequestedCallback={() =>
                            makeOnSuggestionsRequested(
                              dataRequest.engagementGuid,
                              user => user.canBeMentionedInDRComment
                            )
                          }
                        />
                        <div className="data-request-ryan-upload-modal__buttons">
                          <ButtonGroup>
                            <Button
                              disabled={
                                this.dss.getUploadedDocuments().length === 0 ||
                                formik.values.user === null
                              }
                              loading={
                                dss.getUploadingPromise() || submitPromise
                              }
                              size="lg"
                              text={t('Complete')}
                              type="submit"
                              variant="primary"
                            />
                            <Button
                              disabled={
                                (dss.getUploadingPromise() || submitPromise) !==
                                null
                              }
                              onClick={onCancel}
                              size="lg"
                              text={t('Cancel')}
                              variant="secondary"
                            />
                          </ButtonGroup>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </Modal>
          )}
        </UploadModalWarning>
      );
    }

    return null;
  }
}

export default withTranslation()(DataRequestRyanUploadModal);
