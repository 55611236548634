import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonGroup, Modal } from '@ryan/components';

import { Formik } from '../../../utils/forms';
import UpdateSavingsBucketForm from './UpdateSavingsBucketForm/UpdateSavingsBucketForm';
import UpdateSavingsBucketSummary from './UpdateSavingsBucketSummary/UpdateSavingsBucketSummary';
import {
  IUpdateSavingsBucketModalProps,
  TFormikValues,
  UpdateSavingsBucketModalEnums,
  getInitialFormikValues,
  getMappedToFloatFormikValues,
  getSavingsSummaryValues
} from './utils';

import './UpdateSavingsBucketModal.scss';

const UpdateSavingsBucketModal: React.FC<IUpdateSavingsBucketModalProps> = ({
  bucketName,
  lastSavingsForBucket,
  onClose,
  onSubmit,
  savingsCategoryDetail,
  savingsCurrencyCode,
  savingsLastAsOfDate
}) => {
  const { t: textToDisplay } = useTranslation();

  const { ROOT_TO_TEXT } = UpdateSavingsBucketModalEnums;

  const displayBucketName = textToDisplay(`savings.${bucketName}`);

  return (
    <Modal
      className="update-savings-bucket-modal"
      onClose={onClose}
      open
      title={textToDisplay(`${ROOT_TO_TEXT}.title`, {
        bucketName: displayBucketName
      })}
    >
      <Formik<TFormikValues>
        initialValues={getInitialFormikValues(
          savingsCategoryDetail,
          bucketName
        )}
        onSubmit={formikValues => {
          onSubmit(getMappedToFloatFormikValues(bucketName, formikValues));
        }}
      >
        {formik => (
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <p>
              {textToDisplay(`${ROOT_TO_TEXT}.updateSavingsDescription`, {
                bucketName: displayBucketName,
                categoryName: savingsCategoryDetail.name
              })}
            </p>

            <section>
              <UpdateSavingsBucketForm
                bucketName={bucketName}
                formik={formik}
              />

              <UpdateSavingsBucketSummary
                bucketName={bucketName}
                savingsCurrencyCode={savingsCurrencyCode}
                savingsLastAsOfDate={savingsLastAsOfDate}
                savingsSummaryValues={getSavingsSummaryValues(
                  savingsCategoryDetail,
                  formik.values,
                  bucketName,
                  lastSavingsForBucket
                )}
              />
            </section>

            <ButtonGroup>
              <Button
                onClick={onClose}
                text={textToDisplay(`${ROOT_TO_TEXT}.cancel`)}
              />
              <Button
                disabled={!formik.dirty}
                text={textToDisplay(`${ROOT_TO_TEXT}.done`)}
                type="submit"
                variant="primary"
              />
            </ButtonGroup>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default UpdateSavingsBucketModal;
