import InfoTooltip from 'components/InfoTooltip';
import SearchInput from 'components/SearchInput';
import { useUser } from 'hooks';
import { Feature, MAX_SELECTED_COMPANIES, Permission } from 'interfaces';

import classnames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Switch, Tooltip } from '@ryan/components';

import { useSwitcher } from '../SwitcherContext';
import { SwitcherCustomViews } from '../SwitcherCustomViews/SwitcherCustomViews';
import SwitcherFilterApplyButton from '../SwitcherFilterApplyButton/SwitcherFilterApplyButton';
import SwitcherOptions from '../SwitcherOptions/SwitcherOptions';
import filterAccounts from '../filterAccounts';

import './SwitcherDesktop.scss';

const SwitcherDesktop: React.FC = () => {
  const { t } = useTranslation();
  const {
    activeView,
    executiveAccounts,
    permissionService: ps,
    recentViews,
    user,
    isFeatureToggled,
    setActiveView
  } = useUser();
  const {
    expanded,
    filterCompanies,
    filterCustomView,
    filterExecutiveAccess,
    filterTab,
    openDesktop,
    openFilterPane,
    searchQuery,
    onAccountSelect,
    onAccountToggle,
    onFilterCompanyToggle,
    onFilterCompanyToggleAll,
    onFilterCustomViewDelete,
    onFilterCustomViewSelect,
    onFilterExecutiveToggle,
    onFilterPaneReset,
    onFilterPaneToggle,
    onFilterTabSelect,
    onSearchChange,
    onToggleDesktop
  } = useSwitcher();
  const accountListRef = useRef<HTMLDivElement | null>(null);
  const switcherRef = useRef<HTMLDivElement | null>(null);

  // the name to display in the desktop switcher toggle
  const [displayName, setDisplayName] = useState(activeView.name);

  // toggles visual updates for an executive view; true if the current active
  // view is an executive view or user has queued up an executive view to be
  // applied
  const [isExecutiveView, setIsExecutiveView] = useState(
    activeView.isExecutiveView
  );

  // the current list of user accounts to display and search against
  const accounts = useMemo(
    () => (filterExecutiveAccess ? executiveAccounts || [] : user.accountTree),
    [executiveAccounts, filterExecutiveAccess, user]
  );

  // a list of user account filtered by search query
  const filteredAccounts = useMemo(
    () => filterAccounts(searchQuery, accounts),
    [accounts, searchQuery]
  );

  // limit selection to 300 accounts for performance if limit feature is toggled
  const isSelectionLimited =
    isFeatureToggled(Feature.LimitedCustomView) &&
    accounts.length > MAX_SELECTED_COMPANIES;

  /**
   * Callback to close an open switcher on click outside.
   */
  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      // custom view dropdown renders outside custom view component so also
      // check if click occurred inside dropdown to prevent close on dropdown
      // click
      if (
        e.target &&
        openDesktop &&
        !switcherRef.current?.contains(e.target as Element) &&
        !(e.target as Element).closest(
          '.switcher-custom-views__dropdown, .switcher-custom-views__delete-modal'
        )
      ) {
        onToggleDesktop(false);
      }
    },
    [openDesktop, onToggleDesktop]
  );

  /**
   * Renders the Switcher Desktop body.
   */
  const renderBody = () => {
    // render Custom Views list
    if (openFilterPane && filterTab === 'custom-views') {
      return (
        <SwitcherCustomViews
          customViews={user.customViews}
          onCloseSwitcher={onToggleDesktop}
          onCustomViewDeleted={onFilterCustomViewDelete}
          onCustomViewSelected={onFilterCustomViewSelect}
          searchQuery={searchQuery}
          selectedCustomView={filterCustomView}
        />
      );
    }

    // (executive access) render loading state if initial executive account
    // load
    if (filterExecutiveAccess && executiveAccounts === null) {
      return (
        <div className="center-icon">
          <Icon className="loading-spin" name="loading" />
        </div>
      );
    }

    // render empty state - will display if:
    // 1. user has no accounts
    // 2. user toggles on executive access and has no executive accounts
    // 3. user is searching for an account and returns no matches
    if (filteredAccounts.length === 0) {
      return (
        <div className="account-switcher-desktop__empty">
          {searchQuery
            ? t('switcher.empty', { context: 'search', searchQuery })
            : t(
                'switcher.empty',
                filterExecutiveAccess ? { context: 'executive' } : {}
              )}
        </div>
      );
    }

    // Default - rendered in 2 cases:
    // 1. When the Filter pane is closed and the standard list of accounts is
    //    shown.
    // 2. When the Filter pane is open and the "Companies" tab is selected.
    //    `accountSelection` is only used in case 2 so that checkboxes are
    //    rendered.
    return (
      <SwitcherOptions
        accounts={filteredAccounts}
        accountSelection={
          openFilterPane && filterTab === 'companies' ? filterCompanies : null
        }
        activeView={activeView}
        containerRef={accountListRef}
        expanded={expanded}
        level={1}
        onAccountChecked={onFilterCompanyToggle}
        onAccountSelect={onAccountSelect}
        onAccountToggle={onAccountToggle}
        searchQuery={searchQuery}
        t={t}
      />
    );
  };

  // bind event listener to close switcher on click outside when open
  useEffect(() => {
    if (openDesktop) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [openDesktop, handleOutsideClick]);

  // update switcher toggle label and executive view display
  useEffect(() => {
    let updatedDisplayName = activeView.name;
    let updatedExecutiveViewState = activeView.isExecutiveView;

    if (openDesktop && openFilterPane) {
      if (filterTab === 'companies' && filterCompanies.view) {
        updatedDisplayName = filterCompanies.view.name;
        updatedExecutiveViewState = filterCompanies.view.isExecutiveView;
      } else if (filterTab === 'custom-views' && filterCustomView) {
        updatedDisplayName = filterCustomView.name;
        updatedExecutiveViewState = filterCustomView.isExecutiveView;
      }
    }

    setDisplayName(updatedDisplayName);
    setIsExecutiveView(updatedExecutiveViewState);
  }, [
    activeView,
    filterCompanies.view,
    filterCustomView,
    filterTab,
    openDesktop,
    openFilterPane
  ]);

  // scroll to top of accounts list on search query update
  useEffect(() => {
    accountListRef.current?.scrollTo?.(0, 0);
  }, [searchQuery]);

  // close switcher on unmount
  useEffect(
    () => () => {
      onToggleDesktop(false);
    },
    [onToggleDesktop]
  );

  return (
    <div
      className={classnames({
        'account-switcher-desktop': true,
        'account-switcher-desktop--open': openDesktop
      })}
      ref={switcherRef}
    >
      <Button
        aria-label={t(
          openDesktop ? 'switcher.toggleClose' : 'switcher.toggleOpen'
        )}
        className={classnames({
          'account-switcher-desktop__toggle': true,
          'account-switcher-desktop__toggle-inactive': isExecutiveView
        })}
        onClick={() => onToggleDesktop()}
        size="lg"
        variant="text"
      >
        {displayName}
        {isExecutiveView ? (
          <Tooltip
            content={t('ExecutiveCustomViewToolTip')}
            renderTarget={({ open, ...props }) => (
              <span
                aria-expanded={open}
                aria-haspopup
                className="account-switcher-desktop__toggle-tooltip"
                {...props}
              >
                <Icon name="lock" />
              </span>
            )}
          />
        ) : (
          <Icon name={openDesktop ? 'chevron-up' : 'chevron-down'} />
        )}
      </Button>

      {openDesktop && (
        <div className="account-switcher-desktop__menu">
          {/* Recently Viewed */}
          <div className="account-switcher-desktop__recents">
            <div
              className="account-switcher-desktop__recents-header"
              id="account-switcher-desktop-recents-label"
            >
              {t('Recently Viewed')}
            </div>
            <ul aria-labelledby="account-switcher-desktop-recents-label">
              {recentViews.map(view => (
                <li key={view.customViewGuid}>
                  <Button
                    onClick={() => {
                      setActiveView(view);
                      onToggleDesktop(false);
                    }}
                    variant="link"
                  >
                    {view.name}
                  </Button>
                </li>
              ))}
            </ul>
          </div>

          {/* Filter Pane */}
          <div
            className={classnames({
              'account-switcher-desktop__filters': true,
              'account-switcher-desktop__filters--open': openFilterPane
            })}
          >
            <div className="account-switcher-desktop__filters-header">
              <span id="account-switcher-desktop-filters-label">
                {t('Filters')}
              </span>
              <button onClick={onFilterPaneReset} type="button">
                {t('Reset')}
              </button>
            </div>
            <ul aria-labelledby="account-switcher-desktop-filters-label">
              <li
                className={classnames({
                  'account-switcher-desktop__filters-tab': true,
                  'account-switcher-desktop__filters-tab--active':
                    filterTab === 'companies'
                })}
              >
                <button
                  className="account-switcher-desktop__filters-tab-button"
                  onClick={() => onFilterTabSelect('companies')}
                  type="button"
                >
                  {t('Companies')}
                </button>
                <Icon name="chevron-right" />

                {filterTab === 'companies' && (
                  <>
                    {ps.hasPermission(Permission.ExecutiveAccess) && (
                      <div className="account-switcher-desktop__filters-switch">
                        {t('switcher.executiveAuditView')}
                        <InfoTooltip>{t('ExecutiveToggleToolTip')}</InfoTooltip>
                        <Switch
                          checked={filterExecutiveAccess}
                          onChange={onFilterExecutiveToggle}
                        />
                      </div>
                    )}
                    <div className="account-switcher-desktop__filters-switch">
                      {t('Select All')}
                      {isSelectionLimited && (
                        <InfoTooltip>
                          {t('switcher.selectAllDisabled')}
                        </InfoTooltip>
                      )}
                      <Switch
                        checked={filterCompanies.isAllChecked()}
                        disabled={
                          isSelectionLimited || filteredAccounts.length === 0
                        }
                        onChange={onFilterCompanyToggleAll}
                      />
                    </div>
                  </>
                )}
              </li>
              <li
                className={classnames({
                  'account-switcher-desktop__filters-tab': true,
                  'account-switcher-desktop__filters-tab--active':
                    filterTab === 'custom-views'
                })}
              >
                <button
                  className="account-switcher-desktop__filters-tab-button"
                  onClick={() => onFilterTabSelect('custom-views')}
                  type="button"
                >
                  {t('Custom Views')}
                </button>
                <Icon name="chevron-right" />
              </li>
            </ul>
            <div className="account-switcher-desktop__filters-buttons">
              <div className="row">
                <div className="col-6">
                  <Button
                    block
                    onClick={onFilterPaneToggle}
                    size="sm"
                    variant="secondary"
                  >
                    {t('Cancel')}
                  </Button>
                </div>
                <div className="col-6">
                  <SwitcherFilterApplyButton />
                </div>
              </div>
            </div>
          </div>

          {/* Search and Accounts */}
          <div className="account-switcher-desktop__right">
            <div className="account-switcher-desktop__right-header">
              <Button
                icon={openFilterPane ? 'filter-filled' : 'filter'}
                onClick={onFilterPaneToggle}
                size="sm"
                variant="text"
              >
                {t('Filter')}
              </Button>
              <SearchInput
                loading={false}
                onChange={onSearchChange}
                value={searchQuery}
              />
            </div>
            <div
              className="account-switcher-desktop__right-scroll"
              ref={accountListRef}
            >
              {renderBody()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SwitcherDesktop;
