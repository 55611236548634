export enum ButtonSizeEnums {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg'
}

export enum ButtonVariantEnums {
  LINK = 'link',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TEXT = 'text'
}
