import { WithUser, withUser } from 'contexts/UserContext';
import { EntityType, IActivity, IRecentActivity } from 'interfaces';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import getEntityTypes from 'utils/getEntityTypes';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, matchPath, withRouter } from 'react-router';

import { Button, Card } from '@ryan/components';

import UnsavedChangesContext from '../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import ActivityFeed, { ActivityFeedSkeleton } from '../ActivityFeed';
import ActivityEvents from './ActivityEvents';

import './RecentActivityCard.scss';

const isDataAndFiles = (location: RouteComponentProps['location']) =>
  matchPath(location.pathname, '/app/data-and-files') !== null;

interface IRecentActivityProps {
  engagementGuid?: string;
}

type IRecentActivityHOCProps = IRecentActivityProps &
  WithTranslation &
  WithUser &
  RouteComponentProps;

interface IRecentActivityState {
  history: IActivity[];
  historyLoading: boolean;
  entityTypes: EntityType[];
}

class RecentActivityCard extends React.Component<
  IRecentActivityHOCProps,
  IRecentActivityState
> {
  // track when mounted so to avoid setState and memory leaks
  private _isMounted = false;

  // axios cancel token source
  private source?: CancelTokenSource;

  constructor(props: IRecentActivityHOCProps) {
    super(props);
    this.state = {
      history: [],
      historyLoading: true,
      entityTypes: getEntityTypes(
        props.permissionService,
        !!props.engagementGuid,
        isDataAndFiles(props.location)
      )
    };
  }

  static contextType = UnsavedChangesContext;
  context!: React.ContextType<typeof UnsavedChangesContext>;

  // Fetch activity on mount.
  componentDidMount() {
    this._isMounted = true;
    ActivityEvents.subscribe(this.fetchRecentActivity);
    this.fetchRecentActivity();
  }

  componentWillUnmount() {
    // cancel any ongoing requests
    this.source?.cancel('cancelling previous RecentActivityCard request');

    ActivityEvents.unsubscribe(this.fetchRecentActivity);
    this._isMounted = false;
  }

  componentDidUpdate(
    prevProps: IRecentActivityHOCProps,
    prevState: IRecentActivityState
  ) {
    if (
      prevProps.activeView.customViewGuid !==
      this.props.activeView.customViewGuid
    ) {
      this.fetchRecentActivity();
    }
  }

  // Navigate to the correct page when View All is clicked.
  handleViewAllActivity = () => {
    const { engagementGuid, history, location } = this.props;
    const { isUnsavedChanges, setBlockNavigation, setTargetUrl } = this.context;
    const activityUrl = engagementGuid
      ? `/app/project/${engagementGuid}/all-activity`
      : isDataAndFiles(location)
      ? '/app/data-and-files/all-activity'
      : '/app/projects/all-activity';

    if (isUnsavedChanges) {
      setBlockNavigation(true);
      setTargetUrl(activityUrl);

      return false;
    }

    if (engagementGuid) {
      history.push(activityUrl);
    } else {
      // On the account level is the only time we worry about data and files overview
      isDataAndFiles(location)
        ? history.push(activityUrl)
        : history.push(activityUrl);
    }
  };

  fetchRecentActivity = async () => {
    const {
      engagementGuid,
      activeView: { customViewGuid }
    } = this.props;
    const { entityTypes } = this.state;
    const params: IRecentActivity = {
      sort: 'createDate-',
      pageNumber: 1,
      itemsPerPage: 5,
      entityTypes: entityTypes.join(',')
    };

    // refresh cancel token
    this.source?.cancel('cancelling previous RecentActivityCard request');
    this.source = ApiService.CancelToken.source();

    const promise = engagementGuid
      ? ApiService.getEngagementRecentActivity(
          engagementGuid,
          params,
          this.source.token
        )
      : ApiService.getCustomViewRecentActivity(
          customViewGuid,
          params,
          this.source.token
        );

    try {
      this.setState({ historyLoading: true });
      const response = await promise;
      this.setState({
        history: response.data.results
      });
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushServerErrorToast();
      }
    } finally {
      if (this._isMounted) {
        this.setState({ historyLoading: false });
      }
    }
  };

  render() {
    const { t, engagementGuid } = this.props;
    const { history, historyLoading } = this.state;

    return (
      <Card
        className="recent-activity-card"
        role="region"
        title={t(`activity.feed.recentActivity`)}
      >
        {historyLoading ? (
          <ActivityFeedSkeleton />
        ) : (
          <ActivityFeed
            activities={history}
            showAccountName={!engagementGuid}
            showEngagementName={!engagementGuid}
          />
        )}

        {history.length > 0 && (
          <div className="recent-activity-card__actions">
            <Button
              onClick={this.handleViewAllActivity}
              text={t('View All')}
              variant="primary"
            />
          </div>
        )}
      </Card>
    );
  }
}

export default withRouter(withUser(withTranslation()(RecentActivityCard)));
