import React, { Component } from 'react';
import {
  TFunction,
  Trans,
  WithTranslation,
  withTranslation
} from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Button, Card, EMessageTypes, Icon, pushToast } from '@ryan/components';

import {
  WithAmplitude,
  withAmplitude
} from '../../contexts/AmplitudeContext/AmplitudeConsumer';
import { WithDownload, withDownload } from '../../contexts/DownloadContext';
import { WithUser, withUser } from '../../contexts/UserContext';
import {
  ContractFileStatus,
  IContractFile,
  IContractFileSearch,
  IEngagement
} from '../../interfaces';
import ApiService, { CancelTokenSource } from '../../services/ApiService';
import { amplitudeEventDetail } from '../../utils/amplitudeUtils/amplitudeUtils';
import { ButtonSizeEnums } from '../../utils/enums/ButtonSizeEnums';
import { formatDate } from '../../utils/formatDate';
import getContractFileName from '../../utils/getContractFileName';
import getSortParam from '../../utils/getSortParm';
import pushServerErrorToast from '../../utils/pushServerErrorToast';
import ContractFileEditPublishModal from '../ContractFileEditPublishModal/ContractFileEditPublishModal';
import ContractFileUnpublishModal from '../ContractFileUnpublishModal/ContractFileUnpublishModal';
import Empty from '../Empty';
import ContractFileOverflowMenu from '../Table/ContractFileOverflowMenu/ContractFileOverflowMenu';
import TooltipList from '../TooltipList/TooltipList';

import './ContractCard.scss';

export interface IContractCardProps
  extends RouteComponentProps,
    WithAmplitude,
    WithUser,
    WithDownload,
    WithTranslation {
  engagement: IEngagement;
}

type IContractCardHOCProps = WithUser &
  WithTranslation &
  RouteComponentProps &
  WithDownload;

interface IContractCardState {
  contracts: IContractFile[];
  editPublishContractFile: IContractFile | null;
  loading: boolean;
  totalCount: number;
  unpublishContractFile: IContractFile | null;
}

class ContractCard extends Component<
  IContractCardProps & IContractCardHOCProps,
  IContractCardState
> {
  private _isMounted = false;
  private source?: CancelTokenSource;
  readonly NUM_ITEMS_TO_DISPLAY = 3;

  constructor(props: IContractCardProps) {
    super(props);

    this.state = {
      contracts: [],
      editPublishContractFile: null,
      loading: false,
      totalCount: 0,
      unpublishContractFile: null
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchContracts();
  }

  componentWillUnmount() {
    this.source?.cancel();
    this._isMounted = false;
  }

  fetchContracts = () => {
    const { engagement, permissionService: ps } = this.props;

    this.setState({ loading: true }, async () => {
      const params: IContractFileSearch = {
        pageNumber: 1,
        sort: getSortParam({
          id: 'updateDate',
          desc: true
        }),
        itemsPerPage: this.NUM_ITEMS_TO_DISPLAY,
        status: ps.isRyan() ? '' : ContractFileStatus.Published.toString()
      };

      try {
        // refresh cancel token
        this.source?.cancel();
        this.source = ApiService.CancelToken.source();

        const response = await ApiService.getContractFilesByEngagementGuid(
          engagement.engagementGuid,
          params,
          this.source.token
        );

        // Sort contracts by either executed date or alpha order
        const setHasExecutedDate = response.data.results.some(
          contract => contract.executedDate
        );
        if (setHasExecutedDate) {
          response.data.results.sort((a: any, b: any) => {
            if (a.executedDate === b.executedDate) return 0;
            else if (a.executedDate === null) return 1;
            else if (b.executedDate === null) return -1;
            else return a.executedDate < b.executedDate ? 1 : -1;
          });
        } else {
          response.data.results.sort((a: any, b: any) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
          );
        }

        this.setState({
          contracts: response.data.results,
          totalCount: response.data.totalResults
        });
      } catch (error) {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast(`could not fetch contracts - ${error}`);
        }
      } finally {
        if (this._isMounted) {
          this.setState({
            loading: false
          });
        }
      }
    });
  };

  handleDownloadContract = (contractFile: IContractFile) => {
    const { onDownloadContract, triggerAmplitudeEvent } = this.props;

    triggerAmplitudeEvent({
      amplitudeEventAction:
        amplitudeEventDetail.ryanContracts.downloadEventName,
      amplitudeEventLocation: '/app/data-and-files/contracts',
      amplitudeEventName: amplitudeEventDetail.ryanContracts.eventName,
      amplitudeEventProperty:
        amplitudeEventDetail.ryanContracts.downloadPropertyOptions
          .singleProjectFiles
    });
    onDownloadContract(contractFile);
  };

  // TODO: Functionality duplicate on ContractFiles Component. Merge
  handlePublish = async ({
    contractFileToPublish,
    getTextToDisplayCallback,
    handleRefreshCallback
  }: {
    contractFileToPublish: IContractFile;
    getTextToDisplayCallback: TFunction;
    handleRefreshCallback: () => void;
  }) => {
    if (!Boolean(contractFileToPublish.executedDate)) {
      this.setState({ editPublishContractFile: contractFileToPublish });
      return;
    }

    try {
      await ApiService.publishContractFiles(
        contractFileToPublish.engagementGuid,
        [contractFileToPublish.engagementContractDocumentGuid]
      );

      pushToast({
        content: (
          <Trans i18nKey="contracts.modal.publish.success.content">
            <b />
            {{
              displayName: getContractFileName(
                getTextToDisplayCallback,
                contractFileToPublish
              )
            }}
          </Trans>
        ),
        title: getTextToDisplayCallback(
          'contracts.modal.publish.success.title'
        ),
        type: EMessageTypes.SUCCESS
      });

      handleRefreshCallback();
    } catch (error) {
      pushToast({
        content: getTextToDisplayCallback('serverError.content'),
        title: getTextToDisplayCallback('serverError.title'),
        type: EMessageTypes.ERROR
      });
    }
  };

  render() {
    const {
      activeView: { isExecutiveView },
      isAppReadOnly,
      engagement,
      permissionService,
      t: getTextToDisplay
    } = this.props;
    const {
      contracts,
      editPublishContractFile,
      loading,
      totalCount,
      unpublishContractFile
    } = this.state;
    const engagementContracts: IContractFile[] = contracts;

    const hasContracts = contracts.length > 0;
    const isReadOnlyByUserState = isAppReadOnly || isExecutiveView;

    return (
      <>
        <Card
          className="contract-card"
          role="region"
          title={`${getTextToDisplay('dataAndFiles.tabs.contracts')} ${
            hasContracts ? `(${contracts.length}/${totalCount})` : ''
          }`}
        >
          <Button
            className="contract-card__view-all"
            onClick={() => {
              const { history, engagement, initializeEventToTrack } =
                this.props;

              initializeEventToTrack({
                eventName: amplitudeEventDetail.ryanContracts.eventName,
                eventProperty:
                  amplitudeEventDetail.ryanContracts.viewPropertyOptions
                    .singleProjectFiles
              });

              history.push(
                `/app/data-and-files/contracts#accountGuid=${engagement.accountGuid}&engagementGuid=${engagement.engagementGuid}`
              );
            }}
            size="sm"
            text={getTextToDisplay('View All')}
            variant="text"
          />
          {loading ? (
            <Icon className="loading-spin" name="loading" />
          ) : !engagementContracts.length ? (
            <Empty icon="file">
              {getTextToDisplay('contracts.files.empty')}
            </Empty>
          ) : (
            <div className="contract-card">
              <table>
                <tbody>
                  {engagementContracts.map(contractFile => {
                    const tooltipList: { label: string; value: string }[] = [];

                    if (permissionService.isRyan()) {
                      tooltipList.push({
                        label: getTextToDisplay('Original File Name'),
                        value: contractFile.originalName || '–'
                      });
                    }

                    tooltipList.push({
                      label: getTextToDisplay('File Type'),
                      value: contractFile.fileType
                    });

                    if (permissionService.isRyan()) {
                      tooltipList.push({
                        label: getTextToDisplay('Status'),
                        value: contractFile.status
                      });
                    }

                    tooltipList.push({
                      label: getTextToDisplay('contracts.modal.executeddate'),
                      value: contractFile.executedDate
                        ? formatDate(contractFile.executedDate)
                        : '–'
                    });

                    return (
                      <tr key={contractFile.engagementContractDocumentGuid}>
                        <td>
                          <div className="bs bs--light break-word-wrap">
                            <b>
                              {getContractFileName(
                                getTextToDisplay,
                                contractFile
                              )}
                            </b>
                            {/* Only the display name shows for external users, Ryan also sees the original name */}
                            {permissionService.isRyan() && (
                              <small>{contractFile.originalName}</small>
                            )}
                          </div>
                        </td>
                        <td>
                          <TooltipList list={tooltipList} placement="top" />
                        </td>
                        <td>
                          {permissionService.isRyan() ? (
                            <ContractFileOverflowMenu
                              contractFile={contractFile}
                              isEngagementGhosting={engagement.isUserGhosted}
                              isEngagementReadOnly={engagement.isReadOnly}
                              isReadOnlyByUserState={isReadOnlyByUserState}
                              menuPlacement="bottom"
                              onDownload={() => {
                                this.handleDownloadContract(contractFile);
                              }}
                              onEdit={() => {
                                this.setState({
                                  editPublishContractFile: contractFile
                                });
                              }}
                              onPublish={() => {
                                this.handlePublish({
                                  contractFileToPublish: contractFile,
                                  getTextToDisplayCallback: getTextToDisplay,
                                  handleRefreshCallback: this.fetchContracts
                                });
                              }}
                              onUnpublish={() => {
                                this.setState({
                                  unpublishContractFile: contractFile
                                });
                              }}
                              overflowButtonSize={ButtonSizeEnums.MEDIUM}
                            />
                          ) : (
                            <Button
                              aria-label={getTextToDisplay(
                                'contracts.files.download',
                                { name: contractFile.name }
                              )}
                              disabled={
                                isReadOnlyByUserState ||
                                engagement.isUserGhosted
                              }
                              icon="download"
                              onClick={() => {
                                this.handleDownloadContract(contractFile);
                              }}
                              variant="text"
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </Card>

        {editPublishContractFile && (
          <ContractFileEditPublishModal
            contractFile={editPublishContractFile}
            contractStatus={
              Boolean(editPublishContractFile.executedDate)
                ? null
                : ContractFileStatus.Published
            }
            onClose={(updatedContractFile?: IContractFile) => {
              this.setState({ editPublishContractFile: null });

              if (updatedContractFile) {
                this.fetchContracts();
              }
            }}
            open
          />
        )}
        {unpublishContractFile && (
          <ContractFileUnpublishModal
            contractFile={unpublishContractFile}
            onClose={(isUpdated?: boolean) => {
              this.setState({ unpublishContractFile: null });

              if (isUpdated) {
                this.fetchContracts();
              }
            }}
            open
          />
        )}
      </>
    );
  }
}

export default withRouter(
  withAmplitude(withUser(withDownload(withTranslation()(ContractCard))))
);
