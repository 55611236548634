import { useUser } from 'contexts/UserContext';

import { CancelToken } from 'axios';
import classNames from 'classnames';
import ENV from 'env';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, ButtonGroup, pushToast } from '@ryan/components';

import { useStateMounted } from '../../hooks';
import ApiService from '../../services/ApiService';
import { NewUserRequestStatusEnums } from '../../utils/enums/NewUserRequestStatusEnums';
import { formatDate } from '../../utils/formatDate';
import pushServerErrorToast from '../../utils/pushServerErrorToast';
import Modal from '../Modal';
import SelectLanguage from '../SelectLanguage';
import {
  IHandleLanguageChange,
  IHandleRequestAccess,
  IHandleViewNewUserRequest,
  IRenderDeniedContent,
  IRenderModalLoading,
  IRenderPendingContent,
  IRenderRequestContent,
  IRenderSuccessToastContent,
  IUserConversionModalProps,
  TGetNewUserRequestDetails,
  TUpdatedNewUserRequestDetails,
  formatToUserConversionModal
} from './utils';

import './UserConversionModal.scss';

const UserConversionModal: React.FC<IUserConversionModalProps> = ({
  accountGuid,
  onClose,
  user
}) => {
  const { user: loggedInUser } = useUser();
  const history = useHistory();
  const location = useLocation();
  const [isInitialized, setIsInitialized] = useStateMounted(false);
  const [isLoading, setIsLoading] = useStateMounted(false);
  const [submitPromise, setSubmitPromise] =
    useStateMounted<Promise<any> | null>(null);
  const { t: textToDisplay } = useTranslation();

  const [languagePreference, setLanguagePreference] = useState('');
  const [modalTitle, setModalTitle] = useState<string>(
    textToDisplay('userConversionModal.title.requestUserAccess')
  );
  const [newUserRequestDetails, setNewUserRequestDetails] =
    useState<TGetNewUserRequestDetails | null>(null);

  const {
    isActiveUserWithEditAccess,
    isActiveUserWithNewUserApprovalAccess,
    userEmail,
    userFirstName,
    userFullName,
    userGuid,
    userLastName,
    userMemberGuid,
    userNewUserRequestDate,
    userType
  } = formatToUserConversionModal(user);

  const handleGrantAccess = useCallback(
    (updatedNewUserRequestDetails?: TUpdatedNewUserRequestDetails) => {
      if (
        (updatedNewUserRequestDetails &&
          updatedNewUserRequestDetails.newUserStatus !==
            NewUserRequestStatusEnums.NULL) ||
        (newUserRequestDetails &&
          newUserRequestDetails.status !== NewUserRequestStatusEnums.NULL)
      ) {
        history.push(
          `/app/team/review-new-user/${
            updatedNewUserRequestDetails?.userQueueItemGuid ||
            newUserRequestDetails?.queueItemGuid
          }`
        );
      } else {
        history.push({
          pathname: '/app/team/review-new-user',
          state: {
            newUserRequest: {
              accountGuid,
              accountName: user.company,
              activateDate: user.activateDate,
              displayLocation: user.displayLocation || null,
              email: userEmail,
              hasDxpAccess: user.hasDxpAccess,
              firstName: userFirstName,
              fullName: userFullName,
              lastName: userLastName,
              mailingAddress: user.mailingAddress || null,
              minimumNewUserRequestDate: user.minimumNewUserRequestDate || null,
              mobilePhone: user.mobilePhone || null,
              officeAddress: user.officeAddress || null,
              officePhone: user.officePhone || null,
              roleGuid: user.roleGuid || null,
              roleName: user.roleName || null,
              status: null,
              title: user.title || null,
              userGuid: userMemberGuid,
              userType: userType
            },
            previousPath: location.pathname
          }
        });
      }
    },
    [
      accountGuid,
      history,
      location.pathname,
      newUserRequestDetails,
      user,
      userEmail,
      userFirstName,
      userFullName,
      userLastName,
      userMemberGuid,
      userType
    ]
  );

  const initializeWithDeniedOrPending = useCallback(
    (formattedResponseData: TGetNewUserRequestDetails) => {
      setModalTitle(
        textToDisplay(
          `userConversionModal.title.${
            formattedResponseData.status === NewUserRequestStatusEnums.DENIED
              ? 'userAccessDenied'
              : 'userAccessPending'
          }`
        )
      );
      setNewUserRequestDetails(formattedResponseData);
    },
    [setModalTitle, setNewUserRequestDetails, textToDisplay]
  );

  const initializeWithResponseData = useCallback(
    (responseData: TGetNewUserRequestDetails) => {
      const {
        approvedByDate: responseApprovedByDate,
        queueItemGuid: responseQueueItemGuid
      } = responseData;
      const responseNewUserStatus =
        responseData.status || NewUserRequestStatusEnums.NULL;

      if (
        isActiveUserWithNewUserApprovalAccess &&
        responseNewUserStatus === NewUserRequestStatusEnums.PENDING
      ) {
        handleGrantAccess({
          newUserStatus: responseNewUserStatus,
          userQueueItemGuid: responseQueueItemGuid
        });
      } else if (
        responseNewUserStatus === NewUserRequestStatusEnums.APPROVED &&
        responseApprovedByDate !== '0001-01-01T00:00:00+00:00' &&
        new Date(responseApprovedByDate) < new Date()
      ) {
        isActiveUserWithEditAccess
          ? history.push(`/app/team/edit-user/${userGuid || userMemberGuid}`, {
              previousLocationPathname: history.location.pathname
            })
          : history.push(`/app/personal-information/${userMemberGuid}`);
      } else if (
        responseNewUserStatus === NewUserRequestStatusEnums.APPROVED ||
        responseNewUserStatus === NewUserRequestStatusEnums.DENIED ||
        responseNewUserStatus === NewUserRequestStatusEnums.PENDING
      ) {
        initializeWithDeniedOrPending({
          ...responseData,
          status: responseNewUserStatus
        });
      } else if (isActiveUserWithNewUserApprovalAccess) {
        handleGrantAccess();
      }
    },
    [
      handleGrantAccess,
      history,
      initializeWithDeniedOrPending,
      isActiveUserWithEditAccess,
      isActiveUserWithNewUserApprovalAccess,
      userGuid,
      userMemberGuid
    ]
  );

  const initialize = useCallback(
    async (cancelToken: CancelToken) => {
      setIsLoading(true);

      try {
        const response = await ApiService.getNewUserRequestDetails(
          userMemberGuid,
          cancelToken
        );

        if (response.data) {
          setIsInitialized(true);
          initializeWithResponseData(response.data);
          setIsLoading(false);
        } else {
          throw new Error('response data missing');
        }
      } catch (error) {
        pushServerErrorToast();
        onClose(false);
      }
    },
    [
      initializeWithResponseData,
      onClose,
      setIsInitialized,
      setIsLoading,
      userMemberGuid
    ]
  );

  useEffect(() => {
    const cancelTokenSource = ApiService.CancelToken.source();

    if (isInitialized) {
      return;
    }

    initialize(cancelTokenSource.token);

    return () => {
      cancelTokenSource.cancel();
    };
  }, [initialize, isInitialized]);

  const handleLanguageChange: IHandleLanguageChange = event => {
    setLanguagePreference(event.target.value);
  };

  const handleRequestAccess: IHandleRequestAccess = async ({
    newUserRequestData,
    onClose,
    renderToastContent,
    setSubmitPromise,
    successToastTitle
  }) => {
    try {
      const submitPromise = ApiService.requestNewUser(newUserRequestData);
      setSubmitPromise(submitPromise);

      const { status: submitPromiseStatus } = await submitPromise;
      submitPromiseStatus === 200
        ? pushToast({
            content: renderToastContent(),
            title: successToastTitle,
            type: 'success'
          })
        : pushServerErrorToast();
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushServerErrorToast();
      }
    } finally {
      setSubmitPromise(null);
      onClose(true);
    }
  };

  const handleViewNewUserRequest: IHandleViewNewUserRequest = () => {
    history.push('/app/team/manage/newusers');
  };

  const renderDeniedContent: IRenderDeniedContent = ({
    handleGrantAccess,
    handleRequestAccess,
    handleViewNewUserRequest,
    isActiveUserWithNewUserApprovalAccess,
    latestDenialDetails,
    loggedInUser,
    newUserRequestData,
    onClose,
    renderSuccessToastContent,
    requesterFullName,
    requesterUserGuid,
    setSubmitPromise,
    submitPromise,
    textToDisplay,
    userFullName
  }) => {
    const deniedContent = textToDisplay(
      `userConversionModal.content.fromDeniedTo${
        isActiveUserWithNewUserApprovalAccess ? 'Approve' : 'Request'
      }`,
      {
        deniedBy:
          latestDenialDetails?.deniedBy?.userGuid === loggedInUser.userGuid
            ? textToDisplay('You').toLowerCase()
            : latestDenialDetails?.deniedBy?.fullName || '',
        deniedDate: formatDate(
          latestDenialDetails?.deniedDate || new Date().toISOString()
        ),
        name: userFullName,
        requester:
          loggedInUser.userGuid === requesterUserGuid
            ? textToDisplay('You').toLowerCase()
            : requesterFullName,
        ryanPlatform: ENV.RYAN_PLATFORM
      }
    );
    const renderSuccessToastContentArguments = {
      buttonDisplayText: textToDisplay(
        'userConversionModal.successToast.viewRequest'
      ),
      handleViewNewUserRequest,
      successToastContent: textToDisplay(
        'userConversionModal.successToast.content',
        {
          name: userFullName,
          ryanPlatform: ENV.RYAN_PLATFORM
        }
      )
    };

    const handleRequestAccessArguments = {
      newUserRequestData,
      onClose,
      renderToastContent: () =>
        renderSuccessToastContent(renderSuccessToastContentArguments),
      setSubmitPromise,
      successToastTitle: textToDisplay('userConversionModal.successToast.title')
    };

    return (
      <React.Fragment>
        {deniedContent}

        <ButtonGroup>
          <Button
            disabled={!!submitPromise}
            loading={submitPromise}
            onClick={() => {
              isActiveUserWithNewUserApprovalAccess
                ? handleGrantAccess()
                : // HACK: TS expects element from renderToastContent
                  handleRequestAccess(handleRequestAccessArguments as any);
            }}
            variant="primary"
          >
            {textToDisplay(
              `userConversionModal.${
                isActiveUserWithNewUserApprovalAccess
                  ? 'grantAccess'
                  : 'requestAgain'
              }`
            )}
          </Button>
          <Button
            disabled={!!submitPromise}
            onClick={() => {
              onClose(false);
            }}
            variant="secondary"
          >
            {textToDisplay('userConversionModal.cancel')}
          </Button>
        </ButtonGroup>
      </React.Fragment>
    );
  };

  const renderModalLoading: IRenderModalLoading = () => {
    return (
      <div>
        <div
          className="ry-skeleton sk-title"
          style={{ margin: '0 auto 1.5rem' }}
        />
        <div className="ry-skeleton" style={{ marginBottom: '.5rem' }} />
        <div
          className="ry-skeleton"
          style={{ marginBottom: '.5rem', width: '85%' }}
        />
        <div
          className="ry-skeleton"
          style={{ marginBottom: '.5rem', width: '75%' }}
        />
      </div>
    );
  };

  const renderPendingContent: IRenderPendingContent = ({
    buttonDisplayText,
    onClose,
    pendingContent
  }) => {
    return (
      <React.Fragment>
        {pendingContent}

        <ButtonGroup>
          <Button
            onClick={() => {
              onClose(false);
            }}
            variant="primary"
          >
            {buttonDisplayText}
          </Button>
        </ButtonGroup>
      </React.Fragment>
    );
  };

  const renderRequestContent: IRenderRequestContent = ({
    handleLanguageChange,
    handleRequestAccess,
    handleViewNewUserRequest,
    languagePreference,
    newUserRequestData,
    onClose,
    renderSuccessToastContent,
    setSubmitPromise,
    submitPromise,
    textToDisplay,
    userFullName
  }) => {
    const textToDisplayDetail = {
      name: userFullName,
      ryanPlatform: ENV.RYAN_PLATFORM
    };

    const renderSuccessToastContentArguments = {
      buttonDisplayText: textToDisplay(
        'userConversionModal.successToast.viewRequest'
      ),
      handleViewNewUserRequest,
      successToastContent: textToDisplay(
        'userConversionModal.successToast.content',
        textToDisplayDetail
      )
    };

    return (
      <React.Fragment>
        {textToDisplay(
          'userConversionModal.content.request',
          textToDisplayDetail
        )}

        <SelectLanguage
          label={textToDisplay('userConversionModal.welcomeEmailLanguage')}
          onChange={event => {
            handleLanguageChange(event);
          }}
          value={languagePreference}
        />

        <ButtonGroup>
          <Button
            disabled={!languagePreference || !!submitPromise}
            loading={submitPromise}
            onClick={() => {
              handleRequestAccess({
                newUserRequestData: {
                  ...newUserRequestData,
                  languagePreference
                },
                onClose,
                // HACK: TS expects Element and not JSX.Element
                renderToastContent: () =>
                  renderSuccessToastContent(
                    renderSuccessToastContentArguments
                  ) as any,
                setSubmitPromise,
                successToastTitle: textToDisplay(
                  'userConversionModal.successToast.title'
                )
              });
            }}
            variant="primary"
          >
            {textToDisplay('userConversionModal.request')}
          </Button>
          <Button
            disabled={!!submitPromise}
            onClick={() => {
              onClose(false);
            }}
            variant="secondary"
          >
            {textToDisplay('userConversionModal.cancel')}
          </Button>
        </ButtonGroup>
      </React.Fragment>
    );
  };

  const renderSuccessToastContent: IRenderSuccessToastContent = ({
    buttonDisplayText,
    handleViewNewUserRequest,
    successToastContent
  }) => {
    return (
      <React.Fragment>
        {successToastContent}

        <div>
          <Button
            onClick={handleViewNewUserRequest}
            role="link"
            size="sm"
            style={{ margin: '1rem 0 .5rem', padding: 0 }}
            variant="text"
          >
            {buttonDisplayText}
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <Modal
      className={classNames('user-conversion-modal')}
      onClose={() => {
        onClose(false);
      }}
      open
      title={isLoading ? '' : modalTitle}
    >
      {isLoading && renderModalLoading()}

      {!isLoading && newUserRequestDetails && (
        <React.Fragment>
          {newUserRequestDetails.status ===
            NewUserRequestStatusEnums.APPROVED &&
            renderPendingContent({
              buttonDisplayText: textToDisplay('userConversionModal.close'),
              onClose,
              pendingContent: textToDisplay(
                'userConversionModal.content.pendingDeferred',
                {
                  activateDate: formatDate(
                    newUserRequestDetails.approvedByDate
                  ),
                  approvalDate:
                    (newUserRequestDetails.approvedOnDate &&
                      formatDate(newUserRequestDetails.approvedOnDate)) ||
                    '',
                  approver:
                    newUserRequestDetails?.approvedBy?.userGuid ===
                    loggedInUser.profile.userGuid
                      ? textToDisplay('You').toLowerCase()
                      : newUserRequestDetails?.approvedBy?.fullName || '',
                  name: userFullName,
                  requester:
                    newUserRequestDetails?.requester?.userGuid ===
                    loggedInUser.profile.userGuid
                      ? textToDisplay('You').toLowerCase()
                      : newUserRequestDetails?.requester?.fullName || '',
                  ryanPlatform: ENV.RYAN_PLATFORM
                }
              )
            })}

          {newUserRequestDetails.status === NewUserRequestStatusEnums.DENIED &&
            renderDeniedContent({
              handleGrantAccess,
              handleRequestAccess,
              handleViewNewUserRequest,
              isActiveUserWithNewUserApprovalAccess,
              latestDenialDetails:
                newUserRequestDetails.denials &&
                newUserRequestDetails.denials[0],
              loggedInUser: loggedInUser.profile,
              newUserRequestData: {
                accountGuid,
                email: userEmail,
                firstName: userFirstName,
                languagePreference,
                lastName: userLastName,
                memberGuid: userMemberGuid,
                userType
              },
              onClose,
              renderSuccessToastContent,
              requesterFullName:
                (newUserRequestDetails.requester &&
                  newUserRequestDetails.requester.fullName) ||
                '',
              requesterUserGuid:
                (newUserRequestDetails.requester &&
                  newUserRequestDetails.requester.userGuid) ||
                '',
              setSubmitPromise,
              submitPromise,
              textToDisplay,
              userFullName
            })}

          {newUserRequestDetails.status === NewUserRequestStatusEnums.PENDING &&
            renderPendingContent({
              buttonDisplayText: textToDisplay('userConversionModal.close'),
              onClose,
              pendingContent: textToDisplay(
                'userConversionModal.content.pending',
                {
                  name: userFullName,
                  requestDate: formatDate(userNewUserRequestDate),
                  requester:
                    newUserRequestDetails?.requester?.userGuid ===
                    loggedInUser.profile.userGuid
                      ? textToDisplay('You').toLowerCase()
                      : newUserRequestDetails?.requester?.fullName || '',
                  ryanPlatform: ENV.RYAN_PLATFORM
                }
              )
            })}
        </React.Fragment>
      )}

      {!isLoading &&
        !newUserRequestDetails &&
        renderRequestContent({
          handleLanguageChange,
          handleRequestAccess,
          handleViewNewUserRequest,
          languagePreference,
          newUserRequestData: {
            accountGuid,
            email: userEmail,
            firstName: userFirstName,
            lastName: userLastName,
            memberGuid: userMemberGuid,
            userType
          },
          onClose,
          renderSuccessToastContent,
          setSubmitPromise,
          submitPromise,
          textToDisplay,
          userFullName
        })}
    </Modal>
  );
};

export default UserConversionModal;
