import DocumentTitle from 'components/DocumentTitle';
import { IButtonLoadingState, IHelpFormRequest } from 'interfaces';
import ApiService from 'services/ApiService';
import { Formik, formikFieldProps, yup } from 'utils/forms';
import useHelpSubjectOptions from 'utils/useHelpSubjectOptions';

import ENV from 'env';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  Dropdown,
  Icon,
  TextInput,
  Textarea,
  pushToast
} from '@ryan/components';

import './HelpForm.scss';

const initialValues: IHelpFormRequest = {
  name: '',
  email: '',
  subject: '',
  message: ''
};
const maxLengthMessage = 2048;

class HelpForm extends Component<WithTranslation, IButtonLoadingState> {
  readonly state = { loading: null };

  private schema: yup.ObjectSchema<IHelpFormRequest>;

  constructor(props: WithTranslation) {
    super(props);

    const { t } = this.props;
    this.schema = yup.object({
      name: yup
        .string()
        .required(t('helpForm.fields.name.validation.required')),
      email: yup
        .string()
        .required(t('helpForm.fields.email.validation.required'))
        .email(t('helpForm.fields.email.validation.email')),
      subject: yup
        .string()
        .required(t('helpForm.fields.subject.validation.required')),
      message: yup
        .string()
        .required(t('helpForm.fields.message.validation.required'))
        .max(
          maxLengthMessage,
          t('helpForm.fields.message.validation.max', {
            length: maxLengthMessage
          })
        )
    });
  }

  submitForm = (values: IHelpFormRequest) => {
    const promise = ApiService.submitHelpForm(values)
      .then(response => {
        if (response.status === 200) {
          // @todo will push this to the login page once that is in place
          pushToast({
            type: 'success',
            title: 'Your message was sent successfully!',
            content: 'We will be in contact with you soon.'
          });
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          pushToast({
            type: 'error',
            title: `Oh no, something went wrong. Please try again.`
          });
        }
      });
    this.setState({ loading: promise });
  };

  render() {
    const { t } = this.props;
    const { loading } = this.state;

    return (
      <div className="page-help-form">
        <DocumentTitle title={t('Help')} />
        <h1 className="ry-h2">{t('Help')}</h1>
        <p>{t('helpForm.description')}</p>
        <Formik
          initialValues={initialValues}
          onSubmit={this.submitForm}
          validationSchema={this.schema}
        >
          {formik => (
            <form onSubmit={formik.handleSubmit}>
              <TextInput
                {...formikFieldProps('name', formik)}
                label={t('helpForm.fields.name.label')}
                placeholder={t('helpForm.fields.name.placeholder')}
              />
              <TextInput
                {...formikFieldProps('email', formik)}
                label={t('helpForm.fields.email.label')}
                placeholder={t('helpForm.fields.email.placeholder')}
              />
              <Dropdown
                {...formikFieldProps('subject', formik)}
                label={t('helpForm.fields.subject.label')}
                options={useHelpSubjectOptions(t)}
              />
              <Textarea
                {...formikFieldProps('message', formik)}
                label={t('helpForm.fields.message.label')}
                maxLength={maxLengthMessage}
                placeholder={t('helpForm.fields.message.placeholder')}
              />
              <Button
                loading={loading}
                text={t('Submit')}
                type="submit"
                variant="primary"
              />
            </form>
          )}
        </Formik>
        <div className="page-help-form__assistance">
          <h2 className="ry-h3">{t('helpForm.assistance')}</h2>
          <p>
            <a href={`tel:${ENV.CONTACT_PHONE}`}>
              <Icon className="page-help-form__assistance-icon" name="phone" />{' '}
              {ENV.CONTACT_PHONE}
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default withTranslation()(HelpForm);
