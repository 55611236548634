import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button, Card, SplitButton, pushToast } from '@ryan/components';

import Paragraphs from '../../../components/Paragraphs';
import { WithUser, withUser } from '../../../contexts/UserContext';
import { ILearning, Permission } from '../../../interfaces';
import ApiService from '../../../services/ApiService';
import { ButtonSizeEnums } from '../../../utils/enums/ButtonSizeEnums';
import { formatDate } from '../../../utils/formatDate';
import getCommentButtonProps from '../../../utils/getCommentButtonProps';
import LearningDeleteModal from './LearningDeleteModal';
import LearningFiles from './LearningFiles/LearningFiles';
import LearningModal from './LearningModal';

import './LearningCard.scss';

interface ILearningCardProps extends WithTranslation, WithUser {
  isEngagementGhosting: boolean;
  isEngagementReadOnly: boolean;
  learning: ILearning;
  onUpdate: () => void;
  onViewComments: () => void;
}

interface ILearningCardState {
  openEdit: boolean;
  openDelete: boolean;
}

export class LearningCard extends PureComponent<
  ILearningCardProps,
  ILearningCardState
> {
  readonly state = {
    openEdit: false,
    openDelete: false
  };

  handleEdit = () => {
    this.setState({ openEdit: true });
  };

  handleEditClose = (learning: ILearning | null) => {
    if (learning !== null) {
      this.props.onUpdate();
    }

    this.setState({ openEdit: false });
  };

  handleDelete = () => {
    this.setState({ openDelete: true });
  };

  handleDeleteClose = (deleted: boolean) => {
    this.setState({ openDelete: false }, () => {
      if (deleted) {
        this.props.onUpdate();
      }
    });
  };

  handleOnFilesUpdate = () => {
    this.props.onUpdate();
  };

  handleDownloadAllFiles = async () => {
    const { t, learning } = this.props;

    try {
      await ApiService.zipLearningFiles(learning);
      pushToast({
        type: 'success',
        title: t('bulkDownload.success.title'),
        content: t('bulkDownload.success.content')
      });
    } catch {
      pushToast({
        type: 'error',
        title: t('serverError.title'),
        content: t('bulkDownload.error.content')
      });
    }
  };

  render() {
    const {
      activeView: { isExecutiveView },
      isAppReadOnly,
      isEngagementGhosting,
      isEngagementReadOnly,
      learning,
      onViewComments,
      permissionService,
      t: getTextToDisplay
    } = this.props;

    const fileCount = learning.attachments.length;
    const isReadOnlyByEngagementState =
      isEngagementGhosting || isEngagementReadOnly;
    const isReadOnlyByUserState = isAppReadOnly || isExecutiveView;

    return (
      <>
        <Card className="learning-card">
          <div className="learning-card__content">
            <div className="row">
              <div className="col-12 col-md-8">
                <h3 className="ry-h3">{learning.title}</h3>
                <Paragraphs text={learning.description} />
                {(!permissionService.isClient() || fileCount > 0) && (
                  <LearningFiles
                    attachments={learning.attachments}
                    engagementGuid={learning.engagementGuid}
                    isEngagementGhosting={isEngagementGhosting}
                    isEngagementReadOnly={isEngagementReadOnly}
                    learningGuid={learning.learningGuid}
                    onUpdate={this.handleOnFilesUpdate}
                    onUploadFiles={this.handleEdit}
                  />
                )}
              </div>
              <div className="col-12 col-md-4">
                {permissionService.hasPermission(Permission.LearningsEdit) && (
                  <SplitButton
                    block
                    disabled={
                      isReadOnlyByUserState || isReadOnlyByEngagementState
                    }
                    onClick={this.handleEdit}
                    options={[
                      {
                        disabled:
                          isReadOnlyByUserState || isReadOnlyByEngagementState,
                        icon: 'upload',
                        label: getTextToDisplay('Upload Files'),
                        onClick: this.handleEdit
                      },
                      {
                        disabled:
                          isReadOnlyByUserState || isReadOnlyByEngagementState,
                        icon: 'trash',
                        label: getTextToDisplay('Delete'),
                        negative: true,
                        onClick: this.handleDelete
                      }
                    ]}
                    size={ButtonSizeEnums.LARGE}
                    text={getTextToDisplay('learning.edit')}
                  />
                )}
                <div className="well">
                  <ul className="labeled-list row">
                    <li className="col-12 col-sm-6">
                      <label>{getTextToDisplay('Created By')}</label>
                      {permissionService.isUser(learning.createdBy)
                        ? getTextToDisplay('You')
                        : learning.createdByName}
                    </li>
                    <li className="col-12 col-sm-6">
                      <label>{getTextToDisplay('Creation Date')}</label>
                      {formatDate(learning.createDate)}
                    </li>
                    {learning.updateDate && (
                      <li className="col-12 col-sm-6">
                        <label>{getTextToDisplay('Last Modified')}</label>
                        {formatDate(learning.updateDate)}
                      </li>
                    )}
                    {fileCount > 0 && (
                      <li className="col-12 col-sm-6">
                        <label>
                          {getTextToDisplay('learning.attachedFiles', {
                            count: fileCount
                          })}
                        </label>
                        <Button
                          disabled={
                            isReadOnlyByUserState || isEngagementGhosting
                          }
                          onClick={this.handleDownloadAllFiles}
                          variant="link"
                        >
                          {getTextToDisplay('learning.downloadAllFiles')}
                        </Button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="learning-card__footer">
            <Button
              {...getCommentButtonProps(
                getTextToDisplay,
                permissionService.hasPermission(Permission.LearningsContribute),
                {
                  isEngagementReadOnly:
                    isReadOnlyByUserState || isReadOnlyByEngagementState,
                  commentsCount: learning.commentsCount
                },
                isAppReadOnly // TODO: Redundant - Refactor function
              )}
              onClick={onViewComments}
              size="sm"
              variant="text"
            />
          </div>
        </Card>

        <LearningModal
          engagementGuid={learning.engagementGuid}
          engagementName={learning.engagementDisplayNameShort}
          learning={learning}
          onClose={this.handleEditClose}
          open={this.state.openEdit}
        />

        <LearningDeleteModal
          learning={learning}
          onClose={this.handleDeleteClose}
          open={this.state.openDelete}
        />
      </>
    );
  }
}

export default withTranslation()(withUser(LearningCard));
