import Modal from 'components/Modal';
import { IDataRequest, IUserSummary, Permission, UserType } from 'interfaces';
import ApiService from 'services/ApiService';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Avatar, Button, ButtonGroup, Icon, pushToast } from '@ryan/components';

import { WithUser, withUser } from '../../contexts/UserContext';
import getAvatarUrl from '../../utils/getAvatarUrl';
import { UserAutocomplete } from '../AutocompleteAjax';

import './FollowersModal.scss';

interface IFollowersModalProps extends WithTranslation, WithUser {
  engagementGuid: string;
  instructions?: string;
  onClose: (dataRequest?: IDataRequest) => void;
  onUpdate: (count: number | null) => void;
  open?: boolean;
  queueItemGuid: string;
  title?: string;
  userTypeId: UserType;
}

interface IFollowersModalState {
  users: IUserSummary[];
  loading: boolean;
}

type TFollowers = Omit<IUserSummary, 'firstName' | 'lastName'> & {
  firstName: string;
  hasDxpAccess: boolean;
  lastName: string;
};

class FollowersModal extends Component<
  IFollowersModalProps,
  IFollowersModalState
> {
  constructor(props: IFollowersModalProps) {
    super(props);

    this.state = {
      users: [],
      loading: false
    };
  }

  getQueueItemFollowers = async () => {
    const { engagementGuid, queueItemGuid, t } = this.props;
    this.setState({ loading: true });

    try {
      const allowedToReadDataRequests =
        this.props.permissionService?.hasPermission(
          Permission.DataRequestsView
        );

      const response = allowedToReadDataRequests
        ? await ApiService.getQueueItemFollowers(engagementGuid, queueItemGuid)
        : await ApiService.getQueueItemFollowersRestricted(
            engagementGuid,
            queueItemGuid
          );
      this.setState({ users: response.data });
    } catch (error) {
      pushToast({
        type: 'error',
        title: t('serverError.title'),
        content: t('serverError.content')
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleRemoveFollower(follower: IUserSummary) {
    const updated = this.state.users.filter(u => u !== follower);
    this.setState({ users: updated });
  }

  handleFetchUsersFilter = (users: IUserSummary[]) => {
    const { users: followers } = this.state;
    return users.filter(
      user =>
        user.userGuid &&
        !followers.some(follower => follower.userGuid === user.userGuid)
    );
  };

  handleChangeUser = (user: IUserSummary | null) => {
    if (user) {
      const updated = [...this.state.users, user];
      this.setState({ users: updated });
    }
  };

  handleSaveFollowers = async () => {
    this.setState({ loading: true });
    const { engagementGuid, queueItemGuid } = this.props;

    try {
      const followers = this.state.users.map(u => u.userGuid);

      await ApiService.updateQueueItemFollowers(
        engagementGuid,
        queueItemGuid,
        followers
      );

      const count = followers.length;
      this.props.onUpdate(count);
    } catch (error) {
      // Handle error...
      const { t } = this.props;
      pushToast({
        type: 'error',
        title: t('serverError.title'),
        content: t('serverError.content')
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  async componentDidMount() {
    if (this.props.open) {
      this.getQueueItemFollowers();
    }
  }

  async componentDidUpdate(prevProps: IFollowersModalProps) {
    const { open } = this.props;

    if (prevProps.open !== open && open) {
      this.getQueueItemFollowers();
    }
  }

  render() {
    const {
      instructions = this.props.t('followersModal.instructions'),
      onClose,
      open = true,
      t,
      engagementGuid,
      title = this.props.t('Followers'),
      userTypeId
    } = this.props;
    const { loading, users } = this.state;

    return ReactDOM.createPortal(
      <Modal
        className="followers-modal"
        onClose={() => onClose()}
        open={open}
        title={title}
      >
        <p className="instructions">{instructions}</p>

        <UserAutocomplete
          disabled={loading}
          engagementGuid={engagementGuid}
          label={t('followersModal.helperText')}
          onChange={this.handleChangeUser}
          transformResponse={this.handleFetchUsersFilter}
          value={null}
        />

        {loading ? (
          <div className="center-icon">
            <Icon className="loading-spin" name="loading" />
          </div>
        ) : (
          <div className="user-list">
            <h3 className="ry-h3">{`${t('Followers')} (${users.length})`}</h3>
            {(users as TFollowers[]).map(user => (
              <div className="user-list-row" key={user.userGuid}>
                <div className="user-list-row__avatar">
                  <Avatar
                    {...(!user.hasDxpAccess && { variant: 'inverse' })}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    profileImageSrc={getAvatarUrl({
                      avatarUrl: user.avatarUrl,
                      userAvatarDocumentGuid: user.userAvatarDocumentGuid
                    })}
                  />
                </div>
                <div className="user-list-row__name">
                  <span className="user-list-row__user-name">
                    {`${user.firstName} ${user.lastName}`}
                  </span>
                </div>
                <div className="user-list-row__action">
                  {(userTypeId === UserType.Ryan ||
                    userTypeId === user.userTypeId) && (
                    <Button
                      ariaLabel={t('followersModal.remove')}
                      className="button-margins"
                      icon="trash"
                      onClick={() => this.handleRemoveFollower(user)}
                      size="sm"
                      variant="text"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        <ButtonGroup>
          <Button
            onClick={this.handleSaveFollowers}
            text={t('Save')}
            variant="primary"
          />
          <Button
            onClick={() => onClose()}
            text={t('Cancel')}
            variant="secondary"
          />
        </ButtonGroup>
      </Modal>,
      document.body
    );
  }
}

export default withUser(withTranslation()(FollowersModal));
