import classnames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Button, Card } from '@ryan/components';

import { WithUser, withUser } from '../../contexts/UserContext';
import {
  Feature,
  IDataRequest,
  Permission,
  Status,
  UserType
} from '../../interfaces';
import ApiService from '../../services/ApiService';
import { TDateRange } from '../../types';
import { ButtonSizeEnums } from '../../utils/enums/ButtonSizeEnums';
import { ButtonVariantEnums } from '../../utils/enums/ButtonVariantEnums';
import { DataRequestTypeGuidEnum } from '../../utils/enums/DataRequestsEnums';
import { SubStatusEnums } from '../../utils/enums/SubStatusEnums';
import { formatDate } from '../../utils/formatDate';
import getCommentButtonProps from '../../utils/getCommentButtonProps';
import { isDataRequestPastDue } from '../../utils/isPastDue';
import pushServerErrorToast from '../../utils/pushServerErrorToast';
import redirectToDecisionsApp from '../../utils/redirectToDecisions';
import DataRequestActions from '../DataRequestActions/DataRequestActions';
import DataRequestFields from '../DataRequestFields/DataRequestFields';
import DataRequestProgressSummaryCard from '../DataRequestProgressSummaryCard/DataRequestProgressSummaryCard';
import FollowersModal from '../FollowersModal/FollowersModal';
import RecordsModal from '../RecordsModal/RecordsModal';
import StatusIcon from '../StatusIcon/StatusIcon';

import './DataRequestCard.scss';

export interface IDataRequestCardProps {
  dataRequest: IDataRequest;

  /**
   * Used to hide the actions if this card is being displayed in a table
   * that already shows the actions.
   */
  hideActions?: boolean;
  /**
   * Called when the data request is updated.
   */
  onOpenCodeNotes?: () => void;
  onOpenComments: () => void;
  onOpenHistory: () => void;
  onUpdate: () => void;
}

type IDataRequestCardHOCProps = WithUser &
  WithTranslation &
  RouteComponentProps;

interface IDataRequestCardState {
  isDateRangesModalOpen: boolean;
  isDocumentTypesModalOpen: boolean;
  isJurisdictionsModalOpen: boolean;
  openFollowersModal: boolean;
}

// Assumes Permission.DataRequestsView
class DataRequestCard extends Component<
  IDataRequestCardProps & IDataRequestCardHOCProps,
  IDataRequestCardState
> {
  constructor(props: IDataRequestCardProps & IDataRequestCardHOCProps) {
    super(props);
    this.state = {
      isDateRangesModalOpen: false,
      isDocumentTypesModalOpen: false,
      isJurisdictionsModalOpen: false,
      openFollowersModal: false
    };
  }

  handleWatchClick = async () => {
    const { dataRequest, onUpdate } = this.props;
    try {
      await ApiService.addWatcherToQueue(
        dataRequest.engagementGuid,
        !dataRequest.isCurrentUserWatching,
        dataRequest.queueItemGuid
      );
      onUpdate();
    } catch {
      pushServerErrorToast();
    }
  };

  handleFollowersModalClose = () => {
    this.setState({ openFollowersModal: false });
  };

  handleFollowersModalOpen = () => {
    this.setState({ openFollowersModal: true });
  };

  handleFollowersModalUpdate = () => {
    this.props.onUpdate();
    this.handleFollowersModalClose();
  };

  render() {
    const {
      dataRequest,
      hideActions,
      isAppReadOnly,
      isFeatureToggled,
      history,
      onOpenCodeNotes,
      onOpenComments,
      onOpenHistory,
      onUpdate,
      permissionService,
      t: getTextToDisplay,
      user
    } = this.props;

    const {
      assignedToName,
      assignedToSSWMUserName,
      assignedToUserGuid,
      codeAssistance,
      completedDate,
      createdBy,
      createdByName,
      createDate,
      dataRequestType,
      databaseName,
      dateRange,
      documentTypes,
      dueDate,
      engagementDisplayNameShort,
      engagementGuid,
      externalLinkAccess,
      isCurrentUserWatching,
      isEngagementReadOnly,
      isRequireCodeAssistance,
      isUserGhosted,
      jurisdictions,
      progress,
      queueItemGuid,
      serverName,
      statusId,
      subStatus,
      subStatusId,
      title,
      transferredFilesCount,
      watcherCount
    } = dataRequest;

    const viewHistory = (
      <Button
        icon="time"
        onClick={onOpenHistory}
        size="sm"
        text={getTextToDisplay('View History')}
        variant="text"
      />
    );

    const addCodeNotes = (
      count: number,
      isGhosted: boolean,
      onClickCallback: () => void
    ): JSX.Element => (
      <Button
        icon={count === 0 || isGhosted ? 'comment' : 'comment-alert'}
        onClick={onClickCallback}
        size={ButtonSizeEnums.SMALL}
        text={getTextToDisplay(
          count || isGhosted ? 'notesWithCode' : 'addNotesForCode',
          {
            count
          }
        )}
        variant={ButtonVariantEnums.TEXT}
      />
    );

    const addComment = (
      <Button
        {...getCommentButtonProps(
          getTextToDisplay,
          permissionService.hasPermission(Permission.DataRequestsContribute),
          dataRequest,
          isAppReadOnly
        )}
        data-testid="comment-button"
        disabled={isUserGhosted}
        onClick={onOpenComments}
        size="sm"
        variant="text"
      />
    );

    const isDocumentImagesType =
      dataRequestType?.dataRequestTypeGuid ===
      DataRequestTypeGuidEnum.DOCUMENT_IMAGES;

    const isERPType =
      dataRequestType?.dataRequestTypeGuid === DataRequestTypeGuidEnum.ERP_DATA;

    const isDataRequestsUpdateVisible = isFeatureToggled(
      Feature.RWMDataRequests
    );

    return (
      <>
        <Card
          className={classnames(`data-request-card`, {
            'data-request-card--pastdue': isDataRequestPastDue(dataRequest)
          })}
        >
          <div className="data-request-card__content">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-7">
                <DataRequestFields
                  dataRequest={dataRequest}
                  handleDateRangesModalOpen={() =>
                    this.setState({ isDateRangesModalOpen: true })
                  }
                  handleDocumentTypesModalOpen={() =>
                    this.setState({ isDocumentTypesModalOpen: true })
                  }
                  handleJurisdictionsModalOpen={() =>
                    this.setState({ isJurisdictionsModalOpen: true })
                  }
                />
                <div className="data-request-card__comment-tablet">
                  <hr />
                  <div className="data-request-card__comment-tablet-flex">
                    <div className="data-request-card__comment-tablet-flex__code-notes-button">
                      {isDataRequestsUpdateVisible &&
                        dataRequest &&
                        onOpenCodeNotes &&
                        isRequireCodeAssistance &&
                        (isERPType || isDataRequestsUpdateVisible) &&
                        user.profile.userTypeId === UserType.Ryan &&
                        addCodeNotes(
                          dataRequest.codeNotesCount!,
                          dataRequest.isUserGhosted,
                          onOpenCodeNotes
                        )}
                    </div>
                    <div className="data-request-card__comment-tablet-flex__action-buttons">
                      {viewHistory}
                      {addComment}
                    </div>
                  </div>
                </div>
              </div>
              <div className="data-request-card__aside col-12 col-md-4 offset-lg-1">
                {isDataRequestsUpdateVisible &&
                  isERPType &&
                  isRequireCodeAssistance &&
                  progress && (
                    <DataRequestProgressSummaryCard progressId={progress} />
                  )}
                {!hideActions &&
                  (permissionService.hasPermission(
                    Permission.DataRequestsEdit
                  ) ? (
                    <DataRequestActions
                      dataRequest={dataRequest}
                      onUpdate={onUpdate}
                      size="lg"
                    />
                  ) : (
                    <Button
                      block
                      data-testid="navigate-to-data-request-button"
                      disabled={isUserGhosted}
                      onClick={() => {
                        if (isDataRequestsUpdateVisible) {
                          redirectToDecisionsApp();
                        } else {
                          history.push(
                            `/app/data-request/${dataRequest.queueItemGuid}`
                          );
                        }
                      }}
                      size="lg"
                      text={getTextToDisplay(
                        permissionService.hasPermission(
                          Permission.DataRequestsContribute
                        ) &&
                          !isEngagementReadOnly &&
                          !isAppReadOnly &&
                          statusId !== Status.Complete
                          ? 'Transfer Data'
                          : 'View'
                      )}
                    />
                  ))}

                <div className="well">
                  {Object.values(Status).includes(statusId) && (
                    <div className="well__status">
                      <StatusIcon
                        size={ButtonSizeEnums.LARGE}
                        status={statusId}
                      />
                      {isDataRequestsUpdateVisible &&
                        Object.values(SubStatusEnums).includes(subStatusId) && (
                          <>
                            <span className="well__status__dash">&mdash;</span>
                            <span>{subStatus}</span>
                          </>
                        )}
                    </div>
                  )}

                  <hr />
                  <ul className="row labeled-list">
                    <li className="col-12 col-sm-6">
                      <label>
                        {getTextToDisplay('dataRequest.columns.assignedTo')}
                      </label>
                      {permissionService.isUser(assignedToUserGuid)
                        ? getTextToDisplay('You')
                        : isDataRequestsUpdateVisible &&
                          isRequireCodeAssistance &&
                          assignedToSSWMUserName
                        ? assignedToSSWMUserName
                        : assignedToName}
                    </li>
                    {dueDate && (
                      <li className="col-12 col-sm-6">
                        <label>
                          {getTextToDisplay('dataRequest.columns.dueDate')}
                        </label>
                        <span className="duedate">{formatDate(dueDate)}</span>
                      </li>
                    )}
                    <li className="col-12 col-sm-6">
                      <label>
                        {getTextToDisplay('dataRequest.columns.createdByName')}
                      </label>
                      {permissionService.isUser(createdBy)
                        ? getTextToDisplay('You')
                        : createdByName}
                    </li>
                    {createDate && (
                      <li className="col-12 col-sm-6">
                        <label>
                          {getTextToDisplay('dataRequest.columns.createDate')}
                        </label>
                        {formatDate(createDate)}
                      </li>
                    )}
                    {isDataRequestsUpdateVisible &&
                      isRequireCodeAssistance &&
                      (isERPType || isDataRequestsUpdateVisible) &&
                      codeAssistance && (
                        <li className="col-12 col-sm-6">
                          <label>
                            {getTextToDisplay(
                              'dataRequest.dataFields.codeAssistance'
                            )}
                          </label>
                          {codeAssistance}
                        </li>
                      )}
                    {!isEngagementReadOnly && !isAppReadOnly && (
                      <li className="col-12 col-sm-6">
                        <label>{getTextToDisplay('Followers')}</label>
                        <div className="data-request-card__follow-action-container">
                          <Button
                            onClick={this.handleWatchClick}
                            variant="link"
                          >
                            {getTextToDisplay(
                              isCurrentUserWatching ? 'Unfollow' : 'Follow'
                            )}
                          </Button>
                          <span className="data-request-card__follow-action-container__separator">
                            &#8226;
                          </span>
                          <Button
                            onClick={this.handleFollowersModalOpen}
                            variant="link"
                          >
                            {`${getTextToDisplay(
                              'Followers'
                            )} (${watcherCount})`}
                          </Button>
                        </div>
                      </li>
                    )}
                    {statusId !== Status.Todo && (
                      <li className="col-12 col-sm-6">
                        <label>
                          {getTextToDisplay(
                            'dataRequest.columns.transferredFiles'
                          )}
                        </label>
                        {transferredFilesCount}
                      </li>
                    )}
                    {isDataRequestsUpdateVisible &&
                      isDocumentImagesType &&
                      externalLinkAccess !== null &&
                      isRequireCodeAssistance && (
                        <li className="col-12 col-sm-6">
                          <label>
                            {getTextToDisplay(
                              'dataRequest.dataFields.externalLinkAccess'
                            )}
                          </label>
                          {externalLinkAccess}
                        </li>
                      )}
                    {statusId === Status.Complete && completedDate && (
                      <li className="col-12 col-sm-6">
                        <label>
                          {getTextToDisplay(
                            'dataRequest.columns.completionDate'
                          )}
                        </label>
                        {formatDate(completedDate)}
                      </li>
                    )}
                  </ul>
                </div>
                {isDataRequestsUpdateVisible &&
                  isERPType &&
                  statusId === Status.Complete &&
                  isRequireCodeAssistance &&
                  permissionService.isRyan() &&
                  serverName &&
                  databaseName && (
                    <div className="data-request-card__aside__internal-only">
                      <label className="ry-label">
                        {getTextToDisplay('dataRequest.internalOnly')}
                      </label>
                      <hr />
                      <ul className="row labeled-list">
                        <li className="col-12">
                          <label>
                            {getTextToDisplay(
                              'dataRequest.dataFields.serverName'
                            )}
                          </label>
                          {serverName}
                        </li>
                        <li className="col-12">
                          <label>
                            {getTextToDisplay(
                              'dataRequest.dataFields.databaseName'
                            )}
                          </label>
                          {databaseName}
                        </li>
                      </ul>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="data-request-card__comment-mobile">
            {isDataRequestsUpdateVisible &&
              dataRequest &&
              onOpenCodeNotes &&
              isRequireCodeAssistance &&
              (isERPType || isDataRequestsUpdateVisible) &&
              user.profile.userTypeId === UserType.Ryan && (
                <div className="data-request-card__comment-mobile-border">
                  {addCodeNotes(
                    dataRequest.codeNotesCount!,
                    dataRequest.isUserGhosted,
                    onOpenCodeNotes
                  )}
                </div>
              )}
            <div className="data-request-card__comment-mobile-border">
              {viewHistory}
            </div>
            <div className="data-request-card__comment-mobile-border">
              {addComment}
            </div>
          </div>
        </Card>
        <FollowersModal
          engagementGuid={engagementGuid}
          instructions={getTextToDisplay('followersModal.instructions', {
            context: 'dataRequest'
          })}
          onClose={this.handleFollowersModalClose}
          onUpdate={this.handleFollowersModalUpdate}
          open={this.state.openFollowersModal}
          queueItemGuid={queueItemGuid}
          title={getTextToDisplay('Followers', { context: 'dataRequest' })}
          userTypeId={user.profile.userTypeId}
        />
        {isDataRequestsUpdateVisible && this.state.isDateRangesModalOpen && (
          <RecordsModal
            data={dateRange as TDateRange[]}
            dataRequestTitle={title}
            engagementDisplayName={engagementDisplayNameShort}
            modalTitle={getTextToDisplay('dataRequest.recordsModal.dateRanges')}
            onClose={() => this.setState({ isDateRangesModalOpen: false })}
          />
        )}
        {isDataRequestsUpdateVisible && this.state.isJurisdictionsModalOpen && (
          <RecordsModal
            data={(jurisdictions || []).map(jurisdiction => jurisdiction.name)}
            dataRequestTitle={title}
            engagementDisplayName={engagementDisplayNameShort}
            modalTitle={getTextToDisplay(
              'dataRequest.recordsModal.jurisdictions'
            )}
            onClose={() => this.setState({ isJurisdictionsModalOpen: false })}
          />
        )}
        {isDataRequestsUpdateVisible && this.state.isDocumentTypesModalOpen && (
          <RecordsModal
            data={(documentTypes || []).map(documentType => documentType.name)}
            dataRequestTitle={title}
            engagementDisplayName={engagementDisplayNameShort}
            modalTitle={getTextToDisplay(
              'dataRequest.recordsModal.documentTypes'
            )}
            onClose={() => this.setState({ isDocumentTypesModalOpen: false })}
          />
        )}
      </>
    );
  }
}

export default withRouter(withUser(withTranslation()(DataRequestCard)));
