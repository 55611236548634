import { IEngagementTimeline } from 'interfaces';
import { formatDate } from 'utils/formatDate';

import classnames from 'classnames';
import { TFunction } from 'i18next';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Button, Icon, Tooltip } from '@ryan/components';

import AmplitudeApiService from '../../services/AmplitudeApiService';
import history from '../../services/history';
import ScrollingCalendar from '../ScrollingCalendar/ScrollingCalendar';

import './TimelinesCalendar.scss';

interface ITimelinesCalendarProps {
  t: TFunction;
  loading: boolean;
  engagements: IEngagementTimeline[] | null;
  startDate: Date;
  months: number;
  isAtEnd?: boolean;
  renderCTA?: () => React.ReactNode;
  onBackOneMonth?: () => void;
  onForwardOneMonth?: () => void;
  onShowMore?: () => void;
}

export class TimelinesCalendar extends Component<ITimelinesCalendarProps> {
  render() {
    const { loading, startDate, months, onBackOneMonth, onForwardOneMonth } =
      this.props;

    return (
      <ScrollingCalendar
        loading={loading}
        months={months}
        onBackOneMonth={onBackOneMonth}
        onForwardOneMonth={onForwardOneMonth}
        render={({ isMobile, getOffsets }: any) => {
          const { t, loading, engagements, isAtEnd, renderCTA, onShowMore } =
            this.props;
          const isEmpty = !loading && engagements && engagements.length === 0;
          return (
            <div className="timelines-calendar">
              {/* Data */}
              {isMobile
                ? this.renderMobile()
                : this.renderCalendarRows(getOffsets)}

              {/* Empty State */}
              {isEmpty && (
                <div className="timelines-calendar__empty">
                  <Icon name="calendar" />
                  <br />
                  <div className="timelines-calendar__empty-text">
                    {t('timelinesCalender.empty')}
                  </div>
                </div>
              )}

              {/* CTA */}
              {renderCTA
                ? renderCTA()
                : engagements !== null &&
                  isAtEnd !== undefined && (
                    <div className="timelines-calendar__cta">
                      {isAtEnd === true && (
                        <span className="timelines-calendar__end">
                          {t('timelines.end')}
                        </span>
                      )}
                      {isAtEnd === false && (
                        <Button
                          disabled={loading}
                          onClick={onShowMore}
                          size="lg"
                          text="Show 10 More"
                          variant="primary"
                        />
                      )}
                    </div>
                  )}
            </div>
          );
        }}
        startDate={startDate}
      />
    );
  }

  renderMobile() {
    const { engagements } = this.props;
    return (
      <div className="timelines-calendar__mobile-engagements">
        {engagements === null ? (
          <>
            <div className="ry-skeleton sk-mobile-engagement" />
            <div className="ry-skeleton sk-mobile-engagement" />
            <div className="ry-skeleton sk-mobile-engagement" />
          </>
        ) : (
          engagements.map(engagement => {
            const start = formatDate(engagement.alternateBeginDate);
            const end = formatDate(engagement.projectedEndDate);
            return (
              <Link
                className={classnames({
                  'timelines-calendar__mobile-engagement': true,
                  'timelines-calendar__mobile-engagement--inactive':
                    !engagement.isActive
                })}
                key={engagement.engagementGuid}
                style={{
                  borderColor:
                    engagement.parentPracticeAreaBackgroundColor || '#0e247e'
                }}
                to={`/app/project/${engagement.engagementGuid}`}
              >
                <div className="timelines-calendar__engagement-dates">
                  {start} - {end}
                </div>
                <div className="timelines-calendar__engagement-client-name">
                  {engagement.accountName}
                </div>
                <div className="timelines-calendar__engagement-name">
                  {engagement.engagementDisplayNameShort}
                </div>
                <Icon name="external-link" />
              </Link>
            );
          })
        )}
      </div>
    );
  }

  renderCalendarRows(getOffsets: any) {
    const { engagements, months } = this.props;
    const monthPercent = 100 / months;
    return (
      <div className="timelines-calendar__rows">
        {engagements === null &&
          [
            { left: `${monthPercent * 0.5}%`, right: `${monthPercent * 6.5}%` },
            { left: `${monthPercent * 1.5}%`, right: `${monthPercent * 5.5}%` },
            { left: `${monthPercent * 2.5}%`, right: `${monthPercent * 4.5}%` }
          ].map(({ left, right }, i) => (
            <div className="timelines-calendar__row" key={i}>
              <div
                className="ry-skeleton"
                style={{
                  height: '3.5rem',
                  position: 'absolute',
                  top: '50%',
                  left,
                  right,
                  transform: 'translateY(-50%)'
                }}
              />
            </div>
          ))}

        {engagements &&
          engagements.length > 0 &&
          engagements.map(engagement =>
            this.renderCalendarRow(
              engagement,
              getOffsets(
                engagement.alternateBeginDate,
                engagement.projectedEndDate
              )
            )
          )}
      </div>
    );
  }

  renderCalendarRow(engagement: IEngagementTimeline, offsets: any) {
    if (!offsets.isVisible) {
      return null;
    }

    const { t } = this.props;
    const currentPage = history.location.pathname;

    return (
      <div className="timelines-calendar__row" key={engagement.engagementGuid}>
        <Tooltip
          className="timelines-calendar__toolTip"
          content={
            <div>
              <div className="timelines-calendar__engagement-client-longName">
                {engagement.engagementDisplayNameLong}
              </div>
              <div className="timelines-calendar__engagement-accountName">
                {engagement.accountName}
              </div>
              <div className="well timelines-calendar__engagement__well">
                <ul className="labeled-list row">
                  <li className="col-4">
                    <label className="ry-label">
                      {t('timelinesCalender.kickoff')}
                    </label>
                    {formatDate(engagement.alternateBeginDate)}
                  </li>
                  <li className="col-4">
                    <label className="ry-label">
                      {engagement.isActive
                        ? t('timelinesCalender.estimatedEnd')
                        : t('timelinesCalender.completed')}
                    </label>
                    {formatDate(engagement.projectedEndDate)}
                  </li>
                  <li className="col-4">
                    <label className="ry-label">
                      {t('timelinesCalender.milestones')}
                    </label>
                    {`${engagement.milestonesCompleted}/${engagement.milestonesTotal} `}
                    {t('timelinesCalender.completed')}
                  </li>
                </ul>
              </div>
              {engagement.projectDetails !== null &&
                engagement.projectDetails.length > 0 && (
                  <p className="timelines-calendar__engagement-details">
                    {engagement.projectDetails}
                  </p>
                )}
            </div>
          }
          placement="top"
          renderTarget={({ open, ref, ...props }) => (
            <div
              className={'timelines-calendar__engagement-wrapper'}
              onClick={() =>
                AmplitudeApiService.logEvent('view-timeline', {
                  'timeline-path': `${
                    currentPage.includes('/app/projects/overview')
                      ? 'all projects'
                      : 'dashboard'
                  } - timeline`
                })
              }
            >
              <Link
                aria-expanded={open}
                aria-haspopup="true"
                className={classnames({
                  'timelines-calendar__engagement': true,
                  'timelines-calendar__engagement--ol':
                    offsets.isOverflowingLeft,
                  'timelines-calendar__engagement--or':
                    offsets.isOverflowingRight,
                  'timelines-calendar__engagement--inactive':
                    !engagement.isActive
                })}
                innerRef={ref}
                style={{
                  left: `${offsets.left}%`,
                  right: `${offsets.right}%`,
                  borderColor:
                    engagement.parentPracticeAreaBackgroundColor || '#0e247e'
                }}
                to={`/app/project/${engagement.engagementGuid}`}
                {...props}
              >
                <div className="timelines-calendar__engagement-client-name">
                  {engagement.accountName}
                </div>
                <div className="timelines-calendar__engagement-name">
                  {engagement.engagementDisplayNameShort}
                </div>
                <Icon name="external-link" />
              </Link>
            </div>
          )}
        />
      </div>
    );
  }
}

export default TimelinesCalendar;
