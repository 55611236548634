import Empty from 'components/Empty';
import Table from 'components/Table';
import TableEmpty from 'components/TableEmpty/TableEmpty';
import { WithUser, withUser } from 'contexts/UserContext';
import {
  EngagementStatus,
  IContract,
  IContractState,
  ISearchTerm
} from 'interfaces';
import debounce from 'lodash.debounce';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import getSortParam from 'utils/getSortParm';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import classNames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ITableColumn,
  Icon,
  makeTableCheckboxFilter
} from '@ryan/components';

import './ContractFolders.scss';

interface IContractFoldersProps extends WithTranslation, WithUser {
  customViewGuid: string;
  searchQuery: string;
  onContractFolderSelected: (contract: IContract) => void;
  onLoading: (isLoading: boolean) => void;
}

// Assumes Permission.ContractsView
export class ContractFolders extends Component<
  IContractFoldersProps,
  IContractState
> {
  private _isMounted = false;
  private fetchContractsDataCancelToken?: CancelTokenSource;

  readonly state = {
    loading: false,
    contracts: [],
    page: 1,
    pageSize: 10,
    totalCount: 0,
    sorted: {
      id: 'engagementDisplayNameLong',
      desc: false
    },
    filtered: {
      status: [`${EngagementStatus.Active}`]
    }
  };

  private columns: any[];

  constructor(props: IContractFoldersProps) {
    super(props);
    const { t, onContractFolderSelected, permissionService: ps } = props;
    const columns: ITableColumn<IContract>[] = [];

    columns.push({
      id: 'engagementDisplayNameLong',
      label: t('contracts.columns.project'),
      sortable: true,
      width: '60%',
      render: (row: IContract) => (
        <Button
          className="bs bs--icon"
          onClick={() => {
            onContractFolderSelected(row);
          }}
          variant="link"
        >
          <Icon name="folder-open" />
          <b>{row.engagementDisplayNameLong}</b>
          <small>{row.accountName}</small>
        </Button>
      )
    });

    if (ps.isRyan()) {
      columns.push({
        id: 'engagementId',
        label: t('contracts.columns.code'),
        sortable: true,
        render: (row: IContract) => (
          <span className="ry-table__td__engagement-id">
            {row.engagementId}
          </span>
        )
      });
    }

    columns.push({
      id: 'status',
      label: t('projectOverview.columns.isActive'),
      render: (row: IContract) => (
        <div className="projects-overview__pills">
          <div className={`pill ${row.isActive ? 'active' : 'inactive'}`}>
            {t(row.isActive ? 'Active' : 'Inactive')}
          </div>
        </div>
      ),
      filter: makeTableCheckboxFilter([
        { value: EngagementStatus.Active.toString(), label: t('Active') },
        { value: EngagementStatus.InActive.toString(), label: t('Inactive') }
      ]),
      filterActive: (value: string[]) => value.length > 0
    });

    columns.push({
      id: 'fileCount',
      label: t('contracts.columns.files'),
      sortable: true,
      render: (row: IContract) => row.fileCount
    });

    columns.push({
      id: 'action',
      label: '',
      render: (contract: IContract) => (
        <Button
          icon="chevron-right"
          onClick={() => {
            onContractFolderSelected(contract);
          }}
          variant="text"
        />
      )
    });
    this.columns = columns;
  }
  componentDidMount() {
    this._isMounted = true;
    this.fetchContractsData();
  }

  componentDidUpdate(prevProps: IContractFoldersProps) {
    // Compare custom view guid
    // Compare search queries
    if (
      prevProps.customViewGuid !== this.props.customViewGuid ||
      prevProps.searchQuery !== this.props.searchQuery
    ) {
      this.debouncedSearch();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.fetchContractsDataCancelToken?.cancel();
  }

  debouncedSearch = debounce(() => {
    this.fetchContractsData({ page: 1 });
  }, 250);

  fetchContractsData(updates?: Partial<IContractState>) {
    const { customViewGuid, searchQuery, onLoading } = this.props;

    this.setState(
      { ...(updates as IContractState), loading: true },
      async () => {
        onLoading(true);

        const { filtered, page, sorted, pageSize } = this.state;

        const params: ISearchTerm = {
          pageNumber: page,
          searchTerm: searchQuery,
          sort: getSortParam(sorted),
          isActive: filtered!.status.length === 1 ? filtered!.status[0] : '',
          itemsPerPage: pageSize
        };

        try {
          this.fetchContractsDataCancelToken?.cancel();
          this.fetchContractsDataCancelToken = ApiService.CancelToken.source();

          const response = await ApiService.getContractFolders(
            customViewGuid,
            params,
            this.fetchContractsDataCancelToken.token
          );

          this.setState({
            loading: false,
            contracts: response.data.results,
            totalCount: response.data.totalResults
          });
        } catch (error) {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
          }
        } finally {
          if (this._isMounted) {
            onLoading(false);
          }
        }
      }
    );
  }

  onSort = (sorted: any) => {
    this.fetchContractsData({ sorted });
  };

  onPage = (page: number, pageSize: number) => {
    this.fetchContractsData({ page, pageSize });
  };

  onFilter = (filtered: Record<string, unknown>) => {
    this.fetchContractsData({ page: 1, filtered });
  };

  render() {
    const { page, pageSize, totalCount, loading, contracts, sorted, filtered } =
      this.state;

    const { permissionService, searchQuery, t } = this.props;

    return (
      <div
        className={classNames('contract-folders-page', {
          'contract-folders-page--is-ryan': permissionService.isRyan()
        })}
      >
        <Table<IContract>
          columns={this.columns}
          data={contracts}
          filtered={filtered}
          loading={loading}
          onFilterChange={this.onFilter}
          onPageChange={this.onPage}
          onSortChange={this.onSort}
          page={page}
          pageSize={pageSize}
          renderEmpty={() => (
            <TableEmpty searchQuery={searchQuery}>
              <Empty icon="file"> {t('contracts.empty')}</Empty>
            </TableEmpty>
          )}
          rowId="engagementGuid"
          sorted={sorted}
          totalCount={totalCount}
        />
      </div>
    );
  }
}

export default withUser(withTranslation()(ContractFolders));
