import { History as IHistory, Location as ILocation } from 'history';
import AmplitudeApiService from 'services/AmplitudeApiService';

import ENV from 'env';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  Checkbox,
  ErrorBoundary,
  pushToast
} from '@ryan/components';

import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import { useStateMounted } from '../../hooks';
import { IRole, IUser, IUserPermission, UserType } from '../../interfaces';
import ApiService from '../../services/ApiService';
import { formatDate } from '../../utils/formatDate';
import * as GetUserInfoUtil from '../../utils/getUserInfo/getUserInfo';
import standardizeUSDate from '../../utils/standardizeUSDate';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import IBreadcrumb from '../Breadcrumbs/IBreadcrumb';
import DocumentTitle from '../DocumentTitle/DocumentTitle';
import DenyUserModal from '../Modal/DenyUserModal/DenyUserModal';
import NewUserProjectPreviewTable from '../NewUserProjectPreviewTable/NewUserProjectPreviewTable';
import NowOrLater from '../NowOrLater/NowOrLater';
import RoleAndPermissions from '../RoleAndPermissions/RoleAndPermissions';
import SelectLanguage from '../SelectLanguage/SelectLanguage';
import UserInfoCard from '../UserInfoCard/UserInfoCard';
import * as IUpdateUserAcess from './IUpdateUserAccess';

import './UpdateUserAccess.scss';

const UpdateUserAccess: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const [userInfo, setUserInfo] = useStateMounted<IUser | null>(null);
  const { t: getTextToDisplay } = useTranslation();
  const { setIsUnsavedChanges } = useUnsavedChanges();

  const mounted = useRef(false);
  const [activateLaterDate, setActivateLaterDate] = useState<Date | null>(null);
  const [customPermissions, setCustomPermissions] = useState<IUserPermission[]>(
    []
  );
  const [isActivateNow, setIsActivateNow] = useState(true);
  const [isConfirmedThirdPartyUser, setIsConfirmedThirdPartyUser] =
    useState(false);
  const [isDenyUserModalOpen, setIsDenyUserModalOpen] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isThirdPartyUser, setIsThirdPartyUser] = useState(false);
  const [languagePreference, setLanguagePreference] = useState<string>('');
  const [minimumNewUserRequestDate, setMinimumNewUserRequestDate] =
    useState<Date | null>(null);
  const [roles, setRoles] = useState<IRole[] | null>(null);
  const [selectedRoleGuid, setSelectedRoleGuid] = useState<string>('');
  const [submitPromise, setSubmitPromise] = useState<ReturnType<
    | typeof ApiService.completeNewUserRequest
    | typeof ApiService.directNewUserRequest
  > | null>(null);

  const breadcrumbs = [
    { label: getTextToDisplay('updateUser.breadcrumb.team'), to: '/app/team' },
    {
      label: getTextToDisplay('updateUser.breadcrumb.manageTeam'),
      to: '/app/team/manage/newusers'
    },
    {
      label: getTextToDisplay('updateUser.breadcrumb.reviewNewUser'),
      to: match.url
    }
  ];
  const isSaveAndCompleteDisabled =
    !(isActivateNow || activateLaterDate) ||
    !languagePreference ||
    !selectedRoleGuid ||
    (isThirdPartyUser && !isConfirmedThirdPartyUser) ||
    submitPromise !== null;
  // HACK: Not able to access match.params.userRequestGuid
  const matchParams: { [key: string]: any } = match.params;
  const title = getTextToDisplay(
    `updateUser.title.review${isThirdPartyUser ? 'ThirdParty' : ''}`
  );

  const isShowDenyOption =
    typeof matchParams.userRequestGuid === 'string' && userInfo?.status !== 5;

  const userNotInSalesforce = userInfo?.status === 2;

  const initializeDates = useCallback(
    (
      userActivateDate: string | null,
      newUserMinimumRequestDate: string | null
    ) => {
      const formattedActivateDate = userActivateDate
        ? standardizeUSDate(userActivateDate)
        : null;

      const formattedMinimumNewUserRequestDate = newUserMinimumRequestDate
        ? standardizeUSDate(new Date(newUserMinimumRequestDate))
        : null;

      const calculatedActivateLaterDate =
        (formattedActivateDate &&
          formattedMinimumNewUserRequestDate &&
          formattedActivateDate < formattedMinimumNewUserRequestDate) ||
        (!formattedActivateDate &&
          formattedMinimumNewUserRequestDate &&
          formattedMinimumNewUserRequestDate > new Date())
          ? formattedMinimumNewUserRequestDate
          : formattedActivateDate;

      setIsActivateNow(
        calculatedActivateLaterDate === null ||
          calculatedActivateLaterDate < new Date()
      );
      setActivateLaterDate(calculatedActivateLaterDate);
      setMinimumNewUserRequestDate(formattedMinimumNewUserRequestDate);
    },
    [setIsActivateNow, setActivateLaterDate, setMinimumNewUserRequestDate]
  );

  const initializeRole = useCallback(
    (userRoleGuid: string | null, responseRoles: IRole[]) => {
      if (userRoleGuid) {
        const role = responseRoles.find(
          (responseRole: IRole) => responseRole.roleGuid === userRoleGuid
        );

        if (role) {
          setSelectedRoleGuid(role.roleGuid);
        }
      }

      setRoles(responseRoles);
    },
    [setRoles]
  );

  const initialize = useCallback(
    (userInfoToView: any, responseRoles: IRole[]) => {
      const {
        accountName: userAccountName,
        activateDate: userActivateDate,
        firstName: userFirstName,
        languagePreference: userLanguagePreference,
        lastName: userLastName,
        minimumNewUserRequestDate: newUserMinimumRequestDate,
        roleGuid: userRoleGuid,
        userType: userTypeId
      } = userInfoToView;

      initializeDates(userActivateDate, newUserMinimumRequestDate);

      initializeRole(userRoleGuid, responseRoles);

      if (userLanguagePreference) {
        setLanguagePreference(userLanguagePreference);
      }

      setUserInfo({
        ...userInfoToView,
        company: userAccountName,
        fullName: `${userFirstName} ${userLastName}`
      } as unknown as IUser);
      setIsThirdPartyUser((userTypeId & UserType.ThirdParty) > 0);
      setIsInitialized(true);
    },
    [
      initializeDates,
      initializeRole,
      setIsInitialized,
      setIsThirdPartyUser,
      setLanguagePreference,
      setUserInfo
    ]
  );

  const handleErrorOnLoad = useCallback(
    ({
      error,
      history,
      location
    }: {
      error?: any;
      history: IHistory;
      location: ILocation;
    }) => {
      if (error?.response?.status === 403) {
        return;
      }

      pushToast({
        title: getTextToDisplay('updateUser.errorToast.findNewUser'),
        type: 'error'
      });

      handleGoBack({
        history,
        location,
        replaceHistory: true
      });
    },
    [getTextToDisplay]
  );

  useEffect(() => {
    if (isInitialized) {
      return;
    }

    mounted.current = true;

    const { userRequestGuid } = matchParams;
    const { newUserRequest } =
      (location.state as IUpdateUserAcess.IUpdateUserAccessInboundLocationState) ||
      {};

    if (userRequestGuid) {
      ApiService.getNewUserRequestById(userRequestGuid)
        .then(({ data: responseUserInfo }) => {
          if (!responseUserInfo.userGuid) {
            throw new Error('getNewUserRequestById - userGuid not found');
          }

          ApiService.getRolesForUserType(responseUserInfo.userType)
            .then(({ data: responseRoles }) => {
              if (mounted.current) {
                initialize(responseUserInfo, responseRoles);
              }
            })
            .catch(error => {
              handleErrorOnLoad({ error, history, location });
            });
        })
        .catch(error => {
          handleErrorOnLoad({ error, history, location });
        });
    } else if (newUserRequest) {
      ApiService.getRolesForUserType(newUserRequest.userType).then(
        ({ data: responseRoles }) => {
          if (mounted.current) {
            initialize(newUserRequest, responseRoles);
          }
        }
      );
    } else {
      handleErrorOnLoad({ history, location });
    }

    return () => {
      mounted.current = false;
    };
  }, [
    handleErrorOnLoad,
    history,
    initialize,
    isInitialized,
    location,
    matchParams
  ]);

  const handleActivateUserChange: IUpdateUserAcess.IHandleActivateUserChange = (
    now,
    laterDate
  ) => {
    setIsActivateNow(now);
    setActivateLaterDate(laterDate);
    if (!isSaveAndCompleteDisabled || !now) {
      setIsUnsavedChanges(true);
    } else if (isSaveAndCompleteDisabled && now) {
      setIsUnsavedChanges(false);
    }
  };

  useEffect(() => {
    return () => {
      setIsUnsavedChanges(false);
    };
  }, [setIsUnsavedChanges]);

  const handleGoBack = ({
    history,
    location,
    replaceHistory
  }: IUpdateUserAcess.IHandleGoBackProps) => {
    const { previousPath: redirectPath } =
      (location?.state as IUpdateUserAcess.IUpdateUserAccessInboundLocationState) ||
      {};

    if (redirectPath) {
      replaceHistory
        ? history.replace(redirectPath)
        : history.push(redirectPath);
    } else {
      history.goBack();
    }
  };

  const handleRoleAndPermissionsChange: IUpdateUserAcess.IHandleRoleAndPermissionsChange =
    (selectedRoleGuid, customPermissions) => {
      setCustomPermissions(customPermissions);
      setSelectedRoleGuid(selectedRoleGuid);
      setIsUnsavedChanges(true);
    };

  const handleSave = async ({
    activateLaterDate,
    history,
    isActivateNow,
    isReviewedThirdParty,
    isThirdPartyUser,
    languagePreference,
    location,
    permissions,
    roleGuid,
    t,
    userInfo
  }: IUpdateUserAcess.IHandleSaveProps) => {
    if (roles) {
      const selectedRole = roles.find(
        (responseRole: IRole) => responseRole.roleGuid === selectedRoleGuid
      );

      AmplitudeApiService.logEvent('complete-grant-user-access', {
        ...(selectedRole && { role: selectedRole.roleName }),
        'user-activation-type': isActivateNow ? 'now' : 'later',
        'welcome-email-language':
          languagePreference !== '' ? languagePreference : null
      });
    }
    const {
      accountGuid: userAccountGuid,
      queueItemGuid: userQueueItemGuid,
      status: userStatus,
      userGuid,
      userType
    } = userInfo;
    const activateDate = isActivateNow ? new Date() : activateLaterDate;

    // HACK: TS requires check for activeDate on null
    if (activateDate) {
      const requestData = {
        ...(isThirdPartyUser && { isReviewedThirdParty }),
        ...(!userQueueItemGuid && {
          accountGuid: userAccountGuid,
          userType
        }),
        activateDate,
        languagePreference,
        permissions,
        roleGuid
      };

      try {
        const submitPromise =
          userQueueItemGuid && userStatus !== 5
            ? ApiService.completeNewUserRequest(userQueueItemGuid, requestData)
            : ApiService.directNewUserRequest(userGuid, requestData);
        setSubmitPromise({ submitPromise } as unknown as ReturnType<
          | typeof ApiService.completeNewUserRequest
          | typeof ApiService.directNewUserRequest
        >);
        await submitPromise;

        pushToast({
          content: t(
            `updateUser.successToast.saveNewUser${
              isActivateNow ? '' : '-deferred'
            }`,
            {
              ...(!isActivateNow && { activateDate: formatDate(activateDate) }),
              ryanPlatform: ENV.RYAN_PLATFORM,
              userName: userInfo.fullName
            }
          ),
          title: getTextToDisplay('updateUser.successToast.title'),
          type: 'success'
        });

        setIsUnsavedChanges(false);

        history.replace({
          ...history.location,
          state: {
            ...(history.location
              .state as IUpdateUserAcess.IUpdateUserAccessInboundLocationState),
            newUsrRequest: undefined,
            userProfile: undefined
          } as IUpdateUserAcess.IUpdateUserAccessInboundLocationState
        });

        handleGoBack({
          history,
          location,
          replaceHistory: true
        });
      } catch (error) {
        pushToast({
          title: getTextToDisplay('updateUser.errorToast.saveNewUser', {
            userName: userInfo.fullName
          }),
          type: 'error'
        });
      } finally {
        if (mounted.current) {
          setSubmitPromise(null);
        }
      }
    }
  };

  const renderActivateUser = ({
    activateLaterDate,
    handleActivateUserChange,
    isActivateNow,
    minimumNewUserRequestDate,
    t
  }: IUpdateUserAcess.IRenderActivateUserProps) => {
    const todaysDate = new Date();
    const tomorrowsDate = new Date(todaysDate);
    tomorrowsDate.setDate(tomorrowsDate.getDate() + 1);

    let modifiedActivateLater = tomorrowsDate;

    if (activateLaterDate) {
      modifiedActivateLater = activateLaterDate;
    } else if (
      !isActivateNow &&
      minimumNewUserRequestDate &&
      minimumNewUserRequestDate > tomorrowsDate
    ) {
      modifiedActivateLater = minimumNewUserRequestDate;
    }

    return (
      <section>
        <h2 className="ry-h2">{getTextToDisplay('updateUser.activateUser')}</h2>

        <p>
          <Trans
            i18nKey={`updateUser.requestedActivation.${
              isActivateNow ? 'now' : 'later'
            }`}
          >
            <b>
              {{
                date: formatDate(
                  standardizeUSDate(
                    isActivateNow ? todaysDate : modifiedActivateLater
                  )
                )
              }}
            </b>
          </Trans>
        </p>
        <NowOrLater
          {...(minimumNewUserRequestDate &&
          minimumNewUserRequestDate > todaysDate
            ? {
                laterErrorText: getTextToDisplay(
                  'updateUser.activationDateError',
                  {
                    minDate: formatDate(minimumNewUserRequestDate)
                  }
                ),
                laterHelperText: getTextToDisplay(
                  'updateUser.activationDateHelper'
                ),
                laterMinDate: minimumNewUserRequestDate
              }
            : {})}
          laterDate={modifiedActivateLater}
          laterLabel={getTextToDisplay('updateUser.activateLater')}
          now={isActivateNow}
          nowLabel={getTextToDisplay('updateUser.activateNow')}
          onChange={handleActivateUserChange}
        />
      </section>
    );
  };

  const renderUnableToActivateWarning = () => {
    return (
      <section>
        <h2 className="ry-h2">{getTextToDisplay('updateUser.activateUser')}</h2>
        <p>
          <Trans
            i18nKey={`manageTeam.newUser.status.reviewEngagements_warning'
                  }`}
          >
            {getTextToDisplay(
              'manageTeam.newUser.status.reviewEngagements_warning'
            )}
          </Trans>
        </p>
        <ButtonGroup>
          <Button
            onClick={() => {
              handleGoBack({ history, location });
            }}
            size="lg"
            text={getTextToDisplay('Cancel')}
          />
          <Button
            disabled={true}
            loading={submitPromise}
            onClick={() => {
              handleSave({
                activateLaterDate,
                history,
                isActivateNow,
                isReviewedThirdParty: isConfirmedThirdPartyUser,
                isThirdPartyUser,
                languagePreference,
                location,
                permissions: customPermissions,
                roleGuid: selectedRoleGuid,
                t: getTextToDisplay,
                userInfo
              });
            }}
            size="lg"
            text={getTextToDisplay('Save Changes')}
            variant="primary"
          />
        </ButtonGroup>
      </section>
    );
  };

  const renderDocumentTitleAndBreadcrumbs = ({
    breadcrumbs,
    title
  }: IUpdateUserAcess.IRenderDocumentTitleAndBreadcrumbsProps) => {
    return (
      <React.Fragment>
        <DocumentTitle title={title} />

        {breadcrumbs.map((breadcrumb: IBreadcrumb, i: number) => (
          <React.Fragment key={i}>
            <Breadcrumb {...breadcrumb} />
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  };

  return !userInfo || !roles ? (
    <React.Fragment>{getTextToDisplay('Loading')}...</React.Fragment>
  ) : (
    <div className="update-user-access">
      {renderDocumentTitleAndBreadcrumbs({ breadcrumbs, title })}

      <div className="row">
        <div className="col-md-4 order-md-last">
          <ErrorBoundary>
            <UserInfoCard
              user={{
                ...(userInfo.userProfile
                  ? { ...userInfo.userProfile }
                  : { ...userInfo }),
                //HACK: Identify why userType is not set to userTypeId Initially
                userTypeId: userInfo.userType
              }}
            />
          </ErrorBoundary>
        </div>
        <div className="col-md-8">
          <h1 className="ry-h1">{title}</h1>

          {userNotInSalesforce
            ? renderUnableToActivateWarning()
            : renderActivateUser({
                activateLaterDate,
                handleActivateUserChange,
                isActivateNow,
                minimumNewUserRequestDate,
                t: getTextToDisplay
              })}

          {!userNotInSalesforce && (
            <>
              <section className="update-user-access__language-selector">
                <SelectLanguage
                  label={getTextToDisplay('updateUser.languageSelector')}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    setLanguagePreference(event.target.value);
                    setIsUnsavedChanges(true);
                  }}
                  value={languagePreference}
                />
              </section>

              <NewUserProjectPreviewTable
                queueItemGuid={matchParams.userRequestGuid}
                userGuid={userInfo.userGuid}
              />

              <section>
                <h2 className="ry-h2">
                  {getTextToDisplay('updateUser.roleAndPermissions')}
                </h2>

                <RoleAndPermissions
                  canEditUserPermissions
                  customPermissions={customPermissions}
                  isThirdPartyUser={isThirdPartyUser}
                  onChange={handleRoleAndPermissionsChange}
                  roles={roles}
                  selectedRoleGuid={selectedRoleGuid}
                />
              </section>

              {isThirdPartyUser && (
                <section>
                  <Checkbox
                    checked={isConfirmedThirdPartyUser}
                    label={getTextToDisplay(
                      'updateUser.confirmThirdPartyUser',
                      {
                        name: userInfo.fullName || ''
                      }
                    )}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setIsConfirmedThirdPartyUser(event.target.checked);
                      setIsUnsavedChanges(true);
                    }}
                    value={'isConfirmedThirdPartyUser'}
                  />
                </section>
              )}

              <ButtonGroup>
                <Button
                  onClick={() => {
                    if (roles) {
                      const selectedRole = roles.find(
                        (responseRole: IRole) =>
                          responseRole.roleGuid === selectedRoleGuid
                      );

                      AmplitudeApiService.logEvent('cancel-grant-user-access', {
                        ...(selectedRole && { role: selectedRole.roleName }),
                        'user-activation-type': isActivateNow ? 'now' : 'later',
                        'welcome-email-language':
                          languagePreference !== '' ? languagePreference : null
                      });
                    }
                    setIsUnsavedChanges(false);
                    handleGoBack({ history, location });
                  }}
                  size="lg"
                  text={getTextToDisplay('Cancel')}
                />
                <Button
                  disabled={isSaveAndCompleteDisabled}
                  loading={submitPromise}
                  onClick={() => {
                    handleSave({
                      activateLaterDate,
                      history,
                      isActivateNow,
                      isReviewedThirdParty: isConfirmedThirdPartyUser,
                      isThirdPartyUser,
                      languagePreference,
                      location,
                      permissions: customPermissions,
                      roleGuid: selectedRoleGuid,
                      t: getTextToDisplay,
                      userInfo
                    });
                  }}
                  size="lg"
                  text={getTextToDisplay('updateUser.saveAndComplete')}
                  variant="primary"
                />
              </ButtonGroup>
            </>
          )}

          {isShowDenyOption && (
            <section className="update-user-access__deny-access-modal">
              <hr />

              <h2 className="ry-h2">{getTextToDisplay('updateUser.deny')}</h2>
              <p>{getTextToDisplay('updateUser.denyContent')}</p>
              <ButtonGroup>
                <Button
                  negative
                  onClick={() => {
                    setIsDenyUserModalOpen(true);
                  }}
                  text={getTextToDisplay('updateUser.deny')}
                />
              </ButtonGroup>

              {isDenyUserModalOpen && (
                <DenyUserModal
                  guids={{
                    queueItemGuid: userInfo.queueItemGuid!,
                    userGuid: userInfo.userGuid
                  }}
                  onClose={() => {
                    setIsDenyUserModalOpen(false);
                  }}
                  onDenyRequest={() => {
                    setIsDenyUserModalOpen(false);
                    setIsUnsavedChanges(false);
                    handleGoBack({ history, location });
                  }}
                  userFullName={GetUserInfoUtil.getFullName(userInfo as IUser)}
                />
              )}
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateUserAccess;
