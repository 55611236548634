import Modal from 'components/Modal';
import {
  DirectoryItemType,
  FileType,
  IContractFile,
  IDirectoryFile,
  IDirectoryItem,
  IFile,
  IInvoice
} from 'interfaces';
import queryString from 'query-string';
import ApiService from 'services/ApiService';
import { eraseCookie, getCookie } from 'utils/cookies';
import getContractFileName from 'utils/getContractFileName';
import getDocumentDownloadUrl from 'utils/getDocumentDownloadUrl';
import i18n from 'utils/i18n';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import ENV from 'env';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, ButtonGroup, pushToast } from '@ryan/components';

import DownloadContext, { IDownloadContext } from './DownloadContext';

const MAX_DOWNLOAD_COUNT = 5;
const MAX_DOWNLOAD_SIZE = Number(ENV.MAX_FILE_SIZE);

/**
 * Download Utilities
 * https://github.com/sindresorhus/multi-download
 */

interface DownloadFile {
  documentGuid: string;
  name?: string;
  url: string;
}

/**
 * Displays a toast message while the file is being prepared for download. The
 * download endpoint will set a cookie `dxpFileDownloadToken` once the actual
 * transfer of data begins.
 */
function displayDownloadToast(file: DownloadFile) {
  const title = file.name
    ? i18n.t('file.preparingDownload', {
        context: 'named',
        name: file.name
      })
    : i18n.t('file.preparingDownload');
  let intervalTimer: number | undefined = undefined;

  // store reference to close function passed by `pushToast` so toast message
  // can be closed without adding a close button
  let closeToast: () => void;

  /**
   * Closes the download toast message once the download intiiates by checking
   * for the existence of the download token cookie.
   */
  const checkDownloadStatus = () => {
    const downloadTokenCookieName = 'dxpFileDownloadToken';
    const downloadTokenCookieValue = getCookie(downloadTokenCookieName);

    if (
      downloadTokenCookieValue &&
      downloadTokenCookieValue === file.documentGuid
    ) {
      clearInterval(intervalTimer);
      closeToast?.();
      eraseCookie(downloadTokenCookieName);
    }
  };

  // initiate display of download toast
  pushToast({
    // autoclose after timeout if download cannot initiate for whatever reason
    autoClose: 30000,
    dismissible: false,
    type: 'info',
    title,
    // cache close function for this toast
    content: ({ close }) => {
      closeToast = close;
      return null;
    },
    onClose: () => {
      window.clearInterval(intervalTimer);
    }
  });

  // initiate check of download status
  intervalTimer = window.setInterval(checkDownloadStatus, 500);
}

/**
 * Initiates download of a single file.
 *
 * @param file The file to download.
 * @param showToast Displays a toast message while the download is being
 *  prepared.
 */
function downloadFile(file: DownloadFile, showToast = false) {
  if (showToast) {
    displayDownloadToast(file);
  }

  // initiate download via hidden anchor
  const a = document.createElement('a');
  a.download = file.name || '';
  a.href = file.url;
  a.style.display = 'none';

  /**
   * append anchor to DOM to be clicked
   * NOTE: Modals intercept and prevent clicks outside the modal, which prevents
   * downloads of files within modals. Look for modals and append anchor within
   * modal so download can occur. Use last modal as that one will have top-most
   * focus. May need to apply similar logic to other components that also
   * prevent clicks outside of them in the future.
   */
  const lastFocusedEl = ([] as HTMLElement[]).slice
    .call(document.querySelectorAll('.ry-modal'))
    .pop();
  const containerEl = lastFocusedEl || document.body;
  containerEl.append(a);
  a.click();
  a.remove();
}

/**
 * Initiates download of multiple individual files.
 *
 * @see https://github.com/sindresorhus/multi-download/pull/20/files
 * @see https://stackoverflow.com/questions/18451856/how-can-i-let-a-user-download-multiple-files-when-a-button-is-clicked
 * @see https://stackoverflow.com/questions/52051330/browser-is-cancelling-multiple-file-download-requests
 */
function downloadMultipleFiles(files: DownloadFile[]) {
  for (let i = 0; i < files.length; i++) {
    setTimeout(() => {
      // downloadFile doesn't work well in Chrome since the new request cancels the previous one
      // iframe is a workaround for now
      const frame = document.createElement('iframe');
      frame.src = files[i].url;
      frame.hidden = true;
      document.body.appendChild(frame);
      setTimeout(
        frame => document.body.removeChild(frame),
        2 * 60 * 1000,
        frame
      );
    }, 3000 * i);
  }
}

/**
 * Provider
 */

interface DownloadProviderProps {
  children: React.ReactNode;
}

type ZipState = {
  items: IDirectoryItem[];
  engagementGuid?: string;
  status: 'confirm' | Promise<any>;
} | null;

export const DownloadProvider: FunctionComponent<DownloadProviderProps> = ({
  children
}) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [zip, setZip] = useState<ZipState>(null);

  // Zipped files are downloaded after clicking an link in an email.
  // Looks like: /app?zip=GUID
  useEffect(() => {
    const params = queryString.parse(location.search);

    if (params.zip && typeof params.zip === 'string') {
      downloadFile(
        {
          documentGuid: params.zip,
          url: getDocumentDownloadUrl(params.zip, null, FileType.ZipFile)
        },
        true
      );

      // clear zip and amplitude params from URL to prevent duplicate downloading/event logging
      delete params.zip;
      !!params.soc && delete params.soc;
      !!params.ssc && delete params.ssc;
      history.replace({
        search: queryString.stringify(params)
      });
    }
  }, [history, location.search]);

  const context = useMemo<IDownloadContext>(() => {
    /**
     * Triggers zip request if meets requirements.
     * Returns whether zip was started.
     */
    function maybeZip(items: IDirectoryItem[], engagementGuid?: string) {
      // Collect all files (excluding folders)
      const files = items
        .filter(i => i.type === DirectoryItemType.File)
        .map(item => item.item as IDirectoryFile);

      // if any are folders
      // or file count is over threshold
      // or file size is over threshold
      const shouldZip =
        items.some(item => item.type === DirectoryItemType.Folder) ||
        files.length > MAX_DOWNLOAD_COUNT ||
        files.reduce((size, file) => size + file.size, 0) > MAX_DOWNLOAD_SIZE;

      if (shouldZip) {
        setZip({
          items,
          engagementGuid,
          status: 'confirm'
        });
      }

      return shouldZip;
    }

    return {
      onDownloadFiles: (files: IFile[], engagementGuid?: string) => {
        // Our zip detection supports directory items, including folders.
        // But when downloading other files, like Learning files, there are no folders.
        const directoryItems = files.map(
          file =>
            ({
              type: DirectoryItemType.File,
              item: {
                ...file,
                visibleToUserTypes: 0,
                queueItemGuid: '',
                isDataRequestAdHoc: false,
                isDataRequestDeleted: false
              }
            } as IDirectoryItem)
        );

        if (!maybeZip(directoryItems, engagementGuid)) {
          downloadMultipleFiles(
            files.map(file => ({
              url: getDocumentDownloadUrl(
                file.documentGuid,
                engagementGuid || null,
                FileType.Document
              ),
              name: file.displayName,
              documentGuid: file.documentGuid
            }))
          );
        }
      },
      onDownloadDirectoryItems: (
        directoryItems: IDirectoryItem[],
        engagementGuid: string
      ) => {
        if (!maybeZip(directoryItems, engagementGuid)) {
          downloadMultipleFiles(
            directoryItems.map(item => {
              // We know this is a file, because if there
              // were any folders we would have zipped.
              const file = item.item as IDirectoryFile;
              return {
                url: getDocumentDownloadUrl(
                  file.documentGuid,
                  engagementGuid,
                  FileType.Document
                ),
                name: file.displayName,
                documentGuid: file.documentGuid
              };
            })
          );
        }
      },
      onDownloadContract: (contract: IContractFile) => {
        // Contracts do not support batched downloads or zipping.
        downloadFile({
          url: getDocumentDownloadUrl(
            contract.engagementContractDocumentGuid,
            contract.engagementGuid,
            FileType.Contract
          ),
          name: getContractFileName(t, contract),
          documentGuid: contract.engagementContractDocumentGuid
        });
      },
      onDownloadInvoice: (invoice: IInvoice) => {
        // Invoices do not support zipping.
        downloadFile({
          url: getDocumentDownloadUrl(
            invoice.invoiceGuid,
            invoice.engagementGuid,
            FileType.Invoice
          ),
          name: invoice.name,
          documentGuid: invoice.invoiceGuid
        });
      }
    };
  }, [t]);

  function onZipConfirm() {
    if (zip) {
      setZip({
        ...zip,
        status: ApiService.createZipRequest({
          directoryItems: zip.items,
          engagementGuid: zip.engagementGuid
        })
          .then(() => {
            pushToast({
              type: 'success',
              title: t('bulkDownload.success.title'),
              content: t('bulkDownload.success.content')
            });
          })
          .catch(() => {
            pushServerErrorToast();
          })
          .then(() => {
            setZip(null);
          })
      });
    }
  }

  function onZipCancel() {
    setZip(null);
    pushToast({
      type: 'error',
      content: t('bulkDownload.error.content')
    });
  }

  return (
    <DownloadContext.Provider value={context}>
      {children}

      {zip !== null && (
        <Modal
          onClose={onZipCancel}
          open
          title={t('bulkDownload.confirmModal.title')}
        >
          <p>{t('bulkDownload.confirmModal.allowed.content1')}</p>
          <ButtonGroup>
            <Button
              loading={zip.status !== 'confirm' ? zip.status : null}
              onClick={onZipConfirm}
              text={t('bulkDownload.confirmModal.allowed.submit')}
              variant="primary"
            />
            <Button
              disabled={zip.status !== 'confirm'}
              onClick={onZipCancel}
              text={t('bulkDownload.confirmModal.allowed.cancel')}
              variant="secondary"
            />
          </ButtonGroup>
        </Modal>
      )}
    </DownloadContext.Provider>
  );
};

export default DownloadProvider;
