import { IFile } from './IFile';
import { MilestoneType } from './IMilestone';
import { Status } from './Status';
import { UserType } from './UserType';

export enum TaskStatusGroup {
  Outstanding = 1,
  Complete = 2
}

export interface ITask {
  // Account
  accountGuid: string;
  accountName: string;

  // Engagement
  engagementGuid: string;
  engagementDisplayNameShort: string;
  isEngagementReadOnly: boolean; // !isEngagementActive || isUserGhosted
  isEngagementActive: boolean;
  isUserGhosted: boolean;

  // Task
  queueItemGuid: string;
  title: string;
  description: string;
  dueDate: Date;

  assignedToUserGuid: string;
  assignedToName: string;
  assignedToEmail: string;
  userType: UserType; // usertype of assigned user

  engagementMilestoneGuid: string | null;
  engagementMilestoneTitle: string | null;
  milestoneTypeId: MilestoneType | null;

  statusId: Status;
  statusGroupId: TaskStatusGroup;
  status: string;

  // Change
  createDate: Date;
  createdBy: string;
  createdByUserType: UserType;
  createdByName: string;
  createdImpersonatedBy: string;
  createdImpersonatedByName: string;
  completedDate: Date;

  attachments: IFile[];
  commentsCount: number;

  // Followers
  isCurrentUserWatching: boolean;
  watcherCount: number;
}
