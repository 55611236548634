import { IEngagement } from 'interfaces';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

import { Button, Modal } from '@ryan/components';

import './ProjectDetailsSetupModal.scss';

export interface IProjectDetailsSetupModalProps {
  engagement: IEngagement;
  onClose: () => void;
  onGoToEngagementContractsFolder: () => void;
}

const ProjectDetailsSetupModal: React.FC<IProjectDetailsSetupModalProps> = ({
  engagement,
  onClose,
  onGoToEngagementContractsFolder
}) => {
  const { t: textToDisplay } = useTranslation();
  const ROOT_TO_TEXT = 'modal.projectSetUpModal';

  const actions = [
    {
      title: textToDisplay(`${ROOT_TO_TEXT}.actions.publishContracts.title`),
      element: (
        <Button
          className="call-to-action"
          icon="contract"
          onClick={onGoToEngagementContractsFolder}
          size="sm"
          text={textToDisplay(
            `${ROOT_TO_TEXT}.actions.publishContracts.actionText`
          )}
          variant="link"
        />
      )
    }
  ];

  return (
    <Modal
      className="update-savings-bucket-modal"
      onClose={onClose}
      open
      title={
        (
          <Trans i18nKey={`${ROOT_TO_TEXT}.title`}>
            <span className="update-savings-bucket-modal__title" />
            {{ engagementName: engagement.engagementDisplayNameShort }}
          </Trans>
        ) as unknown as string
      }
    >
      <p>
        {textToDisplay(`${ROOT_TO_TEXT}.description`, {
          count: actions.length
        })}
      </p>
      <div className="well">
        {actions.map((action, i) => {
          return (
            <div key={i}>
              <p className="title">{action.title}</p>
              <div>{action.element}</div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default ProjectDetailsSetupModal;
