export enum Feature {
  InternalFiles = '56e1030c-50b2-4b4c-b45a-45b21b6f1767',
  LimitedCustomView = 'e9f2c28f-3885-4acb-bd48-f11ada635b48',
  LockedFiles = '92e393bb-d030-4552-b971-1e21efb8a654',
  ReleaseNotes = '60209b60-3fa2-49ac-8ca8-4d71c7172d64',
  RWMDataRequests = 'b4b9ef9d-308f-4c8f-b673-fad47e11a540',
  UnpublishedProjectsReport = '397c805a-35e6-46ab-bce6-1b117edf0b22'
}

export interface IFeatureToggle {
  featureGuid: Feature;
  featureName: string;
  isEnabled: boolean;
  isSuperAdminEnabled: boolean;
}

// LimitedCustomView
export const MAX_SELECTED_COMPANIES = 300;
