import { EventEmitter } from 'events';
import { createContext } from 'react';
import { IDataRequest } from 'interfaces';

export interface IDataRequestDrawerContext {
  dataRequestDrawerEvents: EventEmitter;
  onDataRequestDrawerOpenHistory: (dataRequest: IDataRequest) => void;
  onDataRequestDrawerOpenComments: (dataRequest: IDataRequest) => void;
}

export const DataRequestDrawerContext = createContext<IDataRequestDrawerContext>(
  {
    dataRequestDrawerEvents: new EventEmitter(),
    onDataRequestDrawerOpenHistory: () => {},
    onDataRequestDrawerOpenComments: () => {}
  }
);
