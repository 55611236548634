import { format } from 'date-fns';
import React from 'react';
import { Trans } from 'react-i18next';

import { Button } from '@ryan/components';

import { TDateRange } from '../types';

export const formatDateRanges = (dateRanges: TDateRange[]) => {
  return dateRanges.map(range => {
    const startDate = new Date(range.dateStart);
    const endDate = new Date(range.dateEnd);
    const startMY = format(startDate, 'MM/yyyy');
    const endMY = format(endDate, 'MM/yyyy');

    return startMY === endMY ? startMY : `${startMY} - ${endMY}`;
  });
};

export const formatRecords = (
  records: string[] | TDateRange[],
  onClick: () => void
) => {
  const isTDateRangeArray = (records as TDateRange[]).every(
    (item): item is TDateRange =>
      typeof item === 'object' &&
      item !== null &&
      'dateStart' in item &&
      'dateEnd' in item
  );
  const formatted = isTDateRangeArray
    ? formatDateRanges(records as TDateRange[])
    : records;

  return formatted.length > 2
    ? [
        <Button key={0} onClick={onClick} variant="link">
          <Trans
            count={formatted.length}
            i18nKey="dataRequest.dataFields.viewSelections"
          ></Trans>
        </Button>
      ]
    : formatted.map((record, index) => (
        <p className="formatted-record" key={index}>
          {record}
        </p>
      ));
};
