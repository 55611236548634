import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Card, Tooltip } from '@ryan/components';

import {
  WithCodeNotes,
  withCodeNotes
} from '../../contexts/CodeNotesDrawerContext';
import {
  WithDataRequestDrawer,
  withDataRequestDrawer
} from '../../contexts/DataRequestDrawerContext';
import { WithUser, withUser } from '../../contexts/UserContext';
import {
  Feature,
  IDataRequest,
  IDataRequestSearchParams,
  Permission,
  Status
} from '../../interfaces';
import ApiService, { CancelTokenSource } from '../../services/ApiService';
import history from '../../services/history';
import { formatDate } from '../../utils/formatDate';
import getCommentButtonProps from '../../utils/getCommentButtonProps';
import { isDataRequestPastDue } from '../../utils/isPastDue';
import pushServerErrorToast from '../../utils/pushServerErrorToast';
import switcherDidUpdate from '../../utils/switcherDidUpdate';
import DataRequestCard from '../DataRequestCard';
import Empty from '../Empty';
import StatusIcon from '../StatusIcon/StatusIcon';
import Table from '../Table';

import './RecentDataRequestsCard.scss';

const sortByDueDate = (dataRequests: IDataRequest[], sortDesc: boolean) =>
  dataRequests
    .map(x => x)
    .sort((a, b) => {
      const aDate = new Date(a.dueDate).getTime();
      const bDate = new Date(b.dueDate).getTime();
      return sortDesc ? bDate - aDate : aDate - bDate;
    });

interface IRecentDataRequestsCardState {
  dataRequests: IDataRequest[];
  loading: boolean;
  totalCount: number;
  sorted: { id: string; desc: boolean };
  expanded: Record<string, unknown>;
}

interface IRecentDataRequestsCardProps
  extends WithTranslation,
    WithUser,
    WithDataRequestDrawer,
    WithCodeNotes {
  // ...
}

// Assumes Permission.DataRequestsView
export class RecentDataRequestsCard extends Component<
  IRecentDataRequestsCardProps,
  IRecentDataRequestsCardState
> {
  readonly state: IRecentDataRequestsCardState = {
    loading: false,
    dataRequests: [],
    totalCount: 0,
    expanded: {},
    sorted: {
      id: 'dueDate',
      desc: true
    }
  };

  private _isMounted = false;

  private columns: any[];

  private source?: CancelTokenSource;

  constructor(props: IRecentDataRequestsCardProps) {
    super(props);

    const { isFeatureToggled, t: getTextToDisplay } = props;

    const isDataRequestsUpdateVisible = isFeatureToggled(
      Feature.RWMDataRequests
    );

    const addedColumn = isDataRequestsUpdateVisible
      ? {
          id: 'dataType',
          label: getTextToDisplay('dataRequest.columns.dataType'),
          render: (row: IDataRequest) => row.dataRequestType.name
        }
      : {
          id: 'createdByName',
          label: getTextToDisplay('dataRequest.columns.requestedBy'),
          render: (row: IDataRequest) => {
            const {
              t: getTextToDisplay,
              user: {
                profile: { userGuid }
              }
            } = this.props;
            return row.createdBy === userGuid
              ? getTextToDisplay('You')
              : row.createdByName;
          },
          sortable: true
        };

    this.columns = [
      {
        id: 'title',
        label: getTextToDisplay('Name'),
        render: (row: IDataRequest) => (
          <Link className="bs" to={`/app/data-request/${row.queueItemGuid}`}>
            <b>{row.title}</b>
            <small>{row.accountName}</small>
          </Link>
        )
      },
      addedColumn,
      {
        id: 'status',
        label: getTextToDisplay('Status'),
        render: (row: IDataRequest) =>
          Object.values(Status).includes(row.statusId) && (
            <StatusIcon status={row.statusId} />
          )
      },
      {
        id: 'assignedTo',
        label: getTextToDisplay('Assigned To'),
        render: (row: IDataRequest) => {
          const {
            user: {
              profile: { userGuid }
            }
          } = this.props;
          return row.assignedToUserGuid === userGuid
            ? getTextToDisplay('You')
            : row.assignedToName;
        }
      },
      {
        id: 'dueDate',
        label: getTextToDisplay('Due Date'),
        render: (row: IDataRequest) => (
          <span className="duedate">{formatDate(row.dueDate)}</span>
        ),
        sortable: true
      },
      {
        id: 'comment',
        label: '',
        align: 'center',
        render: (row: IDataRequest) => {
          const {
            t,
            isAppReadOnly,
            permissionService: ps,
            onDataRequestDrawerOpenComments
          } = this.props;
          const { text, icon } = getCommentButtonProps(
            t,
            ps.hasPermission(Permission.DataRequestsContribute),
            row,
            isAppReadOnly
          );
          return (
            <Tooltip
              content={text}
              placement="top"
              renderTarget={({ open, ref, ...props }) => (
                <Button
                  {...props}
                  className="button-margins"
                  icon={icon}
                  innerRef={ref}
                  onClick={() => onDataRequestDrawerOpenComments(row)}
                  size="sm"
                  variant="text"
                />
              )}
            />
          );
        }
      }
    ];
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchRecentDataRequests();
    this.props.dataRequestDrawerEvents.addListener(
      'commentAdded',
      this.fetchRecentDataRequests
    );
    this.props.dataRequestDrawerEvents.addListener(
      'commentRemoved',
      this.fetchRecentDataRequests
    );
    this.props.codeNotesDrawerEvents.addListener(
      'noteAdded',
      this.fetchRecentDataRequests
    );
    this.props.codeNotesDrawerEvents.addListener(
      'noteRemoved',
      this.fetchRecentDataRequests
    );
  }

  componentDidUpdate(prevProps: IRecentDataRequestsCardProps) {
    if (switcherDidUpdate(prevProps, this.props)) {
      this.fetchRecentDataRequests();
    }
  }

  componentWillUnmount() {
    this.source?.cancel();
    this.props.dataRequestDrawerEvents.removeListener(
      'commentAdded',
      this.fetchRecentDataRequests
    );
    this.props.dataRequestDrawerEvents.removeListener(
      'commentRemoved',
      this.fetchRecentDataRequests
    );
    this.props.codeNotesDrawerEvents.removeListener(
      'noteAdded',
      this.fetchRecentDataRequests
    );
    this.props.codeNotesDrawerEvents.removeListener(
      'noteRemoved',
      this.fetchRecentDataRequests
    );
    this._isMounted = false;
  }

  fetchRecentDataRequests = (
    updates?: Partial<IRecentDataRequestsCardState>
  ) => {
    const { customViewGuid } = this.props.activeView;

    this.setState(
      { ...(updates as IRecentDataRequestsCardState), loading: true },
      async () => {
        const { sorted } = this.state;
        const params: IDataRequestSearchParams = {
          sort: 'createDate-',
          pageNumber: 1,
          itemsPerPage: 5
        };
        this.source?.cancel();
        this.source = ApiService.CancelToken.source();

        try {
          const {
            data: { results, totalResults }
          } = await ApiService.getCustomViewDataRequests(
            customViewGuid,
            params,
            this.source.token
          );
          this.setState({
            dataRequests: sortByDueDate(results, sorted!.desc),
            totalCount: totalResults
          });
        } catch (error) {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
          }
        } finally {
          if (this._isMounted) {
            this.setState({ loading: false });
          }
        }
      }
    );
  };

  handleToggleExpansion = (
    expanded: boolean,
    _row: IDataRequest,
    rowId: string
  ) => {
    this.setState(state => ({
      expanded: { ...state.expanded, [rowId]: expanded }
    }));
  };

  handleSort = (sorted: any) => {
    this.setState(({ dataRequests }) => ({
      dataRequests: sortByDueDate(dataRequests, sorted ? sorted.desc : false),
      sorted
    }));
  };

  getRowClassName = (row: IDataRequest) =>
    isDataRequestPastDue(row) ? 'pastdue' : '';

  render() {
    const { loading, dataRequests, sorted, expanded } = this.state;

    const { t } = this.props;

    return (
      <Card
        className="recent-data-requests-card"
        role="region"
        title={t('dataRequest.recentCard.title')}
      >
        <Table<IDataRequest, string>
          columns={this.columns}
          data={dataRequests}
          expanded={expanded}
          loading={loading}
          onSortChange={this.handleSort}
          onToggleExpansion={this.handleToggleExpansion}
          renderEmpty={this.renderEmpty}
          renderExpandedRow={this.renderExpandedRow}
          rowClassName={this.getRowClassName}
          rowId="queueItemGuid"
          sorted={sorted}
        />

        {dataRequests.length > 0 && (
          <div className="recent-data-requests-card__actions">
            <Button
              onClick={() => history.push('/app/data-and-files/data-requests')}
              text={t('View All')}
              variant="primary"
            />
          </div>
        )}
      </Card>
    );
  }

  renderExpandedRow = (row: IDataRequest) => {
    const {
      onCodeNotesDrawerOpen,
      onDataRequestDrawerOpenComments,
      onDataRequestDrawerOpenHistory
    } = this.props;

    return (
      <DataRequestCard
        dataRequest={row}
        onOpenCodeNotes={() => onCodeNotesDrawerOpen(row)}
        onOpenComments={() => onDataRequestDrawerOpenComments(row)}
        onOpenHistory={() => onDataRequestDrawerOpenHistory(row)}
        onUpdate={this.fetchRecentDataRequests}
      />
    );
  };

  renderEmpty = () => {
    const { t, permissionService: ps } = this.props;
    return (
      <Empty icon="file">
        {t('dataRequest.empty', { context: ps.isRyan() ? 'ryan' : 'client' })}
      </Empty>
    );
  };
}

export default withTranslation()(
  withUser(withDataRequestDrawer(withCodeNotes(RecentDataRequestsCard)))
);
