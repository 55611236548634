import { TDataRequestModalsProps } from './DataRequestModals.interfaces';
import {
  DataRequestModalActionEnums,
  mapAssignmentToCompleteDataRequest,
  mapAssignmentToDeleteDataRequest,
  mapAssignmentToEditDataRequest
} from './DataRequestModals.utils';

import React from 'react';

import DataRequestCompleteModal from '../../../components/DataRequestActions/DataRequestCompleteModal';
import DataRequestDeleteModal from '../../../components/DataRequestActions/DataRequestDeleteModal';
import DataRequestModal from '../../../components/DataRequestModal/DataRequestModal';
import { IDataRequest } from '../../../interfaces';

const DataRequestModals = ({
  assignmentToComplete,
  assignmentToDelete,
  assignmentToEdit,
  onClose
}: TDataRequestModalsProps) => {
  let modalAction: DataRequestModalActionEnums | null = null;
  let dataRequest;

  if (assignmentToComplete) {
    modalAction = DataRequestModalActionEnums.COMPLETE;
    dataRequest = mapAssignmentToCompleteDataRequest(assignmentToComplete);
  } else if (assignmentToDelete) {
    modalAction = DataRequestModalActionEnums.DELETE;
    dataRequest = mapAssignmentToDeleteDataRequest(assignmentToDelete);
  } else if (assignmentToEdit) {
    modalAction = DataRequestModalActionEnums.EDIT;
    dataRequest = mapAssignmentToEditDataRequest(assignmentToEdit);
  }

  return (
    <>
      {modalAction === DataRequestModalActionEnums.COMPLETE && (
        <DataRequestCompleteModal
          dataRequest={dataRequest as IDataRequest}
          onClose={(updatedDataRequest?: IDataRequest) => {
            onClose(
              updatedDataRequest,
              modalAction as DataRequestModalActionEnums
            );
          }}
        />
      )}
      {modalAction === DataRequestModalActionEnums.DELETE && (
        <DataRequestDeleteModal
          dataRequest={dataRequest as IDataRequest}
          onClose={(isDeleted: boolean) => {
            onClose(
              isDeleted ? ({} as IDataRequest) : undefined,
              modalAction as DataRequestModalActionEnums
            );
          }}
        />
      )}
      {modalAction === DataRequestModalActionEnums.EDIT && (
        <DataRequestModal
          dataRequest={dataRequest as IDataRequest}
          onClose={(updatedDataRequest?: IDataRequest) => {
            onClose(
              updatedDataRequest,
              modalAction as DataRequestModalActionEnums
            );
          }}
        />
      )}
    </>
  );
};

export default DataRequestModals;
