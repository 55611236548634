import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonGroup, Modal, TextInput } from '@ryan/components';

import { Formik, formikFieldProps } from '../../../utils/forms';
import {
  IFormikValues,
  ISavingsCategoryModalProps,
  SavingsCategoryModalEnums,
  getSavingsCategoryNameSchema
} from './utils';

const SavingsCategoryModal: React.FC<ISavingsCategoryModalProps> = ({
  categoryName,
  existingCategoryNames,
  modalTitle,
  onClose,
  onSubmit
}) => {
  const { t: textToDisplay } = useTranslation();

  const { CATEGORY_NAME, ROOT_TO_TEXT } = SavingsCategoryModalEnums;

  return (
    <Modal
      onClose={onClose}
      open
      title={textToDisplay(`${ROOT_TO_TEXT}.title.${modalTitle}`)}
    >
      <Formik<IFormikValues>
        initialValues={{ [CATEGORY_NAME]: categoryName }}
        onSubmit={formikValues => {
          onSubmit(formikValues[CATEGORY_NAME].trim());
        }}
        validationSchema={getSavingsCategoryNameSchema(
          textToDisplay,
          existingCategoryNames
        )}
      >
        {formik => (
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <TextInput
              {...formikFieldProps(CATEGORY_NAME, formik)}
              label={textToDisplay(`${ROOT_TO_TEXT}.categoryName`)}
            />

            <ButtonGroup>
              <Button
                onClick={onClose}
                text={textToDisplay(`${ROOT_TO_TEXT}.cancel`)}
              />
              <Button
                disabled={!formik.dirty || !formik.isValid}
                text={textToDisplay(`${ROOT_TO_TEXT}.done`)}
                type="submit"
                variant="primary"
              />
            </ButtonGroup>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default SavingsCategoryModal;
