import Datepicker from 'components/Datepicker';
import Modal from 'components/Modal';
import { IButtonLoadingState, IEngagement, IModalProps } from 'interfaces';

import { addDays, parseISO } from 'date-fns';
import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import { Button, ButtonGroup, Dropdown, pushToast } from '@ryan/components';

import ApiService, { CancelTokenSource } from '../../services/ApiService';
import { TGetSupportedISOCurrencyCodes } from '../../services/ApiService/ApiSavings';
import {
  Formik,
  formikDatepickerProps,
  formikFieldProps,
  yup
} from '../../utils/forms';
import { handleDefaultApiError } from '../../utils/handleApiError/handleApiError';
import InfoTooltip from '../InfoTooltip';

import './ProjectDetailsModal.scss';

interface IProjectDetailsPublishModalProps
  extends IModalProps,
    WithTranslation {
  engagement: IEngagement;
  onSave: (engagement: IEngagement) => void;
}

interface IProjectDetailsPublishModalState extends IButtonLoadingState {
  initialValues: {
    endDate: Date | null;
    isoCurrencyCode: string;
    startDate: Date | null;
    statuteOfLimitations: Date | null;
  };
  isoCurrencyCodeOptions: TGetSupportedISOCurrencyCodes[];
}

const ROOT_TO_TEXT = 'modal.projectPublishModal';

export class ProjectDetailsPublishModal extends Component<
  IProjectDetailsPublishModalProps,
  IProjectDetailsPublishModalState
> {
  private schema: yup.ObjectSchema;

  private source?: CancelTokenSource;

  constructor(props: IProjectDetailsPublishModalProps) {
    super(props);

    const { t, engagement } = props;

    this.schema = yup.object({
      startDate: yup
        .date()
        .nullable()
        .required(t('projectDetails.modal.startDate.required')),
      endDate: yup
        .date()
        .nullable()
        .required(t('projectDetails.modal.endDate.required'))
    });

    this.state = {
      initialValues: {
        endDate: engagement.projectedEndDate
          ? parseISO(engagement.projectedEndDate)
          : null,
        isoCurrencyCode: engagement.projectCurrency || '',
        startDate: engagement.alternateBeginDate
          ? parseISO(engagement.alternateBeginDate)
          : null,
        statuteOfLimitations: engagement.statuteOfLimitations
          ? parseISO(engagement.statuteOfLimitations)
          : null
      },
      isoCurrencyCodeOptions: [],
      loading: null
    };
  }

  componentDidMount() {
    this.source = ApiService.CancelToken.source();

    ApiService.getSupportedISOCurrencyCodes(this.source.token)
      .then(({ data: isoCurrencyCodeOptionsResponse }) => {
        this.setState({
          isoCurrencyCodeOptions: isoCurrencyCodeOptionsResponse
        });
      })
      .catch(error => {
        handleDefaultApiError(error);
      });
  }

  componentDidUpdate(prevProps: IProjectDetailsPublishModalProps) {
    const { alternateBeginDate, projectedEndDate, statuteOfLimitations } =
      this.props.engagement;

    if (
      prevProps.engagement.alternateBeginDate !== alternateBeginDate ||
      prevProps.engagement.projectedEndDate !== projectedEndDate
    ) {
      this.setState({
        initialValues: {
          ...this.state.initialValues,
          startDate: alternateBeginDate ? parseISO(alternateBeginDate) : null,
          endDate: projectedEndDate ? parseISO(projectedEndDate) : null,
          statuteOfLimitations: statuteOfLimitations
            ? parseISO(statuteOfLimitations)
            : null
        }
      });
    }
  }

  componentWillUnmount() {
    this.source?.cancel();
  }

  submitProjectDetailsModal = (values: any) => {
    const { t, engagement, onClose, onSave } = this.props;
    const { isoCurrencyCode } = values;
    const projectName = engagement.engagementDisplayNameShort;

    const isIsoCurrencyCodeUpdated =
      isoCurrencyCode && engagement.projectCurrency !== isoCurrencyCode;

    // TODO: Refactor nested Api call with BED team during refactor. Reduce to one call
    const apiServiceUpdateProjectCurrencyPromise = isIsoCurrencyCodeUpdated
      ? ApiService.updateProjectCurrency({
          currencyGuid: this.state.isoCurrencyCodeOptions.find(
            option => option.isoAlphaCode === isoCurrencyCode
          )!.currencyGuid,
          engagementGuid: engagement.engagementGuid
        })
      : Promise.resolve();

    const promise = apiServiceUpdateProjectCurrencyPromise
      .then(() => {
        return ApiService.publishEngagement(engagement.engagementGuid, values);
      })
      .then(response => {
        pushToast({
          type: 'success',
          title: t('projectDetails.modal.successPublishToast.title'),
          content: (
            <Trans i18nKey="projectDetails.modal.successPublishToast.content">
              <b>{{ projectName }}</b> has been published and is ready for
              clients to view.
            </Trans>
          )
        });

        onSave({
          ...response.data,
          ...(isIsoCurrencyCodeUpdated && { projectCurrency: isoCurrencyCode })
        });
      })
      .catch(error => {
        handleDefaultApiError(error);
      })
      .finally(() => {
        onClose();
      });

    this.setState({ loading: promise });
  };

  render() {
    const { t, open, onClose, engagement } = this.props;
    const { initialValues, loading } = this.state;

    const isSelectIsoCurrencyCodeDisabled =
      engagement.projectCurrency != null &&
      engagement.lastSavingsSummaryAsOfDate != null;

    return (
      <Modal
        className="project-details-modal"
        onClose={onClose}
        open={open}
        title={
          (
            <Trans i18nKey={`${ROOT_TO_TEXT}.title`}>
              <span className="project-details-modal__title"></span>
              {{ engagementName: engagement.engagementDisplayNameShort }}
            </Trans>
          ) as unknown as string
        }
      >
        <Formik
          initialValues={initialValues}
          onSubmit={this.submitProjectDetailsModal}
          validationSchema={this.schema}
        >
          {formik => (
            <form onSubmit={formik.handleSubmit}>
              <p>{t(`${ROOT_TO_TEXT}.description`)}</p>
              <div className="project-details-modal__dates-container">
                <div className="row">
                  <div className="col-lg-6">
                    <Datepicker
                      {...formikDatepickerProps('startDate', formik)}
                      disabled={!engagement.isStartDateEditable}
                      helperText={
                        !engagement.isStartDateEditable
                          ? t(
                              'projectDetails.modal.projectDetails.cannotEditStartDate'
                            )
                          : ''
                      }
                      label={t('projectDetails.modal.startDate.label')}
                      maxDate={
                        formik.values.endDate
                          ? addDays(formik.values.endDate, -1)
                          : undefined
                      }
                    />
                  </div>
                  <div className="col-lg-6">
                    <Datepicker
                      {...formikDatepickerProps('endDate', formik)}
                      disabled={!engagement.isProjectedEndDateEditable}
                      helperText={
                        !engagement.isProjectedEndDateEditable
                          ? t(
                              'projectDetails.modal.projectDetails.cannotEditEndDate'
                            )
                          : ''
                      }
                      label={t('projectDetails.modal.endDate.label')}
                      minDate={
                        formik.values.startDate
                          ? addDays(formik.values.startDate, 1)
                          : undefined
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <Datepicker
                      {...formikDatepickerProps('statuteOfLimitations', formik)}
                      disabled={!engagement.isStatuteOfLimitationsDateEditable}
                      helperText={
                        !engagement.isStatuteOfLimitationsDateEditable
                          ? t(
                              'projectDetails.modal.projectDetails.cannotStatuteDate'
                            )
                          : ''
                      }
                      label={t('projectDetails.modal.statuteOfLimitations')}
                    />
                  </div>
                  <div className="project-details-modal__dates-container__currency-option col-lg-6">
                    <Dropdown
                      {...formikFieldProps('isoCurrencyCode', formik)}
                      disabled={isSelectIsoCurrencyCodeDisabled}
                      // TODO: Update components library to allow JSX if this pattern continues
                      label={
                        (
                          <>
                            {t('contracts.modal.projectCurrencyOptional')}
                            <InfoTooltip>
                              {t('contracts.modal.projectCurrencyToolTip')}
                            </InfoTooltip>
                          </>
                        ) as any
                      }
                      options={[
                        ...(!isSelectIsoCurrencyCodeDisabled &&
                        engagement.projectCurrency == null
                          ? [{ label: `- ${t('Select')} -`, value: '' }]
                          : []),
                        ...this.state.isoCurrencyCodeOptions.map(option => ({
                          label: `${option.isoAlphaCode} - ${option.ryanCurrencyName}`,
                          value: option.isoAlphaCode
                        }))
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="project-details-modal__button-group">
                <ButtonGroup>
                  <Button
                    block
                    loading={loading}
                    text={t(`${ROOT_TO_TEXT}.updateAndPublish`)}
                    type="submit"
                    variant="primary"
                  />
                  <Button
                    block
                    onClick={onClose}
                    text={t('Cancel')}
                    type="button"
                  />
                </ButtonGroup>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default withTranslation()(ProjectDetailsPublishModal);
