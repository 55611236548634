import { IContractFileOverflowMenuProps } from './ContractFileOverflowMenu.interfaces';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, DropdownMenu, IDropdownMenuOption } from '@ryan/components';

import { useUser } from '../../../hooks';

const ContractFileOverflowMenu: React.FunctionComponent<
  IContractFileOverflowMenuProps
> = ({
  contractFile,
  isEngagementGhosting,
  isEngagementReadOnly,
  isReadOnlyByUserState,
  menuPlacement = 'top',
  onDownload,
  onEdit,
  onPublish,
  onUnpublish,
  overflowButtonSize
}) => {
  const { t: getTextToDisplay } = useTranslation();
  const { user } = useUser();

  const TEXT_TO_DISPLAY_ROOT = 'table.contractFileOverflowMenu';

  const isContractPublished = Boolean(contractFile.publishedDate);
  const isReadOnlyByEngagementState =
    isEngagementGhosting || isEngagementReadOnly;
  const isBigFourOfContractFile = contractFile.bigFourUsers.includes(
    user.profile.userGuid
  );
  const isDisabled = isReadOnlyByUserState || isReadOnlyByEngagementState;

  const dropdownMenuOptions = [
    ...(isBigFourOfContractFile
      ? [
          {
            ...(isContractPublished && { negative: true }),
            disabled: isDisabled,
            icon: isContractPublished ? 'hide' : 'show',
            label: getTextToDisplay(
              `${TEXT_TO_DISPLAY_ROOT}.${
                isContractPublished ? 'unpublish' : 'publish'
              }`
            ),
            onClick: isContractPublished ? onUnpublish : onPublish
          }
        ]
      : []),
    ...(isBigFourOfContractFile
      ? [
          {
            disabled: isDisabled,
            icon: 'pencil',
            label: getTextToDisplay(`${TEXT_TO_DISPLAY_ROOT}.edit`),
            onClick: onEdit
          }
        ]
      : []),
    {
      disabled: isReadOnlyByUserState || isEngagementGhosting,
      icon: 'download',
      label: getTextToDisplay(`${TEXT_TO_DISPLAY_ROOT}.download`),
      onClick: onDownload
    }
  ] as IDropdownMenuOption[];

  return (
    <DropdownMenu
      options={dropdownMenuOptions}
      placement={menuPlacement}
      renderTarget={({ onClick, open, ref }) => (
        <Button
          ariaExpanded={open}
          ariaHaspopup="menu"
          icon="more-outline"
          innerRef={ref}
          onClick={onClick}
          size={overflowButtonSize}
          variant="text"
        />
      )}
    />
  );
};

export default ContractFileOverflowMenu;
