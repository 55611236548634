import DocumentTitle from 'components/DocumentTitle';
import TabsAndRoutes from 'components/Tabs/TabsAndRoutes';
import { useUser } from 'contexts/UserContext';
import { Permission } from 'interfaces';
import AmplitudeApiService from 'services/AmplitudeApiService';

import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Overview from './Overview/Overview';
import Reporting from './Reporting/Reporting';
import SavingsSummaryOverview from './SavingsSummaryOverview/SavingsSummaryOverview';
import Tasks from './Tasks/Tasks';
import Timelines from './Timelines/Timelines';

export const Projects: FunctionComponent = () => {
  const { t } = useTranslation();
  const { activeView, permissionService: ps } = useUser();

  return (
    <div className="projects">
      <DocumentTitle title={t('projects.title')} />
      <h4 className="ry-h4">{activeView.name}</h4>
      <h1 className="ry-h1">{t('projects.title')}</h1>
      <TabsAndRoutes
        onTabChangeHandler={key => {
          if (key === '/app/projects/reporting') {
            AmplitudeApiService.logEvent('initiate-reports', {
              'reporting-path': 'reporting tab'
            });
          } else if (key === '/app/projects/timelines') {
            AmplitudeApiService.logEvent('view-timeline', {
              'timeline-path': 'all projects - timelines tab'
            });
          }
        }}
        tabs={[
          {
            label: t('Overview'),
            path: '/app/projects/overview',
            component: Overview
          },
          {
            label: t('timelines.title'),
            path: '/app/projects/timelines',
            component: Timelines,
            enabled: ps.hasPermission(Permission.TimelinesView)
          },
          {
            label: t('Tasks'),
            path: '/app/projects/tasks',
            component: Tasks,
            enabled: ps.hasPermission(Permission.TasksView)
          },
          {
            label: t('Savings'),
            path: '/app/projects/savings-summary',
            component: SavingsSummaryOverview,
            enabled: ps.hasPermission(Permission.SavingsSummaryView)
          },
          {
            label: t('Reporting'),
            path: '/app/projects/reporting',
            component: Reporting,
            enabled: ps.hasPermission(Permission.ReportsView)
          }
        ]}
      />
    </div>
  );
};

export default Projects;
