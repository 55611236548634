import AccountHierarchy from 'components/AccountHierarchy/AccountHierarchy';
import Table from 'components/Table';
import { WithUser, withUser } from 'contexts/UserContext';
import {
  EngagementStatus,
  IEngagementMemberStatus,
  ITableState,
  ITeamManagerEngagementsSearchRequest,
  IUser
} from 'interfaces';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import debouncedSearch from 'utils/debouncedSearch';
import getSortParam from 'utils/getSortParm';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { ChangeEvent, Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  Checkbox,
  Tooltip,
  makeTableCheckboxFilter,
  pushToast
} from '@ryan/components';

import UnsavedChangesContext from '../../../contexts/UnsavedChangesContext/UnsavedChangesContext';

import './EditUserProjects.scss';

enum EngagementMemberStatus {
  None,
  MemberGhosted,
  MemberVisible
}

function getEngagementMemberStatus(memberStatus: IEngagementMemberStatus) {
  if (memberStatus.isMember === false) {
    return EngagementMemberStatus.None;
  }
  return memberStatus.isGhosted
    ? EngagementMemberStatus.MemberGhosted
    : EngagementMemberStatus.MemberVisible;
}

interface IEditUserProjectsProps extends WithUser, WithTranslation {
  userToEdit: IUser;
  onDone: () => void;
}

interface IEditUserProjectsState extends ITableState {
  searchQuery: string;
  engagements: IEngagementMemberStatus[];
  changes: { [key: string]: EngagementMemberStatus | undefined };
  changesPromise: Promise<any> | null;
  selectedEngagements: IEngagementMemberStatus[];
}

export class EditUserProjects extends Component<
  IEditUserProjectsProps,
  IEditUserProjectsState
> {
  private columns: any;

  private _isMounted = false;

  private source?: CancelTokenSource;

  constructor(props: IEditUserProjectsProps) {
    super(props);
    const { t } = props;

    this.state = {
      // Table
      engagements: [],
      loading: true,
      searchQuery: '',
      page: 1,
      pageSize: 10,
      totalCount: 0,
      sorted: {
        id: 'engagementDisplayNameLong',
        desc: false
      },
      filtered: {
        status: [EngagementStatus.Active.toString()]
      },

      // Changes
      changes: {},
      changesPromise: null,
      selectedEngagements: []
    };

    this.columns = [
      {
        id: 'hierarchy',
        label: '',
        align: 'center',
        width: '3rem',
        render: (row: IEngagementMemberStatus) => (
          <AccountHierarchy
            hierarchy={row.accountHierarchy}
            isPublished={row.isPublished}
          />
        )
      },
      {
        id: 'engagementDisplayNameLong',
        label: t('Project Name'),
        sortable: true,
        render: (row: IEngagementMemberStatus) => (
          <div className="bs">
            <b>{row.engagementDisplayNameLong}</b>
            <small>
              {row.accountHierarchy[row.accountHierarchy.length - 1].name}
            </small>
          </div>
        )
      },
      {
        id: 'status',
        label: t('Status'),
        filter: makeTableCheckboxFilter([
          { value: EngagementStatus.Active.toString(), label: t('Active') },
          { value: EngagementStatus.InActive.toString(), label: t('Inactive') }
        ]),
        filterActive: (value: string) => !!value,
        render: (row: IEngagementMemberStatus) => {
          return (
            <div className={`pill ${row.isActive ? 'active' : 'inactive'}`}>
              {t(row.isActive ? 'Active' : 'Inactive')}
            </div>
          );
        }
      },
      {
        id: 'addToProject',
        label: t('manageTeam.addToProject'),
        align: 'center',
        render: this.renderIsMemberCheckbox
      },
      {
        id: 'isVisible',
        label: t('manageTeam.clientVisible'),
        align: 'center',
        render: this.renderIsVisibleCheckbox
      }
    ];
  }

  static contextType = UnsavedChangesContext;
  context!: React.ContextType<typeof UnsavedChangesContext>;

  componentDidUpdate() {
    const { changes, selectedEngagements } = this.state;
    const changedEngagements = selectedEngagements.filter(
      e => typeof changes[e.engagementGuid] !== 'undefined'
    );

    this.context.setIsUnsavedChanges(changedEngagements.length > 0);
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchEngagements();
  }

  componentWillUnmount() {
    this.source?.cancel();
    this.context.setIsUnsavedChanges(false);
    this._isMounted = false;
  }

  fetchEngagements(updates?: Partial<IEditUserProjectsState>) {
    this.setState(
      { ...(updates as IEditUserProjectsState), loading: true },
      async () => {
        const { userToEdit } = this.props;
        const { filtered, pageSize, page, searchQuery, sorted } = this.state;
        const { status } = filtered!;
        const params: ITeamManagerEngagementsSearchRequest = {
          userGuid: userToEdit.userGuid,
          itemsPerPage: pageSize,
          pageNumber: page,
          searchTerm: searchQuery,
          status: status.length === 1 ? status[0] : undefined,
          sort: getSortParam(sorted)
        };
        this.source?.cancel();
        this.source = ApiService.CancelToken.source();

        try {
          // Fetch the project information (for the logged in user - these are projects that the user can add people to)
          const {
            data: { results, totalResults }
          } = await ApiService.getEngagementsForTeamManager(
            params,
            this.source.token
          );

          this.setState({
            engagements: results,
            totalCount: totalResults
          });
        } catch (error) {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
          }
        } finally {
          if (this._isMounted) {
            this.setState({ loading: false });
          }
        }
      }
    );
  }

  handleMemberCheckboxChange = (
    engagement: IEngagementMemberStatus,
    isMember: boolean
  ) => {
    this.updateChanges(
      engagement,
      isMember
        ? EngagementMemberStatus.MemberGhosted
        : EngagementMemberStatus.None
    );
  };

  handleGhostCheckboxChange = (
    engagement: IEngagementMemberStatus,
    isVisible: boolean
  ) => {
    this.updateChanges(
      engagement,
      isVisible
        ? EngagementMemberStatus.MemberVisible
        : EngagementMemberStatus.MemberGhosted
    );
  };

  updateChanges(
    engagement: IEngagementMemberStatus,
    changeStatus: EngagementMemberStatus
  ) {
    const originalStatus = getEngagementMemberStatus(engagement);
    this.setState(
      ({
        changes: prevChanges,
        filtered,
        selectedEngagements: prevSelectedEngagements
      }) => {
        const changes = { ...prevChanges };
        const selectedEngagements = prevSelectedEngagements.find(
          e => e.engagementGuid === engagement.engagementGuid
        )
          ? prevSelectedEngagements
          : [...prevSelectedEngagements, engagement];

        if (changeStatus !== originalStatus) {
          changes[engagement.engagementGuid] = changeStatus;
        } else {
          delete changes[engagement.engagementGuid];
        }

        return {
          changes,
          selectedEngagements,

          // Sort of a hack - forces the table to be re-rendered,
          // since `changes` is not a prop on the Table.
          filtered: { ...filtered }
        };
      }
    );
  }

  handleSearchChange = debouncedSearch(
    (searchQuery: string) => {
      this.setState({ searchQuery });
    },
    (searchQuery: string) => {
      this.fetchEngagements({ page: 1, searchQuery });
    }
  );

  handleSortChange = (sorted: any) => {
    this.fetchEngagements({ sorted });
  };

  handlePageChange = (page: number, pageSize: number) => {
    this.fetchEngagements({ page, pageSize });
  };

  handleFilterChange = (filtered: any) => {
    this.fetchEngagements({ filtered, page: 1 });
  };

  handleSave = async () => {
    const { userToEdit, onDone, t } = this.props;
    const { changes, selectedEngagements } = this.state;
    const changedEngagements = selectedEngagements.filter(
      e => typeof changes[e.engagementGuid] !== 'undefined'
    );

    if (changedEngagements.length > 0) {
      this.setState({
        changesPromise: ApiService.manageRyanUserEngagements(
          userToEdit.userGuid,
          {
            userGuid: userToEdit.userGuid,
            employeeUserId: userToEdit.employeeUserId,
            userEngagementSettings: changedEngagements
              .map(e => ({
                engagementGuid: e.engagementGuid,
                isMember:
                  changes[e.engagementGuid] !== EngagementMemberStatus.None,
                isGhosted:
                  changes[e.engagementGuid] ===
                  EngagementMemberStatus.MemberGhosted
              }))
              .reduce((acc, e) => ({ ...acc, [e.engagementGuid]: e }), {})
          }
        )
          .then(() => {
            pushToast({
              type: 'success',
              content: t('manageTeam.userMembershipUpdatedInProjects', {
                count: changedEngagements.length,
                name: userToEdit.fullName,
                project: changedEngagements[0].engagementDisplayNameLong
              })
            });

            onDone();
          })
          .catch(() => {
            pushServerErrorToast();
          })
      });
    }
  };

  handleCancel = () => {
    this.props.onDone();
  };

  getEngagementMemberStatus(
    memberStatus: IEngagementMemberStatus
  ): EngagementMemberStatus {
    const changeStatus = this.state.changes[memberStatus.engagementGuid];
    if (changeStatus !== undefined) {
      return changeStatus;
    }
    return getEngagementMemberStatus(memberStatus);
  }

  renderIsMemberCheckbox = (row: IEngagementMemberStatus) => {
    // NOTE: Default canAssignToProject to true until API integration.
    // Otherwise checkbox will default to disabled at all times
    const {
      canAssignToProject = true,
      engagementGuid,
      isMember,
      isMembershipRemovable
    } = row;

    const disabled =
      !canAssignToProject || (isMember && !isMembershipRemovable);
    const status = this.getEngagementMemberStatus(row);

    const checked = status !== EngagementMemberStatus.None;

    const checkboxElement = (
      <Checkbox
        checked={checked}
        disabled={disabled}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          this.handleMemberCheckboxChange(row, e.target.checked);
        }}
        value={engagementGuid}
      />
    );

    return !canAssignToProject
      ? this.renderCheckboxWithToolTip(checkboxElement)
      : checkboxElement;
  };

  renderIsVisibleCheckbox = (row: IEngagementMemberStatus) => {
    // NOTE: Default canAssignToProject to true until API integration.
    // Otherwise checkbox will default to disabled at all times
    const { canAssignToProject = true, isUserBigFour } = row;

    const status = this.getEngagementMemberStatus(row);
    const checked = status === EngagementMemberStatus.MemberVisible;
    const disabled = !canAssignToProject || (isUserBigFour && checked);

    const checkboxElement = (
      <Checkbox
        checked={checked}
        disabled={disabled}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          this.handleGhostCheckboxChange(row, e.target.checked);
        }}
        value={row.engagementGuid}
      />
    );

    return !canAssignToProject
      ? this.renderCheckboxWithToolTip(checkboxElement)
      : checkboxElement;
  };

  renderCheckboxWithToolTip = (checkboxElement: React.ReactNode) => {
    const { t: getTextToDisplay } = this.props;

    return (
      <Tooltip
        content={getTextToDisplay('manageTeam.notEligibleToBeAddedToProject')}
        renderTarget={({ open, ...props }) => (
          <div aria-expanded={open} aria-haspopup="true" {...props}>
            {checkboxElement}
          </div>
        )}
      />
    );
  };

  render() {
    const { t } = this.props;

    const {
      engagements,
      sorted,
      searchQuery,
      page,
      pageSize,
      totalCount,
      filtered,
      loading,
      changesPromise,
      changes,
      selectedEngagements
    } = this.state;
    const changedEngagements = selectedEngagements.filter(
      e => typeof changes[e.engagementGuid] !== 'undefined'
    );

    return (
      <div className="edit-user-projects">
        <div className="edit-user-projects__table">
          {/*
            The list of projects that the user can be added to.
            Paginated - should include a state that has all projects the user will be added to
          */}
          <Table<IEngagementMemberStatus>
            columns={this.columns}
            data={engagements}
            filtered={filtered}
            loading={loading}
            onFilterChange={this.handleFilterChange}
            onPageChange={this.handlePageChange}
            onSearchChange={this.handleSearchChange}
            onSortChange={this.handleSortChange}
            page={page}
            pageSize={pageSize}
            rowId="engagementGuid"
            searchQuery={searchQuery}
            sorted={sorted}
            title={t('Your Projects')}
            totalCount={totalCount}
          />
        </div>
        <div className="edit-user-projects__buttons">
          <ButtonGroup>
            <Button
              onClick={this.handleCancel}
              size="lg"
              text={t('Cancel')}
              variant="secondary"
            />
            <Button
              disabled={changedEngagements.length === 0}
              loading={changesPromise}
              onClick={this.handleSave}
              size="lg"
              text={t('editUser.save')}
              variant="primary"
            />
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withUser(EditUserProjects));
