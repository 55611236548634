import { IEngagementTimeline } from 'interfaces';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import history from 'services/history';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import { addMonths, startOfYear } from 'date-fns';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button, Card } from '@ryan/components';

import AmplitudeApiService from '../../services/AmplitudeApiService';
import TimelinesCalendar from '../TimelinesCalendar/TimelinesCalendar';

import './TimelinesCard.scss';

interface ITimelinesCardProps extends WithTranslation {
  customViewGuid: string;
}

interface ITimelinesCardState {
  loading: boolean;
  engagements: IEngagementTimeline[] | null;
  alternateBeginDate: Date;
}

const MONTHS = 12;

function getEndDate(startDate: Date | number) {
  return new Date(addMonths(startDate, MONTHS).getTime() - 1);
}

export class TimelinesCard extends Component<
  ITimelinesCardProps,
  ITimelinesCardState
> {
  private _isMounted = false;
  private fetchTimelinesCancelToken?: CancelTokenSource;

  readonly state: ITimelinesCardState = {
    loading: false,
    engagements: null,
    alternateBeginDate: startOfYear(new Date())
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchTimelines();
  }

  componentDidUpdate(prevProps: ITimelinesCardProps) {
    if (prevProps.customViewGuid !== this.props.customViewGuid) {
      this.fetchTimelines();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.fetchTimelinesCancelToken?.cancel();
  }

  fetchTimelines(updates?: Partial<ITimelinesCardState>) {
    const { customViewGuid } = this.props;
    this.setState(
      {
        ...(updates as any),
        loading: true
      },
      async () => {
        const { alternateBeginDate } = this.state;
        try {
          // refresh cancel token
          this.fetchTimelinesCancelToken?.cancel();
          this.fetchTimelinesCancelToken = ApiService.CancelToken.source();

          const response = await ApiService.getTimelines(
            {
              customViewGuid,
              pageNumber: 1,
              itemsPerPage: 5,
              alternateBeginDate: alternateBeginDate.toISOString(),
              projectedEndDate: getEndDate(alternateBeginDate).toISOString(),
              sort: 'AlternateBeginDate'
            },
            this.fetchTimelinesCancelToken?.token
          );

          this.setState({
            engagements: response.data.results
          });
        } catch (error) {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
          }
        } finally {
          if (this._isMounted) {
            this.setState({
              loading: false
            });
          }
        }
      }
    );
  }

  handleViewAll = () => {
    const currentPage = history.location.pathname;

    AmplitudeApiService.logEvent('view-timeline', {
      'timeline-path': `${
        currentPage.includes('/app/projects/overview')
          ? 'all projects'
          : 'dashboard'
      } - view full timeline`
    });
    history.push('/app/projects/timelines');
  };

  render() {
    const { t } = this.props;
    const { loading, engagements, alternateBeginDate } = this.state;

    return (
      <Card
        className="timelines-card"
        role="region"
        title={t('timelines.title')}
      >
        <TimelinesCalendar
          engagements={engagements}
          loading={loading}
          months={MONTHS}
          renderCTA={() => (
            <div className="timelines-card__view-all">
              <Button
                onClick={this.handleViewAll}
                text={t('timelines.card.viewAll')}
                variant="primary"
              />
            </div>
          )}
          startDate={alternateBeginDate}
          t={t}
        />
      </Card>
    );
  }
}

export default withTranslation()(TimelinesCard);
