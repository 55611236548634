import React, { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';

import { useUser } from '../../../contexts/UserContext';
import {
  ActivityType,
  Feature,
  IDataRequestSummary,
  Status
} from '../../../interfaces';
import { AbstractActivity, ActivityColor } from '../AbstractActivity';
import { ActivityModified } from '../ActivityModified';
import { IActivityProps } from '../IActivityProps';
import { UnrecognizedActivity } from '../UnrecognizedActivity';
import { findModifiedByKey } from '../findModifiedByKey';
import { CommentActivity } from './CommentActivity';

export const DataRequestActivity: FunctionComponent<IActivityProps> = props => {
  const {
    t,
    user,
    activity,
    isNotification,
    showAccountName,
    showEngagementName,
    onCloseNotificationDrawer
  } = props;

  const {
    entityGuid,
    createdBy,
    createdByName,
    snapShot: { entitySummary, documentInformation },
    userInformation
  } = activity;

  const { isFeatureToggled } = useUser();
  const isDataRequestsUpdateVisible = isFeatureToggled(Feature.RWMDataRequests);
  const { title, userGuid } = entitySummary as IDataRequestSummary;

  // Context helper.
  function you(userGuid: string) {
    return user.userGuid === userGuid ? 'you' : 'x';
  }

  switch (activity.activityTypeId) {
    //

    /**
     * Created
     */
    case ActivityType.DataRequestCreated: {
      const tBy = you(createdBy);
      const tAssigned = you(userGuid);
      const tKey = `activity.dataRequest.created.by-${tBy}-assigned-${tAssigned}`;
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          ctaText={t('View Data Request')}
          icon="file-plus"
          isNotification={isNotification}
          isRedirectToDecisions={isDataRequestsUpdateVisible}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.dataRequest.created.title')}
          to={
            isDataRequestsUpdateVisible ? '' : `/app/data-request/${entityGuid}`
          }
        >
          <Trans i18nKey={tKey}>
            <b />
            {{ title }}
            {{ createdByName }}
            {{ assignedToName: userInformation.fullName }}
          </Trans>{' '}
        </AbstractActivity>
      );
    }

    /**
     * Reassigned
     */
    case ActivityType.DataRequestAssignedToUser: {
      const { newValue: newAssignedTo } = findModifiedByKey(
        activity,
        'AssignedToUserGuid'
      );

      const tBy = you(createdBy);
      const tAssigned = you(newAssignedTo.userGuid);
      const tKey = `activity.dataRequest.reassigned.by-${tBy}-assigned-${tAssigned}`;

      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          ctaText={t('View Data Request')}
          icon="file-user"
          isNotification={isNotification}
          isRedirectToDecisions={isDataRequestsUpdateVisible}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.dataRequest.reassigned.title')}
          to={
            isDataRequestsUpdateVisible ? '' : `/app/data-request/${entityGuid}`
          }
        >
          <Trans i18nKey={tKey}>
            <b />
            {{ title }}
            {{ assignedToName: newAssignedTo.fullName }}
            {{ createdByName }}
          </Trans>{' '}
          <ActivityModified
            activity={activity}
            cases={[
              {
                key: 'AssignedToUserGuid',
                label: t('Assigned To'),
                type: (value: { fullName: string }) => value.fullName
              }
            ]}
          />
        </AbstractActivity>
      );
    }

    /**
     * Edited
     */
    case ActivityType.DataRequestModified: {
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          ctaText={t('View Data Request')}
          icon="file-pencil"
          isNotification={isNotification}
          isRedirectToDecisions={isDataRequestsUpdateVisible}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.dataRequest.edited.title')}
          to={
            isDataRequestsUpdateVisible ? '' : `/app/data-request/${entityGuid}`
          }
        >
          <Trans i18nKey={`activity.dataRequest.edited.by-${you(createdBy)}`}>
            <b />
            {{ title }}
            {{ createdByName }}
          </Trans>{' '}
          <ActivityModified
            activity={activity}
            cases={[
              {
                key: 'Title',
                label: t('Title'),
                type: 'string' as const
              },
              {
                key: 'Status',
                label: t('Status'),
                type: (status: Status) => t(`status.${status}`)
              },
              {
                key: 'Description',
                label: t('Description'),
                type: 'string' as const,
                clamp: true
              },
              {
                key: 'DataSpecs',
                label: t('Data Specs'),
                type: 'string' as const,
                clamp: true
              },
              {
                key: 'Attachments',
                label: t('Attachments'),
                type: (value: string[]) => (
                  <ul>
                    {value.map((filename, i) => (
                      <li key={i}>{filename}</li>
                    ))}
                  </ul>
                )
              },
              {
                key: 'DueDate',
                label: t('Due Date'),
                type: 'date' as const
              }
            ]}
          />
        </AbstractActivity>
      );
    }

    /**
     * Completed
     */
    case ActivityType.DataRequestStatusComplete: {
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Green}
          ctaText={t('View Data Request')}
          icon="file-check"
          isNotification={isNotification}
          isRedirectToDecisions={isDataRequestsUpdateVisible}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.dataRequest.completed.title')}
          to={
            isDataRequestsUpdateVisible ? '' : `/app/data-request/${entityGuid}`
          }
        >
          <Trans
            i18nKey={`activity.dataRequest.completed.by-${you(createdBy)}`}
          >
            <b />
            {{ title }}
            {{ createdByName }}
          </Trans>{' '}
          <ActivityModified
            activity={activity}
            cases={[
              {
                key: 'Status',
                label: t('Status'),
                type: (status: Status) => t(`status.${status}`)
              }
            ]}
          />
        </AbstractActivity>
      );
    }

    /**
     * Past Due
     */
    case ActivityType.DataRequestIsPastDue: {
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Red}
          ctaText={t('View Data Request')}
          icon="file-warning"
          isNotification={isNotification}
          isRedirectToDecisions={isDataRequestsUpdateVisible}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.dataRequest.pastDue.title')}
          to={
            isDataRequestsUpdateVisible ? '' : `/app/data-request/${entityGuid}`
          }
        >
          <Trans
            i18nKey={`activity.dataRequest.pastDue.assigned-${you(userGuid)}`}
          >
            <b />
            {{ title }}
            {{ assignedToName: userInformation.fullName }}
          </Trans>{' '}
        </AbstractActivity>
      );
    }

    /**
     * Deleted
     */
    case ActivityType.DataRequestDeleted: {
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          icon="file-delete"
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.dataRequest.deleted.title')}
        >
          <Trans i18nKey={`activity.dataRequest.deleted.by-${you(createdBy)}`}>
            <b />
            {{ title }}
            {{ createdByName }}
          </Trans>
        </AbstractActivity>
      );
    }

    /**
     * Files Uploaded
     */
    case ActivityType.FileUploadedToDataRequest:
    case ActivityType.FileUploadedOnBehalfOfUserToDataRequest: {
      const documents = documentInformation;
      const document = documents[0];
      return (
        <AbstractActivity
          activity={activity}
          color={ActivityColor.Blue}
          ctaText={t('View Data Request')}
          icon="data-file-upload"
          isNotification={isNotification}
          isRedirectToDecisions={isDataRequestsUpdateVisible}
          onCloseNotificationDrawer={onCloseNotificationDrawer}
          showAccountName={showAccountName}
          showEngagementName={showEngagementName}
          title={t('activity.dataRequest.filesUploaded.title')}
          to={
            isDataRequestsUpdateVisible ? '' : `/app/data-request/${entityGuid}`
          }
        >
          <Trans
            count={documents.length}
            i18nKey={
              // by-x
              // by-x-behalf-x
              // by-x-behalf-you
              // by-you
              // by-you-behalf-x
              // by-you-behalf-you
              `activity.dataRequest.filesUploaded.by-${you(createdBy)}${
                document.onBehalfOfUserGuid !== null
                  ? `-behalf-${you(document.onBehalfOfUserGuid)}`
                  : ''
              }`
            }
          >
            <b />
            {{ title }}
            {{ createdByName }}
            {{ behalfOfName: document.onBehalfOfUserName }}
          </Trans>{' '}
        </AbstractActivity>
      );
    }

    case ActivityType.DataRequestCommentedOn: {
      return (
        <CommentActivity
          {...props}
          editedComment={false}
          link={`/app/data-request/${entityGuid}/#comments`}
        >
          {mentionedYou => (
            <Trans
              i18nKey={`activity.dataRequest.commented.by-${you(createdBy)}${
                mentionedYou ? '-mentioned-you' : ''
              }`}
            >
              <b />
              {{ title }}
              {{ createdByName }}
            </Trans>
          )}
        </CommentActivity>
      );
    }

    case ActivityType.DataRequestCommentModified: {
      return (
        <CommentActivity
          {...props}
          editedComment
          link={`/app/data-request/${entityGuid}/#comments`}
        >
          {mentionedYou => (
            <Trans
              i18nKey={`activity.dataRequest.commentEdited.by-${you(
                createdBy
              )}${mentionedYou ? '-mentioned-you' : ''}`}
            >
              <b />
              {{ title }}
              {{ createdByName }}
            </Trans>
          )}
        </CommentActivity>
      );
    }

    default:
      return <UnrecognizedActivity {...props} />;
  }
};
