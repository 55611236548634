import { useUser } from 'contexts/UserContext';
import { Feature, MAX_SELECTED_COMPANIES } from 'interfaces';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Tooltip } from '@ryan/components';

import { useSwitcher } from '../SwitcherContext';

import './SwitcherFilterApplyButton.scss';

const SwitcherFilterApplyButton: React.FC = () => {
  const { t } = useTranslation();
  const { isFeatureToggled } = useUser();
  const {
    filterApplyLoading,
    filterCompanies,
    filterCustomView,
    onFilterCustomViewApply
  } = useSwitcher();
  const hasNoneSelected =
    filterCustomView === null && filterCompanies.view === null;
  const hasTooManySelected =
    isFeatureToggled(Feature.LimitedCustomView) &&
    filterCompanies.view !== null &&
    filterCompanies.view.filters !== null &&
    filterCompanies.view.filters.length > MAX_SELECTED_COMPANIES;
  const button = (
    <Button
      block={true}
      disabled={hasNoneSelected || hasTooManySelected}
      loading={filterApplyLoading}
      onClick={onFilterCustomViewApply}
      size="sm"
      variant="primary"
    >
      {t('Apply')}
    </Button>
  );

  if (hasTooManySelected) {
    return (
      <Tooltip
        content={t('switcher.filterApplyDisabled')}
        placement="top"
        renderTarget={({ open, ...tooltipProps }) => (
          // disabled buttons don't fire mouse events, so we have to wrap it
          <div
            className="switcher-filter-apply-button__container"
            {...tooltipProps}
          >
            {button}
          </div>
        )}
      />
    );
  }

  return button;
};

export default SwitcherFilterApplyButton;
