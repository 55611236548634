import image from 'images/404.svg';
import history from 'services/history';

import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

// import { Formik, formikFieldProps } from 'utils/forms';
import {
  Button
  /* Textarea */
} from '@ryan/components';

import './ErrorPage.scss';

const Error404: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="error-page error-page__404">
      <img alt="" src={image} />
      <h2>{t('errorPage.404.header')}</h2>

      {/*
      <Formik
        initialValues={{ message: '' }}
        onSubmit={(values) => {
          ApiService.log404(values.message);
          history.push('/app/dashboard');
        }}
        render={formik => (
          <form onSubmit={formik.handleSubmit}>
            <p>{t('errorPage.404.content')}</p>
            <Textarea
              {...formikFieldProps('message', formik)}
              label={t('Message')}
              maxLength={250}
              placeholder={t('Message')}
            />
            <div className="error-page__404--buttons">
              <Button text={t('Submit')} variant="primary" />
            </div>
          </form>
        )}
      />
      */}

      <div className="error-page__404--buttons">
        <Button
          onClick={() => {
            history.push(`/app`);
          }}
          size="sm"
          text={t('errorPage.404.button')}
          variant="text"
        />
      </div>
    </div>
  );
};

export default Error404;
