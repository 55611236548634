import Modal from 'components/Modal';
import EngagementContext from 'contexts/EngagementContext';
import { IDataRequest } from 'interfaces';
import ApiService from 'services/ApiService';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { ChangeEvent, Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button, ButtonGroup, Checkbox, pushToast } from '@ryan/components';

import './DataRequestDeleteModal.scss';

interface IDataRequestDeleteModalProps extends WithTranslation {
  open?: boolean;
  dataRequest: IDataRequest;
  onClose: (deleted: boolean) => void;
}

interface IDataRequestDeleteModalState {
  deleteFiles: boolean;
  submitPromise: Promise<any> | null;
}

export class DataRequestDeleteModal extends Component<
  IDataRequestDeleteModalProps,
  IDataRequestDeleteModalState
> {
  static contextType = EngagementContext;
  context!: React.ContextType<typeof EngagementContext>;

  readonly state: IDataRequestDeleteModalState = {
    deleteFiles: false,
    submitPromise: null
  };

  handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ deleteFiles: e.target.checked });
  };

  handleCancel = () => {
    this.props.onClose(false);
  };

  handleDelete = async () => {
    const { t, dataRequest, onClose } = this.props;
    const { title, queueItemGuid } = dataRequest;
    const { deleteFiles } = this.state;

    this.setState({
      submitPromise: ApiService.deleteDataRequest(
        dataRequest.engagementGuid,
        queueItemGuid,
        deleteFiles
      )
        .then(() => {
          pushToast({
            type: 'success',
            title: t('dataRequest.deleteModal.success.title'),
            content: t('dataRequest.deleteModal.success.content', { title })
          });
          onClose(true);
        })
        .catch(() => {
          pushServerErrorToast();
          onClose(false);
        })
    });
  };

  render() {
    const { t, open = true } = this.props;
    const { deleteFiles, submitPromise } = this.state;
    return (
      <Modal
        className="data-request-delete-modal"
        onClose={this.handleCancel}
        open={open}
        title={t('dataRequest.deleteModal.title')}
      >
        <p>{t('dataRequest.deleteModal.content')}</p>
        <Checkbox
          checked={deleteFiles}
          label={t('dataRequest.deleteModal.deleteFiles')}
          onChange={this.handleCheckbox}
          value="delete-files"
        />
        <ButtonGroup>
          <Button
            loading={submitPromise}
            negative
            onClick={this.handleDelete}
            text={t('Yes, Delete')}
          />
          <Button
            disabled={submitPromise !== null}
            onClick={this.handleCancel}
            text={t('No, Cancel')}
            variant="secondary"
          />
        </ButtonGroup>
      </Modal>
    );
  }
}

export default withTranslation()(DataRequestDeleteModal);
