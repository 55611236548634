import { AxiosResponse } from 'axios';
import { addDays, startOfDay } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, ButtonGroup, Modal, pushToast } from '@ryan/components';

import { IDeactivateUserResponse } from '../../../interfaces';
import ApiService from '../../../services/ApiService';
import { MessageTypeEnums } from '../../../utils/enums/MessageTypeEnums';
import NowOrLater from '../../NowOrLater';
import {
  DeactivateUserModalEnums,
  IDeactivateUserModalProps,
  IHandleDeactivate
} from './utils';

const DeactivateUserModal: React.FC<IDeactivateUserModalProps> = ({
  deactivateTimestamp = null,
  hasDeferredDeactivationOption = false,
  onClose,
  userFullName,
  userGuid
}) => {
  const { t: textToDisplay } = useTranslation();

  const [apiServicePromise, setApiServicePromise] = useState<Promise<
    AxiosResponse<IDeactivateUserResponse>
  > | null>(null);
  const [deactivateLaterDate, setDeactivateLaterDate] = useState<Date | null>(
    null
  );
  const [isDeactivateNow, setIsDeactivateNow] = useState(true);

  const { ROOT_TO_TEXT } = DeactivateUserModalEnums;

  useEffect(() => {
    if (!hasDeferredDeactivationOption) {
      return;
    }

    const todaysDate = new Date();

    const hasFutureDeactivation =
      deactivateTimestamp && new Date(deactivateTimestamp) > todaysDate;

    setDeactivateLaterDate(
      hasFutureDeactivation
        ? new Date(deactivateTimestamp!)
        : startOfDay(addDays(todaysDate, 1))
    );
    setIsDeactivateNow(!hasFutureDeactivation);
  }, [deactivateTimestamp, hasDeferredDeactivationOption]);

  const handleDeactivate: IHandleDeactivate = async ({
    callbacks,
    deactivateDate,
    guid,
    toasts
  }) => {
    try {
      const apiServiceDeactivateUserResponse = ApiService.deactivateUser(
        guid,
        deactivateDate.toISOString()
      );

      callbacks.setApiServicePromiseCallback(apiServiceDeactivateUserResponse);
      await apiServiceDeactivateUserResponse;

      pushToast(toasts.successToast);
      callbacks.onCloseCallback(true);
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushToast(toasts.errorToast);
      }
      callbacks.onCloseCallback();
    }
  };

  return (
    <Modal
      onClose={onClose}
      open
      title={textToDisplay(`${ROOT_TO_TEXT}.title`)}
    >
      <p>
        <Trans i18nKey={`${ROOT_TO_TEXT}.deactivatePrompt`}>
          <b>{{ userFullName }}</b>
        </Trans>
      </p>

      {hasDeferredDeactivationOption && (
        <NowOrLater
          laterDate={deactivateLaterDate}
          laterLabel={textToDisplay(`${ROOT_TO_TEXT}.deactivateLater`)}
          now={isDeactivateNow}
          nowLabel={textToDisplay(`${ROOT_TO_TEXT}.deactivateNow`)}
          onChange={(
            isDeactivateNowSelected: boolean,
            updatedDeactivateLaterDate: Date | null
          ) => {
            setDeactivateLaterDate(updatedDeactivateLaterDate);
            setIsDeactivateNow(isDeactivateNowSelected);
          }}
        />
      )}

      <ButtonGroup>
        <Button
          onClick={() => onClose()}
          text={textToDisplay(`${ROOT_TO_TEXT}.noCancel`)}
        />
        <Button
          loading={apiServicePromise}
          negative
          onClick={() =>
            handleDeactivate({
              callbacks: {
                onCloseCallback: onClose,
                setApiServicePromiseCallback: setApiServicePromise
              },
              deactivateDate:
                hasDeferredDeactivationOption && !isDeactivateNow
                  ? (deactivateLaterDate as Date)
                  : new Date(),
              guid: userGuid,
              toasts: {
                errorToast: {
                  title: textToDisplay(
                    `${ROOT_TO_TEXT}.errorToast.unableToDeactivate`,
                    { userFullName }
                  ),
                  type: MessageTypeEnums.ERROR
                },
                successToast: {
                  title: textToDisplay(
                    `${ROOT_TO_TEXT}.successToast.${
                      hasDeferredDeactivationOption && !isDeactivateNow
                        ? 'successfullyScheduledDeactivation'
                        : 'successfullyDeactivated'
                    }`,
                    { userFullName }
                  ),
                  type: MessageTypeEnums.SUCCESS
                }
              }
            })
          }
          text={textToDisplay(`${ROOT_TO_TEXT}.yesDeactivate`)}
          type="submit"
        />
      </ButtonGroup>
    </Modal>
  );
};

export default DeactivateUserModal;
