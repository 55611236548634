import { TDataRequestDetailsProps } from './DetailsByType.interfaces';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@ryan/components';

import Attachments from '../../../components/FileAttachments/Attachments';
import StatusIcon from '../../../components/StatusIcon/StatusIcon';
import { useUser } from '../../../hooks';
import { ButtonSizeEnums } from '../../../utils/enums/ButtonSizeEnums';
import { ButtonVariantEnums } from '../../../utils/enums/ButtonVariantEnums';
import { formatDate } from '../../../utils/formatDate';

import './DataRequestDetails.scss';

const DataRequestDetails = ({
  assignmentAssignedTo,
  assignmentAttachments,
  assignmentCreatedBy,
  assignmentCreatedDate,
  assignmentDataSpecs,
  assignmentDescription,
  assignmentDueDate,
  assignmentFollowerCount,
  assignmentStatusId,
  assignmentTitle,
  assignmentTransferredFilesCount,
  commentButtonDisplayTextPath,
  commentButtonProps,
  engagementDisplayNameShort,
  engagementGuid,
  isAssignmentCurrentlyWatched,
  isFollowButtonDisabled,
  isPastDue,
  onOpenDataRequestHistory,
  onOpenFollowersModal,
  onToggleFollow
}: TDataRequestDetailsProps) => {
  const ROOT_TO_TEXT = 'worklistPage.openTab.detailsByType';

  const { t: getTextToDisplay } = useTranslation();
  const {
    activeView,
    user: { profile: activeUserProfile }
  } = useUser();

  return (
    <div className="data-request-details">
      <div className="data-request-details__information">
        <h2>{engagementDisplayNameShort}</h2>
        <h3>{assignmentTitle}</h3>

        {assignmentDescription && (
          <>
            <h4>{getTextToDisplay(`${ROOT_TO_TEXT}.description`)}</h4>
            <p>{assignmentDescription}</p>
          </>
        )}

        {assignmentDataSpecs && (
          <>
            <h4 className="data-request-details__information__data-specs-title">
              {getTextToDisplay(`${ROOT_TO_TEXT}.dataRequest.dataSpecs`)}
            </h4>
            <p>{assignmentDataSpecs}</p>
          </>
        )}

        {assignmentAttachments.length > 0 && (
          <Attachments
            attachments={assignmentAttachments}
            disableDownload={
              /* dataRequest.isUserGhosted || TODO: Worklist: Keep this? */ activeView.isExecutiveView
            }
            engagementGuid={engagementGuid}
          />
        )}

        <div className="data-request-details__information__actions">
          <Button
            icon="time"
            onClick={onOpenDataRequestHistory}
            size={ButtonSizeEnums.SMALL}
            variant={ButtonVariantEnums.TEXT}
          >
            {getTextToDisplay(`${ROOT_TO_TEXT}.dataRequest.viewHistory`)}
          </Button>

          <Button
            {...commentButtonProps}
            // disabled={isUserGhosted} // TODO: Keep this functionality?
          >
            {commentButtonDisplayTextPath}
          </Button>
        </div>
      </div>

      <div className="data-request-details__status">
        <div className={`well${isPastDue ? ' past-due-assignment' : ''}`}>
          <StatusIcon status={assignmentStatusId} />

          <div className="data-request-details__status__details">
            <ul>
              <li>
                <label>
                  {getTextToDisplay(`${ROOT_TO_TEXT}.dataRequest.assignedTo`)}
                </label>
                {assignmentAssignedTo.userGuid === activeUserProfile.memberGuid
                  ? getTextToDisplay(
                      `${ROOT_TO_TEXT}.dataRequest.assignedToYou`
                    )
                  : assignmentAssignedTo.name}
              </li>
              <li>
                <label>
                  {getTextToDisplay(`${ROOT_TO_TEXT}.dataRequest.dueDate`)}
                </label>
                <span className={'due-date'}>
                  {formatDate(assignmentDueDate)}
                </span>
              </li>
              <li>
                <label>
                  {getTextToDisplay(`${ROOT_TO_TEXT}.dataRequest.requestedBy`)}
                </label>
                {assignmentCreatedBy.userGuid === activeUserProfile.memberGuid
                  ? getTextToDisplay(
                      `${ROOT_TO_TEXT}.dataRequest.requestedByYou`
                    )
                  : assignmentCreatedBy.name}
              </li>
              <li>
                <label>
                  {getTextToDisplay(
                    `${ROOT_TO_TEXT}.dataRequest.requestedDate`
                  )}
                </label>
                {formatDate(assignmentCreatedDate)}
              </li>
              <li>
                <label>{getTextToDisplay(`${ROOT_TO_TEXT}.followers`)}</label>
                <Button
                  disabled={isFollowButtonDisabled}
                  onClick={onToggleFollow}
                  size={ButtonSizeEnums.SMALL}
                  variant={ButtonVariantEnums.TEXT}
                >
                  {getTextToDisplay(
                    `${ROOT_TO_TEXT}.${
                      isAssignmentCurrentlyWatched ? 'unfollow' : 'follow'
                    }`
                  )}
                </Button>
                •
                <Button
                  onClick={onOpenFollowersModal}
                  size={ButtonSizeEnums.SMALL}
                  variant={ButtonVariantEnums.TEXT}
                >
                  {getTextToDisplay(`${ROOT_TO_TEXT}.followersWithCount`, {
                    count: assignmentFollowerCount
                  })}
                </Button>
              </li>
              {Boolean(assignmentTransferredFilesCount) && (
                <li>
                  <label>
                    {getTextToDisplay(
                      `${ROOT_TO_TEXT}.dataRequest.transferredFiles`
                    )}
                  </label>
                  {assignmentTransferredFilesCount}
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataRequestDetails;
