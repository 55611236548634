import DocumentTitle from 'components/DocumentTitle';
import { IPracticeArea } from 'interfaces';
import ApiService from 'services/ApiService';
import { PracticeAreaPatch } from 'services/ApiService/ApiLookup';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { FunctionComponent, useEffect, useState } from 'react';

import { Button, Checkbox, Table, TextInput } from '@ryan/components';

import './PracticeAreaConfiguration.scss';

const PracticeAreaConfiguration: FunctionComponent = () => {
  const [loading, setLoading] = useState(true);
  const [practiceAreas, setPracticeAreas] = useState<IPracticeArea[]>([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentlySelectedPracticeArea, setCurrentlySelectedPracticeArea] =
    useState<IPracticeArea | null>(null);

  useEffect(() => {
    const fetchPracticeAreas = async () => {
      try {
        let { data: practiceAreas } = await ApiService.getAllPracticeAreas();

        practiceAreas = practiceAreas.filter(
          practiceArea => practiceArea.isParent
        );

        setPracticeAreas(practiceAreas);
      } catch (error) {
        pushServerErrorToast();
      } finally {
        setLoading(false);
      }
    };

    fetchPracticeAreas();
  }, []);

  const columns = [
    {
      id: 'fullName',
      label: 'Name',
      render: (area: IPracticeArea) => `${area.practiceArea}`
    },
    {
      id: 'fullName',
      label: 'ID',
      render: (area: IPracticeArea) => `${area.practiceAreaId}`
    },
    {
      id: 'fullName',
      label: 'Background Color',
      render: (area: IPracticeArea) => `${area.backgroundColor || 'N/A'}`
    },
    {
      id: 'fullName',
      label: 'Text Color',
      render: (area: IPracticeArea) => `${area.textColor || 'N/A'}`
    },
    {
      id: 'fullName',
      label: 'Scheduling Tab Enabled',
      render: (area: IPracticeArea) => `${area.schedulingTabEnabled}`
    },
    {
      id: 'fullName',
      label: 'Edit',
      render: (area: IPracticeArea) => (
        <button
          onClick={() => {
            setEditModalOpen(true);
            setCurrentlySelectedPracticeArea(area);
          }}
        >
          Edit
        </button>
      )
    }
  ];

  return (
    <div className="parent-practice-area-table">
      <DocumentTitle title="Edit Practice Area" />
      <Table<IPracticeArea>
        columns={columns}
        data={practiceAreas || []}
        loading={loading}
        loadingCount={3}
        rowId="practiceAreaGuid"
      />
      {editModalOpen && currentlySelectedPracticeArea && (
        <EditPracticeAreaModal
          onClose={() => {
            setEditModalOpen(false);
            setCurrentlySelectedPracticeArea(null);
            setLoading(true);
            ApiService.getAllPracticeAreas()
              .then(({ data: practiceAreas }) => {
                practiceAreas = practiceAreas.filter(
                  practiceArea => practiceArea.isParent
                );

                setPracticeAreas(practiceAreas);
              })
              .catch(e => {
                pushServerErrorToast();
              })
              .finally(() => {
                setLoading(false);
              });
          }}
          selectedPracticeArea={currentlySelectedPracticeArea}
        />
      )}
    </div>
  );
};

const EditPracticeAreaModal: FunctionComponent<{
  onClose: () => void;
  selectedPracticeArea: IPracticeArea;
}> = ({ onClose, selectedPracticeArea }) => {
  const {
    backgroundColor: bgColor,
    textColor: txtColor,
    schedulingTabEnabled,
    practiceArea: name,
    practiceAreaId: id,
    practiceAreaGuid: guid
  } = selectedPracticeArea;

  const [backgroundColor, setBackgroundColor] = useState(bgColor || '');
  const [textColor, setTextColor] = useState(txtColor || '');
  const [enabled, setEnabled] = useState(schedulingTabEnabled);

  const updateDetailsForPracticeArea = () => {
    // OPTIMIZE - add basic validation, did something actually change? are the color valid hex strings?
    const params: PracticeAreaPatch = {
      backgroundColor,
      practiceAreaGuid: guid,
      schedulingTabEnabled: enabled,
      textColor
    };

    ApiService.updateParentPracticeArea(guid, params)
      .then(response => {
        onClose();
      })
      .catch(error => {
        onClose();
      });
  };

  return (
    <div className="practice-area-edit-modal">
      <div className="modal-content">
        <h3>
          Edit Practice Area for {name} - {id}
        </h3>
        <TextInput
          label="Background Color"
          onChange={e => setBackgroundColor(e.target.value)}
          value={backgroundColor}
        />
        <TextInput
          label="Text Color"
          onChange={e => setTextColor(e.target.value)}
          value={textColor}
        />
        <Checkbox
          checked={enabled}
          label="Scheduling Tab Enabled"
          onChange={event => setEnabled(event.target.checked)}
          value=""
        />
        <div className="modal-buttons">
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={updateDetailsForPracticeArea} variant="primary">
            Update
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PracticeAreaConfiguration;
