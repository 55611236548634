import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Tab, getTabKey } from './Tab';
import { Tabs } from './Tabs';

interface Props {
  tabs: Tab[];
  onTabChangeHandler?: (key: string) => void;
}

export const TabsAndRoutes: FunctionComponent<Props> = ({
  onTabChangeHandler,
  tabs
}) => {
  const enabledTabs = tabs.filter(tab => tab.enabled !== false);

  if (enabledTabs.length === 0) {
    return null;
  }

  const routes = enabledTabs.map(tab => {
    const { label, enabled, ...props } = tab;
    return <Route key={getTabKey(tab)} {...props} />;
  });

  return (
    <>
      <Tabs onTabChangeHandler={onTabChangeHandler} tabs={enabledTabs} />
      <Switch>
        {routes}
        <Redirect to={getTabKey(enabledTabs[0])} />
      </Switch>
    </>
  );
};

export default TabsAndRoutes;
