import React, { FunctionComponent, ReactNode } from 'react';
import { IActivity } from 'interfaces';
import { formatDate } from 'utils/formatDate';
import './ActivityModified.scss';

type Case = {
  key: string;
  label: string;
  type: 'string' | 'date' | ((datum: any) => ReactNode);
  clamp?: boolean;
};

interface IActivityModifiedProps {
  activity: IActivity;
  cases: Case[];
}

function getFormatter(_case: Case): (value: any) => ReactNode {
  if (typeof _case.type === 'function') {
    return _case.type;
  }
  switch (_case.type) {
    case 'string':
      return _case.clamp
        ? (value: string) => <div className="line-clamp">{value}</div>
        : (value: string) => value;
    case 'date':
      return (value: string) => (value ? formatDate(value) : '');
  }
}

export const ActivityModified: FunctionComponent<IActivityModifiedProps> = props => {
  const { activity, cases } = props;
  const { modifiedInformation } = activity.snapShot;
  if (
    modifiedInformation !== null &&
    modifiedInformation.length &&
    cases.some(c => modifiedInformation.some(m => m.key === c.key))
  ) {
    return (
      <div className="activity-modified">
        <table>
          <tbody>
            {modifiedInformation.map(modified => {
              const _case = cases.find(c => c.key === modified.key);
              if (_case) {
                const formatter = getFormatter(_case);
                return (
                  <tr key={modified.key}>
                    <th>{_case.label}</th>
                    <td>
                      {formatter(modified.newValue)}
                      <del>{formatter(modified.oldValue)}</del>
                    </td>
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
        </table>
      </div>
    );
  }
  return null;
};
