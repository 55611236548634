import { UserAutocomplete } from 'components/AutocompleteAjax';
import DocumentTitle from 'components/DocumentTitle';
import { WithUser, withUser } from 'contexts/UserContext';
import { IUserSummary } from 'interfaces';
import UserService from 'services/UserService';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button } from '@ryan/components';

import './Impersonate.scss';

interface IImpersonateProps extends WithTranslation, WithUser {
  // ...
}

interface IImpersonateState {
  impersonateUser: IUserSummary | null;
  loading: boolean;
}

class Impersonate extends Component<IImpersonateProps, IImpersonateState> {
  constructor(props: IImpersonateProps) {
    super(props);

    this.state = {
      impersonateUser: null,
      loading: false
    };
  }

  /**
   * Impersonate User
   */

  handleChangeUser = (user: IUserSummary | null) => {
    this.setState({ impersonateUser: user });
  };

  handleImpersonateUser = () => {
    const { impersonateUser } = this.state;
    const { setUser } = this.props;
    if (impersonateUser) {
      UserService.impersonate(impersonateUser.userGuid, setUser);
    }
  };

  /**
   * Render
   */

  render() {
    const { t } = this.props;
    const { impersonateUser } = this.state;

    return (
      <div className="impersonate-page">
        <DocumentTitle title={t('impersonate.title')} />

        <h4 className="ry-h4">{t('All Accounts')}</h4>
        <h1 className="ry-h1">{t('impersonate.title')}</h1>

        {/* Impersonate User */}
        <section className="impersonate-page__impersonate">
          <h3 className="ry-h3">{t('impersonate.impersonate')}</h3>
          <div className="horizontal-form">
            <UserAutocomplete
              helperText={t('impersonate.helperText')}
              onChange={this.handleChangeUser}
              value={impersonateUser}
            />
            <Button
              disabled={impersonateUser === null}
              onClick={this.handleImpersonateUser}
              text={t('impersonate.impersonate')}
              variant="primary"
            />
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation()(withUser(Impersonate));
