import { TTaskActionsProps } from './ActionsByType.interfaces';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, SplitButton } from '@ryan/components';

import { useAppReadOnly, useUser } from '../../../hooks';
import { Permission, Status, UserType } from '../../../interfaces';
import { ButtonSizeEnums } from '../../../utils/enums/ButtonPropsEnums';
import { IconEnums } from '../../../utils/enums/IconEnums';

const TaskActions = ({
  createdByUserType,
  isEngagementReadOnly,
  onMarkInProgress,
  onOpenCompleteModal,
  onOpenDeleteModal,
  onOpenEditModal,
  onOpenReassignModal,
  statusId
}: TTaskActionsProps) => {
  const ROOT_TO_TEXT = 'worklistPage.openTab.actionsByType.task';

  const isAppReadOnly = useAppReadOnly();
  const { t: getTextToDisplay } = useTranslation();
  const { permissionService } = useUser();

  const defaultProps = {
    block: true,
    size: ButtonSizeEnums.SMALL
  };
  const isCreatedByClient = createdByUserType === UserType.Client;
  const isDisabledSplitButtonOptions = isAppReadOnly || isEngagementReadOnly;

  const splitButtonOptions = [
    {
      disabled: isDisabledSplitButtonOptions,
      icon: IconEnums.PENCIL,
      label: getTextToDisplay(`${ROOT_TO_TEXT}.edit`),
      onClick: onOpenEditModal
    },
    ...(statusId === Status.Todo
      ? [
          {
            disabled: isDisabledSplitButtonOptions,
            icon: IconEnums.APPROVE,
            label: getTextToDisplay(`${ROOT_TO_TEXT}.complete`),
            onClick: onOpenCompleteModal
          }
        ]
      : []),
    {
      disabled: isDisabledSplitButtonOptions,
      icon: IconEnums.USER_REASSIGN,
      label: getTextToDisplay(`${ROOT_TO_TEXT}.reassign`),
      onClick: onOpenReassignModal
    },
    ...(permissionService.isClient() && !isCreatedByClient
      ? []
      : [
          {
            disabled: isDisabledSplitButtonOptions,
            icon: IconEnums.TRASH,
            label: getTextToDisplay(`${ROOT_TO_TEXT}.delete`),
            negative: true,
            onClick: onOpenDeleteModal
          }
        ])
  ];

  return permissionService.hasPermission(Permission.TasksEdit) ? (
    <SplitButton
      {...defaultProps}
      disabled={isDisabledSplitButtonOptions}
      onClick={
        statusId === Status.Todo ? onMarkInProgress : onOpenCompleteModal
      }
      options={splitButtonOptions}
      size={ButtonSizeEnums.SMALL}
    >
      {getTextToDisplay(
        `${ROOT_TO_TEXT}.mark${Object.values(Status)[statusId]}`
      )}
    </SplitButton>
  ) : (
    <Button {...defaultProps} disabled>
      {getTextToDisplay(
        `${ROOT_TO_TEXT}.${Object.values(Status)[statusId - 1]}`
      )}
    </Button>
  );
};

export default TaskActions;
