import ActivityEvents from 'components/RecentActivityCard/ActivityEvents';
import { WithUser, withUser } from 'contexts/UserContext';
import { ITask, ITaskCounts, Permission } from 'interfaces';
import AmplitudeApiService from 'services/AmplitudeApiService';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import history from 'services/history';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import { AxiosResponse } from 'axios';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button, Card, Icon, Tooltip } from '@ryan/components';

import UnsavedChangesContext from '../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import {
  AmplitudeActionType,
  getAmplitudeLocation
} from '../../utils/amplitudeUtils/amplitudeUtils';
import TaskModal from '../TaskActions/TaskModal/TaskModal';
import TaskDataViz from './TaskDataViz';

import './TaskDataViz.scss';

interface ITaskDataVizCardProps extends WithTranslation, WithUser {
  customViewGuid?: string;
  engagementGuid?: string;
  isEngagementReadOnly?: boolean;
}

interface ITaskDataVizCardState {
  counts: ITaskCounts | null;
  isNewTaskModalOpen: boolean;
}

class TaskDataVizCard extends React.Component<
  ITaskDataVizCardProps,
  ITaskDataVizCardState
> {
  private source?: CancelTokenSource;

  private _isMounted = false;

  static defaultProps = {
    isEngagementReadOnly: false
  };

  readonly state: ITaskDataVizCardState = {
    counts: null,
    isNewTaskModalOpen: false
  };

  static contextType = UnsavedChangesContext;
  context!: React.ContextType<typeof UnsavedChangesContext>;

  componentDidMount() {
    this._isMounted = true;
    this.fetchTaskCounts();
  }

  componentDidUpdate(prevProps: ITaskDataVizCardProps) {
    if (prevProps.customViewGuid !== this.props.customViewGuid) {
      this.fetchTaskCounts();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.source?.cancel();
  }

  handleViewTasks = () => {
    const { engagementGuid } = this.props;
    const { isUnsavedChanges, setBlockNavigation, setTargetUrl } = this.context;
    const tasksUrl = engagementGuid
      ? `/app/project/${engagementGuid}/tasks`
      : '/app/projects/tasks';
    const location = getAmplitudeLocation();

    if (isUnsavedChanges) {
      setBlockNavigation(true);
      setTargetUrl(tasksUrl);
    } else {
      history.push(tasksUrl);
    }
    if (location === 'dashboard') {
      AmplitudeApiService.logEvent(
        `${AmplitudeActionType.CLICK}-view-tasks-${location}`
      );
    }
  };

  handleNewTask = () => {
    const { permissionService: ps } = this.props;
    if (ps.hasPermission(Permission.TasksEdit)) {
      this.setState({ isNewTaskModalOpen: true });
    }
    const location = getAmplitudeLocation();
    if (location === 'dashboard') {
      AmplitudeApiService.logEvent(`create-tasks-${location}`);
    }
  };

  handleNewTaskClose = (task?: ITask) => {
    this.setState({ isNewTaskModalOpen: false });

    if (task) {
      ActivityEvents.emit();
      this.fetchTaskCounts();
    }
  };

  async fetchTaskCounts() {
    const { engagementGuid, customViewGuid } = this.props;

    let request: Promise<AxiosResponse<ITaskCounts>> | undefined;

    // refresh cancel token
    this.source?.cancel();
    this.source = ApiService.CancelToken.source();

    if (engagementGuid) {
      request = ApiService.getEngagementTaskCounts(
        engagementGuid,
        this.source.token
      );
    } else if (customViewGuid) {
      request = ApiService.getCustomViewTaskCounts(
        customViewGuid,
        this.source.token
      );
    }

    if (request) {
      try {
        const response = await request;
        // if unmounted, execution will stop at the above request since a cancel is triggered.
        // will to go to `catch` block
        this.setState({ counts: response.data });
      } catch (error) {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
        if (this._isMounted) {
          this.setState({
            counts: {
              toDoTotal: 0,
              inProgressTotal: 0,
              completeTotal: 0,
              total: 0
            }
          });
        }
      }
    }
  }

  render() {
    const {
      t,
      engagementGuid,
      isAppReadOnly,
      isEngagementReadOnly,
      permissionService: ps
    } = this.props;
    const { counts, isNewTaskModalOpen } = this.state;
    return (
      <>
        <Card className="task-dataviz" role="region" title={t('Tasks')}>
          <Tooltip
            content={t(
              engagementGuid
                ? 'taskDataViz.engagementTooltip'
                : 'taskDataViz.accountTooltip'
            )}
            placement="top"
            renderTarget={({ open, ...props }) => (
              <span
                aria-expanded={open}
                aria-haspopup="true"
                className="task-dataviz__tooltip"
                {...props}
              >
                <Icon name="information" />
              </span>
            )}
          />

          {counts === null ? (
            <div className="center-icon">
              <Icon className="loading-spin" name="loading" />
            </div>
          ) : (
            <TaskDataViz counts={counts} t={t} />
          )}

          <div className="task-dataviz__buttons">
            <Button
              block
              onClick={this.handleViewTasks}
              text={t('taskDataViz.view')}
              variant="primary"
            />

            {ps.hasPermission(Permission.TasksEdit) && (
              <Button
                aria-label="Add Task"
                disabled={isEngagementReadOnly || isAppReadOnly}
                icon="plus"
                onClick={this.handleNewTask}
                variant="secondary"
              />
            )}
          </div>
        </Card>

        {ps.hasPermission(Permission.TasksEdit) && (
          <TaskModal
            engagementGuid={engagementGuid}
            onClose={this.handleNewTaskClose}
            open={isNewTaskModalOpen}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(withUser(TaskDataVizCard));
