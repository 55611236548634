import Modal from 'components/Modal';
import { IRole, IRolePermission } from 'interfaces';
import ApiService from 'services/ApiService';
import isSameSetOfPermissions from 'utils/isSameSetOfPermissions';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { ChangeEvent, Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button, ButtonGroup, Dropdown, pushToast } from '@ryan/components';

import RolePermissions from './RolePermissions';
import RolePermissionsLegend from './RolePermissionsLegend';

import './EditRole.scss';

const clone = (a: any[]) => a.map(r => r);

interface IEditRoleProps extends WithTranslation {
  roles: IRole[]; // all roles
  role: IRole; // role to edit
  onSave: (role: IRole) => void;
  onCancel: () => void;
  onDelete: () => void;
}

interface IEditRoleState {
  permissions: IRolePermission[];
  savedPermissions: IRolePermission[];
  deleteRole: boolean;
  deleteReassignRole: IRole | null;
  deleteLoading: Promise<any> | null;
  loading: Promise<any> | null;
}

export class EditRole extends Component<IEditRoleProps, IEditRoleState> {
  constructor(props: IEditRoleProps) {
    super(props);

    const { rolePermissions } = props.role;

    this.state = {
      permissions: clone(rolePermissions),
      savedPermissions: clone(rolePermissions), // cached for save button
      deleteRole: false,
      deleteReassignRole: null,
      deleteLoading: null,
      loading: null
    };
  }

  /**
   * Refresh permissions when role is changed.
   */
  componentDidUpdate(prevProps: IEditRoleProps) {
    const { role } = this.props;
    if (prevProps.role !== role) {
      this.setState({
        permissions: clone(role.rolePermissions),
        savedPermissions: clone(role.rolePermissions)
      });
    }
  }

  /**
   * Update Role
   */

  handlePermissionsChange = (permissions: IRolePermission[]) => {
    this.setState({ permissions });
  };

  handleSave = async () => {
    const { t, role, onSave } = this.props;
    const { permissions } = this.state;

    const apiPromise = ApiService.updateRole(role.roleGuid, { permissions })
      .then(response => {
        this.setState({ loading: null });
        const updatedRole = response.data;
        pushToast({
          type: 'success',
          title: t('manageRoles.edit.successToastTitle'),
          content: t('manageRoles.edit.successToastContent', {
            role: updatedRole.roleName
          })
        });
        onSave(updatedRole);
      })
      .catch(() => {
        this.setState({ loading: null });
        pushServerErrorToast();
      });

    this.setState({ loading: apiPromise });
  };

  /**
   * Delete Role
   */

  getReassignRoles() {
    const { roles, role: deleteRole } = this.props;
    return roles.filter(
      role =>
        role.roleGuid !== deleteRole.roleGuid &&
        role.userTypes === deleteRole.userTypes
    );
  }

  getReassignRoleOptions() {
    const { t } = this.props;
    return this.getReassignRoles().map(role => ({
      value: role.roleGuid,
      label: `${role.roleName} (${t(`userTypes.${role.userTypes}`)})`,
      disabled: false
    }));
  }

  handleDelete = () => {
    const reassignRoles = this.getReassignRoles();
    this.setState({
      deleteRole: true,
      deleteReassignRole: reassignRoles[0]
    });
  };

  handleDeleteReassignChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { roles } = this.props;
    const roleGuid = e.target.value;
    const role = roles.find(r => r.roleGuid === roleGuid);
    if (role) {
      this.setState({ deleteReassignRole: role });
    }
  };

  handleDeleteConfirm = async () => {
    const { deleteReassignRole } = this.state;
    if (deleteReassignRole) {
      this.setState({
        deleteLoading: this.deleteCall(deleteReassignRole).then(() => {
          this.setState({ deleteLoading: null });
        })
      });
    }
  };

  async deleteCall(deleteReassignRole: IRole) {
    const { t, role, onDelete } = this.props;
    try {
      await ApiService.deleteRole(role.roleGuid, deleteReassignRole.roleGuid);
      pushToast({
        type: 'success',
        title: t('manageRoles.delete.successToastTitle'),
        content: t('manageRoles.delete.successToastContent', {
          role: role.roleName,
          reassignRole: deleteReassignRole.roleName
        })
      });
      onDelete();
    } catch {
      pushServerErrorToast();
    }
  }

  handleDeleteCancel = () => {
    this.setState({ deleteRole: false });
  };

  /**
   * Render
   */

  render() {
    const { t, role, onCancel } = this.props;
    const {
      permissions,
      deleteRole,
      deleteReassignRole,
      deleteLoading,
      loading,
      savedPermissions
    } = this.state;

    const isNotUpdated: boolean = isSameSetOfPermissions(
      savedPermissions,
      permissions
    );

    const reassignRoleOptions = this.getReassignRoleOptions();
    const canDelete = reassignRoleOptions.length > 0;

    return (
      <div className="edit-role">
        <hr />
        <h3 className="ry-h3">
          {t('manageRoles.edit.title', {
            role: role.roleName
          })}
        </h3>
        <p>{t('manageRoles.edit.description', { role: role.roleName })}</p>
        <div className="row">
          <div className="col-8">
            <RolePermissions
              loading={false}
              onChange={this.handlePermissionsChange}
              permissions={permissions}
            />
          </div>
          <div className="col-4">
            <div className="well">
              <ul className="labeled-list">
                <li>
                  <label>{t('manageRoles.edit.userType')}</label>
                  {t(`userTypes.${role.userTypes}`)}
                </li>
              </ul>
            </div>
            <RolePermissionsLegend t={t} />
          </div>
        </div>
        <div className="edit-role__actions">
          <Button onClick={onCancel} size="lg" text={t('Cancel')} />
          <Button
            disabled={isNotUpdated}
            loading={loading}
            onClick={this.handleSave}
            size="lg"
            text={t('Save Changes')}
            type="submit"
            variant="primary"
          />
        </div>

        <hr />
        <h2 className="ry-h2">{t('manageRoles.delete.title')}</h2>
        <p>
          {canDelete
            ? t('manageRoles.delete.description')
            : t('manageRoles.delete.descriptionDisabled')}
        </p>
        <Button
          disabled={!canDelete}
          loading={deleteLoading}
          negative
          onClick={this.handleDelete}
          text={t('Delete')}
        />

        <Modal
          onClose={this.handleDeleteCancel}
          open={deleteRole}
          title={t('manageRoles.delete.modalTitle', { role: role.roleName })}
        >
          <p>{t('manageRoles.delete.modalDescription')}</p>
          <Dropdown
            label={t('manageRoles.delete.modalLabel')}
            onChange={this.handleDeleteReassignChange}
            options={reassignRoleOptions}
            value={deleteReassignRole ? deleteReassignRole.roleGuid : ''}
          />
          <ButtonGroup>
            <Button
              negative
              onClick={this.handleDeleteConfirm}
              size="lg"
              text={t('manageRoles.delete.modalConfirm')}
            />
            <Button
              onClick={this.handleDeleteCancel}
              size="lg"
              text={t('Cancel')}
            />
          </ButtonGroup>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(EditRole);
