import { TFunction } from 'i18next';
import React, { FunctionComponent, useCallback, useRef, useState } from 'react';

import { Button, ITextareaMentionsRef, MentionsValue } from '@ryan/components';

import CommentInput from '../CommentInput';

import './NewComment.scss';

export interface INewCommentProps {
  t: TFunction;
  engagementGuid?: string;
  makeOnSuggestionsRequestedCallback?: any;
  loading: Promise<any> | null;
  commentText: MentionsValue;
  onChange: (commentText: MentionsValue) => void;
  onSave: () => void;
}

const maxLengthComment = 250;

const NewComment: FunctionComponent<INewCommentProps> = ({
  commentText,
  engagementGuid,
  makeOnSuggestionsRequestedCallback,
  loading,
  onChange,
  onSave,
  t
}) => {
  const [isValid, setIsValid] = useState(true);
  const errorMessage = t('comment.create.error.maxLength', {
    length: maxLengthComment
  });

  // combine max comment length validation with prop handlers
  const compositeOnChange = useCallback(
    (commentText: MentionsValue) => {
      onChange(commentText);

      // validate comment length
      setIsValid(commentText.length <= maxLengthComment);
    },
    [onChange]
  );

  const commentInputRef = useRef<ITextareaMentionsRef | null>(null);
  const newCommentRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="new-comment" ref={newCommentRef}>
      <CommentInput
        {...(!isValid ? { feedback: errorMessage, invalid: true } : {})}
        boundingParentRef={newCommentRef}
        engagementGuid={engagementGuid}
        makeOnSuggestionsRequestedCallback={makeOnSuggestionsRequestedCallback}
        onChange={compositeOnChange}
        placeholder={t('comment.placeholder')}
        placement="top"
        ref={commentInputRef}
        value={commentText}
      />
      <div className="new-comment__actions">
        <Button
          disabled={commentText.text.trim().length === 0 || !isValid}
          loading={loading}
          onClick={() => {
            if (isValid) {
              onSave();
              if (
                commentInputRef.current &&
                commentInputRef.current.clearContent
              ) {
                (commentInputRef.current.clearContent as () => void)();
              }
            }
          }}
          size="sm"
          text={t('Send')}
          variant="primary"
        />
      </div>
    </div>
  );
};

export default NewComment;
