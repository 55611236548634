import { ICountry, ISubdivision, IUser } from 'interfaces';
import ApiService from 'services/ApiService';
import { formikFieldProps } from 'utils/forms';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import { FormikProps } from 'formik';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Avatar,
  Button,
  Dropdown,
  Switch,
  TextInput,
  Textarea
} from '@ryan/components';

import UnsavedChangesContext from '../../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import getAvatarUrl from '../../../utils/getAvatarUrl';
import IContactInformationFormValues from './IContactInformationFormValues';

import './ContactInformationForm.scss';

interface IContactInformationFormState {
  countries: ICountry[];
  subdivisions: { [key: string]: ISubdivision[] };
}

interface IContactInformationFormProps extends WithTranslation {
  formik: FormikProps<IContactInformationFormValues>;
  selectedUser: IUser | null;
  onUpdateProfileImage: () => void;
}

/**
 * Form wrapped in formik, rendered inside of the ContactInformation component.
 */
class ContactInformationForm extends Component<
  IContactInformationFormProps,
  IContactInformationFormState
> {
  readonly state: IContactInformationFormState = {
    countries: [],
    subdivisions: {}
  };

  static contextType = UnsavedChangesContext;
  context!: React.ContextType<typeof UnsavedChangesContext>;

  fetchCountries = () => {
    ApiService.getCountries()
      .then(({ data: countries }) => {
        // remove countries missing data as they will be invalid options in
        // the country dropdown
        this.setState({
          countries: countries.filter(country => !!country.isoNumericCode)
        });
      })
      .catch(() => pushServerErrorToast());
  };

  getCountryDropdownOptions() {
    const { countries } = this.state;
    return [
      // insert initial blank option
      {
        value: '',
        label: ''
      },

      // insert countries list
      ...countries.map(country => ({
        value: country.isoNumericCode,
        label: country.ryanCountryName
      }))
    ];
  }

  fetchCountrySubdivisions = (isoNumericCode: string) => {
    const { subdivisions } = this.state;

    if (isoNumericCode !== '' && subdivisions[isoNumericCode] === undefined) {
      ApiService.getCountrySubdivisions(isoNumericCode)
        .then(response => {
          this.setState(({ subdivisions }) => ({
            subdivisions: {
              ...subdivisions,
              [isoNumericCode]: response.data
            }
          }));
        })
        .catch(() => pushServerErrorToast());
    }
  };

  getSubdivisionOptions = (isoCountryCode: string) => {
    const { subdivisions } = this.state;
    return [
      {
        value: '',
        label: ''
      },
      ...(subdivisions[isoCountryCode] || []).map(subdivision => ({
        value: subdivision.isoSubdivisionCode,
        label: subdivision.ryanSubdivisionName
      }))
    ];
  };

  componentDidMount() {
    this.fetchCountries();

    const selectedCountry = this.props.formik.values.officeAddress.country;
    if (selectedCountry) {
      this.fetchCountrySubdivisions(selectedCountry);
    }
  }

  componentDidUpdate() {
    const { officeAddress, mailingAddress } = this.props.formik.values;
    this.fetchCountrySubdivisions(officeAddress.country!);
    this.fetchCountrySubdivisions(mailingAddress.country!);
  }

  handleToggleOfSameAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { formik } = this.props;
    formik.setFieldTouched('isSameAddress', true);
    formik.setFieldValue('isSameAddress', e.target.checked);
  };

  mailToOnClick = () => {
    const { selectedUser } = this.props;
    if (selectedUser) {
      ApiService.getUserManagerEmail(selectedUser.userGuid).then(response => {
        window.location.href = `mailto:${response.data.userManagerEmail}?subject=Client Email Update Request`;
      });
    }
  };

  render() {
    const {
      t: getTextToDisplay,
      selectedUser,
      formik,
      onUpdateProfileImage
    } = this.props;
    const isUpdatePending = selectedUser ? selectedUser.isPendingUpdate : false;

    this.context.setIsUnsavedChanges(formik.dirty);

    return (
      <form className="contact-information" onSubmit={formik.handleSubmit}>
        <div className="row">
          {/* LEFTMOST COLUMNS */}
          <div className="col-lg-4">
            <section className="contact-information__personal-info">
              <div className="contact-information__avatar-edit">
                <Avatar
                  {...(!selectedUser?.hasDxpAccess && { variant: 'inverse' })}
                  firstName={formik.values.firstName}
                  lastName={formik.values.lastName}
                  profileImageSrc={getAvatarUrl({
                    avatarUrl: selectedUser?.avatarUrl ?? null,
                    userAvatarDocumentGuid:
                      selectedUser?.userAvatarDocumentGuid ?? null
                  })}
                />
                <Button
                  icon="pencil"
                  onClick={onUpdateProfileImage}
                  text="Update Picture"
                  variant="text"
                />
              </div>
              <TextInput
                {...formikFieldProps('firstName', formik)}
                label={getTextToDisplay('First Name')}
                placeholder={formik.values.firstName}
              />
              <TextInput
                {...formikFieldProps('lastName', formik)}
                label={getTextToDisplay('Last Name')}
                placeholder={formik.values.lastName}
              />
              <TextInput
                {...formikFieldProps('title', formik)}
                label={getTextToDisplay('Title')}
                placeholder={formik.values.title}
              />
              <TextInput
                {...formikFieldProps('mobilePhone', formik)}
                label={getTextToDisplay('Mobile Number', {
                  context: 'optional'
                })}
                placeholder={formik.values.mobilePhone}
              />
              <TextInput
                {...formikFieldProps('officePhone', formik)}
                label={getTextToDisplay('Office Number')}
                placeholder={formik.values.officePhone}
              />
              <div className="contact-information__email-field">
                <TextInput
                  {...formikFieldProps('email', formik)}
                  disabled
                  helperText={getTextToDisplay('contactInformation.text')}
                  label={getTextToDisplay('Email')}
                  placeholder={formik.values.email}
                />
                <Button
                  onClick={() => this.mailToOnClick()}
                  size="sm"
                  text={getTextToDisplay(
                    'contactInformation.requestEmailUpdate'
                  )}
                  variant="text"
                />
              </div>
            </section>
          </div>

          {/* RIGHTMOST COLUMNS, ADDRESS FIELDS */}
          <div className="col-lg-8">
            <section className="contact-information__address-info">
              <h3>{getTextToDisplay('Mailing Address')}</h3>
              {this.renderAddress('mailingAddress')}

              <h3>{getTextToDisplay('Physical Address')}</h3>
              <div className="contact-information__switch-button">
                <Switch
                  checked={formik.values.isSameAddress}
                  onChange={this.handleToggleOfSameAddress}
                />
                <label className="ry-label">
                  {getTextToDisplay('addressCard.sameAs')}
                </label>
              </div>
              {!formik.values.isSameAddress &&
                this.renderAddress('officeAddress')}
            </section>
          </div>
        </div>

        <div className="contact-information__update-button">
          <Button
            disabled={isUpdatePending}
            size="lg"
            text={
              isUpdatePending
                ? getTextToDisplay('Updates Pending')
                : getTextToDisplay('Update')
            }
            type="submit"
            variant="primary"
          />
        </div>
      </form>
    );
  }

  renderAddress(addressField: 'officeAddress' | 'mailingAddress') {
    const { t: getTextToDisplay, formik } = this.props;
    const { subdivisions } = this.state;
    const country = formik.values[addressField].country!;

    return (
      <div>
        <div className="row">
          <div className="col-12 col-md-6">
            <Textarea
              label={getTextToDisplay('Address')}
              {...formikFieldProps(`${addressField}.addressLine1`, formik)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <TextInput
              label={getTextToDisplay('City')}
              {...formikFieldProps(`${addressField}.city`, formik)}
            />
          </div>
          <div className="col-12 col-md-6">
            <Dropdown
              disabled={
                subdivisions[country] === undefined ||
                subdivisions[country].length === 0
              }
              label={getTextToDisplay('State/Province')}
              options={this.getSubdivisionOptions(country)}
              {...formikFieldProps(`${addressField}.stateCode`, formik)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <TextInput
              label={getTextToDisplay('Zip/Postal Code')}
              {...formikFieldProps(`${addressField}.postalCode`, formik)}
            />
          </div>
          <div className="col-12 col-md-6">
            <Dropdown
              label={getTextToDisplay('Country')}
              options={this.getCountryDropdownOptions()}
              {...formikFieldProps(`${addressField}.country`, formik)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ContactInformationForm);
