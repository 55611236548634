import { IUserSummary } from '.';

import { IDataRequestSummary } from './IDataRequestSummary';
import { IEngagementActivitySummary } from './IEngagementActivitySummary';
import { IFileSummary } from './IFileSummary';
import { IInvoiceSummary } from './IInvoiceSummary';
import { ILearningSummary } from './ILearningSummary';
import { INewUserSummary } from './INewUserSummary';
import { ITaskSummary } from './ITaskSummary';

export enum EntityType {
  DataRequest = 1,
  Contract = 2,
  Invoice = 3,
  Task = 4,
  Milestone = 5,
  Learning = 6,
  File = 7,
  Team = 8,
  SavingsSummary = 9,
  Project = 10,
  AccessControl = 11
}

export enum ActivityType {
  /**
   * Data Requests 1-50
   */
  DataRequestCreated = 1,
  DataRequestModified = 2,
  DataRequestDeleted = 5,
  DataRequestAssignedToUser = 6,
  DataRequestStatusComplete = 8,
  DataRequestIsPastDue = 9,
  FileUploadedToDataRequest = 4,
  FileUploadedOnBehalfOfUserToDataRequest = 10,
  DataRequestCommentedOn = 3,
  DataRequestCommentModified = 11,

  /**
   * Files 51-100
   */
  FileUploadedAdHoc = 51,
  FilesDeleted = 52,
  FileUploadComment = 53,
  FileVisibilityModified = 54,
  FileUploadCommentModified = 55,
  FileLinkedToProject = 56,
  ZipReady = 57,
  FileShared = 58,
  FileUploadedAdHocOnBehalfOf = 59,

  /**
   * Invoices 101-150
   */
  InvoiceAvailable = 101,
  InvoicePastDue = 102,
  InvoicePayment = 103,

  /**
   * Tasks 151-200
   */
  TaskCommentedOn = 151,
  TaskCreated = 152,
  TaskModified = 153,
  TaskAssignedToUser = 154,
  TaskStatusComplete = 156,
  TaskDeleted = 157,
  TaskPastDue = 160,
  TaskCommentModified = 161,

  /**
   * Learnings 201-250
   */
  LearningCreated = 201,
  LearningModified = 203,
  LearningDeleted = 204,
  LearningComment = 202,
  LearningCommentModified = 206,

  /**
   * Savings 251-300
   */
  SavingsUpdated = 251,
  SavingsDecreased = 252,
  SavingsIncreased = 253,

  /**
   * Contracts 351-400
   */
  ContractAvailable = 352,
  ContractModified = 353,
  ContractPublished = 354,

  /**
   * Milestones 401-450
   */
  MilestoneCreated = 402,
  MilestoneModified = 403,
  MilestoneCompleted = 405,
  MilestoneDeleted = 406,
  MilestoneComment = 401,
  MilestoneCommentModified = 408,

  /**
   * Access Control 501-550
   */
  UserDeactivated = 502,
  UserReactivated = 503,
  UserActivated = 504,
  UserScheduledForDeactivation = 505,
  UserScheduledForReactivation = 506,
  UserRoleModified = 507,
  ClientContactProfileUpdate = 508,
  ClientContactProfileUpdateError = 509,
  NewUserRequestCreated = 510,
  NewUserRequestApproved = 511,
  NewUserRequestDenied = 512,
  NewUserAccessRequested = 513,

  /**
   * Team 551-600
   */
  UserAddedToEngagement = 551,

  /**
   * Engagements 601-650
   */
  ProjectPublished = 601,
  ProjectUnpublished = 602,
  ProjectModified = 603
}

export interface Modified {
  key: string;
  oldValue: any;
  newValue: any;
}

export interface IActivity {
  // Account
  accountGuid: string | null;
  accountName: string; // at time of request

  // Engagement
  engagementGuid: string | null;
  engagementDisplayNameShort: string | null; // at time of request
  isoCurrencyCode: string | null; // at time of request

  // Activity
  activityFeedItemGuid: string;
  activityTypeId: ActivityType;
  entityGuid: string;
  entityTypeId: EntityType;

  // Activity Metadata
  snapShot: {
    entitySummary:
      | IDataRequestSummary
      | IFileSummary
      | IInvoiceSummary
      | ILearningSummary
      | INewUserSummary
      | ITaskSummary
      | IEngagementActivitySummary
      | {
          // User
          userGuid: string;
          name: string;
          email: string;
          role: string;
        };
    modifiedInformation: Modified[];
    documentInformation: {
      documentGuid: string;
      documentName: string;
      onBehalfOfUserGuid: string | null;
      onBehalfOfUserName: string | null;
      folderGuid: string | null;
      folderName: string | null;
    }[];

    commentText: string | null;
  };

  // Grabbed at time of request -- Current user information for those mentioned in comments
  commentTaggedUsers: {
    userGuid: string;
    fullName: string;
  }[];

  // Grabbed at time of request -- Current user information for user mentioned in entity summary
  userInformation: IUserSummary;

  // Activity Created Data
  createDate: string;
  createdBy: string;
  createdByName: string; // at time of request
  createdImpersonatedBy: string | null;
  createdImpersonatedByName: string | null; // at time of request
  decisionsEntityId: string | null;
}
