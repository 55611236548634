import { useUser } from 'contexts/UserContext';
import {
  IWorkspace,
  IWorkspaceConnection,
  Permission,
  WorkspaceStatus
} from 'interfaces';

import React, { ComponentProps, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, IButtonProps, SplitButton } from '@ryan/components';

type ISplitButtonProps = ComponentProps<typeof SplitButton>;

export interface IWorkspaceButtonProps {
  workspace: IWorkspace;
  connections: IWorkspaceConnection[] | null;
  isReadOnly?: boolean;
  onEditWorkspace: () => void;
  onDuplicateWorkspace: () => void;
  onCompleteWorkspace: () => void;
  onReopenWorkspace: () => void;
  onLaunchWorkspace: () => void;
  onDismissWorkspace: () => void;
  onRevertWorkspace: () => void;
  onDeleteWorkspace: () => void;
}

const WorkspaceButton: FunctionComponent<IWorkspaceButtonProps> = ({
  workspace,
  connections,
  isReadOnly: disabled = false,
  onEditWorkspace,
  onDuplicateWorkspace,
  onCompleteWorkspace,
  onReopenWorkspace,
  onLaunchWorkspace,
  onDismissWorkspace,
  onRevertWorkspace,
  onDeleteWorkspace
}) => {
  const { t } = useTranslation();
  const { permissionService: ps } = useUser();

  const isCreateError =
    workspace.status === WorkspaceStatus.ErrorCreate ||
    workspace.status === WorkspaceStatus.ErrorClone ||
    workspace.status === WorkspaceStatus.ErrorDelete;
  const isUpdateError =
    workspace.status === WorkspaceStatus.ErrorUpdate ||
    workspace.status === WorkspaceStatus.ErrorRestore;
  const isError = isCreateError || isUpdateError;
  const isComplete = workspace.status === WorkspaceStatus.Complete;
  const isPending = workspace.status === WorkspaceStatus.Pending;

  const isSchedulingAdmin = ps.hasPermission(Permission.SchedulingAdmin);
  const isBig4OrEA = ps.isBig4OrExecutiveAssistant();

  const hasConnectionAccess =
    !!connections &&
    connections.some(c => c.connectionGuid === workspace.connectionGuid);

  const canDuplicate = hasConnectionAccess;
  const canEdit = isBig4OrEA && hasConnectionAccess;
  const canDelete = isBig4OrEA && isSchedulingAdmin;

  // Admins can dismiss or revert errors
  if (isSchedulingAdmin && isError) {
    return (
      <div className="scheduling__error-cell">
        <Button
          className="scheduling__dismiss-button"
          disabled={disabled}
          negative
          size="sm"
          variant="text"
          {...(isCreateError && {
            text: t('Dismiss'),
            icon: 'delete',
            onClick: onDismissWorkspace
          })}
          {...(isUpdateError && {
            text: t('Revert'),
            icon: 'undo',
            onClick: onRevertWorkspace
          })}
        />
      </div>
    );
  }

  // Users can reopen completed workspaces
  if (isComplete) {
    const buttonProps: Partial<IButtonProps | ISplitButtonProps> = {
      text: t('Reopen'),
      size: 'sm' as const,
      block: true,
      disabled,
      onClick: onReopenWorkspace
    };

    // If the user can delete workspaces, use a SplitButton.
    // Otherwise, just a button.
    return canDelete ? (
      <SplitButton
        {...(buttonProps as ISplitButtonProps)}
        options={[
          {
            label: t('Delete'),
            icon: 'trash',
            negative: true,
            disabled,
            onClick: onDeleteWorkspace
          }
        ]}
      />
    ) : (
      <Button {...(buttonProps as IButtonProps)} />
    );
  }

  if (!isError) {
    const options: ISplitButtonProps['options'] = [];

    if (!isPending) {
      if (canEdit) {
        options.push({
          disabled,
          label: t('Edit'),
          icon: 'pencil',
          onClick: onEditWorkspace
        });
      }

      if (canDuplicate) {
        options.push({
          disabled,
          label: t('Duplicate'),
          icon: 'duplicate',
          onClick: onDuplicateWorkspace
        });
      }

      options.push({
        disabled,
        label: t('Complete'),
        icon: 'approve',
        onClick: onCompleteWorkspace
      });

      if (canDelete) {
        options.push({
          disabled,
          label: t('Delete'),
          icon: 'trash',
          negative: true,
          onClick: onDeleteWorkspace
        });
      }
    }

    return (
      <SplitButton
        block
        disabled={isPending}
        onClick={onLaunchWorkspace}
        options={options}
        size="sm"
        text={t('Launch')}
      />
    );
  }

  return null;
};

export default WorkspaceButton;
