import qs from 'query-string';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from 'react-router-dom';

import { Button, Card, EMessageTypes, pushToast } from '@ryan/components';

import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import DataRequestActions from '../../components/DataRequestActions/DataRequestActions';
import DataRequestFields from '../../components/DataRequestFields/DataRequestFields';
import DocumentTitle from '../../components/DocumentTitle';
import FileDirectory, {
  FileDirectoryHeader,
  FileDirectoryRefreshType,
  NewFolderModal
} from '../../components/FileDirectory';
import { RYAN_INTERNAL } from '../../components/FileDirectory/utils/FileDirectoryEnums';
import FollowersModal from '../../components/FollowersModal/FollowersModal';
import RecordsModal from '../../components/RecordsModal/RecordsModal';
import StatusIcon from '../../components/StatusIcon/StatusIcon';
import { useCodeNotes } from '../../contexts/CodeNotesDrawerContext/CodeNotesDrawerConsumer';
import { useDataRequestDrawer } from '../../contexts/DataRequestDrawerContext';
import { useUser } from '../../contexts/UserContext';
import { useStateMounted } from '../../hooks';
import deletedImage from '../../images/data-request-deleted.svg';
import {
  DSSDocument,
  Feature,
  IAccount,
  IDataRequest,
  IEngagement,
  IFolder,
  Permission,
  Status,
  UserType
} from '../../interfaces';
import ApiService, { CancelTokenSource } from '../../services/ApiService';
import { TDateRange } from '../../types';
import EngagementAccessError from '../../utils/EngagementAccessError';
import { ButtonSizeEnums } from '../../utils/enums/ButtonSizeEnums';
import { DataRequestTypeGuidEnum } from '../../utils/enums/DataRequestsEnums';
import { SubStatusEnums } from '../../utils/enums/SubStatusEnums';
import { formatDate } from '../../utils/formatDate';
import getCommentButtonProps from '../../utils/getCommentButtonProps';
import pushServerErrorToast from '../../utils/pushServerErrorToast';
import { ICustomViewOutboundLocationState } from '../CustomView';
import { DirectoryItemStatus } from '../DataAndFiles/Files/Files';
import {
  IDirectoryState,
  fetchDirectory as fetchDirectoryByState,
  setHash,
  updateStateFoldersAndFolderPath,
  useDirectoryState
} from '../Project/DirectoryState';
import DataRequestRyanUploadModal from './DataRequestRyanUploadModal';
import DataRequestUploadModal from './DataRequestUploadModal';

import './DataRequest.scss';

const initialDirectoryState: IDirectoryState = {
  didDirectoryChange: false,
  directoryItems: [],
  directoryItemsBySearch: null,
  engagementFolders: null,
  filtered: {},
  folderPath: [],
  loading: false,
  page: 1,
  pageSize: 10,
  selected: [],
  sorted: {
    desc: true,
    id: 'createDate'
  },
  totalCount: 0,
  totalFileCount: 0
};

/**
 * Renders the Data Request and File Upload page. Assums the user has the
 *`Permission.DataRequestsView`permission.
 */
const DataRequest: React.FC = () => {
  const {
    dataRequestDrawerEvents,
    onDataRequestDrawerOpenComments,
    onDataRequestDrawerOpenHistory
  } = useDataRequestDrawer();
  const { codeNotesDrawerEvents, onCodeNotesDrawerOpen } = useCodeNotes();
  const history = useHistory();
  const location = useLocation<ICustomViewOutboundLocationState | unknown>();
  const { queueItemGuid } = useParams<{ queueItemGuid?: string }>();
  const match = useRouteMatch();
  const { t: getTextToDisplay } = useTranslation();
  const {
    activeView,
    getAccountByGuid,
    isAppReadOnly,
    isEngagementInView,
    isFeatureToggled,
    permissionService,
    setActiveAccountForEngagement,
    user: {
      profile: { userGuid, userTypeId }
    }
  } = useUser();

  const [account, setAccount] = useStateMounted<IAccount | null>(null);
  const [engagement, setEngagement] = useStateMounted<IEngagement | null>(null);
  const [dataRequest, setDataRequest] = useStateMounted<IDataRequest | null>(
    null
  );
  const [dataRequestDefaultFolderGuid, setDataRequestDefaultFolderGuid] =
    useState<string | null>(null);
  const [dataRequestDeleted, setDataRequestDeleted] = useState(false);

  const [folderGuidHash, setFolderGuidHash] = useState<string | null>(null);
  const [isDateRangesModalOpen, setIsDateRangesModalOpen] = useState(false);
  const [isDocumentTypesModalOpen, setIsDocumentTypesModalOpen] =
    useState(false);
  const [isInternalFilesFeatureEnabled] = useState(
    isFeatureToggled(Feature.InternalFiles)
  );
  const [isJurisdictionsModalOpen, setIsJurisdictionsModalOpen] =
    useState(false);
  const [isRWMDataRequestsFeatureEnabled] = useState(
    isFeatureToggled(Feature.RWMDataRequests)
  );
  const [newFolder, setNewFolder] = useState(false);
  const [openFollowersModal, setOpenFollowersModal] = useState(false);
  const [pageTitle, setPageTitle] = useState('');

  // upload files
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [uploadModalLoading, setUploadModalLoading] =
    useState<Promise<unknown> | null>(null);

  // upload files on behalf of a client
  const [openRyanUploadModal, setOpenRyanUploadModal] = useState(false);

  // directory state
  const [directoryState, setDirectoryState] = useDirectoryState(
    initialDirectoryState
  );
  const {
    directoryItems,
    engagementFolders,
    filtered,
    folderPath,
    loading,
    page,
    pageSize,
    selected,
    sorted,
    totalCount,
    totalFileCount
  } = directoryState;

  // store directory state and engagement to ref to prevent exceesive updates in
  // hooks
  const directoryStateRef = useRef(directoryState);
  const engagementRef = useRef(engagement);
  const sourceRef = useRef<CancelTokenSource>();
  const directorySourceRef = useRef<CancelTokenSource>();
  const updateSourceRef = useRef<CancelTokenSource>();
  const canContribute = permissionService.hasPermission(
    Permission.DataRequestsContribute
  );
  const canEdit = permissionService.hasPermission(Permission.DataRequestsEdit);
  const isAdHoc = !!dataRequest && dataRequest.isAdHoc;
  const isDocumentImagesType =
    dataRequest?.dataRequestType?.dataRequestTypeGuid ===
    DataRequestTypeGuidEnum.DOCUMENT_IMAGES;
  const isERPType =
    dataRequest?.dataRequestType?.dataRequestTypeGuid ===
    DataRequestTypeGuidEnum.ERP_DATA;
  const isReadOnly =
    engagement === null || engagement.isReadOnly || isAppReadOnly;

  /**
   * Redirect from page if the view no longer contains the current data request.
   *
   * @param isAdHoc Redirects to Files overview if the data request is an ad hoc
   *  file upload.
   * @param replace Replace the current entry on the history stack on redirect.
   */
  const handleNotInView = useCallback(
    (isAdHoc?: boolean, replace?: boolean): void => {
      const redirectTo = isAdHoc
        ? '/app/data-and-files/files'
        : '/app/data-and-files/data-requests';

      if (replace) {
        history.replace(redirectTo);
      } else {
        history.push(redirectTo);
      }
    },
    [history]
  );

  const fetchDataRequest = useCallback(async () => {
    // reset dataRequestDeleted
    setDataRequestDeleted(false);

    // extract and update location state so it doesn't affect future renders;
    // we do not want to update the account switcher if redirecting back to this
    // page after updating a custom view
    let customViewUpdated = false;

    // NOTE: use `history.location` instead of `location` for hook depdendency
    // purposes, otherwise this will retun on state update
    if (
      (history.location.state as ICustomViewOutboundLocationState)
        ?.customViewUpdated
    ) {
      customViewUpdated = true;
      history.replace({
        ...history.location,
        state: {
          ...(history.location.state as Record<string, unknown>),
          customViewUpdated: false
        }
      });
    }

    try {
      // refresh cancel token
      sourceRef.current?.cancel();
      sourceRef.current = ApiService.CancelToken.source();
      const { data: dataRequestFromResponse } = await ApiService.getDataRequest(
        queueItemGuid || '',
        sourceRef.current.token
      );

      // If redirecting back to this page after creating/editing a custom view,
      // verify data request is still included in the updated custom view and
      // redirect back to projects overview if not.
      if (customViewUpdated) {
        try {
          const isInView = await isEngagementInView(
            dataRequestFromResponse.engagementGuid,
            sourceRef.current.token
          );

          if (!isInView) {
            handleNotInView(dataRequestFromResponse.isAdHoc, true);
            return;
          }
        } catch (error) {
          // redirect if cannot verify data request is in view
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
            handleNotInView(dataRequestFromResponse.isAdHoc, true);
            return;
          }
        }
      }

      // cache data request in state
      setDataRequest(dataRequestFromResponse);
      setDataRequestDefaultFolderGuid(
        dataRequestFromResponse.defaultFolderGuid
      );

      // get associated engagement from data request
      if (
        !engagementRef.current ||
        engagementRef.current.engagementGuid !==
          dataRequestFromResponse.engagementGuid
      ) {
        const { data: engagementFromResponse } = await ApiService.getEngagement(
          dataRequestFromResponse.engagementGuid,
          sourceRef.current.token
        );

        // get associated account from engagement
        const accountMatch = await getAccountByGuid(
          engagementFromResponse.accountGuid
        );

        if (!accountMatch) {
          throw new EngagementAccessError();
        }

        // If the data request is not a member of the view, we probably came here
        // from a notification link or moving through history after a switcher
        // update and should update view to one that includes this data request.
        if (!customViewUpdated) {
          setActiveAccountForEngagement(engagementFromResponse);
        }

        setAccount(accountMatch.account);
        setEngagement(engagementFromResponse);
      }
    } catch (error: any) {
      if (!ApiService.isCancel(error)) {
        if (error?.response?.status === 410) {
          setDataRequestDeleted(true);
        } else {
          pushServerErrorToast();
        }
      }
    }
  }, [
    history,
    queueItemGuid,
    getAccountByGuid,
    handleNotInView,
    isEngagementInView,
    setAccount,
    setActiveAccountForEngagement,
    setDataRequest,
    setEngagement
  ]);

  /**
   * Fetches the engagement's directory tree. Only files associated with the
   * data request will be visible.
   */
  const fetchDirectory = useCallback(async () => {
    if (!dataRequest) return;

    const { engagementGuid, queueItemGuid } = dataRequest;

    // make copy of directory state that can be directly modified by
    // `DirectoryState` methods
    const mutableDirectoryState: IDirectoryState = {
      ...directoryStateRef.current
    };

    // parse folder GUID from URL
    const { folderGuid } = qs.parse(location.hash);

    // refresh cancel token
    directorySourceRef.current?.cancel();
    directorySourceRef.current = ApiService.CancelToken.source();

    try {
      // Guarantee engagementFolders.
      // If folderGuid is valid, set folderPath,
      // ...otherwise, clear folderGuid from URL. (Will come back.)
      const isValid = await updateStateFoldersAndFolderPath(
        engagementGuid,
        folderGuid,
        mutableDirectoryState,
        directorySourceRef.current.token
      );

      if (!isValid) {
        handleNavigateToRoot();
        return;
      }

      // save state changes from `updateStateFoldersAndFolderPath` with
      // loading: true
      setDirectoryState({
        ...mutableDirectoryState,
        loading: true
      });

      // Request.
      const responseState = await fetchDirectoryByState(
        engagementGuid,
        {
          ...mutableDirectoryState,
          engagementFolders: mutableDirectoryState.engagementFolders!,
          status: DirectoryItemStatus.ALL_FILES
        },
        { queueItemGuid },
        directorySourceRef.current.token
      );

      // Save state wth response.
      setDirectoryState({
        ...responseState,
        selected: [],

        // reset directory change status on completion of request
        didDirectoryChange: false
      });
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushServerErrorToast();
      }
    } finally {
      setDirectoryState({
        loading: false
      });
    }
  }, [dataRequest, location.hash, setDirectoryState]);

  /**
   * Callback called on file upload.
   */
  const handleFilesUploaded = useCallback(() => {
    setOpenRyanUploadModal(false);
    setUploadModalOpen(false);

    // move data request to "in progress" status if status is Todo and refresh
    // directory (fetchDirectory will be called via effect hook if data request
    // changes)
    if (dataRequest?.statusId === Status.Todo) {
      setDataRequest(
        prevDataRequest =>
          prevDataRequest && {
            ...prevDataRequest,
            statusId: Status.InProgress
          }
      );
    } else {
      fetchDirectory();
    }
  }, [dataRequest, fetchDirectory, setDataRequest]);

  /**
   * Callback called on click of `DataRequestActions` action.
   */
  const handleUpdate = useCallback(() => {
    fetchDataRequest();
    fetchDirectory();
  }, [fetchDataRequest, fetchDirectory]);

  /**
   * Client: Upload files.
   */

  /**
   * Opens the client user file upload modal.
   */
  const handleUploadFiles = useCallback(() => {
    setUploadModalOpen(true);
  }, []);

  /**
   * Callback called on client user file upload.
   */
  const handleUploadFilesSubmit = useCallback(
    async (documents: DSSDocument[]) => {
      if (!dataRequest) return;

      const promise = ApiService.uploadDataRequestFiles(
        dataRequest.engagementGuid,
        dataRequest.queueItemGuid,
        documents
      );
      setUploadModalLoading(promise);

      try {
        await promise;
        pushToast({
          content: getTextToDisplay('uploadFilesSuccess.content', {
            count: documents.length,
            filename: documents[0].documentName
          }),
          title: getTextToDisplay('uploadFilesSuccess.title'),
          type: EMessageTypes.SUCCESS
        });
        fetchDataRequest();
        handleFilesUploaded();
      } catch (error) {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      } finally {
        setUploadModalLoading(null);
      }
    },
    [dataRequest, fetchDataRequest, handleFilesUploaded, getTextToDisplay]
  );

  /**
   * Closes the client file upload modal.
   */
  const handleUploadFilesCancel = useCallback(() => {
    setUploadModalOpen(false);
  }, []);

  /**
   * Ryan: Upload files on behalf of a client.
   */

  /**
   * Opens the Ryan user file upload modal.
   */
  const handleRyanUploadFiles = useCallback(() => {
    setOpenRyanUploadModal(true);
  }, []);

  /**
   * Closes the Ryan user file upload modal.
   */
  const handleRyanUploadFilesClose = useCallback(
    (uploadedFiles: boolean) => {
      if (uploadedFiles) {
        fetchDataRequest();
        handleFilesUploaded();
      }

      setOpenRyanUploadModal(false);
    },
    [fetchDataRequest, handleFilesUploaded]
  );

  /**
   * Opens the "create new folder" modal.
   */
  const handleFolderCreate = useCallback(() => {
    setNewFolder(true);
  }, []);

  /**
   * Closes the "create new folder" modal.
   */
  const handleFolderCreateClose = useCallback(
    (folder?: IFolder) => {
      setNewFolder(false);

      if (folder) {
        setDirectoryState({ engagementFolders: null });
      }
    },
    [setDirectoryState]
  );

  /**
   * Navigates to the *Data & Files - Files* page when clicking on the root
   * account breadcrumb link.
   */
  const handleNavigateToAccount = () => {
    history.push('/app/data-and-files/files');
  };

  /**
   * Navigates to the root directory on the engagement breadcrumb link.
   */
  const handleNavigateToRoot = () => {
    setHash({ folderGuid: undefined });
  };

  /**
   * Callback called on folder click in the directory browser.
   */
  const handleNavigateToFolder = useCallback((folderGuid: string) => {
    setHash({ folderGuid });
  }, []);

  /**
   * Table Actions
   */

  const handleSort = useCallback(
    (sorted: any) => {
      setDirectoryState({
        sorted
      });
    },
    [setDirectoryState]
  );

  const handleFilter = useCallback(
    (filtered: IDirectoryState['filtered']) => {
      setDirectoryState({
        filtered,
        page: 1
      });
    },
    [setDirectoryState]
  );

  const handlePage = useCallback(
    (page: number, pageSize: number) => {
      setDirectoryState({
        page,
        pageSize
      });
    },
    [setDirectoryState]
  );

  const handleRefresh = useCallback(
    async (refreshType?: FileDirectoryRefreshType) => {
      setDirectoryState({
        engagementFolders: null
      });

      if (
        refreshType === FileDirectoryRefreshType.FILE_DELETED ||
        refreshType === FileDirectoryRefreshType.FILE_ARCHIVED
      ) {
        fetchDataRequest();
      }
    },
    [fetchDataRequest, setDirectoryState]
  );

  const handleSelection = useCallback(
    (selected: string[]) => {
      setDirectoryState({
        selected
      });
    },
    [setDirectoryState]
  );

  /**
   * Follow/Unfollow Data Request Callbacks
   */

  /**
   * Callback called on clicking the "follow/unfollow" toggle.
   */
  const handleToggleFollow = useCallback(async () => {
    if (!dataRequest) return;

    try {
      const response = await ApiService.addWatcherToQueue(
        dataRequest.engagementGuid,
        !dataRequest.isCurrentUserWatching,
        dataRequest.queueItemGuid
      );
      setDataRequest(prevDataRequest => ({
        ...prevDataRequest,
        ...(response.data as IDataRequest)
      }));
    } catch {
      pushServerErrorToast();
    }
  }, [dataRequest, setDataRequest]);

  /**
   * Closes the followers modal.
   */
  const handleFollowersModalClose = useCallback(() => {
    setOpenFollowersModal(false);
  }, []);

  /**
   * Opens the followers modal.
   */
  const handleFollowersModalOpen = useCallback(() => {
    setOpenFollowersModal(true);
  }, []);

  /**
   * Callback called on followers modal submit.
   */
  const handleFollowersModalUpdate = useCallback(() => {
    setOpenFollowersModal(false);
    fetchDataRequest();
  }, [fetchDataRequest]);

  // update page title
  useEffect(() => {
    if (account) {
      if (isAdHoc) {
        setPageTitle(
          getTextToDisplay('dataRequest.detailPage.titleForFileUpload')
        );
      } else if (userTypeId === UserType.Client) {
        setPageTitle(getTextToDisplay('dataRequest.detailPage.titleForClient'));
      } else {
        setPageTitle(getTextToDisplay('dataRequest.detailPage.titleForRyan'));
      }
    }
  }, [account, isAdHoc, userTypeId, getTextToDisplay]);

  // update directory state ref
  useEffect(() => {
    directoryStateRef.current = directoryState;
  }, [directoryState]);

  // update engagement ref
  useEffect(() => {
    engagementRef.current = engagement;
  }, [engagement]);

  // listen for comments changes
  useEffect(() => {
    dataRequestDrawerEvents.addListener('commentAdded', fetchDataRequest);
    dataRequestDrawerEvents.addListener('commentRemoved', fetchDataRequest);

    return () => {
      dataRequestDrawerEvents.removeListener('commentAdded', fetchDataRequest);
      dataRequestDrawerEvents.removeListener(
        'commentRemoved',
        fetchDataRequest
      );
    };
  }, [dataRequestDrawerEvents, fetchDataRequest]);

  useEffect(() => {
    codeNotesDrawerEvents.addListener('noteAdded', fetchDataRequest);
    codeNotesDrawerEvents.addListener('noteRemoved', fetchDataRequest);

    return () => {
      codeNotesDrawerEvents.removeListener('noteAdded', fetchDataRequest);
      codeNotesDrawerEvents.removeListener('noteRemoved', fetchDataRequest);
    };
  }, [codeNotesDrawerEvents, fetchDataRequest]);

  // fetch data request on URL path update
  useEffect(() => {
    // reset relevant props on before fetch
    setAccount(null);
    setEngagement(null);
    setDataRequest(null);
    setDataRequestDeleted(false);
    setNewFolder(false);
    setOpenFollowersModal(false);
    setDirectoryState(prevDirectoryState => ({
      ...initialDirectoryState,
      pageSize: prevDirectoryState.pageSize
    }));

    // fetch data request
    fetchDataRequest();
  }, [
    queueItemGuid,
    fetchDataRequest,
    setAccount,
    setDataRequest,
    setDirectoryState,
    setEngagement
  ]);

  // redirect if current data request is no longer associated with the updated
  // view on view change
  useEffect(
    () => {
      const handleViewUpdate = async () => {
        if (!dataRequest) return;

        try {
          updateSourceRef.current?.cancel();
          updateSourceRef.current = ApiService.CancelToken.source();
          const isInView = await isEngagementInView(
            dataRequest.engagementGuid,
            updateSourceRef.current.token
          );

          if (!isInView) {
            handleNotInView(dataRequest.isAdHoc);
            return;
          }
        } catch (error) {
          // redirect if cannot verify data request is in view
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
            handleNotInView(dataRequest.isAdHoc);
            return;
          }
        }
      };

      handleViewUpdate();
    },
    // don't run hook on dataRequest change as activeView will be updated to
    // align with dataRequest updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeView, handleNotInView, isEngagementInView]
  );

  // check if comment drawer should open on hash update
  useEffect(() => {
    if (dataRequest && /^#comments$/.test(location.hash)) {
      onDataRequestDrawerOpenComments(dataRequest);
    }
  }, [dataRequest, location.hash, onDataRequestDrawerOpenComments]);

  // update engagement folders if data request transfer destination has changed
  // since the new transfer destination may be a new folder
  useEffect(() => {
    (async function () {
      if (dataRequestDefaultFolderGuid && engagementRef.current) {
        const response = await ApiService.getEngagementFolders(
          engagementRef.current.engagementGuid
        );
        const foldersWithoutInternal = response.data.filter(
          folder => folder.folderName !== RYAN_INTERNAL
        );
        if (isInternalFilesFeatureEnabled) {
          setDirectoryState({
            engagementFolders: response.data
          });
        } else {
          setDirectoryState({
            engagementFolders: foldersWithoutInternal
          });
        }
      }
    })();
  }, [
    dataRequestDefaultFolderGuid,
    isInternalFilesFeatureEnabled,
    setDirectoryState
  ]);

  // store folder GUID hash value to state to trigger directory update on change
  useEffect(() => {
    const { folderGuid } = qs.parse(location.hash);

    if (typeof folderGuid === 'string' || folderGuid === null) {
      setFolderGuidHash(folderGuid);
    }
  }, [location.hash]);

  // toggle directory update flag on hash update
  useEffect(() => {
    setDirectoryState({
      didDirectoryChange: true
    });
  }, [folderGuidHash, setDirectoryState]);

  // update directory
  useEffect(
    () => {
      fetchDirectory();
    },
    // hash updates will cause `fetchDirectory` to update so don't need to add
    // `folderGuidHash` to dependency tree; re-add if function no longer listens
    // for hash changes
    [
      dataRequest,
      engagementFolders,
      filtered,
      page,
      pageSize,
      sorted,
      fetchDirectory
    ]
  );

  // on directory update, decrement current page if last item on a page greater
  // than 1 has been deleted
  useEffect(
    () => {
      if (directoryItems.length === 0 && page > 1) {
        handlePage(page - 1, pageSize);
      }
    },
    // omit page from dependencies as it causes excessive iterations of hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [directoryItems.length, pageSize, handlePage]
  );

  // cancel ongoing requests on unmount
  useEffect(
    () => () => {
      sourceRef.current?.cancel();
      directorySourceRef.current?.cancel();
      updateSourceRef.current?.cancel();
    },
    []
  );

  // Early return if Data Request is deleted
  if (dataRequestDeleted) {
    return (
      <div className="data-request-page">
        <DocumentTitle title={getTextToDisplay('dataAndFiles.title')} />
        <div className="data-request-page__deleted">
          <img alt="" src={deletedImage} />
          <h1 className="ry-h2">
            {getTextToDisplay('dataRequest.detailPage.deletedTitle')}
          </h1>
          <p>{getTextToDisplay('dataRequest.detailPage.deletedContent')}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="data-request-page">
      <DocumentTitle title={getTextToDisplay('dataAndFiles.title')} />
      <Breadcrumb label="Data & Files" to="/app/data-and-files/data-requests" />
      {account === null ? (
        <>
          <div className="ry-skeleton sk-super-title" />
          <div className="ry-skeleton sk-title" />
        </>
      ) : (
        <>
          <Breadcrumb label={pageTitle} to={match.url} />
          <h4 className="ry-h4">{account.name}</h4>
          <h1 className="ry-h1">{pageTitle}</h1>
        </>
      )}
      <div className="row">
        <div className="col-md-8 col-12">
          <Card>
            <div className="data-request-page__card-content">
              {dataRequest === null ? (
                <>
                  <div className="ry-skeleton sk-super-title" />
                  <div className="ry-skeleton sk-title" />
                  <div className="sk-p">
                    <div className="ry-skeleton" />
                    <div className="ry-skeleton" />
                    <div className="ry-skeleton" />
                  </div>
                  <div className="sk-p">
                    <div className="ry-skeleton" />
                    <div className="ry-skeleton" />
                  </div>
                </>
              ) : (
                <DataRequestFields
                  dataRequest={dataRequest}
                  handleDateRangesModalOpen={() =>
                    setIsDateRangesModalOpen(true)
                  }
                  handleDocumentTypesModalOpen={() =>
                    setIsDocumentTypesModalOpen(true)
                  }
                  handleJurisdictionsModalOpen={() =>
                    setIsJurisdictionsModalOpen(true)
                  }
                />
              )}
            </div>
            <div className="data-request-page__card-actions">
              <div>
                {isRWMDataRequestsFeatureEnabled &&
                  dataRequest &&
                  (isERPType || isDocumentImagesType) &&
                  dataRequest.isRequireCodeAssistance &&
                  userTypeId === UserType.Ryan && (
                    <Button
                      className="data-request-page__card-actions__code-note__button"
                      disabled={!dataRequest}
                      icon={
                        dataRequest.codeNotesCount === 0 ||
                        dataRequest.isUserGhosted
                          ? 'comment'
                          : 'comment-alert'
                      }
                      onClick={() => {
                        if (dataRequest) {
                          onCodeNotesDrawerOpen(dataRequest);
                        }
                      }}
                      size="sm"
                      text={getTextToDisplay(
                        dataRequest.codeNotesCount! || dataRequest.isUserGhosted
                          ? 'notesWithCode'
                          : 'addNotesForCode',
                        { count: dataRequest.codeNotesCount }
                      )}
                      variant="text"
                    />
                  )}
              </div>
              <div>
                <Button
                  disabled={!dataRequest}
                  icon="time"
                  onClick={() => {
                    if (dataRequest) {
                      onDataRequestDrawerOpenHistory(dataRequest);
                    }
                  }}
                  size={ButtonSizeEnums.SMALL}
                  variant="text"
                >
                  {getTextToDisplay('View History')}
                </Button>
                <Button
                  {...getCommentButtonProps(
                    getTextToDisplay,
                    canContribute,
                    dataRequest,
                    isAppReadOnly
                  )}
                  onClick={() => {
                    if (dataRequest) {
                      onDataRequestDrawerOpenComments(dataRequest);
                    }
                  }}
                  size={ButtonSizeEnums.SMALL}
                  variant="text"
                />
              </div>
            </div>
          </Card>
          <FileDirectoryHeader
            engagement={engagement}
            folderPath={folderPath}
            isTransferredFilesView={true}
            loading={loading}
            onDataRequests={true}
            onFolderCreate={handleFolderCreate}
            onNavigateToAccount={handleNavigateToAccount}
            onNavigateToFolder={handleNavigateToFolder}
            onNavigateToRoot={handleNavigateToRoot}
            shouldHideBreadcrumbs={true}
            t={getTextToDisplay}
            title={getTextToDisplay('Transferred Files')}
            view={activeView}
          />
          <FileDirectory
            directoryItems={directoryItems}
            directoryItemsBySearch={null}
            engagement={engagement}
            engagementFolders={engagementFolders}
            filtered={filtered}
            folderPath={folderPath}
            isTransferredFilesView={true}
            loading={loading}
            onFilter={handleFilter}
            onNavigateToFolder={handleNavigateToFolder}
            onPage={handlePage}
            onRefresh={handleRefresh}
            onSelection={handleSelection}
            onSort={handleSort}
            page={page}
            pageSize={pageSize}
            selected={selected!}
            showFileTooltips={true}
            sorted={sorted}
            t={getTextToDisplay}
            totalCount={totalCount}
            totalFileCount={totalFileCount}
          />
        </div>
        <div className="data-request-page__aside col-12 col-md-4">
          {canEdit &&
            !isAdHoc &&
            userTypeId === UserType.Ryan &&
            (dataRequest === null ? (
              <div className="ry-skeleton sk-btn-lg" />
            ) : (
              <DataRequestActions
                dataRequest={dataRequest}
                isOnDetailPage
                onUpdate={handleUpdate}
                size="lg"
              />
            ))}
          <div className="well">
            {/* loading */}
            {dataRequest === null && (
              <>
                <div className="ry-skeleton sk-status" />
                <hr />
                <ul className="row labeled-list">
                  <li className="col-12 col-lg-6">
                    <div className="ry-skeleton" />
                    <div className="ry-skeleton" />
                  </li>
                  <li className="col-12 col-lg-6">
                    <div className="ry-skeleton" />
                    <div className="ry-skeleton" />
                  </li>
                  <li className="col-12 col-lg-6">
                    <div className="ry-skeleton" />
                    <div className="ry-skeleton" />
                  </li>
                  <li className="col-12 col-lg-6">
                    <div className="ry-skeleton" />
                    <div className="ry-skeleton" />
                  </li>
                  <li className="col-12 col-lg-6">
                    <div className="ry-skeleton" />
                    <div className="ry-skeleton" />
                  </li>
                  <li className="col-12 col-lg-6">
                    <div className="ry-skeleton" />
                    <div className="ry-skeleton" />
                  </li>
                  <li className="col-12 col-lg-6">
                    <div className="ry-skeleton" />
                    <div className="ry-skeleton" />
                  </li>
                </ul>
              </>
            )}

            {/* ad hoc */}
            {dataRequest && dataRequest.isAdHoc && (
              <>
                <b>
                  {getTextToDisplay('dataRequest.detailPage.uploadDetails')}
                </b>
                <hr />
                <ul className="labeled-list row">
                  <li className="col-12 col-lg-6">
                    <label>
                      {getTextToDisplay('dataRequest.columns.uploadByName')}
                    </label>
                    {dataRequest.createdBy === userGuid
                      ? getTextToDisplay('You')
                      : dataRequest.createdByName}
                  </li>
                  {dataRequest.createDate && (
                    <li className="col-12 col-lg-6">
                      <label>
                        {getTextToDisplay('dataRequest.columns.uploadDate')}
                      </label>
                      {formatDate(dataRequest.createDate)}
                    </li>
                  )}
                </ul>
              </>
            )}

            {/* normal (not ad hoc) */}
            {dataRequest && !dataRequest.isAdHoc && (
              <>
                {Object.values(Status).includes(dataRequest.statusId) && (
                  <div className="well__status">
                    <StatusIcon
                      size={ButtonSizeEnums.LARGE}
                      status={dataRequest.statusId}
                    />
                    {isRWMDataRequestsFeatureEnabled &&
                      Object.values(SubStatusEnums).includes(
                        dataRequest.subStatusId
                      ) && (
                        <>
                          <span className="well__status__dash">&mdash;</span>
                          <span>{dataRequest.subStatus}</span>
                        </>
                      )}
                  </div>
                )}
                <hr />
                <ul className="labeled-list row">
                  <li className="col-12 col-lg-6">
                    <label>
                      {getTextToDisplay('dataRequest.columns.assignedTo')}
                    </label>
                    {dataRequest.assignedToUserGuid === userGuid
                      ? getTextToDisplay('You')
                      : isRWMDataRequestsFeatureEnabled &&
                        dataRequest.isRequireCodeAssistance &&
                        dataRequest.assignedToSSWMUserName
                      ? dataRequest.assignedToSSWMUserName
                      : dataRequest.assignedToName}
                  </li>
                  {dataRequest.dueDate && (
                    <li className="col-12 col-lg-6">
                      <label>
                        {getTextToDisplay('dataRequest.columns.dueDate')}
                      </label>
                      <span className="duedate">
                        {formatDate(dataRequest.dueDate)}
                      </span>
                    </li>
                  )}
                  <li className="col-12 col-lg-6">
                    <label>
                      {getTextToDisplay('dataRequest.columns.createdByName')}
                    </label>
                    {dataRequest.createdBy === userGuid
                      ? getTextToDisplay('You')
                      : dataRequest.createdByName}
                  </li>
                  {dataRequest.createDate && (
                    <li className="col-12 col-lg-6">
                      <label>
                        {getTextToDisplay('dataRequest.columns.createDate')}
                      </label>
                      {formatDate(dataRequest.createDate)}
                    </li>
                  )}
                  {isRWMDataRequestsFeatureEnabled &&
                    (isERPType || isDocumentImagesType) &&
                    dataRequest.isRequireCodeAssistance &&
                    dataRequest.codeAssistance && (
                      <li className="col-12 col-lg-6">
                        <label>
                          {getTextToDisplay(
                            'dataRequest.dataFields.codeAssistance'
                          )}
                        </label>
                        {dataRequest.codeAssistance}
                      </li>
                    )}
                  {!isReadOnly && (
                    <li className="col-12 col-lg-6">
                      <label>{getTextToDisplay('Followers')}</label>
                      <div className="data-request-page__follow-action-container">
                        <Button onClick={handleToggleFollow} variant="link">
                          {getTextToDisplay(
                            dataRequest.isCurrentUserWatching
                              ? 'Unfollow'
                              : 'Follow'
                          )}
                        </Button>
                        <span className="data-request-page__follow-action-container__separator">
                          &#8226;
                        </span>
                        <Button
                          onClick={handleFollowersModalOpen}
                          variant="link"
                        >
                          {`${getTextToDisplay('Followers')} (${
                            dataRequest.watcherCount
                          })`}
                        </Button>
                      </div>
                    </li>
                  )}
                  {dataRequest.statusId !== Status.Todo && (
                    <li className="col-12 col-lg-6">
                      <label>
                        {getTextToDisplay(
                          'dataRequest.columns.transferredFiles'
                        )}
                      </label>
                      {dataRequest.transferredFilesCount}
                    </li>
                  )}
                  {isRWMDataRequestsFeatureEnabled &&
                    isDocumentImagesType &&
                    dataRequest.externalLinkAccess !== null &&
                    dataRequest.isRequireCodeAssistance && (
                      <li className="col-12 col-lg-6">
                        <label>
                          {getTextToDisplay(
                            'dataRequest.dataFields.externalLinkAccess'
                          )}
                        </label>
                        {dataRequest.externalLinkAccess}
                      </li>
                    )}
                  {dataRequest.statusId === Status.Complete &&
                    dataRequest.completedDate && (
                      <li className="col-12 col-lg-6">
                        <label>
                          {getTextToDisplay(
                            'dataRequest.columns.completionDate'
                          )}
                        </label>
                        {formatDate(dataRequest.completedDate)}
                      </li>
                    )}
                </ul>
              </>
            )}
          </div>

          {isRWMDataRequestsFeatureEnabled &&
            isERPType &&
            dataRequest?.statusId === Status.Complete &&
            dataRequest.isRequireCodeAssistance &&
            dataRequest.serverName &&
            dataRequest.databaseName &&
            permissionService.isRyan() && (
              <div className="well">
                <label className="ry-label">
                  {getTextToDisplay('dataRequest.internalOnly')}
                </label>
                <hr />
                <ul className="row labeled-list">
                  <li className="col-12">
                    <label>
                      {getTextToDisplay('dataRequest.dataFields.serverName')}
                    </label>
                    {dataRequest.serverName}
                  </li>
                  <li className="col-12">
                    <label>
                      {getTextToDisplay('dataRequest.dataFields.databaseName')}
                    </label>
                    {dataRequest.databaseName}
                  </li>
                </ul>
              </div>
            )}

          {!isAdHoc &&
            ((permissionService.isRyan() && canEdit) ||
              (permissionService.isClient() && canContribute)) && (
              <Button
                block
                disabled={
                  dataRequest === null ||
                  isReadOnly ||
                  dataRequest.statusId === Status.Complete
                }
                onClick={
                  permissionService.isRyan()
                    ? handleRyanUploadFiles
                    : handleUploadFiles
                }
                size="lg"
                variant="primary"
              >
                {getTextToDisplay('Upload Files')}
              </Button>
            )}
        </div>
      </div>

      {canEdit && (
        <DataRequestRyanUploadModal
          dataRequest={dataRequest}
          engagement={engagement}
          onClose={handleRyanUploadFilesClose}
          open={openRyanUploadModal}
        />
      )}

      {canContribute && (
        <DataRequestUploadModal
          dataRequest={dataRequest}
          loading={uploadModalLoading}
          onCancel={handleUploadFilesCancel}
          onSubmit={handleUploadFilesSubmit}
          open={uploadModalOpen}
        />
      )}

      {canContribute && engagement && newFolder && (
        <NewFolderModal
          defaultFolder={
            folderPath.length ? folderPath[folderPath.length - 1] : null
          }
          engagement={engagement}
          folders={engagementFolders}
          onClose={handleFolderCreateClose}
          rootName={engagement.engagementDisplayNameShort}
        />
      )}

      {dataRequest && account && (
        <FollowersModal
          engagementGuid={dataRequest.engagementGuid}
          instructions={getTextToDisplay('followersModal.instructions', {
            context: 'dataRequest'
          })}
          onClose={handleFollowersModalClose}
          onUpdate={handleFollowersModalUpdate}
          open={openFollowersModal}
          queueItemGuid={dataRequest.queueItemGuid}
          title={getTextToDisplay('Followers', { context: 'dataRequest' })}
          userTypeId={userTypeId}
        />
      )}
      {isRWMDataRequestsFeatureEnabled &&
        dataRequest?.dateRange &&
        isDateRangesModalOpen && (
          <RecordsModal
            data={dataRequest.dateRange as TDateRange[]}
            dataRequestTitle={dataRequest.title}
            engagementDisplayName={dataRequest.engagementDisplayNameShort}
            modalTitle={getTextToDisplay('dataRequest.recordsModal.dateRanges')}
            onClose={() => setIsDateRangesModalOpen(false)}
          />
        )}
      {isRWMDataRequestsFeatureEnabled &&
        dataRequest?.jurisdictions &&
        isJurisdictionsModalOpen && (
          <RecordsModal
            data={dataRequest.jurisdictions.map(
              jurisdiction => jurisdiction.name
            )}
            dataRequestTitle={dataRequest.title}
            engagementDisplayName={dataRequest.engagementDisplayNameShort}
            modalTitle={getTextToDisplay(
              'dataRequest.recordsModal.jurisdictions'
            )}
            onClose={() => setIsJurisdictionsModalOpen(false)}
          />
        )}
      {isRWMDataRequestsFeatureEnabled &&
        dataRequest?.documentTypes &&
        isDocumentTypesModalOpen && (
          <RecordsModal
            data={dataRequest.documentTypes.map(
              documentType => documentType.name
            )}
            dataRequestTitle={dataRequest.title}
            engagementDisplayName={dataRequest.engagementDisplayNameShort}
            modalTitle={getTextToDisplay(
              'dataRequest.recordsModal.documentTypes'
            )}
            onClose={() => setIsDocumentTypesModalOpen(false)}
          />
        )}
    </div>
  );
};

export default DataRequest;
