import DataRequests from 'components/DataRequests/DataRequests';
import DocumentTitle from 'components/DocumentTitle';
import TabsAndRoutes from 'components/Tabs/TabsAndRoutes';
import { useUser } from 'contexts/UserContext';
import { Permission } from 'interfaces';
import { amplitudeEventDetail } from 'utils/amplitudeUtils/amplitudeUtils';

import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { useAmplitude } from '../../contexts/AmplitudeContext/AmplitudeConsumer';
import Contracts from './Contracts/Contracts';
import Files from './Files/Files';
import Invoices from './Invoices/Invoices';
import Overview from './Overview/Overview';

const DataAndFiles: FunctionComponent = () => {
  const { initializeEventToTrack } = useAmplitude();
  const { t } = useTranslation();
  const { activeView, permissionService: ps } = useUser();

  return (
    <div className="data-and-files">
      <DocumentTitle title={t('dataAndFiles.title')} />
      <h4 className="ry-h4">{activeView.name}</h4>
      <h1 className="ry-h1">{t('dataAndFiles.title')}</h1>

      <TabsAndRoutes
        onTabChangeHandler={destinationRoute => {
          if (destinationRoute !== '/app/data-and-files/invoices') {
            return;
          }

          initializeEventToTrack({
            eventName: amplitudeEventDetail.ryanInvoices.eventName,
            eventProperty:
              amplitudeEventDetail.ryanInvoices.viewPropertyOptions.dataAndFiles
          });
        }}
        tabs={[
          {
            // Overview
            label: t('dataAndFiles.tabs.overview'),
            path: '/app/data-and-files/overview',
            component: Overview,
            enabled:
              ps.hasPermission(Permission.ActivityRead) ||
              ps.hasPermission(Permission.DataRequestsView)
          },
          {
            // Data Requests
            label: t('dataAndFiles.tabs.dataRequests'),
            path: '/app/data-and-files/data-requests',
            component: DataRequests,
            enabled: ps.hasPermission(Permission.DataRequestsView)
          },
          {
            // Files
            label: t('dataAndFiles.tabs.files'),
            path: '/app/data-and-files/files',
            component: Files
          },
          {
            // Contracts
            label: t('dataAndFiles.tabs.contracts'),
            path: '/app/data-and-files/contracts',
            component: Contracts,
            enabled: ps.hasPermission(Permission.ContractsView)
          },

          {
            // Invoices
            label: t('dataAndFiles.tabs.invoices'),
            path: '/app/data-and-files/invoices',
            component: Invoices,
            enabled: ps.hasPermission(Permission.InvoicesView)
          }
        ]}
      />
    </div>
  );
};

export default DataAndFiles;
