import classnames from 'classnames';
import ENV from 'env';
import React, { useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, GlobalHeader, Icon } from '@ryan/components';

import { useAmplitude } from '../../../contexts/AmplitudeContext/AmplitudeConsumer';
import { useUnsavedChanges } from '../../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import { useUser } from '../../../contexts/UserContext';
import { Feature, Permission } from '../../../interfaces';
import {
  AmplitudeActionType,
  amplitudeEventDetail
} from '../../../utils/amplitudeUtils/amplitudeUtils';
import { RedirectToDecisionUrlEnum } from '../../../utils/enums/RedirectToDecisionsEnum';
import getAvatarUrl from '../../../utils/getAvatarUrl';
import NotificationDrawer from '../../NotificationDrawer/NotificationDrawer';
import useNotifications from '../../NotificationDrawer/useNotifications';
import { SwitcherConsumer, isSwitcherOnRoute } from '../../Switcher';
import GlobalHeaderTabLink from '../GlobalHeaderTabLink';

import './Navigation.scss';

const Navigation: React.FC = () => {
  const { initializeEventToTrack } = useAmplitude();
  const history = useHistory();
  const { t: getTextToDisplay } = useTranslation();
  const { executiveAccounts, isFeatureToggled, permissionService, user } =
    useUser();
  const location = useLocation();
  const { isUnsavedChanges, setBlockNavigation, setTargetUrl } =
    useUnsavedChanges();

  // check if user has executive accounts if they have no personal accounts
  const hasAccounts = !!user.accountTree.length || !!executiveAccounts?.length;
  const isDataRequestsUpdateVisible = isFeatureToggled(Feature.RWMDataRequests);
  const isThirdPartyUser = permissionService.isThirdParty();

  // should display switcher if user has multple personal accounts or executive
  // accounts if they have executive access
  const showAccountSwitcher = hasAccounts && isSwitcherOnRoute(location);

  const handleMenuNavigation = useCallback(
    (url: string) => (event: React.MouseEvent) => {
      if (isUnsavedChanges) {
        setBlockNavigation(true);
        setTargetUrl(url);
        event.preventDefault();
      } else {
        history.push(url);
      }
    },
    [history, isUnsavedChanges, setBlockNavigation, setTargetUrl]
  );

  const userDropdownOptions = useMemo(() => {
    const options = [
      {
        icon: 'user-circle',
        label: getTextToDisplay('Log out'),
        onClick: handleMenuNavigation('/public/logout')
      },
      {
        icon: 'cog',
        label: getTextToDisplay('Profile'),
        onClick: handleMenuNavigation('/app/profile')
      }
    ];

    if (
      permissionService.hasPermission(Permission.Impersonate) &&
      !user.dxpImpersonatingUserGuid
    ) {
      options.push({
        icon: 'user',
        label: getTextToDisplay('Impersonate'),
        onClick: handleMenuNavigation('/app/impersonate')
      });
    }

    if (permissionService.isSuperAdmin()) {
      options.push({
        icon: 'user-profile',
        label: getTextToDisplay('Manage Roles'),
        onClick: handleMenuNavigation('/app/roles')
      });

      options.push({
        icon: 'search',
        label: getTextToDisplay('User Search'),
        onClick: handleMenuNavigation('/app/usersearch')
      });

      options.push({
        icon: 'bulb',
        label: getTextToDisplay('Feature Toggles'),
        onClick: handleMenuNavigation('/app/featuretoggles')
      });

      options.push({
        icon: 'ryan-logo',
        label: getTextToDisplay('Superadmin Dashboard'),
        onClick: handleMenuNavigation('/app/superadmin/dashboard')
      });
    }

    return options;
  }, [
    getTextToDisplay,
    handleMenuNavigation,
    permissionService,
    user.dxpImpersonatingUserGuid
  ]);

  const [
    state,
    {
      closeDrawer,
      dismissNotifications,
      fetchNext,
      fetchNotifications,
      openDrawer,
      trackDismissNotifications
    }
  ] = useNotifications();
  // TODO: Worklist: Refactor how we handle navigation on Edits
  const handleNavigation = (url: string) => (event: React.MouseEvent) => {
    // TODO: Worklist: Workaround for amplitude on navigation to worklist
    // TODO: Worklist: Tracking clicks when there is no URL change?
    if (url === '/app/worklist') {
      initializeEventToTrack({
        eventAction: AmplitudeActionType.CLICK,
        eventName: amplitudeEventDetail.global.viewWorklistEventName
      });
    }

    if (isUnsavedChanges) {
      setBlockNavigation(true);
      setTargetUrl(url);
      event.preventDefault();
    } else if (url === '/app') {
      history.push('/app');
    }
  };

  const redirectToDecisionsWorklist = (event: React.MouseEvent) => {
    const returnUrl = `&Return=${window.location.href}`;
    const decisionsRedirectUrl =
      ENV.DECISIONS_BASE_URL +
      RedirectToDecisionUrlEnum.WORKLIST_REQUEST +
      returnUrl;

    if (isUnsavedChanges) {
      setBlockNavigation(true);
      setTargetUrl(decisionsRedirectUrl);
      event.preventDefault();
    } else {
      window.open(decisionsRedirectUrl, '_self');
    }
  };

  return (
    <GlobalHeader isInternalUser={permissionService.isRyan()}>
      {/* Logo */}
      <GlobalHeader.Logo
        alt={getTextToDisplay('dashboard.title')}
        onClick={handleNavigation('/app')}
      />

      {/* Tabs */}
      <GlobalHeader.Tabs>
        {hasAccounts && (
          <>
            <GlobalHeaderTabLink
              onClick={handleNavigation('/app/projects')}
              to="/app/projects"
            >
              <Icon name="ryan-logo" />
              {getTextToDisplay('projects.link')}
            </GlobalHeaderTabLink>
            {permissionService.hasPermission(Permission.FilesRead) && (
              <GlobalHeaderTabLink
                onClick={handleNavigation('/app/data-and-files')}
                to="/app/data-and-files"
              >
                <Icon name="bookmarks-document" />
                {getTextToDisplay('dataAndFiles.title')}
              </GlobalHeaderTabLink>
            )}
            <GlobalHeaderTabLink
              onClick={handleNavigation('/app/team')}
              to="/app/team"
            >
              <Icon name="user" />
              {getTextToDisplay('team.title')}
            </GlobalHeaderTabLink>

            {!permissionService.isThirdParty() && (
              <GlobalHeaderTabLink
                onClick={handleNavigation('/app/worklist')}
                to="/app/worklist"
              >
                <Icon name="clipboard" />
                {getTextToDisplay('appHeader.navigation.worklist')}
              </GlobalHeaderTabLink>
            )}
          </>
        )}
        {isDataRequestsUpdateVisible && !isThirdPartyUser && (
          <Button
            className="ry-global-header__tab"
            onClick={redirectToDecisionsWorklist}
            role="link"
            variant="link"
          >
            <Icon name="clipboard" />
            {getTextToDisplay('worklist')}
          </Button>
        )}
      </GlobalHeader.Tabs>

      {/* User Menu */}
      <GlobalHeader.User
        avatarImageUrl={getAvatarUrl(user.profile)}
        firstName={user.profile.firstName || ''}
        greeting={getTextToDisplay('Hi')}
        lastName={user.profile.lastName || ''}
        options={userDropdownOptions}
      />

      {/* Notifications */}
      <button
        aria-label={getTextToDisplay('Show notifications')}
        className={classnames({
          'app-header__notifications': true,
          'app-header__notifications--none':
            state.notifications === null || state.notifications.length === 0
        })}
        onClick={() => openDrawer(state.totalResults)}
      >
        <span className="app-header__notifications-icon-container">
          <Icon name="bell" />
        </span>
      </button>

      {/* Account Switcher (Mobile) */}
      {showAccountSwitcher && (
        <SwitcherConsumer>
          {({ openMobile, onToggleMobile }) => (
            <button
              aria-label={getTextToDisplay(
                openMobile ? 'switcher.toggleClose' : 'switcher.toggleOpen'
              )}
              className={classnames({
                'app-header__account-switcher': true,
                'app-header__account-switcher--open': openMobile
              })}
              onClick={onToggleMobile}
            >
              <Icon name="list" />
            </button>
          )}
        </SwitcherConsumer>
      )}

      {createPortal(
        <NotificationDrawer
          notifications={state.notifications}
          notificationsItemsPerPage={state.itemsPerPage}
          notificationsLoading={state.loading}
          notificationsTotalResults={state.totalResults}
          onClose={closeDrawer}
          onDismissNotifications={dismissNotifications}
          onFetchNext={fetchNext}
          onFetchNotifications={fetchNotifications}
          onTrackDismissAllNotifications={() =>
            trackDismissNotifications(state.totalResults)
          }
          onTrackDismissNotifications={trackDismissNotifications}
          open={state.open}
          t={getTextToDisplay}
        />,
        document.body
      )}
    </GlobalHeader>
  );
};

export default Navigation;
