import AutocompleteAjax, {
  IAutocompleteAjaxProps
} from 'components/AutocompleteAjax';
import { IEngagementSummary } from 'interfaces';
import ApiService from 'services/ApiService';

import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { autocompleteHighlight } from '@ryan/components';

import './EngagementAutocomplete.scss';

interface IEngagementAutocompleteProps
  extends Omit<
    IAutocompleteAjaxProps<IEngagementSummary>,
    'getOptionValue' | 'renderOption' | 'onFetchOptions'
  > {
  accountGuid?: string | null;
  customViewGuid?: string | null;
  transformResponse?: (
    engagements: IEngagementSummary[]
  ) => IEngagementSummary[];
}

function getOptionValue(engagement: IEngagementSummary) {
  return engagement.engagementDisplayNameLong;
}

function renderOption(
  engagement: IEngagementSummary,
  { query }: { query: string }
) {
  return (
    <div className="engagement-autocomplete__option">
      {autocompleteHighlight(getOptionValue(engagement), query)}
      <div className="engagement-autocomplete__option-account">
        {engagement.clientName}
      </div>
    </div>
  );
}

const EngagementAutocomplete: FunctionComponent<IEngagementAutocompleteProps> =
  ({ customViewGuid, accountGuid, transformResponse, ...props }) => {
    const { t } = useTranslation();
    const onFetchOptions = useCallback(
      (query: string) =>
        ApiService.engagementAutocomplete(query, customViewGuid, accountGuid)
          .then(response => response.data)
          .then(transformResponse || ((e: IEngagementSummary[]) => e)),
      [customViewGuid, accountGuid, transformResponse]
    );

    return (
      <AutocompleteAjax<IEngagementSummary>
        getOptionValue={getOptionValue}
        label={t('Project')}
        onFetchOptions={onFetchOptions}
        renderOption={renderOption}
        {...props}
      />
    );
  };

export default EngagementAutocomplete;
