import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Card, Icon } from '@ryan/components';

import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import AmplitudeApiService from '../../services/AmplitudeApiService';
import history from '../../services/history';
import { IReportingCardProps } from './utils';

import './ReportingCard.scss';

const ReportingCard: FunctionComponent<IReportingCardProps> = ({
  engagements
}) => {
  const { t: getTextToDisplay } = useTranslation();
  const { isUnsavedChanges, setBlockNavigation, setTargetUrl } =
    useUnsavedChanges();

  const handleGenerateReportClick = (url: string) => {
    if (isUnsavedChanges) {
      setBlockNavigation(true);
      setTargetUrl(url);
    } else {
      history.push(url);
    }
  };

  return (
    <Card role="region" title={getTextToDisplay('reportingCard.title')}>
      <div className="reporting-card__content">
        <Icon name="file" />
        <p>{getTextToDisplay('reportingCard.content')}</p>
      </div>
      <Button
        block
        onClick={() => {
          const reportingPath = history.location.pathname.includes('overview')
            ? 'single-project'
            : 'dashboard';
          AmplitudeApiService.logEvent('initiate-reports', {
            'reporting-path': reportingPath
          });
          handleGenerateReportClick(
            engagements.length === 1
              ? `/app/projects/reporting#${engagements[0].engagementGuid}`
              : `/app/projects/reporting`
          );
        }}
        text={getTextToDisplay('reportingCard.button')}
        variant="primary"
      />
    </Card>
  );
};

export default ReportingCard;
