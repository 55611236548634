import { makeOnSuggestionsRequested } from 'components/Comments/CommentInput/CommentInput';
import Modal from 'components/Modal';
import { IDataRequest, Status } from 'interfaces';
import ApiService from 'services/ApiService';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { Component, createRef } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  MentionsValue,
  pushToast
} from '@ryan/components';

import CommentInput from '../Comments/CommentInput';

interface IDataRequestReopenModalProps extends WithTranslation {
  dataRequest: IDataRequest;
  open: boolean;
  onClose: (updatedDataRequest?: IDataRequest) => void;
}

interface IDataRequestReopenModalState {
  comment: MentionsValue;
  isValid: boolean;
}

const maxLengthComment = 250;

export class DataRequestReopenModal extends Component<
  IDataRequestReopenModalProps,
  IDataRequestReopenModalState
> {
  private modalContentRef = createRef<HTMLDivElement>();

  readonly state = {
    comment: new MentionsValue(),
    isValid: true
  };

  componentDidUpdate(prevProps: IDataRequestReopenModalProps) {
    if (this.props.open !== prevProps.open) {
      this.setState({ comment: new MentionsValue() });
    }
  }

  handleChange = (comment: MentionsValue) => {
    const isValid = comment.length <= maxLengthComment;
    this.setState({ comment, isValid });
  };

  handleReopen = async () => {
    const { t, dataRequest } = this.props;
    const { comment, isValid } = this.state;

    if (!isValid) return;

    try {
      const response = await ApiService.updateDataRequest(dataRequest, {
        statusId: Status.InProgress,
        comment: comment.toJSON().text
      });

      const updatedDataRequest = response.data;

      pushToast({
        type: 'success',
        title: t('dataRequest.reopenModal.successTitle'),
        content: t('dataRequest.reopenModal.successContent', {
          title: updatedDataRequest.title
        })
      });

      this.handleClose(updatedDataRequest);
    } catch (error) {
      pushServerErrorToast();
      this.handleClose();
    }
  };

  handleClose = (updatedDataRequest?: IDataRequest) => {
    this.props.onClose(updatedDataRequest);
  };

  render() {
    const { t, open, dataRequest } = this.props;
    const { comment, isValid } = this.state;
    const validationProps = {
      feedback: t('dataRequest.reopenModal.comment.maxLength', {
        length: maxLengthComment
      }),
      invalid: !isValid
    };

    return (
      <Modal
        onClose={() => this.handleClose()}
        open={open}
        title={t('dataRequest.reopenModal.title')}
      >
        <div ref={this.modalContentRef}>
          <CommentInput
            {...(!isValid ? validationProps : {})}
            boundingParentRef={this.modalContentRef}
            label={t('dataRequest.reopenModal.comment.label')}
            makeOnSuggestionsRequestedCallback={() =>
              makeOnSuggestionsRequested(
                dataRequest.engagementGuid,
                user => user.canBeMentionedInDRComment
              )
            }
            onChange={this.handleChange}
            value={comment}
          />
          <ButtonGroup>
            <Button
              disabled={!isValid}
              onClick={this.handleReopen}
              text={t('Reopen')}
              variant="primary"
            />
            <Button
              onClick={() => this.handleClose()}
              text={t('Cancel')}
              variant="secondary"
            />
          </ButtonGroup>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(DataRequestReopenModal);
