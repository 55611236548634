import { makeOnSuggestionsRequested } from 'components/Comments/CommentInput/CommentInput';

import { parseISO, startOfDay } from 'date-fns';
import React, { Component, createRef } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  Checkbox,
  MentionsValue,
  Message,
  TextInput,
  Textarea,
  pushToast
} from '@ryan/components';

import { WithUser, withUser } from '../../contexts/UserContext';
import {
  Feature,
  FolderSelection,
  IAttachmentUpdates,
  IDataRequest,
  IDataRequestRequest,
  IEngagementSummary,
  IFile,
  IFolderTree,
  IUserSummary
} from '../../interfaces';
import ApiService from '../../services/ApiService';
import { DSSManager } from '../../utils/DSS';
import { ApiErrorResponseEnums } from '../../utils/enums/ApiErrorResponseEnums';
import findFolder from '../../utils/findFolder';
import {
  FormikProps,
  formikAutocompleteAjaxProps,
  formikCheckboxProps,
  formikCommentProps,
  formikDatepickerProps,
  formikFieldProps,
  withFormik,
  yup
} from '../../utils/forms';
import pushServerErrorToast from '../../utils/pushServerErrorToast';
import { EngagementAutocomplete, UserAutocomplete } from '../AutocompleteAjax';
import CommentInput from '../Comments/CommentInput';
import Datepicker from '../Datepicker';
import { RYAN_INTERNAL } from '../FileDirectory/utils/FileDirectoryEnums';
import ManageAttachments from '../ManageAttachments';
import SelectFolder from '../SelectFolder/SelectFolder';

const maxLengthTitle = 50;
const maxLengthDescription = 250;
const maxLengthDataSpecs = 600;
const maxLengthComment = 250;

interface IDataRequestFormValues {
  title: string;
  description: string;
  engagement: IEngagementSummary | null;
  assignedTo: Partial<IUserSummary> | null;
  dueDate: Date | null;
  dataSpecs: string;
  attachments: IAttachmentUpdates;
  defaultFolder: FolderSelection;
  comment: MentionsValue;
  isCurrentUserWatching: boolean;
}

/**
 * The earliest due date should be today, unless we are editing
 * a DataRequest whose due date has passed.
 * @param dataRequest
 */
function minDueDate(dataRequest?: IDataRequest) {
  const today = startOfDay(new Date());
  if (dataRequest) {
    const dueDate = startOfDay(parseISO(dataRequest.dueDate));
    if (dueDate < today) {
      return dueDate;
    }
  }
  return today;
}

/**
 * Inner Form
 */

interface IDataRequestInnerFormProps
  extends IDataRequestFormProps,
    FormikProps<IDataRequestFormValues>,
    WithUser {}

interface IDataRequestInnerFormState {
  engagementFetching: boolean;
  userFetching: boolean;
  folders: IFolderTree[] | null;
  attachments: IFile[];
}

class DataRequestInnerForm extends Component<
  IDataRequestInnerFormProps,
  IDataRequestInnerFormState
> {
  private formRef = createRef<HTMLFormElement>();

  readonly state: IDataRequestInnerFormState = {
    engagementFetching: false,
    userFetching: false,
    folders: null,
    attachments:
      this.props.dataRequest !== undefined
        ? this.props.dataRequest.attachments
        : []
  };

  /**
   * If we are editing a data request, fetch engagement and assigned user.
   * If we have an engagement, fetch engagement.
   */
  componentDidMount() {
    const { dataRequest, engagementGuid } = this.props;
    if (dataRequest) {
      this.fetchDataRequestValues(dataRequest);
    } else if (engagementGuid) {
      this.fetchEngagementValues(engagementGuid);
    }
  }

  /**
   * Fetch the engagement for a new data request for a specific engagement.
   */
  async fetchEngagementValues(engagementGuid: string) {
    const { setFieldValue } = this.props;
    this.setState({ engagementFetching: true });
    try {
      const response = await ApiService.getEngagement(engagementGuid);
      const engagement = response.data;
      setFieldValue('engagement', engagement);
      this.fetchFolders(engagement.engagementGuid);
    } catch {
      // server error
    }
    this.setState({ engagementFetching: false });
  }

  /**
   * Fetch the engagement and assignedTo user for a data request
   * that's being edited.
   */
  async fetchDataRequestValues(dataRequest: IDataRequest) {
    const { onCancel, resetForm, setValues, values } = this.props;
    const {
      assignedToUserGuid: dataRequestAssignedToUserGuid,
      defaultFolderGuid: dataRequestDefaultFolderGuid,
      engagementGuid: dataRequestEngagementGuid
    } = dataRequest;

    this.setState({ engagementFetching: true, userFetching: true });

    try {
      const [
        { data: engagementResponse },
        { data: userResponse },
        { data: foldersResponse }
      ] = await Promise.all([
        ApiService.getEngagement(dataRequestEngagementGuid),
        ApiService.getUser(dataRequestAssignedToUserGuid as string),
        ApiService.getEngagementFolders(dataRequestEngagementGuid)
      ]);

      const dataRequestDefaultFolder =
        dataRequestDefaultFolderGuid &&
        findFolder(foldersResponse, dataRequestDefaultFolderGuid);
      const updatedFormValues = {
        ...values,
        ...(dataRequestDefaultFolder && {
          defaultFolder: dataRequestDefaultFolder.element
        }),
        assignedTo: {
          firstName: userResponse.firstName,
          fullName: userResponse.fullName,
          lastName: userResponse.lastName,
          userGuid: userResponse.userGuid,
          title: userResponse.title
        },
        engagement: {
          ...engagementResponse,
          clientName: engagementResponse.accountName
        }
      };

      this.setState({ folders: foldersResponse });
      setValues(updatedFormValues);
      resetForm({ values: updatedFormValues });
    } catch {
      pushServerErrorToast();
      onCancel();
    } finally {
      this.setState({ engagementFetching: false, userFetching: false });
    }
  }

  async fetchFolders(engagementGuid: string) {
    this.setState({ folders: null });
    const { data: folders } = await ApiService.getEngagementFolders(
      engagementGuid
    );
    const {
      values: { engagement }
    } = this.props;
    if (engagement && engagement.engagementGuid === engagementGuid) {
      this.setState({ folders });
    }
    return folders;
  }

  handleEngagementChange = (option: IEngagementSummary | null) => {
    const { values, setValues } = this.props;

    if (option === null) {
      this.setState({ folders: null });
    }

    setValues({
      ...values,
      engagement: option,
      assignedTo: null,
      defaultFolder: null
    });

    if (option !== null) {
      this.fetchFolders(option.engagementGuid);
    }
  };

  handleFetchAssignableUsers = async (query: string) => {
    const { engagement } = this.props.values;
    if (engagement) {
      const response = await ApiService.getEngagementDataRequestAssignableUsers(
        engagement.engagementGuid,
        query
      );
      return response.data;
    }
    return [];
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const { handleSubmit } = this.props;

    e.preventDefault();
    handleSubmit(e);
  };

  render() {
    const {
      customViewGuid,
      dataRequest,
      dss,
      engagementGuid,
      isFeatureToggled,
      onCancel,
      t: getTextToDisplay,
      ...formik
    } = this.props;
    const { attachments } = this.state;
    const { engagementFetching, userFetching, folders } = this.state;
    const isNew = typeof dataRequest === 'undefined';
    const isInternalFilesFunctionalityVisible = isFeatureToggled(
      Feature.InternalFiles
    );
    const foldersWithoutInternal = (folders || []).filter(
      folder => folder.folderName !== RYAN_INTERNAL
    );

    return (
      <>
        {formik.status.error}
        <form
          autoComplete="off"
          onSubmit={this.handleSubmit}
          ref={this.formRef}
        >
          <TextInput
            {...formikFieldProps('title', formik)}
            label={getTextToDisplay('dataRequest.modal.fields.title.label')}
          />
          <Textarea
            {...formikFieldProps('description', formik)}
            label={getTextToDisplay(
              'dataRequest.modal.fields.description.label'
            )}
            maxLength={maxLengthDescription}
          />
          <EngagementAutocomplete
            {...formikAutocompleteAjaxProps('engagement', formik)}
            customViewGuid={customViewGuid}
            disabled={
              engagementGuid !== undefined || !isNew || engagementFetching
            }
            label={getTextToDisplay(
              'dataRequest.modal.fields.engagement.label'
            )}
            onChange={this.handleEngagementChange}
          />
          <UserAutocomplete
            {...formikAutocompleteAjaxProps(
              'assignedTo',
              formik,
              (name, formik, ...args) => {
                const [user] = args;
                formik.setFieldValue(
                  name,
                  user
                    ? {
                        userGuid: user?.userGuid,
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                        title: user?.title,
                        fullName: user?.fullName
                      }
                    : null
                );
              }
            )}
            disabled={formik.values.engagement === null || userFetching}
            label={getTextToDisplay(
              'dataRequest.modal.fields.assignedTo.label'
            )}
            onFetchOptions={this.handleFetchAssignableUsers}
          />
          <Datepicker
            {...formikDatepickerProps('dueDate', formik)}
            label={getTextToDisplay('dataRequest.modal.fields.dueDate.label')}
            minDate={minDueDate(dataRequest)}
          />
          <Textarea
            {...formikFieldProps('dataSpecs', formik)}
            helperText={getTextToDisplay(
              'dataRequest.modal.fields.dataSpecs.helperText'
            )}
            label={getTextToDisplay('dataRequest.modal.fields.dataSpecs.label')}
            maxLength={maxLengthDataSpecs}
          />

          <ManageAttachments
            attachments={attachments}
            dss={dss}
            engagementGuid={
              dataRequest?.engagementGuid ||
              formik.values.engagement?.engagementGuid ||
              null
            }
            engagementName={
              dataRequest?.engagementDisplayNameShort ||
              formik.values.engagement?.engagementDisplayNameShort ||
              null
            }
            onChange={updates => formik.setFieldValue('attachments', updates)}
            value={formik.values.attachments}
          />

          <SelectFolder
            disabled={folders === null}
            feedback={formik.status.transferDestinationError}
            folders={
              !isInternalFilesFunctionalityVisible
                ? foldersWithoutInternal
                : folders || []
            }
            invalid={formik.status.transferDestinationError != null}
            label={getTextToDisplay(
              'dataRequest.modal.fields.defaultFolder.label'
            )}
            moveFileCount={2}
            onChange={(folder: FolderSelection) => {
              if (formik.status.transferDestinationError) {
                formik.setStatus({
                  ...formik.status,
                  transferDestinationError: null
                });
              }
              formik.setFieldValue('defaultFolder', folder);
            }}
            rootName={
              formik.values.engagement
                ? formik.values.engagement.engagementDisplayNameShort
                : ''
            }
            value={formik.values.defaultFolder} // Just passing a number above 1 so it grabs the plural translation
          />
          <CommentInput
            {...formikCommentProps('comment', formik)}
            boundingParentRef={this.formRef}
            disabled={formik.values.engagement === null}
            label={getTextToDisplay('dataRequest.modal.fields.comment.label')}
            makeOnSuggestionsRequestedCallback={() =>
              makeOnSuggestionsRequested(
                formik.values.engagement
                  ? formik.values.engagement.engagementGuid
                  : '',
                user => user.canBeMentionedInDRComment
              )
            }
          />
          <Checkbox
            {...formikCheckboxProps('isCurrentUserWatching', formik)}
            label={getTextToDisplay('Follow', { context: 'dataRequest' })}
            value="isCurrentUserWatching"
          />
          <ButtonGroup>
            <Button
              disabled={!formik.dirty}
              loading={dss.getUploadingPromise() || formik.status.loading}
              text={getTextToDisplay(isNew ? 'Create' : 'Save')}
              type="submit"
              variant="primary"
            />
            <Button
              disabled={
                (dss.getUploadingPromise() || formik.status.loading) !== null
              }
              onClick={onCancel}
              text={getTextToDisplay('Cancel')}
            />
          </ButtonGroup>
        </form>
      </>
    );
  }
}

/**
 * Formik Wrapper
 */

interface IDataRequestFormProps extends WithTranslation {
  dss: DSSManager;
  customViewGuid: string;
  dataRequest?: IDataRequest;
  engagementGuid?: string;
  onSubmitted: (dataRequest: IDataRequest) => void;
  onCancel: () => void;
}

const defaultValues: IDataRequestFormValues = {
  title: '',
  description: '',
  engagement: null,
  assignedTo: null,
  dueDate: null,
  dataSpecs: '',
  attachments: {
    addUploaded: [],
    deleteAttachments: [],
    addExisting: []
  },
  defaultFolder: null,
  comment: new MentionsValue(),
  isCurrentUserWatching: true
};

export default withTranslation()(
  withFormik<IDataRequestFormProps, IDataRequestFormValues>({
    mapPropsToValues: props => {
      const { dataRequest } = props;
      if (dataRequest) {
        return {
          ...defaultValues,
          title: dataRequest.title,
          description: dataRequest.description,
          dataSpecs: dataRequest.dataSpecs || '',
          defaultFolder: null,
          isCurrentUserWatching: dataRequest.isCurrentUserWatching,
          dueDate: parseISO(dataRequest.dueDate)
        };
      }

      return defaultValues;
    },

    mapPropsToStatus: () => ({
      loading: null
    }),

    validationSchema: ({ t, dataRequest }: IDataRequestFormProps) =>
      yup.object({
        title: yup
          .string()
          .trim()
          .required(t('dataRequest.modal.fields.title.required'))
          .max(
            maxLengthTitle,
            t('dataRequest.modal.fields.title.maxLength', {
              length: maxLengthTitle
            })
          ),
        description: yup.string().max(
          maxLengthDescription,
          t('dataRequest.modal.fields.description.maxLength', {
            length: maxLengthDescription
          })
        ),
        engagement: yup
          .object()
          .nullable()
          .required(t('dataRequest.modal.fields.engagement.required')),
        assignedTo: yup
          .object()
          .nullable()
          .required(t('dataRequest.modal.fields.assignedTo.required')),
        dueDate: yup
          .date()
          .nullable()
          .required(t('dataRequest.modal.fields.dueDate.required'))
          .min(
            minDueDate(dataRequest),
            dataRequest
              ? t('dataRequest.modal.fields.dueDate.minCreated')
              : t('dataRequest.modal.fields.dueDate.minToday')
          ),
        dataSpecs: yup.string().max(
          maxLengthDataSpecs,
          t('dataRequest.modal.fields.dataSpecs.maxLength', {
            length: maxLengthDataSpecs
          })
        ),
        defaultFolder: yup.object().nullable(),
        comment: yup.mixed().validateCommentLength(
          maxLengthComment,
          t('dataRequest.modal.fields.comment.maxLength', {
            length: maxLengthComment
          })
        ),
        isCurrentUserWatching: yup.boolean()
      }),

    handleSubmit: async (values, formik) => {
      const {
        setStatus,
        props: { t, dataRequest, onSubmitted }
      } = formik;
      const isNew = typeof dataRequest === 'undefined';

      const dataRequestRequest: IDataRequestRequest = {
        title: values.title,
        description: values.description,
        engagementGuid: values.engagement!.engagementGuid,
        assignedToUserGuid: values.assignedTo!.userGuid,
        dueDate: values.dueDate!.toISOString(),
        dataSpecs: values.dataSpecs,
        attachments: values.attachments,
        defaultFolder: values.defaultFolder,
        comment: values.comment.toJSON().text,
        isCurrentUserWatching: values.isCurrentUserWatching
      };

      const promise = isNew
        ? ApiService.createDataRequest(dataRequestRequest)
        : ApiService.updateDataRequest(dataRequest!, dataRequestRequest);

      setStatus({ loading: promise });

      try {
        const response = await promise;
        const savedDataRequest = response.data;
        const { title, createdBy, assignedToName, assignedToUserGuid } =
          savedDataRequest;
        const tAssigned = createdBy === assignedToUserGuid ? 'you' : 'x';

        pushToast({
          type: 'success',
          title: t(
            isNew
              ? 'dataRequest.modal.new.success.title'
              : 'dataRequest.modal.edit.success.title'
          ),
          content: (
            <Trans
              i18nKey={
                isNew
                  ? `dataRequest.modal.new.success.content-assigned-${tAssigned}`
                  : 'dataRequest.modal.edit.success.content'
              }
            >
              <b>{{ title }}</b> has been successfully saved.
              {{ assignedToName }}
            </Trans>
          )
        });

        setStatus({ loading: null });

        onSubmitted(savedDataRequest);
      } catch (error: any) {
        const { data, status } = error.response || {};

        const loading = null;

        let folderNameError: string | null = null;

        if (
          status === ApiErrorResponseEnums.BAD_REQUEST &&
          Array.isArray(data)
        ) {
          data.forEach(error => {
            if (error.propertyName === 'FolderName') {
              folderNameError = error.errorMessage;
            }
          });
        }

        if (folderNameError) {
          setStatus({
            loading,
            transferDestinationError: folderNameError
          });
          return;
        }

        setStatus({
          error: (
            <Message title={t('serverError.title')} type="error">
              {t('serverError.content')}
            </Message>
          ),
          loading
        });
      }
    }
  })(withUser(DataRequestInnerForm))
);
