import PhoneNumber from 'components/PhoneNumber';
import Table from 'components/Table';
import OverflowMenu from 'components/Table/OverflowMenu/OverflowMenu';
import UserConversionModal from 'components/UserConversionModal/UserConversionModal';
import UserDetails from 'components/UserDetails/UserDetails';
import UserInfoCard from 'components/UserInfoCard/UserInfoCard';
import { WithUser, withUser } from 'contexts/UserContext';
import { ITableState, ITeamSearchParams, IUser, UserType } from 'interfaces';
import AmplitudeApiService from 'services/AmplitudeApiService';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import debouncedSearch from 'utils/debouncedSearch';
import getSortParam from 'utils/getSortParm';
import pushServerErrorToast from 'utils/pushServerErrorToast';
import switcherDidUpdate from 'utils/switcherDidUpdate';

import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import { Button, ITableColumn, Icon } from '@ryan/components';

import UserNameAndProfile from '../../components/Table/UserNameAndProfile/UserNameAndProfile';
import { formatToUserNameAndProfile } from '../../components/Table/UserNameAndProfile/utils';
import { mapUserToUserDetailsUserData } from '../../components/UserDetails/utils';
import TeamCardSkeleton from './TeamCardSkeleton';

import './TeamTable.scss';

interface ITeamTableProps
  extends RouteComponentProps<{ userType: string }>,
    WithTranslation,
    WithUser {}

interface ITeamTableState extends ITableState {
  clientManager: IUser | null;
  clientManagerLoading: boolean;
  clientPrincipal: IUser | null;
  clientPrincipalLoading: boolean;
  users: IUser[];
  userToBeGrantedAccess: IUser | null;
}

class TeamTable extends Component<ITeamTableProps, ITeamTableState> {
  readonly state: ITeamTableState = {
    clientManager: null,
    clientManagerLoading: false,
    clientPrincipal: null,
    clientPrincipalLoading: false,
    expanded: {},
    loading: true,
    page: 1,
    pageSize: 10,
    searchQuery: '',
    selected: [],
    sorted: {
      desc: false,
      id: 'FirstName'
    },
    totalCount: 0,
    users: [],
    userToBeGrantedAccess: null
  };

  private columns: ITableColumn<IUser>[];

  private _isMounted = false;

  private sourceTeamUsers?: CancelTokenSource;

  constructor(props: ITeamTableProps) {
    super(props);
    const { t, history, permissionService: ps } = props;
    const { userType } = this.props.match.params;

    if (
      ps.isThirdParty() &&
      (userType === 'client' || userType === 'third-party')
    ) {
      history.push('/app/team/ryan');
    }

    this.columns = [
      {
        id: 'fullName',
        label: t('NameAndTitle'),
        width: '40%',
        render: (row: IUser) => (
          <UserNameAndProfile
            isLinkable
            user={formatToUserNameAndProfile({
              additionalInfoKey: 'title',
              user: row
            })}
          />
        ),
        sortable: true
      },
      {
        id: 'displayLocation',
        label: t('team.columns.location'),
        render: (row: IUser) => <div>{row.displayLocation || '\u2013'}</div>,
        sortable: true
      },
      {
        id: 'projectCount',
        label: t('team.columns.projects'),
        render: 'projectCount'
      },
      {
        id: 'officePhone',
        label: t('team.columns.office'),
        render: ({ directDialNumber, officePhone, userTypeId }) => (
          <PhoneNumber
            phone={
              userTypeId === UserType.Ryan && directDialNumber
                ? directDialNumber
                : officePhone
            }
          />
        )
      },
      {
        id: 'mobilePhone',
        label: t('team.columns.mobile'),
        render: ({ mobilePhone }) => <PhoneNumber phone={mobilePhone} />
      },
      {
        id: 'email',
        label: t('team.columns.email'),
        render: (row: IUser) =>
          row.email !== '' && (
            <Button
              ariaLabel={t('Email')}
              icon="mail"
              onClick={() => {
                window.location.href = `mailto:${row.email}`;
              }}
              size="sm"
              variant="text"
            />
          )
      },
      {
        id: 'ryanUserOptions',
        label: '',
        render: (row: IUser) => this.renderAdditionalOptions(row)
      }
    ];
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchClientManagers();
    this.fetchUsers();
    this.handleColumns(this.props.match.params.userType);
  }

  componentDidUpdate(prevProps: ITeamTableProps) {
    const prevUserType = prevProps.match.params.userType;
    const nextUserType = this.props.match.params.userType;

    if (prevUserType !== nextUserType) {
      this.fetchUsers({
        users: [],
        totalCount: 0,
        page: 1,
        searchQuery: ''
      });

      this.handleColumns(nextUserType);
    }

    if (switcherDidUpdate(prevProps, this.props)) {
      this.fetchClientManagers();
      this.fetchUsers({ page: 1 });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleColumns(userType: string) {
    const { t } = this.props;

    // We want to format the tables depending on userType in params
    const companyIndex = this.columns.map(x => x.id).indexOf('company');

    // remove company column for Ryan users and add for non-Ryan users
    if (userType === 'ryan' && companyIndex > -1) {
      this.columns.splice(companyIndex, 1);
    } else if (userType !== 'ryan' && companyIndex === -1) {
      this.columns.splice(2, 0, {
        id: 'company',
        label: t('Company'),
        sortable: true,
        render: 'company'
      });
    }
  }

  fetchClientManagers() {
    const { activeView } = this.props;

    this.setState({
      clientPrincipal: null,
      clientManager: null
    });

    // Find the Client Principal/Manager for this Account
    if (activeView.accountView) {
      const { clientPrincipalGuid, clientManagerGuid } =
        activeView.accountView.account;

      if (clientPrincipalGuid) {
        this.setState({ clientPrincipalLoading: false });
        ApiService.getUser(clientPrincipalGuid)
          .then(response => {
            this.setState({
              clientPrincipal: response.data,
              clientPrincipalLoading: false
            });
          })
          .catch(() => {
            this.setState({ clientPrincipalLoading: false });
          });
      }

      if (clientManagerGuid) {
        this.setState({ clientManagerLoading: false });
        ApiService.getUser(clientManagerGuid)
          .then(response => {
            this.setState({
              clientManager: response.data,
              clientManagerLoading: false
            });
          })
          .catch(() => {
            this.setState({ clientManagerLoading: false });
          });
      }
    }
  }

  fetchUsers(updates?: Partial<ITeamTableState>) {
    const { match, activeView } = this.props;

    let newUserType: number;
    switch (match.params.userType) {
      case 'client':
        newUserType = UserType.Client;
        break;

      case 'ryan':
        newUserType = UserType.Ryan;
        break;

      case 'third-party':
        newUserType = UserType.ThirdParty;
        break;

      default:
        break;
    }

    this.setState(
      { ...(updates as ITeamTableState), loading: true },
      async () => {
        const { page, searchQuery, sorted, pageSize } = this.state;

        const params: ITeamSearchParams = {
          pageNumber: page,
          userType: newUserType,
          searchTerm: searchQuery,
          sort: getSortParam(sorted),
          itemsPerPage: pageSize
        };

        try {
          this.sourceTeamUsers?.cancel();
          this.sourceTeamUsers = ApiService.CancelToken.source();

          const {
            data: { results, totalResults }
          } = await ApiService.getTeamUsersByCustomView(
            activeView.customViewGuid,
            params,
            this.sourceTeamUsers.token
          );

          this.setState({
            loading: false,
            totalCount: totalResults,
            users: results
          });
        } catch (error) {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();

            if (this._isMounted) {
              this.setState({ loading: false });
            }
          }
        }
      }
    );
  }

  handleGrantUserAccess = (user: IUser) => {
    AmplitudeApiService.logEvent('grant-user-access', {
      'grant-access-path': 'meatball big-team'
    });
    this.sourceTeamUsers?.cancel();
    this.sourceTeamUsers = ApiService.CancelToken.source();
    ApiService.getUserInfoForProfilePage(
      user.memberGuid,
      this.sourceTeamUsers.token
    )
      .then(({ data }) => {
        const userInfo = {
          ...data.memberProfile,
          canCurrentUserApproveNurConversion:
            data.canCurrentUserApproveNurConversion,
          currentUserIsGhosted: data.currentUserIsGhosted,
          newUserStatus: data.newUserRequestStatus?.generalStatus,
          queueItemGuid: data.queueItemGuid
        };
        this.setState({ userToBeGrantedAccess: userInfo });
      })
      .catch(error => {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      });
  };

  handleGrantUserAccessClose = (isUpdateRequired: boolean) => {
    if (isUpdateRequired) {
      this.fetchUsers({});
    }

    this.setState({ userToBeGrantedAccess: null });
  };

  /**
   * Table handlers
   */

  handleSearch = debouncedSearch(
    (searchQuery: string) => {
      this.setState({ searchQuery });
    },
    (searchQuery: string) => {
      this.fetchUsers({ page: 1, searchQuery });
    }
  );

  handleSort = (sorted: any) => {
    this.fetchUsers({ sorted });
  };

  handlePage = (page: number, pageSize: number) => {
    this.fetchUsers({ page, pageSize });
  };

  handleToggleExpansion = (expanded: any, row: any, rowId: any) => {
    this.setState(state => ({
      expanded: { ...state.expanded, [rowId]: expanded }
    }));
  };

  handleViewUser = (user: IUser) => {
    const { t, history, match } = this.props;
    const userType = match ? match.params.userType : '';
    history.push(`/app/personal-information/${user.memberGuid}`, {
      pushFrom: [
        {
          route: `/app/team/${userType}`,
          title: t('Team')
        }
      ]
    });
  };

  /**
   * Renders
   */

  showManageTeamCTA() {
    const { match, permissionService: ps } = this.props;
    const userType = match.params.userType;
    return ps.canViewManageTeams() && (!ps.isClient() || userType === 'client');
  }

  renderActions = () => {
    const {
      t,
      history,
      isAppReadOnly,
      match,
      permissionService: ps
    } = this.props;
    const userType = match.params.userType;

    if (this.showManageTeamCTA()) {
      return (
        <Button
          icon="users"
          onClick={() => {
            // if user does not actually have the ability to edit members, send
            // them to the NUR page

            const url =
              (ps.isRyan() && !ps.canEditUsersOnSameAccount()) || isAppReadOnly
                ? '/app/team/manage/newusers'
                : `/app/team/manage/${userType}`;
            const location = {
              pathname: url,
              state: {
                previousPath: window.location.pathname
              }
            };

            history.push(location);
          }}
          text={t('manageTeam.title')}
          variant="text"
        />
      );
    }

    return null;
  };

  renderAdditionalOptions = (user: IUser) => {
    const passthroughProps = {
      isOverflowDisabled: this.props.isAppReadOnly,
      permissionService: this.props.permissionService,
      textToDisplay: this.props.t
    };

    return (
      <OverflowMenu
        {...passthroughProps}
        dropdownMenuButtonSize={'sm'}
        handleGrantUserAccess={this.handleGrantUserAccess}
        isActiveUserWithManageEngagementAccess={false}
        isOverflowDisabled={user.currentUserIsGhosted || false}
        user={user as any} // TODO: OverflowMenu is looking for IEngagementUser
      />
    );
  };

  renderExpandedRow = (user: IUser) => {
    const { activeView } = this.props;
    return (
      <UserDetails
        customViewGuid={activeView.customViewGuid}
        onViewAll={() => this.handleViewUser(user)}
        userData={mapUserToUserDetailsUserData(user)}
      />
    );
  };

  renderEmpty = () => {
    const { t, isAppReadOnly, match, activeView } = this.props;
    const { searchQuery } = this.state;
    const userType = match.params.userType;
    const viewName = activeView.name;

    return (
      <div className="table-empty">
        <Icon className="table-empty__icon" name="users" />
        {searchQuery ? (
          <div className="table-empty__content">
            <Trans i18nKey="Search Not Found">
              <b>{{ searchQuery }}</b> could not be found.
            </Trans>
            <br />
            {t('manageTeam.adjustSearch')}
          </div>
        ) : (
          <div className="table-empty__content">
            {t('team.empty', {
              // TODO: Update to use `team.empty.${userType}` duing refactor
              userType: userType === 'ryan' ? 'Ryan' : userType,
              viewName
            })}
            <br />
            {this.showManageTeamCTA() && (
              <Button
                disabled={isAppReadOnly}
                onClick={() => {
                  const url = `/app/team/manage/${userType}`;
                  const location = {
                    pathname: url,
                    state: {
                      previousPath: window.location.pathname
                    }
                  };

                  this.props.history.push(location);
                }}
                text={t('manageTeam.title')}
                variant="primary"
              />
            )}
          </div>
        )}
      </div>
    );
  };

  renderRyanTeam() {
    const { t, permissionService: ps } = this.props;
    const {
      clientManager,
      clientManagerLoading,
      clientPrincipal,
      clientPrincipalLoading
    } = this.state;

    // If the user is a Third-Party user,
    // or if there's no CP or CM cards,
    // simply render the Table without the CM/CP cards
    if (
      ps.isThirdParty() ||
      (!clientPrincipal &&
        !clientPrincipalLoading &&
        !clientManager &&
        !clientManagerLoading)
    ) {
      return this.renderTable();
    }

    return (
      <div className="row">
        <div className="col-12 col-lg-4 order-lg-last">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-12">
              {clientPrincipalLoading ? (
                <TeamCardSkeleton />
              ) : (
                clientPrincipal !== null && (
                  <UserInfoCard
                    cardTitle={t('Client Principal')}
                    user={clientPrincipal}
                  />
                )
              )}
            </div>
            <div className="col-12 col-md-6 col-lg-12">
              {clientManagerLoading ? (
                <TeamCardSkeleton />
              ) : (
                clientManager !== null && (
                  <UserInfoCard
                    cardTitle={t('Client Manager')}
                    user={clientManager}
                  />
                )
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8">{this.renderTable()}</div>
      </div>
    );
  }

  renderClientTeam() {
    return this.renderTable();
  }

  renderThirdPartyTeam() {
    return this.renderTable();
  }

  renderTable() {
    const { t } = this.props;
    const { loading, users, searchQuery, sorted, page, pageSize, totalCount } =
      this.state;
    const { userType } = this.props.match.params;

    // capitalize user type
    let title;

    switch (userType) {
      case 'client':
        title = t('Client');
        break;

      case 'ryan':
        title = 'Ryan';
        break;

      case 'third-party':
        title = t('Third Party');
        break;

      default:
        break;
    }

    return (
      <Table<IUser>
        columns={this.columns}
        data={users}
        expanded={this.state.expanded}
        isLoadedResetOnDataChange
        loading={loading}
        onPageChange={this.handlePage}
        onSearchChange={this.handleSearch}
        onSortChange={this.handleSort}
        onToggleExpansion={this.handleToggleExpansion}
        page={page}
        pageSize={pageSize}
        renderActionPlacement={1}
        renderActions={this.renderActions}
        renderEmpty={this.renderEmpty}
        renderExpandedRow={this.renderExpandedRow}
        rowId="memberGuid"
        searchQuery={searchQuery}
        sorted={sorted}
        title={title}
        totalCount={totalCount}
      />
    );
  }

  render() {
    const { userType } = this.props.match.params;
    const { userToBeGrantedAccess } = this.state;

    return (
      <div className="team-table" data-qid="team-table">
        {userType === 'client' && this.renderClientTeam()}
        {userType === 'ryan' && this.renderRyanTeam()}
        {userType === 'third-party' && this.renderThirdPartyTeam()}

        {userToBeGrantedAccess && (
          <UserConversionModal
            accountGuid={userToBeGrantedAccess.accountGuid as any as string}
            onClose={this.handleGrantUserAccessClose as any}
            user={userToBeGrantedAccess}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(withUser(TeamTable));
