import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Message, Modal, pushToast } from '@ryan/components';

import ApiService from '../../../services/ApiService';
import { DSSManagerEventType, useDSSManager } from '../../../utils/DSS';
import ImageResize from '../../../utils/DSS/ImageCompressor';
import { MessageTypeEnums } from '../../../utils/enums/MessageTypeEnums';
import DSSFileUpload from '../../DSSFileUpload/DSSFileUpload';
import {
  IHandleDSSManagerChange,
  IHandleUploaded,
  UploadProfilePictureModalEnums,
  UploadProfilePictureModalProps
} from './utils';

const UploadProfilePictureModal: React.FC<UploadProfilePictureModalProps> = ({
  onClose,
  onUserUpdate,
  userGuid
}) => {
  const { t: textToDisplay } = useTranslation();

  const [isServerError, setIsServerError] = useState(false);

  const { IMAGE_FILE_TYPES, ROOT_TO_SERVER_ERROR_TEXT, ROOT_TO_TEXT } =
    UploadProfilePictureModalEnums;

  const handleDSSManagerBeforeUpload: IHandleDSSManagerChange = files => {
    // HACK: DSSManager requires Promise<File[]> even though this is single file upload
    return Promise.all([ImageResize.createResizedImage(files[0])]);
  };

  const handleUploaded: IHandleUploaded = async ({
    dssDocument,
    guid,
    onCloseCallback,
    onUserUpdateCallback,
    setIsServerErrorCallback,
    successToast
  }) => {
    try {
      await ApiService.updateClientAvatar(guid, dssDocument);

      pushToast({
        ...successToast,
        type: MessageTypeEnums.SUCCESS
      });

      onUserUpdateCallback();
      onCloseCallback();
    } catch (error) {
      setIsServerErrorCallback(true);
    }
  };

  // TODO: DSSManager is not idomatic and should be refactored
  const dssManager = useDSSManager({
    onBeforeUpload: handleDSSManagerBeforeUpload,
    onChange: (event: DSSManagerEventType, ...args: any[]) => {
      setIsServerError(false);

      if (event === DSSManagerEventType.Uploaded) {
        handleUploaded({
          dssDocument: args[1],
          guid: userGuid,
          onCloseCallback: onClose,
          onUserUpdateCallback: onUserUpdate,
          setIsServerErrorCallback: setIsServerError,
          successToast: {
            content: textToDisplay(
              `${ROOT_TO_TEXT}.successToast.uploadSuccessful`
            ),
            title: textToDisplay(`${ROOT_TO_TEXT}.successToast.title`)
          }
        });
      }
    }
  });

  return (
    <Modal
      onClose={onClose}
      open
      title={textToDisplay(`${ROOT_TO_TEXT}.title`)}
    >
      {isServerError && (
        <Message
          key="server-error"
          title={textToDisplay(`${ROOT_TO_SERVER_ERROR_TEXT}.title`)}
          type={MessageTypeEnums.ERROR}
        >
          {textToDisplay(`${ROOT_TO_SERVER_ERROR_TEXT}.content`)}
        </Message>
      )}

      <DSSFileUpload
        accept={IMAGE_FILE_TYPES}
        disabled={userGuid === ''}
        dssManager={dssManager}
        errorText={{
          type: textToDisplay(`${ROOT_TO_TEXT}.invalidFileTypeError`)
        }}
        shouldClearErrorsOnFilesAdded={true}
      />
    </Modal>
  );
};

export default UploadProfilePictureModal;
