import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Tooltip } from '@ryan/components';

import CodeNoteField from '../CodeNotes/CodeNoteField/CodeNoteField';
import CodeNotes from '../CodeNotes/CodeNotes';
import Drawer from '../Drawer';
import { ICodeNotesDrawerProps } from './utils';

import './CodeNotesDrawer.scss';

const CodeNotesDrawer: FunctionComponent<ICodeNotesDrawerProps> = ({
  canComment,
  codeNotes,
  dataRequest,
  editCodeNote,
  editCodeNoteText,
  isCodeNotesLoading,
  isEditCodeNoteLoading,
  isNewCodeNoteLoading,
  onClose,
  onDeleteCodeNote,
  onEditCodeNote,
  onEditCodeNoteCancel,
  onEditCodeNoteSave,
  onNewCodeCodeSave
}) => {
  const { t: getTextToDisplay } = useTranslation();
  return (
    <Drawer className="code-notes-drawer" onClose={onClose} open>
      <Drawer.Header>
        <h4 className="ry-h4">{dataRequest?.engagementDisplayNameShort}</h4>
        <h4 className="code-notes-drawer__subheader">{dataRequest?.title}</h4>
        <h4 className="code-notes-drawer__subheader">
          {dataRequest?.dataRequestType?.name}
        </h4>
        <h3 className="ry-h3">
          {getTextToDisplay('codeNotes.notesWithCode')}
          <Tooltip
            content={<div>{getTextToDisplay('codeNotes.tooltip')}</div>}
            placement="top"
            renderTarget={({ open, ...props }) => (
              <div
                aria-expanded={open}
                aria-haspopup="true"
                className="code-notes-drawer__tooltip"
                {...props}
              >
                <Icon name="information" />
              </div>
            )}
          />
        </h3>
      </Drawer.Header>
      <Drawer.Body>
        <CodeNotes
          canComment={canComment}
          codeNotes={codeNotes}
          editCodeNote={editCodeNote}
          editCodeNoteText={editCodeNoteText}
          engagementGuid={dataRequest!.engagementGuid}
          isEditCodeNoteLoading={isEditCodeNoteLoading}
          isLoading={isCodeNotesLoading}
          onDelete={onDeleteCodeNote}
          onEdit={onEditCodeNote}
          onEditCancel={onEditCodeNoteCancel}
          onEditSave={onEditCodeNoteSave}
        />
      </Drawer.Body>
      {canComment && (
        <Drawer.Footer>
          {!dataRequest?.isUserGhosted && (
            <CodeNoteField
              engagementGuid={dataRequest!.engagementGuid}
              isLoading={isNewCodeNoteLoading}
              onSave={onNewCodeCodeSave}
            />
          )}
        </Drawer.Footer>
      )}
    </Drawer>
  );
};

export default CodeNotesDrawer;
