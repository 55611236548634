import { WithUser, withUser } from 'contexts/UserContext';
import { parse, stringify } from 'query-string';

import { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Updates the current view to a single account view matching a GUID specified
 * by an `account={accountGuid}` URL search parameter.
 * Currently used for Contract, Invoice, and New User activity links.
 */
const AccountRedirect: FunctionComponent<WithUser> = ({
  setActiveAccount,
  getAccountByGuid
}) => {
  const history = useHistory();
  const { account: accountGuid, ...params } =
    parse(history.location.search) || {};

  useEffect(
    () => {
      (async function () {
        // If there is an account in the url...
        if (accountGuid && typeof accountGuid === 'string') {
          // Remove account paramn from url.
          // Should replace and not push since we're just removing a param
          history.replace({
            ...history.location,
            search: stringify(params)
          });

          /**
           * We need to find the url account. If it is not a child of the
           * active account, then we should set it as the active account.
           * @todo This will always change your current view.
           */
          const { account = undefined, executiveAccessOnly = false } =
            (await getAccountByGuid(accountGuid)) || {};
          setActiveAccount(account, true, executiveAccessOnly);
        }
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountGuid]
  );

  return null;
};

export default withUser(AccountRedirect);
