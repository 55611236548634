import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Tooltip } from '@ryan/components';

import DataRequestProgressTracker from './DataRequestProgressTracker/DataRequestProgressTracker';
import { DataRequestProgressTrackerEnums } from './DataRequestProgressTracker/utils';
import { IDataRequestProgressSummaryCard } from './utils';

import './DataRequestProgressSummaryCard.scss';

export const DataRequestProgressSummaryCard: IDataRequestProgressSummaryCard =
  ({ progressId }) => {
    const { t: getTextToDisplay } = useTranslation();

    return (
      <div className="progress-card">
        <div className="progress-card__status">
          <Icon name="success-filled" />
          <div className="progress-card__status__text">
            {getTextToDisplay(progressId)}
          </div>
        </div>
        <Tooltip
          content={
            <DataRequestProgressTracker
              activeStep={progressId as DataRequestProgressTrackerEnums}
            />
          }
          placement="left"
          renderTarget={({ open, ...props }) => (
            <div
              aria-expanded={open}
              aria-haspopup="true"
              className="progress-card__tooltip"
              {...props}
            >
              <Icon name="information" />
            </div>
          )}
        />
      </div>
    );
  };

export default DataRequestProgressSummaryCard;
