import { UserAutocomplete } from 'components/AutocompleteAjax';
import Modal from 'components/Modal';
import Paragraphs from 'components/Paragraphs';
import { ITask, IUserSummary } from 'interfaces';
import ApiService from 'services/ApiService';
import { Formik, formikAutocompleteAjaxProps, yup } from 'utils/forms';

import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import { Button, ButtonGroup, pushToast } from '@ryan/components';

import './ReassignTaskModal.scss';

interface IReassignTaskModalValues {
  assignedTo: IUserSummary | null;
}

interface IReassignTaskModalProps extends WithTranslation {
  task: ITask;
  open?: boolean;
  onClose: (updatedTask?: ITask) => void;
}

export class ReassignTaskModal extends Component<IReassignTaskModalProps> {
  private schema = yup.object({
    assignedTo: yup
      .object<IUserSummary>()
      .nullable()
      .required(this.props.t('taskActions.reassignModal.required'))
  });

  handleFetchAssignableUsers = async (query: string) => {
    const { engagementGuid } = this.props.task;
    const response = await ApiService.getEngagementTaskAssignableUsers(
      engagementGuid,
      query
    );
    return response.data;
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleSubmit = async (values: IReassignTaskModalValues) => {
    const { t, task, onClose } = this.props;

    try {
      const { data: updatedTask } = await ApiService.updateTask(task, {
        assignedToUserGuid: values.assignedTo!.userGuid
      });
      const { title } = updatedTask;

      pushToast({
        type: 'success',
        title: t('taskActions.reassignModal.successToast.title'),
        content: (
          <Trans i18nKey="taskActions.reassignModal.successToast.content">
            <b>{{ title }}</b> has been re-assigned.
          </Trans>
        )
      });
      onClose(updatedTask);
    } catch (error) {
      pushToast({
        type: 'error',
        title: t('serverError.title'),
        content: t('serverError.content')
      });
      onClose();
    }
  };

  render() {
    const { t, open = true, task } = this.props;
    return (
      <Modal
        className="reassign-task-modal"
        onClose={this.handleClose}
        open={open}
        title={t('taskActions.reassignModal.title')}
      >
        <h4 className="ry-h4">{task.engagementDisplayNameShort}</h4>
        <h3 className="ry-h3">{task.title}</h3>
        <Paragraphs text={task.description} />
        <div className="reassign-task-modal__assigned-to">
          <label className="ry-label">
            {t('taskActions.reassignModal.assignedTo')}
          </label>
          {task.assignedToName}
        </div>

        <Formik
          initialValues={{ assignedTo: null }}
          onSubmit={this.handleSubmit}
          validationSchema={this.schema}
        >
          {formik => (
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <UserAutocomplete
                {...formikAutocompleteAjaxProps('assignedTo', formik)}
                label={t('taskActions.reassignModal.reassignTo')}
                onFetchOptions={this.handleFetchAssignableUsers}
              />
              <ButtonGroup>
                <Button
                  disabled={(() => {
                    const { assignedTo }: { assignedTo: any } = formik.values;
                    const selectedUser = assignedTo
                      ? assignedTo!.userGuid
                      : null;
                    return task.assignedToUserGuid === selectedUser;
                  })()}
                  text={t('taskActions.reassignModal.reassign')}
                  type="submit"
                  variant="primary"
                />
                <Button
                  onClick={this.handleClose}
                  text={t('Cancel')}
                  variant="secondary"
                />
              </ButtonGroup>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default withTranslation()(ReassignTaskModal);
