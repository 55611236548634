import { CancelToken } from 'axios';
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Avatar, Button, Icon } from '@ryan/components';

import { useUser } from '../../../../hooks';
import {
  IEngagement,
  IMilestone,
  IUser,
  Permission
} from '../../../../interfaces';
import ApiService from '../../../../services/ApiService';
import getAvatarUrl from '../../../../utils/getAvatarUrl';
import pushServerErrorToast from '../../../../utils/pushServerErrorToast';
import Paragraphs from '../../../Paragraphs';
import {
  EnumsProjectSnapshotOverview,
  IProjectSnapshotOverviewProps,
  IRenderContact,
  IRenderOverviewDetail,
  IRenderOverviewSkeleton,
  TContactUser,
  TProjectSnapshotEngagementContact,
  TProjectSnapshotOverviewDetails,
  formatEngagementToProjectSnapshotOverview,
  formatEngagementUserToProjectSnapshotOverview
} from './utils';

import './ProjectSnapshotOverview.scss';

const ProjectSnapshotOverview: React.FC<IProjectSnapshotOverviewProps> = ({
  engagement,
  handleViewProject
}) => {
  const { permissionService } = useUser();

  const [engagementManager, setEngagementManager] =
    useState<TProjectSnapshotEngagementContact | null>(null);
  const [engagementPrincipal, setEngagementPrincipal] =
    useState<TProjectSnapshotEngagementContact | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [overviewDetails, setOverviewDetails] =
    useState<TProjectSnapshotOverviewDetails>({
      estimatedEnd: '',
      kickoff: '',
      lastUpdated: '',
      milestoneEnd: '',
      milestoneStatus: '',
      statuteWaiverExpiration: '',
      summary: '',
      upcomingMilestone: ''
    });
  const { t: textToDisplay } = useTranslation();

  const hasTimelinesViewPermission = permissionService.hasPermission(
    Permission.TimelinesView
  );
  const overViewDetailOrder = [
    'kickoff',
    'estimatedEnd',
    'lastUpdated',
    'statuteWaiverExpiration'
  ];

  const overViewMilestoneDetailOrder = [
    'upcomingMilestone',
    'milestoneStatus',
    'milestoneEnd'
  ];
  const textToDisplayPath = 'table.projectSnapshot.overview.';

  const initializeWithResponseData = useCallback(
    (
      engagementData: IEngagement,
      engagementUsers: IUser[],
      engagementMilestones: IMilestone[]
    ) => {
      const openMilestones = engagementMilestones.filter(
        milestone =>
          (milestone.statusId as number) !==
          EnumsProjectSnapshotOverview.MILESTONE_COMPLETE
      );

      setOverviewDetails(
        formatEngagementToProjectSnapshotOverview(
          engagementData,
          openMilestones
        )
      );

      const { engagementManagerGuid, engagementPrincipalGuid } = engagementData;

      if (engagementManagerGuid) {
        const manager = formatEngagementUserToProjectSnapshotOverview(
          engagementUsers.find(
            engagementUser => engagementUser.userGuid === engagementManagerGuid
          )
        );
        setEngagementManager(manager);
      }

      if (engagementPrincipalGuid) {
        const principal = formatEngagementUserToProjectSnapshotOverview(
          engagementUsers.find(
            engagementUser =>
              engagementUser.userGuid === engagementPrincipalGuid
          )
        );
        setEngagementPrincipal(principal);
      }

      setIsLoading(false);
    },
    [setEngagementManager, setEngagementPrincipal, setIsLoading]
  );

  const initialize = useCallback(
    async (engagementData: IEngagement, cancelToken: CancelToken) => {
      try {
        const responseMilestones = hasTimelinesViewPermission
          ? await ApiService.getMilestones(
              engagementData.engagementGuid,
              cancelToken
            )
          : { data: [] };

        const { engagementManagerGuid, engagementPrincipalGuid } =
          engagementData;

        const engagementUsersRequests = [];
        if (engagementManagerGuid) {
          engagementUsersRequests.push(
            ApiService.getUser(engagementManagerGuid, cancelToken)
          );
        }
        if (engagementPrincipalGuid) {
          engagementUsersRequests.push(
            ApiService.getUser(engagementPrincipalGuid, cancelToken)
          );
        }

        const responsesEngagmentUsers = await Promise.all(
          engagementUsersRequests
        );
        const parsedResponsesEngagmentUsers = responsesEngagmentUsers
          .filter(responseEngagmentUser => responseEngagmentUser.data)
          .map(responseEngagmentUser => responseEngagmentUser.data);

        if (
          parsedResponsesEngagmentUsers.length ===
            engagementUsersRequests.length &&
          responseMilestones.data
        ) {
          initializeWithResponseData(
            engagementData,
            parsedResponsesEngagmentUsers,
            responseMilestones.data
          );
        } else {
          throw new Error('response data missing');
        }
      } catch (error) {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      } finally {
        setIsLoading(false);
      }
    },
    [hasTimelinesViewPermission, initializeWithResponseData, setIsLoading]
  );

  useEffect(() => {
    const cancelTokenSource = ApiService.CancelToken.source();

    initialize(engagement, cancelTokenSource.token);

    return () => {
      cancelTokenSource.cancel();
    };
  }, [initialize, engagement]);

  const renderContact: IRenderContact = ({ role, user }) => (
    <div className="project-snapshot-overview__contacts__contact">
      <Avatar
        firstName={user.firstName}
        lastName={user.lastName}
        profileImageSrc={getAvatarUrl({
          avatarUrl: user.avatarUrl,
          userAvatarDocumentGuid: user.userAvatarDocumentGuid
        })}
      />

      <div className="project-snapshot-overview__contacts__contact__title-name">
        <h4>{role}</h4>
        <Link to={`/app/personal-information/${user.userGuid}`}>
          {user.fullName}
        </Link>
      </div>
    </div>
  );

  const renderOverviewDetail: IRenderOverviewDetail = ({ data, title }) => (
    <div className="project-snapshot-overview__details__detail" key={title}>
      <div className="project-snapshot-overview__details__detail__title">
        {title}
      </div>
      <div className="project-snapshot-overview__details__detail__metric">
        {['In Progress', 'To Do'].includes(data) && (
          <Icon name={`status-${data.replace(' ', '-').toLowerCase()}`} />
        )}
        {data}
      </div>
    </div>
  );

  const renderOverviewSkeleton: IRenderOverviewSkeleton = (
    overViewDetailTitles,
    overViewMilestoneDetailTitles
  ) => {
    return (
      <>
        <div className="ry-skeleton project-snapshot-overview__paragraph-skeleton" />
        <div className="ry-skeleton project-snapshot-overview__paragraph-skeleton" />
        <div className="ry-skeleton project-snapshot-overview__paragraph-skeleton" />
        <div className="ry-skeleton project-snapshot-overview__paragraph-skeleton" />
        <div className="ry-skeleton project-snapshot-overview__paragraph-skeleton" />

        <div className="project-snapshot-overview__contacts">
          <div className="ry-skeleton" />
          <div className="ry-skeleton" />
        </div>

        <div className="project-snapshot-overview__details">
          {overViewDetailTitles.map(title => (
            <div
              className="project-snapshot-overview__details__detail"
              key={title}
            >
              <div className="project-snapshot-overview__details__detail__title">
                {title}
              </div>
              <div className="ry-skeleton" />
            </div>
          ))}
        </div>

        <div className="project-snapshot-overview__details">
          {overViewMilestoneDetailTitles.map(title => (
            <div
              className="project-snapshot-overview__details__detail"
              key={title}
            >
              <div className="project-snapshot-overview__details__detail__title">
                {title}
              </div>
              <div className="ry-skeleton" />
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="project-snapshot-overview">
      <Button
        disabled={isLoading}
        onClick={
          handleViewProject as any as MouseEventHandler<HTMLButtonElement>
        }
        size="sm"
        text={textToDisplay(`${textToDisplayPath}view`)}
        variant="text"
      />

      <h4 className="ry-h4">{textToDisplay(`${textToDisplayPath}title`)}</h4>

      {isLoading ? (
        renderOverviewSkeleton(
          overViewDetailOrder.map(key =>
            textToDisplay(`${textToDisplayPath}details.${key}`)
          ),
          overViewMilestoneDetailOrder.map(key =>
            textToDisplay(`${textToDisplayPath}details.${key}`)
          )
        )
      ) : (
        <>
          {!overviewDetails.summary ? (
            <div className="project-snapshot-overview__no-highlights">
              <Icon name="project" />{' '}
              {textToDisplay(`${textToDisplayPath}noProjectHighlights`)}
            </div>
          ) : (
            <Paragraphs text={overviewDetails.summary} />
          )}

          <div className="project-snapshot-overview__contacts">
            {engagementPrincipal &&
              renderContact({
                role: textToDisplay(`${textToDisplayPath}engagementPrincipal`),
                user: { ...engagementPrincipal } as TContactUser
              })}
            {engagementManager &&
              renderContact({
                role: textToDisplay(`${textToDisplayPath}engagementManager`),
                user: { ...engagementManager } as TContactUser
              })}
          </div>

          {hasTimelinesViewPermission && (
            <>
              <div className="project-snapshot-overview__details">
                {overViewDetailOrder.map(key =>
                  renderOverviewDetail({
                    data: overviewDetails[key as keyof typeof overviewDetails],
                    title: textToDisplay(`${textToDisplayPath}details.${key}`)
                  })
                )}
              </div>

              <div className="project-snapshot-overview__details">
                {overViewMilestoneDetailOrder.map((key, index) => {
                  let data =
                    overviewDetails[key as keyof typeof overviewDetails];

                  if (index === 1 && data !== '-') {
                    data = textToDisplay(
                      `table.projectSnapshot.overview.milestoneStatus.${data.toLocaleLowerCase()}`
                    );
                  }

                  return renderOverviewDetail({
                    data,
                    title: textToDisplay(`${textToDisplayPath}details.${key}`)
                  });
                })}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ProjectSnapshotOverview;
