import ContractCard from 'components/ContractCard';
import FileDirectory, {
  FileDirectoryHeader,
  NewFolderModal
} from 'components/FileDirectory';
import FilesUploadModal from 'components/FilesUpload/FilesUploadModal';
import InvoiceCard from 'components/InvoiceCard';
import { WithUser, withUser } from 'contexts/UserContext';
import { IEngagement, IFolder, Permission } from 'interfaces';
import qs from 'query-string';
import {
  DEFAULT_SORT_FILES,
  DirectoryItemStatus
} from 'routes/DataAndFiles/Files/Files';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import debouncedSearch from 'utils/debouncedSearch';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import classnames from 'classnames';
import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import { Button, Dropdown, IOption, Icon, pushToast } from '@ryan/components';

import {
  IDirectoryState,
  fetchDirectory,
  setHash,
  updateStateFoldersAndFolderPath
} from '../DirectoryState';

import './ProjectFiles.scss';

interface IProjectFilesProps
  extends WithTranslation,
    WithUser,
    RouteComponentProps {
  engagement: IEngagement;
}

interface IProjectFilesState extends IDirectoryState {
  fileStateFilterGuid: string | null;
  isDirectView: boolean;
  newFolder: boolean;
  status: DirectoryItemStatus;
  totalArchivedFileCount: number;
  uploadFiles: boolean;
}

export class ProjectFiles extends Component<
  IProjectFilesProps,
  IProjectFilesState
> {
  private _isMounted = false;

  private source?: CancelTokenSource;

  readonly state: IProjectFilesState = {
    didDirectoryChange: false,
    directoryItems: [],
    directoryItemsBySearch: null,
    engagementFolders: null,
    fileStateFilterGuid: null,
    filtered: {},
    folderPath: [],
    isDirectView: false,
    loading: false,
    newFolder: false,
    page: 1,
    pageSize: 10,
    searchQuery: '',
    selected: [],
    sorted: DEFAULT_SORT_FILES,
    status: DirectoryItemStatus.ACTIVE,
    totalArchivedFileCount: 0,
    totalCount: 0,
    totalFileCount: 0,
    uploadFiles: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.update();
  }

  componentDidUpdate(prevProps: IProjectFilesProps) {
    const prevHash = qs.parse(prevProps.location.hash);
    const hash = qs.parse(this.props.location.hash);
    if (
      prevHash.folderGuid !== hash.folderGuid ||
      prevHash.documentGuid !== hash.documentGuid
    ) {
      this.setState(
        {
          didDirectoryChange: true
        },
        this.update
      );
    }
  }

  componentWillUnmount() {
    this.source?.cancel();
    this._isMounted = false;
  }

  async update(updates?: Partial<IProjectFilesState>) {
    const { engagement, history, location, t } = this.props;
    const hash = qs.parse(location.hash);
    const params = qs.parse(location.search);
    const state = { ...this.state, ...updates };

    if (state.isDirectView) {
      delete params.isDirectView;
      history.replace({
        ...location,
        search: qs.stringify(params)
      });

      state.isDirectView = false;
    }

    const isDirectView = params.isDirectView === 'true';
    const modifiedState = {
      ...state,
      ...(isDirectView && {
        fileStateFilterGuid: 'All Files',
        isDirectView,
        status: DirectoryItemStatus.ALL_FILES
      })
    };

    // refresh cancel token
    this.source?.cancel();
    this.source = ApiService.CancelToken.source();

    try {
      this.setState({ loading: true });

      // Guarantee engagementFolders.
      // If folderGuid is valid, set folderPath,
      // ...otherwise, clear folderGuid from URL. (Will come back.)
      const isValid = await updateStateFoldersAndFolderPath(
        engagement.engagementGuid,
        hash.folderGuid,
        modifiedState,
        this.source.token
      );

      if (!isValid) {
        // throw warning if invalid folderGuid without a searchQuery - this
        // indicates that a URL for a folder has been removed
        if (!modifiedState.searchQuery) {
          pushToast({
            type: 'warning',
            title: t('folder.unavailable')
          });
        }
        this.setState(
          { ...modifiedState, loading: false },
          this.handleNavigateToRoot
        );
        return;
      }

      // Save state changes with loading: true.
      this.setState({ ...modifiedState, loading: true });

      // Request.
      const responseState = await fetchDirectory(
        engagement.engagementGuid,
        {
          ...modifiedState,
          engagementFolders: modifiedState.engagementFolders!
        },
        {
          documentGuid:
            typeof hash.documentGuid === 'string'
              ? hash.documentGuid
              : undefined
        },
        this.source.token
      );

      // Save state wth response. If viewing direct link to file, update folder
      // path to file's parent folder to update breadcrumbs
      this.setState(
        state => ({
          ...state,
          folderPath:
            (hash.documentGuid &&
              responseState.directoryItems?.length === 1 &&
              responseState.directoryItems[0].parentFolder?.path) ||
            state.folderPath,
          ...responseState,
          loading: false,
          selected: [],

          // reset directory change status on completion of request
          didDirectoryChange: false
        }),
        () => {
          // check if file is no longer available to be viewed
          if (hash.documentGuid && this.state.totalFileCount === 0) {
            this.handleLinkedFileUnavailable();
          }
        }
      );
    } catch (error: any) {
      if (!ApiService.isCancel(error) && error?.response) {
        // check if error thrown due to invalid documentGuid
        if (hash.documentGuid && error.response.status === 400) {
          this.handleLinkedFileUnavailable();
        } else {
          pushServerErrorToast(`could not fetch project files - ${error}`);
        }
      }
    } finally {
      if (this._isMounted) {
        this.setState({
          loading: false
        });
      }
    }
  }

  /**
   * Create a new folder.
   */

  handleFolderCreate = () => {
    this.setState({ newFolder: true });
  };

  handleFolderCreateClose = (folder?: IFolder) => {
    if (folder) {
      this.update({ newFolder: false, engagementFolders: null });
    } else {
      this.setState({ newFolder: false });
    }
  };

  /**
   * File Upload (as hoc)
   */

  handleUploadFiles = () => {
    this.setState({ uploadFiles: true });
  };

  handleUploadFilesClose = (uploadedFiles: boolean) => {
    if (uploadedFiles) {
      this.update({ uploadFiles: false, engagementFolders: null });
    } else {
      this.setState({ uploadFiles: false });
    }
  };

  /**
   * Navigate Directory
   */

  handleNavigateToAccount = () => {
    const { history } = this.props;
    history.push('/app/data-and-files/files');
  };

  handleNavigateToRoot = () => {
    setHash({ folderGuid: undefined, documentGuid: undefined });
  };

  handleNavigateToFolder = (folderGuid: string) => {
    setHash({ folderGuid, documentGuid: undefined });

    // reset search query to clear search results if navigating to new folder
    // via search
    this.setState({
      searchQuery: ''
    });
  };

  /**
   * Direct Link - Show All Files
   */

  /**
   * Navigates back to engagement root if viewing a direct link to a file that
   * is no longer available.
   */
  handleLinkedFileUnavailable = () => {
    const { t } = this.props;
    pushToast({
      type: 'warning',
      title: t('file.unavailable')
    });
    this.handleNavigateToRoot();
  };

  handleShowAllFiles = () => {
    // since we have linked directly to a file it should be the only item
    // in directoryItems - navigate to this file's folder via folderGuid
    const { directoryItems } = this.state;
    const linkedItem = directoryItems[0];
    setHash({
      documentGuid: undefined,
      folderGuid: linkedItem?.item.folderGuid || undefined
    });
  };

  /**
   * Table handlers
   */

  handleSort = (sorted: any) => {
    this.update({ sorted });
  };

  handleFilter = (filtered: IProjectFilesState['filtered']) => {
    this.update({ filtered, page: 1 });
  };

  handlePage = (page: number, pageSize: number) => {
    this.update({ page, pageSize });
  };

  handleSearch = debouncedSearch(
    (searchQuery: string) => {
      this.setState({ searchQuery });
    },
    (searchQuery: string) => {
      this.update({ page: 1, searchQuery });
    }
  );

  handleSelection = (selected: string[]) => {
    this.setState({ selected });
  };

  /*
   * File State Filters
   */

  renderFilterAction = () => {
    const { permissionService, t } = this.props;

    if (permissionService.isThirdParty()) return null;

    const filterValue = this.state.fileStateFilterGuid || 'Active';

    return (
      <div className="file-filter-dropdown">
        <Dropdown
          name="filterByStatus"
          onChange={e => {
            this.onFilterChange(e.target.value as IOption);
          }}
          options={['Active', 'All Files'].map(value => ({
            label: t(value),
            value
          }))}
          value={filterValue}
        />
      </div>
    );
  };

  onFilterChange = (event: IOption) => {
    this.update({
      fileStateFilterGuid: event as string,
      status: (DirectoryItemStatus as { [key: string]: any })[
        (event as string).replace(' ', '_').toUpperCase()
      ]
    });
  };

  render() {
    const { t, activeView, location, permissionService, engagement } =
      this.props;

    const {
      directoryItems,
      directoryItemsBySearch,
      engagementFolders,
      filtered,
      folderPath,
      loading,
      newFolder,
      page,
      pageSize,
      searchQuery,
      selected,
      sorted,
      status,
      totalArchivedFileCount,
      totalCount,
      totalFileCount,
      uploadFiles
    } = this.state;

    const totalFileCountForDirectory =
      status === DirectoryItemStatus.ALL_FILES
        ? totalFileCount
        : totalFileCount - totalArchivedFileCount;

    const { documentGuid } = qs.parse(location.hash);
    const showContracts = permissionService.hasPermission(
      Permission.ContractsView
    );
    const showInvoices = permissionService.hasPermission(
      Permission.InvoicesView
    );

    const hasContractOrInvoicesCards = showContracts || showInvoices;

    const renderFilesTable = () => {
      return (
        <>
          <FileDirectoryHeader
            engagement={engagement}
            folderPath={folderPath}
            isTransferredFilesView={false}
            loading={loading}
            onDataRequests={false}
            onFolderCreate={this.handleFolderCreate}
            onNavigateToAccount={this.handleNavigateToAccount}
            onNavigateToFolder={this.handleNavigateToFolder}
            onNavigateToRoot={this.handleNavigateToRoot}
            onSearch={this.handleSearch}
            onUploadFile={this.handleUploadFiles}
            renderAction={this.renderFilterAction}
            searchQuery={searchQuery}
            t={t}
            title={t('Uploaded Files')}
            view={activeView}
          />
          <FileDirectory
            directoryItems={directoryItems}
            directoryItemsBySearch={directoryItemsBySearch}
            engagement={engagement}
            engagementFolders={engagementFolders}
            fileStatusFilter={status}
            filtered={filtered}
            folderPath={folderPath}
            isSingleProjectView={hasContractOrInvoicesCards}
            loading={loading}
            onFilter={this.handleFilter}
            onNavigateToFolder={this.handleNavigateToFolder}
            onPage={this.handlePage}
            onRefresh={() => {
              this.update({ engagementFolders: null }).then(() => {
                const { directoryItems, page, pageSize } = this.state;

                // decrement current page if last item on a page greater than
                // 1 has been deleted
                if (directoryItems.length === 0 && page > 1) {
                  this.handlePage(page - 1, pageSize);
                }
              });
            }}
            onSelection={this.handleSelection}
            onSort={this.handleSort}
            page={page}
            pageSize={pageSize}
            searchQuery={searchQuery}
            selected={selected!}
            sorted={sorted}
            t={t}
            totalCount={totalCount}
            totalFileCount={
              totalFileCountForDirectory > 0 ? totalFileCountForDirectory : 0
            }
          />
          {documentGuid && !searchQuery && (
            <div className="files-tab__direct-link">
              <Icon name="warning-outline" />
              <div className="files-tab__direct-link-text">
                <Trans i18nKey="file.directLink">
                  <b />
                </Trans>
              </div>
              <Button
                onClick={this.handleShowAllFiles}
                size="sm"
                text={t('Show all files')}
                variant="text"
              />
            </div>
          )}
        </>
      );
    };

    return (
      <div className="files-tab">
        {hasContractOrInvoicesCards ? (
          <div className="row">
            <div
              className={classnames({
                'col-lg-8': true
              })}
            >
              {renderFilesTable()}
            </div>
            {
              <div className="files-tab__widgets col-12 col-lg-4">
                {showContracts && <ContractCard engagement={engagement} />}
                {showInvoices && <InvoiceCard engagement={engagement} />}
              </div>
            }
          </div>
        ) : (
          <>{renderFilesTable()}</>
        )}

        <FilesUploadModal
          defaultFolder={folderPath[folderPath.length - 1] || null}
          engagement={engagement}
          onClose={this.handleUploadFilesClose}
          open={uploadFiles}
        />

        {newFolder && (
          <NewFolderModal
            defaultFolder={
              folderPath.length ? folderPath[folderPath.length - 1] : null
            }
            engagement={engagement}
            folders={engagementFolders}
            onClose={this.handleFolderCreateClose}
            rootName={engagement.engagementDisplayNameShort}
          />
        )}
      </div>
    );
  }
}

export default withUser(withTranslation()(ProjectFiles));
