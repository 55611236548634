import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../contexts/UserContext';
import { ICodeNote } from '../../interfaces/ICodeNote';
import CommentSkeleton from '../Comments/CommentSkeleton';
import Empty from '../Empty';
import CodeNote from './CodeNote/CodeNote';
import { ICodeNotesProps } from './utils/ICodeNotes';
import { groupCodeNotesByDate } from './utils/utilFunctions/utilFunctions';

import './CodeNotes.scss';

export const CodeNotes: FunctionComponent<ICodeNotesProps> = ({
  engagementGuid,
  isLoading,
  codeNotes,
  canComment,
  editCodeNote,
  editCodeNoteText,
  isEditCodeNoteLoading,
  onEdit,
  onEditSave,
  onEditCancel,
  onDelete
}) => {
  const { permissionService } = useUser();
  const { i18n, t: getTextToDisplay } = useTranslation();
  const { language } = i18n;
  return (
    <div className="code-notes">
      {isLoading && codeNotes === null && (
        <div className="code-notes__day-group">
          <div className="code-notes__day-group-label">
            <span className="code-notes__day-group-label-text">
              <span className="ry-skeleton" />
            </span>
          </div>
          <CommentSkeleton />
          <CommentSkeleton />
          <CommentSkeleton />
        </div>
      )}

      {!!codeNotes && codeNotes.length === 0 && (
        <Empty icon="comment">{getTextToDisplay('codeNotes.empty')}</Empty>
      )}

      {groupCodeNotesByDate({
        getTextToDisplay,
        language,
        notes: codeNotes ? codeNotes : []
      }).map(note => (
        <div className="code-notes__day-group" key={note.key}>
          <div className="code-notes__day-group-label">
            <b className="code-notes__day-group-label-text">{note.label}</b>
          </div>
          {note.codeNotes.map((codeNote: ICodeNote) => {
            let editText: any['editText'];

            if (
              editCodeNote &&
              editCodeNote &&
              editCodeNote.noteGuid === codeNote.noteGuid
            ) {
              editText = editCodeNoteText;
            }

            return (
              <CodeNote
                canEdit={
                  canComment && permissionService.isUser(codeNote.createdBy)
                }
                codeNote={codeNote}
                editLoading={isEditCodeNoteLoading}
                editText={editText}
                engagementGuid={engagementGuid}
                key={codeNote.noteGuid}
                onDelete={onDelete}
                onEdit={onEdit}
                onEditCancel={onEditCancel}
                onEditSave={onEditSave}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default CodeNotes;
