import {
  Feature,
  IAccount,
  ICustomViewSummary,
  IUserIdentity
} from 'interfaces';

import { CancelToken } from 'axios';
import { createContext } from 'react';

import { PermissionService } from './PermissionService';

export interface IUserContext {
  // User
  user: IUserIdentity;
  onUserUpdate: () => void;

  // Accounts
  getAccountByGuid: (acountGuid: string) => Promise<
    | {
        account: IAccount;
        accountHierarchy: string[];
        executiveAccessOnly: boolean;
      }
    | undefined
  >;
  setActiveAccount: (
    account: IAccount | undefined,
    includingSubsidiaries: boolean,
    isExecutiveView: boolean
  ) => Promise<void>;
  setActiveAccountForEngagement: (engagement: {
    accountGuid: string;
    engagementGuid: string;
  }) => Promise<void>;

  // Executive Accounts
  executiveAccounts: IAccount[] | null;
  loadExectiveAccounts: () => Promise<IAccount[] | null>;

  // Views
  activeView: ICustomViewSummary;
  recentViews: ICustomViewSummary[];
  isEngagementInView: (
    engagementGuid: string,
    cancelToken?: CancelToken
  ) => Promise<boolean>;
  setActiveView: (view: ICustomViewSummary) => void;
  deleteCustomView: (view: ICustomViewSummary) => void;

  // App Access
  permissionService: PermissionService;
  isAppReadOnly: boolean; // global read-only status to toggle read-only mode
  isFeatureToggled: (featureGuid: Feature) => boolean;

  setUser: (newUser: IUserIdentity) => void;
}

// This is effectively a typescript hack.
// We prevent rendering children until we have a context,
// so no need to type with (WithUser | null).
export const UserContext = createContext<IUserContext>(
  {} as any as IUserContext
);
