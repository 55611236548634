import { TTaskDetailsProps } from './DetailsByType.interfaces';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Icon } from '@ryan/components';

import Attachments from '../../../components/FileAttachments/Attachments';
import StatusIcon from '../../../components/StatusIcon/StatusIcon';
import { useUser } from '../../../hooks';
import { Permission, UserType } from '../../../interfaces';
import { ButtonSizeEnums } from '../../../utils/enums/ButtonSizeEnums';
import { ButtonVariantEnums } from '../../../utils/enums/ButtonVariantEnums';
import { formatDate } from '../../../utils/formatDate';

import './TaskDetails.scss';

const TaskDetails = ({
  assignmentAttachments,
  assignmentCreatedBy,
  assignmentCreatedDate,
  assignmentDescription,
  assignmentFollowerCount,
  assignmentStatusId,
  assignmentTitle,
  commentButtonDisplayTextPath,
  commentButtonProps,
  engagementDisplayNameShort,
  engagementGuid,
  engagementMilestoneGuid,
  engagementMilestoneTitle,
  isAssignmentCurrentlyWatched,
  isFollowButtonDisabled,
  isPastDue,
  onLinkToMilestone,
  onOpenFollowersModal,
  onToggleFollow
}: TTaskDetailsProps) => {
  const ROOT_TO_TEXT = 'worklistPage.openTab.detailsByType';

  const { t: getTextToDisplay } = useTranslation();
  const {
    activeView,
    permissionService,
    user: { profile: activeUserProfile }
  } = useUser();

  return (
    <div className="task-details">
      <div className="task-details__information">
        <h2>{engagementDisplayNameShort}</h2>
        <h3>{assignmentTitle}</h3>

        {assignmentDescription && (
          <>
            <h4>{getTextToDisplay(`${ROOT_TO_TEXT}.description`)}</h4>
            <p>{assignmentDescription}</p>
          </>
        )}

        {assignmentAttachments.length > 0 && (
          <Attachments
            attachments={assignmentAttachments}
            disableDownload={
              /* dataRequest.isUserGhosted || TODO: Worklist: Keep this? */ activeView.isExecutiveView
            }
            engagementGuid={engagementGuid}
          />
        )}

        <div className="task-details__information__actions">
          <Button
            {...commentButtonProps}
            // disabled={isUserGhosted} // TODO: Worklist: Keep this functionality?
          >
            {commentButtonDisplayTextPath}
          </Button>
        </div>
      </div>

      <div className="task-details__status">
        <div className={`well${isPastDue ? ' past-due-assignment' : ''}`}>
          <StatusIcon status={assignmentStatusId} />

          <div className="task-details__status__details">
            <ul>
              <li>
                <label>
                  {getTextToDisplay(`${ROOT_TO_TEXT}.task.createdBy`)}
                </label>
                {assignmentCreatedBy.userGuid === activeUserProfile.memberGuid
                  ? getTextToDisplay(`${ROOT_TO_TEXT}.task.createdByYou`)
                  : assignmentCreatedBy.name}
              </li>
              <li>
                <label>
                  {getTextToDisplay(`${ROOT_TO_TEXT}.task.creationDate`)}
                </label>
                {formatDate(assignmentCreatedDate)}
              </li>
              <li>
                <label>{getTextToDisplay(`${ROOT_TO_TEXT}.followers`)}</label>
                <Button
                  disabled={isFollowButtonDisabled}
                  onClick={onToggleFollow}
                  size={ButtonSizeEnums.SMALL}
                  variant={ButtonVariantEnums.TEXT}
                >
                  {getTextToDisplay(
                    `${ROOT_TO_TEXT}.${
                      isAssignmentCurrentlyWatched ? 'unfollow' : 'follow'
                    }`
                  )}
                </Button>
                •
                <Button
                  onClick={onOpenFollowersModal}
                  size={ButtonSizeEnums.SMALL}
                  variant={ButtonVariantEnums.TEXT}
                >
                  {getTextToDisplay(`${ROOT_TO_TEXT}.followersWithCount`, {
                    count: assignmentFollowerCount
                  })}
                </Button>
              </li>
            </ul>
          </div>
        </div>

        {permissionService.hasPermission(Permission.TimelinesView) && (
          <>
            {engagementMilestoneTitle && (
              <div className="task-details__status__linked-to">
                <Icon name="link" />
                {getTextToDisplay(`${ROOT_TO_TEXT}.task.linkedTo`)}
                <Link
                  to={`/app/project/${engagementGuid}/overview/milestones/${engagementMilestoneGuid}`}
                >
                  {engagementMilestoneTitle}
                </Link>
              </div>
            )}

            {!engagementMilestoneTitle &&
              permissionService.hasPermission(Permission.TasksEdit) &&
              (permissionService.isRyan() ||
                (permissionService.isClient() &&
                  assignmentCreatedBy.userType === UserType.Client)) && (
                <Button
                  className="task-details__status__link-milestone"
                  icon="link"
                  onClick={onLinkToMilestone}
                  size={ButtonSizeEnums.SMALL}
                  variant={ButtonVariantEnums.TEXT}
                >
                  {getTextToDisplay(`${ROOT_TO_TEXT}.task.addLink`)}
                </Button>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default TaskDetails;
