import { AxiosResponse } from 'axios';
import classnames from 'classnames';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, TextInput } from '@ryan/components';

import ApiService from '../../../services/ApiService';
import pushServerErrorToast from '../../../utils/pushServerErrorToast';
import Datepicker from '../../Datepicker';
import Dropdown from '../../Dropdown/Dropdown';
import Modal from '../../Modal';
import ReleaseNotes from './ReleaseNotes/ReleaseNotes';
import {
  IReleaseNotesModalProps,
  TItemTypes,
  TItemTypesFetchProps,
  TNotesScrollProps,
  TReleaseNote,
  TReleaseNotesFetchProps
} from './utils';

import './ReleaseNotesModal.scss';

const ReleaseNotesModal: React.FC<IReleaseNotesModalProps> = ({ onClose }) => {
  const { t: textToDisplay } = useTranslation();
  const emptyOption = { label: '', value: '' };
  const [releaseNotes, setReleaseNotes] = useState<TReleaseNote[]>([]);
  const [isReleaseNotesLoading, setIsReleaseNotesLoading] = useState(false);
  const [isFiltersOpened, setIsFiltersOpened] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeItemType, setActiveItemType] = useState<TItemTypes>(emptyOption);
  const [isItemTypesLoading, setIsItemTypesLoading] = useState(false);
  const [itemTypes, setItemTypes] = useState<TItemTypes[]>([emptyOption]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalAmount, setTotalAmount] = useState(0);

  const listInnerRef = useRef();

  const handleNotesScroll = useCallback((props: TNotesScrollProps) => {
    const { listHref, callbacks } = props;

    if (listHref.current) {
      const { scrollTop, scrollHeight, clientHeight } = listHref.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight;

      if (isNearBottom) {
        callbacks.setCurrentPageCallback((prevCount: number) => prevCount + 1);
      }
    }
  }, []);

  const fetchItemTypes = useCallback(async (props: TItemTypesFetchProps) => {
    try {
      const { callbacks } = props;
      callbacks.setIsItemTypesLoadingCallback(true);

      const { data } = await ApiService.getItemTypes();

      if (data) {
        const itemTypes = data.map(obj => ({
          label: obj.name,
          value: obj.releaseItemTypeGuid
        }));
        const allItemTypes = { label: 'All', value: 'all' };

        itemTypes.unshift(allItemTypes);
        callbacks.setItemTypesCallback(itemTypes);
      }
    } catch {
      const { callbacks } = props;
      callbacks.pushServerErrorToastCallback();
    } finally {
      const { callbacks } = props;
      callbacks.setIsItemTypesLoadingCallback(false);
    }
  }, []);

  const fetchReleaseNotes = useCallback(
    async (props: TReleaseNotesFetchProps) => {
      try {
        const {
          activeItem,
          activePage,
          callbacks,
          dateEnd,
          searchString,
          dateStart
        } = props;

        const itemType =
          activeItem && activeItem?.value !== 'all' ? activeItem.value : null;

        callbacks.setIsReleaseNotesLoadingCallback(true);

        const {
          data: { pageNumber, results, totalResults }
        }: AxiosResponse = await ApiService.getReleaseNotes({
          EndDate: dateEnd,
          PageNumber: activePage,
          ReleaseItemTypeGuid: itemType,
          SearchTerm:
            searchString && searchString.length ? searchString : undefined,
          StartDate: dateStart
        });

        const resultsWithAdjustedDate = results.map(
          (result: { releaseDate: string | number | Date }) => ({
            ...result,
            releaseDate: new Date(
              new Date(result.releaseDate).getTime() + 2 * 60 * 60 * 1000
            ).toISOString()
          })
        );

        callbacks.setTotalAmountCallback(totalResults);

        pageNumber > 1
          ? callbacks.setReleaseNotesCallback((prevState: any) => [
              ...prevState,
              ...resultsWithAdjustedDate
            ])
          : callbacks.setReleaseNotesCallback(resultsWithAdjustedDate);
      } catch {
        const { callbacks } = props;
        callbacks.setReleaseNotesCallback([]);
        callbacks.setCurrentPageCallback(1);
      } finally {
        const { callbacks } = props;
        callbacks.setIsReleaseNotesLoadingCallback(false);
      }
    },
    []
  );

  useEffect(() => {
    const notesLength = releaseNotes.length;
    const shouldFetchReleaseNotes =
      totalAmount > notesLength || currentPage === 1;

    if (shouldFetchReleaseNotes) {
      fetchReleaseNotes({
        activeItem: activeItemType,
        activePage: currentPage,
        callbacks: {
          pushServerErrorToastCallback: pushServerErrorToast,
          setCurrentPageCallback: setCurrentPage,
          setIsReleaseNotesLoadingCallback: setIsReleaseNotesLoading,
          setReleaseNotesCallback: setReleaseNotes,
          setTotalAmountCallback: setTotalAmount
        },
        dateEnd: endDate,
        dateStart: startDate,
        searchString: searchQuery
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeItemType,
    currentPage,
    endDate,
    fetchReleaseNotes,
    searchQuery,
    startDate
  ]);

  useEffect(() => {
    fetchItemTypes({
      callbacks: {
        pushServerErrorToastCallback: pushServerErrorToast,
        setIsItemTypesLoadingCallback: setIsItemTypesLoading,
        setItemTypesCallback: setItemTypes
      }
    });
  }, [fetchItemTypes]);

  useEffect(() => {
    const listInnerElement: any = listInnerRef.current;

    const handleScroll = () => {
      handleNotesScroll({
        listHref: listInnerRef,
        callbacks: {
          setCurrentPageCallback: setCurrentPage
        }
      });
    };

    if (listInnerElement) {
      listInnerElement.addEventListener('scroll', handleScroll);

      return () => {
        listInnerElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleNotesScroll]);

  const handleItemTypeChange = (
    itemTypeValue?: string | null,
    itemTypesDropdown?: TItemTypes[],
    callbacks?: {
      setCurrentPageCallback: (page: number) => void;
      setActiveItemTypeCallback: (itemType: TItemTypes) => void;
    }
  ) => {
    const itemTypeLabel = itemTypesDropdown?.find(obj => {
      return obj.value === itemTypeValue;
    });

    const itemTypeObject = {
      label: itemTypeLabel ? itemTypeLabel.label : '',
      value: itemTypeValue || ''
    };

    callbacks?.setCurrentPageCallback(1);
    callbacks?.setActiveItemTypeCallback(itemTypeObject);
  };

  const itemTypesCallbacks = {
    setCurrentPageCallback: setCurrentPage,
    setActiveItemTypeCallback: setActiveItemType
  };

  const isFiltersChanged =
    endDate !== null || startDate !== null || activeItemType?.label;

  return (
    <Modal
      className="release-notes-modal"
      onClose={onClose}
      open
      title={textToDisplay('modal.releaseNotesModal.title')}
    >
      <div className="release-notes-modal__inner-wrapper">
        <div className="release-notes-modal__filters">
          <div className="release-notes-modal__filters__top">
            <TextInput
              icon={searchQuery ? 'delete' : 'search'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setCurrentPage(1);
                setSearchQuery(e.target.value);
              }}
              onIconClick={
                searchQuery
                  ? () => {
                      setCurrentPage(1);
                      setSearchQuery('');
                    }
                  : undefined
              }
              placeholder={textToDisplay('modal.releaseNotesModal.search')}
              value={searchQuery}
            />
            <Button
              disabled={isReleaseNotesLoading}
              icon={
                isFiltersOpened && isFiltersChanged ? 'filter-filled' : 'filter'
              }
              onClick={() => setIsFiltersOpened(!isFiltersOpened)}
              size="sm"
              variant="text"
            >
              {textToDisplay('modal.releaseNotesModal.filters')}
            </Button>
          </div>
          <div
            className={classnames(`release-notes-modal__filters__bottom`, {
              'release-notes-modal__filters__bottom--active': isFiltersOpened
            })}
          >
            <div
              className={classnames(
                `release-notes-modal__filters__bottom__dropdown-wrapper`,
                {
                  'release-notes-modal__filters__bottom__dropdown-wrapper--loading':
                    isItemTypesLoading
                }
              )}
            >
              <Dropdown
                dropdownOptions={itemTypes}
                id="releaseNoteItemTypes"
                isDisabled={isItemTypesLoading || isReleaseNotesLoading}
                label={textToDisplay('modal.releaseNotesModal.itemTypes')}
                onChange={e =>
                  handleItemTypeChange(e, itemTypes, itemTypesCallbacks)
                }
                value={activeItemType}
              />
              {isItemTypesLoading && (
                <Icon
                  className="loading-spin"
                  data-testid="loading-icon"
                  name="loading"
                />
              )}
            </div>
            <Datepicker
              className="release-notes-modal__filters__bottom__start-date"
              disabled={isReleaseNotesLoading}
              label={textToDisplay('modal.releaseNotesModal.startDate')}
              maxDate={endDate}
              name="startDate"
              onChange={(e, value) => {
                setStartDate(value);
                setCurrentPage(1);
              }}
            />
            <Datepicker
              className="release-notes-modal__filters__bottom__end-date"
              disabled={isReleaseNotesLoading}
              label={textToDisplay('modal.releaseNotesModal.endDate')}
              minDate={startDate}
              name="endDate"
              onChange={(e, value) => {
                const endDate = value ? new Date(value) : value;
                endDate?.setHours(23, 59, 59);
                setEndDate(endDate);
                setCurrentPage(1);
              }}
            />
          </div>
        </div>
        <ReleaseNotes
          className={isFiltersOpened ? 'release-note--opened' : ''}
          isLoading={isReleaseNotesLoading}
          listInnerRef={listInnerRef}
          releaseNotes={releaseNotes}
        />
      </div>
    </Modal>
  );
};

export default ReleaseNotesModal;
