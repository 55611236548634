import { format, isSameSecond, parseISO } from 'date-fns';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Button, DropdownMenu, Mentions } from '@ryan/components';

import { IUser } from '../../../interfaces';
import getAvatarUrl from '../../../utils/getAvatarUrl';
import CodeNoteField from '../CodeNoteField/CodeNoteField';
import {
  ICodeNoteProps,
  ICodeNoteTaggedUser,
  IRenderCodeNotesContent
} from './utils';

import './CodeNote.scss';

const CodeNote: FunctionComponent<ICodeNoteProps> = ({
  engagementGuid,
  codeNote,
  canEdit,
  editText,
  editLoading,
  onEdit,
  onEditSave,
  onEditCancel,
  onDelete
}) => {
  const [firstName, lastName] = (codeNote.createdByName || '').split(' ');
  const user = {
    avatarUrl: codeNote?.avatarUrl
  };
  const { t } = useTranslation();

  const edited = !isSameSecond(
    new Date(codeNote.createDate),
    new Date(codeNote.updateDate)
  );

  const renderCodeNotesContent: IRenderCodeNotesContent = ({
    isEditable,
    note,
    textToEdit
  }) => {
    if (isEditable && textToEdit !== undefined) {
      return (
        <CodeNoteField
          codeNoteGuid={note.noteGuid}
          codeNoteText={textToEdit}
          engagementGuid={engagementGuid}
          isEditable={isEditable}
          isLoading={editLoading}
          onCancel={onEditCancel}
          onSave={onEditSave}
        />
      );
    } else {
      return (
        <Mentions
          value={{
            text: note.noteText,
            users: (note.noteTaggedUsers || []).map(
              (user: ICodeNoteTaggedUser) => ({
                display: user.fullName,
                id: user.userGuid
              })
            )
          }}
        />
      );
    }
  };

  return (
    <div className="code-note">
      <Avatar
        firstName={firstName}
        lastName={lastName}
        profileImageSrc={getAvatarUrl(user as IUser)}
      />
      <div className="code-note__meta">
        <b className="code-note__meta__name">{codeNote?.createdByName}</b>
        <span className="code-note__meta__date">
          {format(parseISO(codeNote?.createDate), t('format.time'))}
          {edited &&
            codeNote.updateDate !== null &&
            ` (${t('codeNotes.edited')})`}
        </span>
      </div>
      <div className="code-note__content">
        {renderCodeNotesContent({
          isEditable: canEdit,
          note: codeNote,
          textToEdit: editText!
        })}
      </div>
      {canEdit && (
        <DropdownMenu
          options={[
            {
              icon: 'pencil',
              label: t('Edit'),
              onClick: () => onEdit(codeNote)
            },
            {
              icon: 'trash',
              label: t('Delete'),
              negative: true,
              onClick: () => onDelete(codeNote)
            }
          ]}
          placement="top-end"
          renderTarget={({ ref, open, onClick }) => (
            <Button
              aria-expanded={open}
              aria-haspopup="menu"
              aria-label={t(
                open ? 'codeNotes.menuClose' : 'codeNotes.menuOpen'
              )}
              className="code-note__menu"
              icon="more-outline"
              innerRef={ref}
              onClick={onClick}
              size="sm"
              variant="text"
            />
          )}
        />
      )}
    </div>
  );
};

export default CodeNote;
