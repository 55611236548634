// Routes
import { UserManager } from 'oidc-client';
import HelpForm from 'routes/HelpForm/HelpForm';
import Logout from 'routes/Logout/Logout';
import {
  ForgotPassword,
  ResetPassword,
  ResetPasswordSuccess
} from 'routes/PasswordManagement';

import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import EventWrapperPassthrough from '../../routes/EventWrapperPassthrough/EventWrapperPassthrough';

interface IPublicRouterProps {
  userManager: UserManager;
}

/**
 * A router for pages that can be viewed without user-authentication.
 */
const PublicRouter: FunctionComponent<IPublicRouterProps> = ({
  userManager
}) => (
  <Switch>
    <Route component={Logout} path="/public/logout" />
    <Route component={ForgotPassword} path="/public/forgot-password" />
    <Route component={ResetPassword} path="/public/reset-password/:requestId" />
    <Route
      component={ResetPasswordSuccess}
      path="/public/reset-password-success"
    />
    <Route
      path="/public/newuser-password/expired"
      render={() => <ForgotPassword newUser />}
    />
    <Route
      path="/public/newuser-password/:requestId"
      render={props => <ResetPassword newUser {...props} />}
    />
    <Route
      path="/public/newuser-password-success"
      render={props => <ResetPasswordSuccess newUser {...props} />}
    />
    <Route component={HelpForm} path="/public/help" />
    <Route
      exact
      path="/public/adhoc-uploads"
      render={() => (
        <EventWrapperPassthrough
          route={'/app/adhoc-uploads'}
          userManager={userManager}
        />
      )}
    />
    <Route
      exact
      path="/public/adhoc-help-page"
      render={() => (
        <EventWrapperPassthrough
          route={'/app/adhoc-help-page'}
          userManager={userManager}
        />
      )}
    />
    <Redirect to="/" />
  </Switch>
);

export default PublicRouter;
