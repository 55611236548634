import { MilestoneDrawerConsumer } from 'contexts/MilestoneDrawerContext';
import { useAppReadOnly } from 'contexts/UserContext';
import { IMilestone, IPagedDataResponse, ITask } from 'interfaces';
import getCommentButtonProps from 'utils/getCommentButtonProps';

import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Tooltip } from '@ryan/components';

import Paragraphs from '../Paragraphs';
import MilestoneForm from './MilestoneForm';
import MilestoneHeader from './MilestoneHeader';
import MilestoneTasks from './MilestoneTasks';

export interface IMilestoneProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  canCommentMilestones: boolean;
  canEditMilestones: boolean;

  milestone: IMilestone;
  milestones: IMilestone[];
  isActive: boolean;
  submitPromise: Promise<any> | null;
  engagementKickoffDate: Date | null;
  engagementProjectedEndDate: Date | null;
  onToggleActive: () => void;
  onSubmit: (milestone: IMilestone) => void;
  onDelete: () => void;

  tasks: IPagedDataResponse<ITask> | null;
  onCreateTask: () => void;
}

const Milestone: FunctionComponent<IMilestoneProps> = ({
  canCommentMilestones: canCommentMilestonesProp,
  canEditMilestones: canEditMilestonesProp,
  className,
  milestone,
  milestones,
  isActive,
  submitPromise,
  engagementKickoffDate,
  engagementProjectedEndDate,
  onToggleActive,
  onSubmit,
  onDelete,
  tasks,
  onCreateTask,
  ...rest
}) => {
  const { t } = useTranslation();
  const isAppReadOnly = useAppReadOnly();
  const [editing, setEditing] = useState(false);

  // disable editing if active view is an executive view
  const canCommentMilestones = canCommentMilestonesProp && !isAppReadOnly;
  const canEditMilestones = canEditMilestonesProp && !isAppReadOnly;

  // Stop editing if no longer active
  useEffect(() => {
    !isActive && setEditing(false);
  }, [isActive]);

  // Stop editing when submit succeeds
  useEffect(() => {
    submitPromise !== null && submitPromise.then(() => setEditing(false));
  }, [submitPromise]);

  if (isActive && editing) {
    return (
      <MilestoneForm
        engagementKickoffDate={engagementKickoffDate}
        engagementProjectedEndDate={engagementProjectedEndDate}
        milestone={milestone}
        milestones={milestones}
        onCancel={() => setEditing(false)}
        onCreateTask={onCreateTask}
        onDelete={onDelete}
        onSubmit={values => {
          onSubmit({
            ...milestone,
            title: values.title,
            detail: values.detail,
            statusId: values.statusId,
            // non-null assertion because validation in
            // MilestoneForm should prevent nulls
            startDate: values.startDate!,
            endDate: values.endDate!
          });
        }}
        submitPromise={submitPromise}
        tasks={tasks}
      />
    );
  }

  return (
    <div
      aria-expanded={isActive}
      className={classNames(
        'milestone',
        {
          'milestone--active': isActive
        },
        className
      )}
      data-id={milestone.engagementMilestoneGuid}
      {...rest}
    >
      <div className="milestone-drop-shadow">
        <MilestoneHeader
          isActive={isActive}
          milestone={milestone}
          onClick={onToggleActive}
        />
        {isActive && (
          <div className="milestone__content">
            <div className="row">
              <div className="col-lg-6">
                <Paragraphs text={milestone.detail} />
              </div>
              <div className="col-lg-6">
                <MilestoneTasks
                  milestone={milestone}
                  onCreateTask={onCreateTask}
                  tasks={tasks}
                />
              </div>
            </div>

            <div className="milestone__buttons">
              {/**
               * EDIT
               * Shown when not editing.
               */}
              {canEditMilestones && milestone.isEditable && (
                <Button
                  disabled={milestone.isEngagementReadOnly || isAppReadOnly}
                  icon="pencil"
                  onClick={() => setEditing(true)}
                  text={t('Edit')}
                  variant="primary"
                />
              )}

              {/**
               * EDIT (DISABLED)
               * Shown when not editing, but milestone is not
               * editable due to being owned by an external source.
               */}
              {canEditMilestones && !milestone.isEditable && (
                <Tooltip
                  content={t('milestones.milestoneContent.rpm')}
                  placement="top"
                  renderTarget={({ open, ref, ...props }) => (
                    <div
                      aria-expanded={open}
                      aria-haspopup="true"
                      ref={ref}
                      {...props}
                    >
                      {
                        // HACK: Observed issue where Tooltip content would appear on initial hover and remain in the page even after
                        // hovering has stopped. To restore desired behavior, adding style={{pointerEvents: 'none}}. Possible explanation
                        // is that a disabled button will not respond to hover events, and the initial is captured by the parent div. By setting
                        // pointerEvents to none, the hover interactions work as expected showing and dismissing the tooltip.
                        // PointerEvents: none will need to be removed for the button to be interacted with.
                      }
                      <Button
                        disabled
                        icon="pencil"
                        style={{ pointerEvents: 'none' }} // HACK: Observed issue where Tooltip content would appear on initial hover and remain
                        text={t('Edit')}
                        variant="primary"
                      />
                    </div>
                  )}
                />
              )}

              {/**
               * OPEN COMMENTS
               * Only saved Milestones can have comments.
               */}
              {'engagementMilestoneGuid' in milestone && (
                <MilestoneDrawerConsumer>
                  {({ onMilestoneDrawerOpen }) => (
                    <Button
                      {...getCommentButtonProps(
                        t,
                        canCommentMilestones,
                        milestone,
                        isAppReadOnly
                      )}
                      onClick={() => onMilestoneDrawerOpen(milestone)}
                      size="sm"
                      variant="text"
                    />
                  )}
                </MilestoneDrawerConsumer>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Milestone;
