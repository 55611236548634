import React, { FunctionComponent, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button, Icon } from '@ryan/components';

import {
  EngagementPersonnelFilter,
  JurisdictionFilter,
  PracticeAreaFilter,
  ProjectDataType,
  ProjectFilter
} from '../interfaces';

interface DataTypeSectionProps extends WithTranslation {
  clearSelection: (filter: string, dataType: ProjectDataType) => void;
  onClear: (dataType: ProjectDataType) => void;
  selectedFilters: ProjectFilter[];
  type: ProjectDataType;
}

const DataTypeSection: FunctionComponent<DataTypeSectionProps> = ({
  t,
  clearSelection,
  onClear,
  selectedFilters,
  type
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const renderPillLabel = (filter: ProjectFilter) => {
    switch (type) {
      case ProjectDataType.AccountHandler:
      case ProjectDataType.EngagementManager:
      case ProjectDataType.EngagementPrincipal:
        const engagementPersonnel = filter as EngagementPersonnelFilter;
        return <p>{engagementPersonnel.fullName}</p>;
      case ProjectDataType.Jurisdiction:
        const jurisdiction = filter as JurisdictionFilter;
        return <p>{jurisdiction.name}</p>;
      case ProjectDataType.PracticeArea:
        const practiceArea = filter as PracticeAreaFilter;
        return <p>{practiceArea.name}</p>;
    }
  };

  const renderSectionTitle = () => {
    switch (type) {
      case ProjectDataType.AccountHandler:
        return (
          <p>
            {t('advancedFilterModal.totalEngagementHandlers', {
              numSelected: selectedFilters.length
            })}
          </p>
        );
      case ProjectDataType.EngagementManager:
        return (
          <p>
            {t('advancedFilterModal.totalEngagementManagers', {
              numSelected: selectedFilters.length
            })}
          </p>
        );
      case ProjectDataType.EngagementPrincipal:
        return (
          <p>
            {t('advancedFilterModal.totalEngagementPrincipals', {
              numSelected: selectedFilters.length
            })}
          </p>
        );
      case ProjectDataType.Jurisdiction:
        return (
          <p>
            {t('advancedFilterModal.totalJurisdictions', {
              numSelected: selectedFilters.length
            })}
          </p>
        );
      case ProjectDataType.PracticeArea:
        return (
          <p>
            {t('advancedFilterModal.totalPracticeAreas', {
              numSelected: selectedFilters.length
            })}
          </p>
        );
    }
  };

  const clearTapped = (filter: ProjectFilter) => {
    switch (type) {
      case ProjectDataType.AccountHandler:
      case ProjectDataType.EngagementManager:
      case ProjectDataType.EngagementPrincipal:
        const engagementPersonnel = filter as EngagementPersonnelFilter;
        clearSelection(engagementPersonnel.userGuid, type);
        return;
      case ProjectDataType.Jurisdiction:
        const jurisdiction = filter as JurisdictionFilter;
        clearSelection(jurisdiction.jurisdictionGuid, type);
        return;
      case ProjectDataType.PracticeArea:
        const practiceArea = filter as PracticeAreaFilter;
        clearSelection(practiceArea.practiceAreaGuid, type);
        return;
    }
  };

  return (
    <div className="data-type-section">
      <div className="section-header">
        <button
          id="collapse-section"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <Icon name={isCollapsed ? 'chevron-down' : 'chevron-up'} />
        </button>
        {renderSectionTitle()}
        <button id="clear-all" onClick={() => onClear(type)}>
          {t('Clear')}
        </button>
      </div>
      {!isCollapsed && (
        <div className="selected-filters-container">
          {selectedFilters.map((filter, index) => (
            <div className="selected-filter-pill" key={index}>
              {renderPillLabel(filter)}
              <Button
                icon="delete"
                onClick={() => clearTapped(filter)}
                size="sm"
                style={{ fill: '#fff', color: 'white', padding: 0 }}
                variant="link"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(DataTypeSection);
