import { FileAttach } from 'components/FileAttachments';
import { AttachmentLink } from 'components/FileLink';
import { IAttachmentUpdates, IFile } from 'interfaces';
import { DSSManager } from 'utils/DSS';

import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ryan/components';

import './ManageAttachments.scss';

export interface IManageAttachmentsProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange' | 'value'> {
  attachments: IFile[];
  dss: DSSManager;
  engagementGuid: string | null;
  engagementName: string | null;
  value: IAttachmentUpdates;
  canDeleteAttachment?: (file: IFile) => boolean;
  onChange: (value: IAttachmentUpdates) => void;
}

/**
 * Component to manage the attachments on an entity, like
 * a Data Request, Task, or Learning. Used in Modals.
 */
const ManageAttachments: FunctionComponent<IManageAttachmentsProps> = ({
  attachments,
  className,
  dss,
  engagementGuid,
  engagementName,
  value,
  canDeleteAttachment,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation();

  /**
   * Add new attachments
   */
  function onAddAttachmentChange(updates: IAttachmentUpdates) {
    onChange({
      // FileAttach only gives us the adds
      addUploaded: updates.addUploaded,
      addExisting: updates.addExisting,
      // Don't overwrite deletes!
      deleteAttachments: value.deleteAttachments
    });
  }

  /**
   * Delete an existing attachment
   */
  function onDeleteExistingAttachment(file: IFile) {
    onChange({
      ...value,
      deleteAttachments: [...value.deleteAttachments, file.documentGuid]
    });
  }

  const existingAttachments = attachments
    .filter(file => !value.deleteAttachments.includes(file.documentGuid))
    .sort((a, b) => {
      const aDate = new Date(a.uploadedDate).getTime();
      const bDate = new Date(b.uploadedDate).getTime();
      return aDate - bDate;
    });

  return (
    <div className={classNames('manage-attachments', className)} {...rest}>
      {/**
       * Attach new files
       */}
      <label className="ry-label">{t('Attach a File (Optional)')}</label>
      <FileAttach
        dss={dss}
        engagementGuid={engagementGuid}
        engagementName={engagementName}
        excludeFromSearchResults={attachments.map(file => file.documentGuid)}
        onAttachmentUpdate={onAddAttachmentChange}
      />

      {/**
       * Remove existing file attachments
       */}
      {existingAttachments.length > 0 && (
        <>
          <label className="ry-label">
            {t('Attachments', { context: 'files' })}
          </label>
          <ul className="manage-attachments__existing">
            {existingAttachments.map(file => (
              <li key={`${file.documentGuid}`}>
                <div className="manage-attachments__existing-name">
                  <Icon name="paperclip" />
                  <AttachmentLink engagementGuid={engagementGuid} file={file} />
                </div>
                {(canDeleteAttachment === undefined ||
                  canDeleteAttachment(file)) && (
                  <button
                    aria-label={t('Unattach', { name: file.displayName })}
                    className="manage-attachments__existing-delete"
                    onClick={() => onDeleteExistingAttachment(file)}
                    type="button"
                  >
                    <Icon name="trash" />
                  </button>
                )}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default ManageAttachments;
