import { useUser } from 'hooks';
import UserService from 'services/UserService';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Banner, Button } from '@ryan/components';

import './ImpersonatingBanner.scss';

/**
 * Renders a banner if the current user is being impersonated.
 */
const ImpersonatingBanner: React.FC = () => {
  const { t } = useTranslation();
  const [impersonating, setImpersonating] = useState<string | null>(null);
  const { setUser, user } = useUser();

  // get name of user being impersonated
  useEffect(() => {
    setImpersonating(
      user.dxpImpersonatingUserGuid
        ? user.profile.fullName || 'an unnamed user'
        : null
    );
  }, [user]);

  return impersonating ? (
    <Banner type="warning">
      <div className="impersonating-banner">
        <div>{t('impersonate.banner', { user: impersonating })}</div>
        <Button
          icon="delete"
          onClick={() => UserService.stopImpersonating(setUser)}
          size="sm"
          text={t('impersonate.stopImpersonating')}
          variant="text"
        />
      </div>
    </Banner>
  ) : null;
};

export default ImpersonatingBanner;
