import Screen from 'components/Screen';
import {
  Feature,
  IAccount,
  ICustomViewCreateType,
  ICustomViewSummary,
  ICustomViewType,
  IUserIdentity,
  Permission
} from 'interfaces';
import { parse } from 'query-string';
import AmplitudeApiService from 'services/AmplitudeApiService';
import ApiService from 'services/ApiService';
import { EXECUTIVE_VIEW_STORAGE_KEY } from 'services/ApiService/storageKeys';
import history from 'services/history';
import { logger } from 'services/logger';
import EngagementAccessError from 'utils/EngagementAccessError';
import { setCookie } from 'utils/cookies';

import { CancelToken } from 'axios';
import memoizeOne from 'memoize-one';
import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import { pushToast } from '@ryan/components';

import { PermissionService } from './PermissionService';
import { loadRecentViews, storeRecentViews } from './RecentViewsCache';
import { IUserContext, UserContext } from './UserContext';
import { findAccountByGuid } from './findAccount';

import './UserProvider.scss';

enum FetchStatus {
  Loading,
  Success,
  Error
}

interface IUserProviderProps extends WithTranslation {
  children: React.ReactNode;
}

interface IUserProviderState {
  accountSwitcherStatus: FetchStatus | null;
  status: FetchStatus;
  user: IUserIdentity | null;
  executiveAccounts: IAccount[] | null;
  activeView: ICustomViewSummary | null;
  recentViews: ICustomViewSummary[];
}

export class UserProvider extends Component<
  IUserProviderProps,
  IUserProviderState
> {
  readonly state: IUserProviderState = {
    accountSwitcherStatus: null,
    status: FetchStatus.Loading,
    user: null,
    executiveAccounts: null,
    activeView: null,
    recentViews: []
  };

  // cache executive view fetch request so only single call is made
  private executiveAccountsPromise: Promise<IAccount[] | null> | null = null;

  getContext = memoizeOne(
    (
      user: IUserIdentity,
      executiveAccounts: IAccount[] | null,
      activeView: ICustomViewSummary,
      recentViews: ICustomViewSummary[]
    ) => {
      const context: IUserContext = {
        // User
        user,
        onUserUpdate: this.handleUserUpdate,

        // Accounts
        executiveAccounts,
        getAccountByGuid: this.getAccountByGuid,
        setActiveAccount: this.setActiveAccount,
        setActiveAccountForEngagement: this.setActiveAccountForEngagement,

        // Executive Accounts
        loadExectiveAccounts: this.loadExectiveAccounts,

        // Views
        activeView,
        recentViews,
        isEngagementInView: this.isEngagementInView,
        setActiveView: this.setActiveView,
        deleteCustomView: this.deleteCustomView,

        // App Access
        permissionService: new PermissionService(user),
        isAppReadOnly: activeView.isExecutiveView,
        isFeatureToggled: this.isFeatureToggled,

        setUser: this.setUser
      };

      /**
       * Set flag indiciating executive view status. This is used by backend to
       * determine whether user should be granted access to projects they are
       * not a member of but has access via ExecutiveAccess.
       */
      if (activeView.isExecutiveView) {
        window.sessionStorage.setItem(EXECUTIVE_VIEW_STORAGE_KEY, 'true');
      } else {
        window.sessionStorage.removeItem(EXECUTIVE_VIEW_STORAGE_KEY);
      }

      /**
       * Legacy cookie functionality for executive view status.
       *
       * @todo remove when backend updates are made to no longer use this cookie
       */
      setCookie('isExecutiveView', `${activeView.isExecutiveView}`);

      return context;
    }
  );

  /**
   *
   * Load user...
   *
   */

  componentDidMount() {
    const { account: accountGuid } = parse(history?.location?.search || '');

    if (accountGuid && typeof accountGuid === 'string') {
      this.setState({ accountSwitcherStatus: FetchStatus.Loading });
    }

    this.fetchUser();
  }

  handleUserUpdate = () => {
    this.fetchUser();
  };

  async fetchUser() {
    const { t } = this.props;

    try {
      const { data: user } = await ApiService.userIdentity();

      AmplitudeApiService.updateUserProperties(user);

      //AmplitudeApiService.setUserId(user.profile.userGuid); this has been commented until a universal user identifier can be established across every application in Ryan/Tax.com. I am leaving this here rather than removing it for easier re-implementation later

      // If the user has not yet accepted the latest terms of use, redirect. Do not redirect if
      // the user is being impersonalized:
      if (
        user.profile.needsToAcceptTermsOfUse === true &&
        user.dxpImpersonatingUserGuid == null
      ) {
        history.push('/go-to/accept-terms');
        return;
      }

      // Load recent views from cache, tossing any views we no longer
      // have access to and updating the others with latest name, projectCount, etc.
      const recentViews = loadRecentViews(
        user.profile.userGuid,
        user.customViews
      );

      const defaultView = await this.getDefaultView(user, recentViews);

      this.setState({
        status: FetchStatus.Success,
        user,
        activeView: defaultView,
        recentViews: storeRecentViews(user.profile.userGuid, [
          defaultView,
          ...recentViews
        ])
      });
    } catch (error) {
      logger.error(`could not fetch user identity - ${error}`);
      this.setState({
        status: FetchStatus.Error
      });
      pushToast({
        type: 'error',
        title: t('serverError.title'),
        content: (
          <Trans i18nKey="serverError.content2">
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a className="ry-link" href="/app" />
          </Trans>
        ),
        autoClose: 0
      });
    }
  }

  /**
   * The `activeView` is set to one of the following (in order)...
   * 1. The most recent view from "Recently Viewed"
   * 2. The first standard account
   * 3. The first executive account
   * 4. An empty placeholder view.
   */
  async getDefaultView(user: IUserIdentity, recentViews: ICustomViewSummary[]) {
    if (recentViews.length) {
      return recentViews[0];
    }

    // First, try the standard account list
    let firstAccount: [IAccount, boolean] = [user.accountTree[0], false];

    // If no accounts in standard list, try executive accounts
    if (!firstAccount[0]) {
      let { executiveAccounts } = this.state;

      // If executive accounts are not loaded, try to load them
      // We may not have permission
      if (!executiveAccounts) {
        executiveAccounts = await this.fetchExectiveAccounts(user);
        this.setState({ executiveAccounts });
      }

      // If we have them now, try to use the first account
      if (executiveAccounts) {
        firstAccount = [executiveAccounts[0], true];
      }
    }

    // If we have an account to use, create a view
    if (firstAccount[0]) {
      const response = await ApiService.createAccountView(
        firstAccount[0],
        true,
        firstAccount[1]
      );

      return response.data;
    }

    /**
     * This loads the app with limited access and a message
     * informing the user that they have no accounts.
     * @todo Would like a better solution here. Passing null for the activeView
     * would be a headache to type in consuming components, but is probably the best?
     */
    const placeholderView: ICustomViewSummary = {
      customViewGuid: 'PLACEHOLDER-VIEW-0001',
      name: '',
      type: ICustomViewType.Dynamic,
      createType: ICustomViewCreateType.CustomOneTimeUse,
      isExecutiveView: false,
      projectCount: 0,
      accountView: null
    };

    return placeholderView;
  }

  /**
   *
   * Account Helpers
   *
   */

  /**
   * Gets an account by guid.
   * We'll need to check both account trees.
   */
  getAccountByGuid = async (accountGuid: string) => {
    const { user, activeView } = this.state;
    if (user && activeView) {
      // Check regular account tree first.
      let match = findAccountByGuid(user.accountTree, accountGuid);
      if (match) {
        return { ...match, executiveAccessOnly: false };
      }

      // Check executive account tree second.
      if (!match) {
        match = findAccountByGuid(
          (await this.loadExectiveAccounts()) || [],
          accountGuid
        );
      }
      if (match) {
        return { ...match, executiveAccessOnly: true };
      }
    }
  };

  /**
   *
   * Switcher
   *
   */

  loadExectiveAccounts = async (): Promise<IAccount[] | null> => {
    const { user, executiveAccounts } = this.state;
    if (executiveAccounts) return executiveAccounts;
    if (this.executiveAccountsPromise) return this.executiveAccountsPromise;

    if (user) {
      const promise = this.fetchExectiveAccounts(user);
      this.executiveAccountsPromise = promise;
      return promise;
    }

    return null;
  };

  private fetchExectiveAccounts = async (
    user: IUserIdentity
  ): Promise<IAccount[] | null> => {
    const ps = new PermissionService(user);

    if (ps.hasPermission(Permission.ExecutiveAccess)) {
      const response = await ApiService.getExecutiveAccounts(
        user.profile.userGuid
      );
      const executiveAccounts = response.data;
      this.setState({ executiveAccounts });
      return executiveAccounts;
    }

    return null;
  };

  isEngagementInView = async (
    engagementGuid: string,
    cancelToken?: CancelToken
  ): Promise<boolean> => {
    const { activeView } = this.state;
    return activeView
      ? ApiService.isEngagementInView(
          engagementGuid,
          activeView.customViewGuid,
          cancelToken
        )
      : false;
  };

  setActiveView = (activeView: ICustomViewSummary) => {
    const { user, executiveAccounts, recentViews } = this.state;

    if (user) {
      // if active view is a newly created, non-one-time use view, append view to
      // user's custom views list as it will not appear in list until next
      // identity fetch occurs
      const activeViewInContext = user.customViews.find(
        view => view.customViewGuid === activeView.customViewGuid
      );
      const shouldAppendToViews =
        activeView.createType !== ICustomViewCreateType.CustomOneTimeUse &&
        !activeViewInContext;

      // if active view already in context, update view to reflect any edits
      // that may have occurred
      if (activeViewInContext) {
        activeViewInContext.name = activeView.name;
        activeViewInContext.projectCount = activeView.projectCount;
      }

      this.setState(
        {
          user: shouldAppendToViews
            ? { ...user, customViews: [...user.customViews, activeView] }
            : user,
          executiveAccounts,
          activeView,
          recentViews: storeRecentViews(user.profile.userGuid, [
            activeView,
            ...recentViews
          ])
        },
        () => {
          ApiService.updateViewLastViewed(activeView.customViewGuid)
            // swallow any errors
            .catch(() => {});
        }
      );
    }
  };

  /**
   * Selects an account to be the new active account.
   */
  setActiveAccount = async (
    account: IAccount | undefined,
    includingSubsidiaries: boolean,
    isExecutiveView: boolean
  ) => {
    if (account) {
      const { data: view } = await ApiService.createAccountView(
        account,
        includingSubsidiaries,
        isExecutiveView
      );

      this.setActiveView(view);
    }

    if (this.state.accountSwitcherStatus === FetchStatus.Loading) {
      this.setState({ accountSwitcherStatus: FetchStatus.Success });
    }
  };

  /**
   * If the engagement is not associated to the active account, set the active
   * account.
   */
  setActiveAccountForEngagement = async (engagement: {
    accountGuid: string;
    engagementGuid: string;
  }): Promise<void> => {
    const isInView = await this.isEngagementInView(engagement.engagementGuid);

    if (!isInView) {
      const match = await this.getAccountByGuid(engagement.accountGuid);

      if (match) {
        await this.setActiveAccount(
          match.account,
          true,
          match.executiveAccessOnly
        );
      } else {
        throw new EngagementAccessError();
      }
    }
  };

  deleteCustomView = async (view: ICustomViewSummary) => {
    const { user, activeView, recentViews: prevRecentViews } = this.state;
    if (user && activeView) {
      // remove from custom views
      const customViews = user.customViews.filter(c => c !== view);

      // remove from recent views
      const recentViews = storeRecentViews(
        user.profile.userGuid,
        prevRecentViews.filter(v => v.customViewGuid !== view.customViewGuid)
      );

      this.setState(
        {
          user: { ...user, customViews },
          recentViews
        },
        async () => {
          if (activeView.customViewGuid === view.customViewGuid) {
            const defaultView = await this.getDefaultView(user, recentViews);
            this.setActiveView(defaultView);
          }
        }
      );
    }
  };

  setUser = (newUser: IUserIdentity) => {
    this.setState({ user: newUser });
  };

  /**
   *
   * Feature Toggles
   *
   */

  // Feature is enabled if toggle is present in list.
  isFeatureToggled = (featureGuid: Feature) => {
    return (this.state.user?.featureToggles || []).some(
      f => f.featureGuid === featureGuid
    );
  };

  /**
   *
   * Render
   *
   */

  render() {
    const { children } = this.props;
    const {
      accountSwitcherStatus,
      status,
      user,
      executiveAccounts,
      activeView,
      recentViews
    } = this.state;

    const accountSwitcherLoaded = accountSwitcherStatus === FetchStatus.Success;
    const isIncludeAccountSwitcherLoading = accountSwitcherStatus !== null;
    const loaded = status === FetchStatus.Success;

    const screenLoading = isIncludeAccountSwitcherLoading
      ? status === FetchStatus.Loading ||
        accountSwitcherStatus === FetchStatus.Loading
      : status === FetchStatus.Loading;

    const screenReveail = isIncludeAccountSwitcherLoading
      ? loaded && accountSwitcherLoaded
      : loaded;

    return (
      <Screen loading={screenLoading} reveal={screenReveail}>
        {loaded && user && activeView && (
          <UserContext.Provider
            value={this.getContext(
              user,
              executiveAccounts,
              activeView,
              recentViews
            )}
          >
            {children}
          </UserContext.Provider>
        )}
      </Screen>
    );
  }
}

export default withTranslation()(UserProvider);
