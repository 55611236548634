import DSSFileUpload from 'components/DSSFileUpload/DSSFileUpload';
import Modal from 'components/Modal';
import UploadModalWarning from 'components/UploadModalWarning/UploadModalWarning';
import { DSSDocument, IDataRequest } from 'interfaces';
import { DSSManager } from 'utils/DSS';

import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button, ButtonGroup, Message } from '@ryan/components';

interface IDataRequestUploadModalProps extends WithTranslation {
  open: boolean;
  loading: Promise<any> | null;
  dataRequest: IDataRequest | null;
  onSubmit: (files: DSSDocument[]) => void;
  onCancel: () => void;
}

interface IDataRequestUploadModalState {
  message: React.ReactNode | null;
}

class DataRequestUploadModal extends PureComponent<
  IDataRequestUploadModalProps,
  IDataRequestUploadModalState
> {
  private dss = new DSSManager({ onChange: () => this.forceUpdate() });

  readonly state: IDataRequestUploadModalState = {
    message: null
  };

  /**
   * Cancel if we have not submitted.
   */
  tryCancel() {
    const { loading, onCancel } = this.props;

    if (loading === null) {
      this.reset();
      onCancel();
    }
  }

  /**
   * Reset any errors we have in our state.
   */
  reset() {
    this.dss.clearUploads();
    this.setState({ message: null });
  }

  /**
   * User clicked "Done" button.
   */
  handleSubmit = async () => {
    const { t, onSubmit } = this.props;
    const documents = this.dss.getUploadedDocuments();

    // if no documents uploaded, we can safely close
    if (documents.length === 0) {
      this.tryCancel();
      return;
    }

    // remove any messages
    this.setState({ message: null });

    try {
      // onSubmit will make the request
      await onSubmit(documents);

      // onSubmit will close the modal on success
      // we just need to reset our state
      this.reset();
    } catch (error) {
      this.setState({
        message: (
          <Message title={t('serverError.title')} type="error">
            {t('serverError.content')}
          </Message>
        )
      });
    }
  };

  render() {
    const { t, open, loading, dataRequest } = this.props;

    if (open && dataRequest) {
      return (
        <UploadModalWarning dss={this.dss} onClose={() => this.tryCancel()}>
          {({ dss, warning, onEscape, onCancel }) => (
            <Modal
              className="data-request-upload-modal"
              onClose={onEscape}
              open
              title={t('Upload Files')}
            >
              {warning}
              {this.state.message}
              <DSSFileUpload
                dssManager={dss}
                multiple
                title={t('Select Files')}
              />
              <ButtonGroup>
                <Button
                  disabled={dss.getUploadedDocuments().length === 0}
                  loading={dss.getUploadingPromise() || loading}
                  onClick={this.handleSubmit}
                  text={t('Done')}
                  variant="primary"
                />
                <Button
                  disabled={(dss.getUploadingPromise() || loading) !== null}
                  onClick={onCancel}
                  text={t('Cancel')}
                  variant="secondary"
                />
              </ButtonGroup>
            </Modal>
          )}
        </UploadModalWarning>
      );
    }

    return null;
  }
}

export default withTranslation()(DataRequestUploadModal);
