import Datepicker from 'components/Datepicker';
import Modal from 'components/Modal';
import {
  IButtonLoadingState,
  IEngagement,
  IEngagementRequest,
  IModalProps
} from 'interfaces';
import ApiService from 'services/ApiService';
import { ProjectEditTypeEnums } from 'utils/enums/ProjectEditTypeEnums';
import {
  Formik,
  formikDatepickerProps,
  formikFieldProps,
  yup
} from 'utils/forms';

import { addDays, parseISO } from 'date-fns';
import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import { Button, ButtonGroup, Textarea, pushToast } from '@ryan/components';

import './ProjectDetailsModal.scss';

interface IProjectDetailsEditModalProps extends IModalProps, WithTranslation {
  engagement: IEngagement;
  firstMilestoneStartDate: Date | null;
  lastMilestoneEndDate: Date | undefined;
  onSave: (engagement: IEngagement) => void;
  editType: ProjectEditTypeEnums;
}

interface IProjectDetailsEditModalState extends IButtonLoadingState {
  initialValues: {
    projectDetails: string;
    startDate: Date | null;
    endDate: Date | null;
    statuteOfLimitations: Date | null;
  };
}

const maxLengthDetails = 500;

export class ProjectDetailsEditModal extends Component<
  IProjectDetailsEditModalProps,
  IProjectDetailsEditModalState
> {
  private schema: yup.ObjectSchema;

  constructor(props: IProjectDetailsEditModalProps) {
    super(props);

    const { engagement } = props;

    this.schema = yup.object().shape({});

    this.state = {
      loading: null,
      initialValues: {
        projectDetails: engagement.projectDetails || '',
        startDate: engagement.alternateBeginDate
          ? parseISO(engagement.alternateBeginDate)
          : null,
        endDate: engagement.projectedEndDate
          ? parseISO(engagement.projectedEndDate)
          : null,
        statuteOfLimitations: engagement.statuteOfLimitations
          ? parseISO(engagement.statuteOfLimitations)
          : null
      }
    };
  }

  componentDidUpdate(prevProps: IProjectDetailsEditModalProps) {
    const {
      projectDetails,
      alternateBeginDate,
      projectedEndDate,
      statuteOfLimitations
    } = this.props.engagement;

    const { editType, firstMilestoneStartDate, lastMilestoneEndDate, t } =
      this.props;

    // Choose which validation schema to use
    if (editType === 'general') {
      this.schema = yup.object({
        endDate: yup
          .date()
          .nullable()
          .required(t('projectDetails.modal.endDate.required'))
          .test(
            'is-projected-end-after-milestone-date',
            t(
              'milestones.milestoneContent.validations.dateBoundaries.milestoneEndDate'
            ),
            function (endDate: Date | null) {
              return !(
                endDate &&
                lastMilestoneEndDate &&
                lastMilestoneEndDate > endDate
              );
            }
          ),
        startDate: yup
          .date()
          .nullable()
          .required(t('projectDetails.modal.startDate.required'))
          .test(
            'is-kickoff-after-milestone-date',
            t(
              'milestones.milestoneContent.validations.dateBoundaries.milestoneDate'
            ),
            function (startDate: Date | null) {
              const startDay = new Date(startDate as Date).setHours(0, 0, 0, 0);
              const firstMilestoneStartDay = new Date(
                firstMilestoneStartDate as Date
              ).setHours(0, 0, 0, 0);
              return !(
                startDate !== null &&
                firstMilestoneStartDate != null &&
                firstMilestoneStartDay < startDay
              );
            }
          )
      });
    } else if (editType === 'highlights') {
      this.schema = yup.object().shape({
        projectDetails: yup.string().max(
          maxLengthDetails,
          t('projectDetails.modal.projectDetails.maxLength', {
            count: maxLengthDetails
          })
        )
      });
    }

    if (prevProps.engagement !== this.props.engagement) {
      this.setState({
        initialValues: {
          projectDetails: projectDetails || '',
          startDate: alternateBeginDate ? parseISO(alternateBeginDate) : null,
          endDate: projectedEndDate ? parseISO(projectedEndDate) : null,
          statuteOfLimitations: statuteOfLimitations
            ? parseISO(statuteOfLimitations)
            : null
        }
      });
    }
  }

  submitProjectDetailsModal = (values: IEngagementRequest) => {
    const { t, engagement } = this.props;

    const promise = ApiService.updateEngagement(
      engagement.engagementGuid,
      values
    )
      .then(response => {
        const projectName = response.data.engagementDisplayNameShort;

        pushToast({
          type: 'success',
          title: t('projectDetails.modal.successToast.title'),
          content: (
            <Trans i18nKey="projectDetails.modal.successToast.content">
              Project Details for the project <b>{{ projectName }}</b> have been
              successfully updated.
            </Trans>
          )
        });

        if (this.props.onClose) {
          this.setState({ loading: null });
          this.props.onClose();
        }

        if (this.props.onSave) {
          this.props.onSave(response.data);
        }
      })
      .catch(() => {
        pushToast({
          type: 'error',
          title: t('serverError.title'),
          content: t('serverError.content')
        });

        if (this.props.onClose) {
          this.props.onClose();
        }
      });

    this.setState({ loading: promise });
  };

  render() {
    const { t, open, onClose, engagement, editType } = this.props;
    const { initialValues, loading } = this.state;
    const isProjectTimeframeEditable =
      engagement.isProjectedEndDateEditable && engagement.isStartDateEditable;

    return (
      <Modal
        className="project-details-modal"
        onClose={onClose}
        open={open}
        title={
          (
            <Trans i18nKey={`projectDetails.modal.projectDetails.title`}>
              <span className="project-details-modal__title"></span>
              {{ engagementName: engagement.engagementDisplayNameShort }}
            </Trans>
          ) as unknown as string
        }
      >
        <Formik
          initialValues={initialValues}
          onSubmit={this.submitProjectDetailsModal}
          validationSchema={() => this.schema}
        >
          {formik => (
            <form onSubmit={formik.handleSubmit}>
              {editType === 'highlights' && (
                <Textarea
                  label={t('projectDetails.modal.projectDetails.label')}
                  maxLength={maxLengthDetails}
                  {...formikFieldProps('projectDetails', formik)}
                />
              )}
              {editType === 'general' && (
                <div className="project-details-modal__dates-container">
                  <div className="row">
                    <div className="col-lg-6">
                      <Datepicker
                        {...formikDatepickerProps('startDate', formik)}
                        disabled={!isProjectTimeframeEditable}
                        helperText={
                          !isProjectTimeframeEditable
                            ? t(
                                'projectDetails.modal.projectDetails.cannotEditStartDate'
                              )
                            : ''
                        }
                        label={t('projectDetails.modal.startDate.label')}
                        maxDate={
                          formik.values.endDate
                            ? addDays(formik.values.endDate, -1)
                            : undefined
                        }
                      />
                    </div>
                    <div className="col-lg-6">
                      <Datepicker
                        {...formikDatepickerProps('endDate', formik)}
                        disabled={!isProjectTimeframeEditable}
                        helperText={
                          !isProjectTimeframeEditable
                            ? t(
                                'projectDetails.modal.projectDetails.cannotEditEndDate'
                              )
                            : ''
                        }
                        label={t('projectDetails.modal.endDate.label')}
                        minDate={
                          formik.values.startDate
                            ? addDays(formik.values.startDate, 1)
                            : undefined
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <Datepicker
                        {...formikDatepickerProps(
                          'statuteOfLimitations',
                          formik
                        )}
                        disabled={
                          !engagement.isStatuteOfLimitationsDateEditable
                        }
                        helperText={
                          !engagement.isStatuteOfLimitationsDateEditable
                            ? t(
                                'projectDetails.modal.projectDetails.cannotStatuteDate'
                              )
                            : ''
                        }
                        label={t('projectDetails.modal.statuteOfLimitations')}
                      />
                    </div>
                  </div>
                </div>
              )}
              <ButtonGroup>
                <Button onClick={onClose} text={t('Cancel')} type="button" />
                <Button
                  disabled={!formik.dirty}
                  loading={loading}
                  text={t('Save')}
                  type="submit"
                  variant="primary"
                />
              </ButtonGroup>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default withTranslation()(ProjectDetailsEditModal);
