import React, { FunctionComponent, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button, ButtonGroup, Icon, Modal } from '@ryan/components';

import { useUser } from '../../contexts/UserContext';
import useUniqueId from '../../utils/useUniqueId';
import Dropdown from '../Dropdown/Dropdown';
import { FilterOptions, ProjectDataType, ProjectFilter } from './interfaces';
import AdditionalFilterSelect from './utils/AdditionalFilterSelect';
import FilterSelections from './utils/FilterSelections';

import './AdvancedFilterModal.scss';

export interface AdvancedFilterModalProps extends WithTranslation {
  availableFilters: FilterOptions;
  clearSelection: (filterGuid: string, dataType: ProjectDataType) => void;
  onApply: () => void;
  onClear: (dataType: ProjectDataType) => void;
  onClearAll: () => void;
  onClose: () => void;
  onDeselectAll: (filters: ProjectFilter[], dataType: ProjectDataType) => void;
  onFilterOptionSelect: (
    filter: ProjectFilter,
    dataType: ProjectDataType
  ) => void;
  open: boolean;
  selectedFilters: FilterOptions;
  toggleFilters: (filters: ProjectFilter[], dataType: ProjectDataType) => void;
}

const AdvancedFilterModal: FunctionComponent<AdvancedFilterModalProps> = ({
  availableFilters,
  clearSelection,
  onApply,
  onClear,
  onClearAll,
  onClose,
  onDeselectAll,
  onFilterOptionSelect,
  open,
  selectedFilters,
  t: getTextToDisplay,
  toggleFilters
}) => {
  const { user } = useUser();

  const id = useUniqueId();

  const [currentDataType, setCurrentDatatype] = useState<
    ProjectDataType | null | undefined
  >(null);

  const [addItemsSectionCollapsed, setAddItemsSectionCollapsed] =
    useState(false);
  const [recentlyClearedSelections, setRecentlyClearedSelections] =
    useState(false);
  const [isDropdownShown, setIsDropdownShown] = useState(false);

  const {
    accountHandlers,
    engagementManagers,
    engagementPrincipals,
    jurisdictions,
    practiceAreas
  } = selectedFilters;

  const totalFilters =
    accountHandlers.length +
    engagementManagers.length +
    engagementPrincipals.length +
    jurisdictions.length +
    practiceAreas.length;

  const submitButtonDisabled = totalFilters === 0 && !recentlyClearedSelections;

  const {
    profile: { userTypeId }
  } = user;

  const filterTypeOptions: { label: string; value: string }[] = [
    {
      label: getTextToDisplay('PT Account Handler'),
      value: ProjectDataType.AccountHandler
    },
    {
      label: getTextToDisplay('Engagement Manager'),
      value: ProjectDataType.EngagementManager
    },
    {
      label: getTextToDisplay('Engagement Principal'),
      value: ProjectDataType.EngagementPrincipal
    },
    {
      label: getTextToDisplay('Jurisdiction'),
      value: ProjectDataType.Jurisdiction
    },
    {
      label: getTextToDisplay('Practice Area'),
      value: ProjectDataType.PracticeArea
    }
  ];

  const closeHandler = () => {
    setRecentlyClearedSelections(false);
    setCurrentDatatype(null);
    onClose();
  };

  const applyHandler = () => {
    setRecentlyClearedSelections(false);
    setCurrentDatatype(null);
    onApply();
  };

  return (
    <Modal
      dismissible
      onClose={closeHandler}
      open={open}
      preventCloseOnClickOutside
      style={{ width: '550px' }}
      title={getTextToDisplay('Advanced Filter')}
    >
      <div className="total-selected-header">
        <h4 className="modal-header">
          {getTextToDisplay('advancedFilterModal.totalSelected', {
            numSelected: totalFilters
          })}
        </h4>
        <button
          id="clear-all"
          onClick={() => {
            setRecentlyClearedSelections(true);
            onClearAll();
          }}
        >
          {getTextToDisplay('activity.notifications.clearAll')}
        </button>
      </div>
      <FilterSelections
        clearSelection={(filter, dataType) => {
          clearSelection(filter, dataType);
          setRecentlyClearedSelections(true);
        }}
        onClear={dataType => {
          onClear(dataType);
          setRecentlyClearedSelections(true);
        }}
        selectedFilters={selectedFilters}
      />
      <div className="filter-selection-section">
        <div className="add-items">
          <h4 className="add-items-header">
            {getTextToDisplay('advancedFilterModal.addItems')}
          </h4>
          <button
            id="collapse-section"
            onClick={() =>
              setAddItemsSectionCollapsed(!addItemsSectionCollapsed)
            }
          >
            <Icon
              name={addItemsSectionCollapsed ? 'chevron-down' : 'chevron-up'}
            />
          </button>
        </div>
        {!addItemsSectionCollapsed && (
          <>
            <Dropdown
              dropdownOptions={filterTypeOptions.sort((a, b) =>
                a.label.localeCompare(b.label)
              )}
              id={id}
              label={getTextToDisplay('advancedFilterModal.chooseDataType')}
              onChange={type => {
                setCurrentDatatype(type as ProjectDataType);
                setIsDropdownShown(true);
              }}
              value={{
                label: currentDataType as string,
                value: currentDataType as string
              }}
            />
            {currentDataType && (
              <div className="filter-selection-section__additional-filters">
                <AdditionalFilterSelect
                  availableFilters={availableFilters}
                  dataTypeFilter={currentDataType}
                  isDropdownShown={isDropdownShown}
                  onDeselectAll={(filters, dataType) => {
                    onDeselectAll(filters, dataType);
                    setRecentlyClearedSelections(true);
                  }}
                  onFilterSelect={(filter, dataType) => {
                    onFilterOptionSelect(filter, dataType);
                    setRecentlyClearedSelections(true);
                  }}
                  onSelectAll={toggleFilters}
                  selectedFilters={selectedFilters}
                  setIsDropdownShown={setIsDropdownShown}
                />
              </div>
            )}
          </>
        )}
      </div>
      <ButtonGroup>
        <Button
          disabled={submitButtonDisabled}
          onClick={applyHandler}
          text={getTextToDisplay('customView.submit')}
          type="submit"
          variant="primary"
        />
        <Button onClick={closeHandler} text={getTextToDisplay('Cancel')} />
      </ButtonGroup>
    </Modal>
  );
};

export default withTranslation()(AdvancedFilterModal);
