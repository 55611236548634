import { useStateMounted } from 'hooks';

import React, {
  FocusEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Trans } from 'react-i18next';

import { Autocomplete, IAutocompleteProps } from '@ryan/components';

import { throttle } from './throttle';

import './AutocompleteAjax.scss';

export interface IAutocompleteAjaxProps<T>
  extends Omit<
    IAutocompleteProps<T>,
    | 'autoComplete'
    | 'onChange'
    | 'onOptionsClearRequested'
    | 'onOptionSelected'
    | 'onOptionsFetchRequested'
    | 'options'
    | 'optionsFor'
    | 'optionsForText'
    | 'shouldRenderSuggestions'
    | 'value'
  > {
  /**
   * The maximum number of options to display at once.
   *
   * @default 100
   */
  optionsSizeMax?: number;

  /**
   * The value of the field.
   */
  value: T | null;

  clearErrorCallback?: () => void;
  getOptionValue: (option: T) => string;
  onChange: (option: T | null) => void;
  onFetchOptions: (query: string) => Promise<T[]>;
}

const shouldRenderSuggestions = () => true;

/**
 * @todo could be moved to ryan-components
 */
const AutocompleteAjax = <T extends any>({
  clearErrorCallback,
  getOptionValue,
  label,
  loading: loadingProp,
  onBlur,
  onChange,
  onFetchOptions,
  onFocus,
  optionsSizeMax = 100,
  type = 'search',
  value,
  ...rest
}: IAutocompleteAjaxProps<T>): ReactElement<any, any> | null => {
  const [focus, setFocus] = useState(false);
  const [loading, setLoading] = useStateMounted(false);
  const [options, setOptions] = useStateMounted<T[]>([]);
  const [optionsFor, setOptionsFor] = useStateMounted<string | undefined>(
    undefined
  );
  const [query, setQuery] = useState(
    value === null ? '' : getOptionValue(value)
  );
  const responsePromise = useRef<Promise<any>>();

  // a subset of options to display to improve performance against large
  // datasets ie. accounts; this is the list that will be displayed regardless
  // of the number of actual results returned
  const optionsToDisplay = useMemo(
    () => options.slice(0, optionsSizeMax),
    [options, optionsSizeMax]
  );

  const handleOptionsFetchRequested = useMemo(
    () =>
      throttle(async (newQuery: string) => {
        clearErrorCallback && clearErrorCallback();
        setLoading(true);

        try {
          const request = (responsePromise.current = onFetchOptions(newQuery));
          const response = await request;

          if (responsePromise.current === request) {
            setOptions(response);
            setOptionsFor(newQuery);
          }
        } catch {
          // ...
        } finally {
          setLoading(false);
        }
      }),
    [onFetchOptions, setLoading, setOptions, setOptionsFor]
  );

  const handleOptionsClearRequested = useCallback(() => {
    setOptions([]);
    setOptionsFor(undefined);
  }, [setOptions, setOptionsFor]);

  const handleChange = useCallback(
    (_e: React.ChangeEvent, newQuery: string) => {
      setQuery(newQuery);

      // call onChange if clearing value
      if (!newQuery) {
        onChange(null);
      }
    },
    [onChange]
  );

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setFocus(true);
      onFocus?.(e);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      setFocus(false);
      onBlur?.(e);

      // if the user has changed the query...
      const valueQuery = value === null ? '' : getOptionValue(value);

      if (query !== valueQuery) {
        // this component is controlled, so we need to
        // set the query to match the value
        setQuery(valueQuery);

        // but, since the user changed the query,
        // we should trigger a clear
        onChange(null);

        if (valueQuery === '' && clearErrorCallback) {
          clearErrorCallback();
        }
      }
    },
    [getOptionValue, onBlur, onChange, query, value]
  );

  const optionsForText = useCallback(
    (newQuery: string) => (
      <Trans
        i18nKey={
          newQuery.length
            ? 'autocompleteNoResults'
            : 'autocompleteNoResultsEmptyQuery'
        }
      >
        <b />
        {{ query: newQuery }}
        {{ label }}
      </Trans>
    ),
    [label]
  );

  // update query on value prop update
  useEffect(() => {
    setQuery(value === null ? '' : getOptionValue(value));
  }, [getOptionValue, value]);

  return (
    <Autocomplete<T>
      autoComplete="off"
      getOptionValue={getOptionValue}
      label={label}
      loading={loading || loadingProp}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      onOptionsClearRequested={handleOptionsClearRequested}
      onOptionSelected={onChange}
      onOptionsFetchRequested={handleOptionsFetchRequested}
      options={optionsToDisplay}
      optionsFor={optionsFor}
      optionsForText={optionsForText}
      shouldRenderSuggestions={shouldRenderSuggestions}
      spellCheck="false"
      type={type}
      value={focus ? query : value ? getOptionValue(value) : ''}
      {...rest}
    />
  );
};

export default AutocompleteAjax;
