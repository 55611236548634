import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ryan/components';

import { withUser } from '../../contexts/UserContext';
import { Feature } from '../../interfaces';
import { TDateRange } from '../../types';
import { DataRequestTypeGuidEnum } from '../../utils/enums/DataRequestsEnums';
import { formatRecords } from '../../utils/formatRecords';
import Attachments from '../FileAttachments/Attachments';
import Paragraphs from '../Paragraphs';
import { IDataRequestFieldsProps } from './utils';

import './DataRequestFields.scss';

export const DataRequestFields: IDataRequestFieldsProps = ({
  activeView,
  dataRequest,
  handleDateRangesModalOpen,
  handleDocumentTypesModalOpen,
  handleJurisdictionsModalOpen,
  isFeatureToggled,
  permissionService
}) => {
  const { t: getTextToDisplay } = useTranslation();

  const isDocumentImagesType =
    dataRequest.dataRequestType?.dataRequestTypeGuid ===
    DataRequestTypeGuidEnum.DOCUMENT_IMAGES;
  const isERPType =
    dataRequest.dataRequestType?.dataRequestTypeGuid ===
    DataRequestTypeGuidEnum.ERP_DATA;
  const isDataRequestsUpdateVisible = isFeatureToggled(Feature.RWMDataRequests);

  return (
    <div className="data-request-content">
      <h4 className="ry-h4">{dataRequest.engagementDisplayNameShort}</h4>
      <h3 className="ry-h3">{dataRequest.title}</h3>
      {isDataRequestsUpdateVisible && dataRequest.dataRequestType?.name && (
        <h4 className="data-request-content__data-request-type">
          {dataRequest.dataRequestType?.name}
        </h4>
      )}
      {isDataRequestsUpdateVisible && isDocumentImagesType && (
        <>
          <div className="data-request-content__data-request-fields">
            {dataRequest.documentTypes && (
              <div className="data-request-content__data-request-fields__field">
                <div className="ry-label">
                  {getTextToDisplay('dataRequest.dataFields.documentTypes')}
                </div>
                <div className="data-request-content__data-request-fields__field__value-array">
                  {formatRecords(
                    dataRequest.documentTypes.map(
                      documentType => documentType.name
                    ),
                    handleDocumentTypesModalOpen
                  )}
                </div>
              </div>
            )}
            {dataRequest.requestType && (
              <div className="data-request-content__data-request-fields__field">
                <div className="ry-label">
                  {getTextToDisplay('dataRequest.dataFields.requestType')}
                </div>
                <div className="data-request-content__data-request-fields__field__value">
                  {dataRequest.requestType.name}
                </div>
              </div>
            )}
            {dataRequest.accessType && (
              <div className="data-request-content__data-request-fields__field">
                <div className="ry-label">
                  {getTextToDisplay('dataRequest.dataFields.accessType')}
                </div>
                <div className="data-request-content__data-request-fields__field__value">
                  {dataRequest.accessType.name}
                </div>
              </div>
            )}
            {dataRequest.remoteAccessPlatform && (
              <div className="data-request-content__data-request-fields__field">
                <div className="ry-label">
                  {getTextToDisplay(
                    'dataRequest.dataFields.remoteAccessPlatform'
                  )}
                </div>
                <div className="data-request-content__data-request-fields__field__value">
                  {dataRequest.remoteAccessPlatform.name}
                </div>
              </div>
            )}
            {dataRequest.virtualMachine !== null &&
              dataRequest.isRequireCodeAssistance && (
                <div className="data-request-content__data-request-fields__field">
                  <div className="ry-label">
                    {getTextToDisplay('dataRequest.dataFields.virtualMachine')}
                  </div>
                  <div className="data-request-content__data-request-fields__field__value">
                    {dataRequest.virtualMachine
                      ? getTextToDisplay('dataRequest.yes')
                      : getTextToDisplay('dataRequest.no')}
                  </div>
                </div>
              )}
          </div>
          <div className="data-request-content__data-request-fields">
            {dataRequest.imagingSystem && (
              <div className="data-request-content__data-request-fields__field">
                <div className="ry-label">
                  {getTextToDisplay('dataRequest.dataFields.imagingSystem')}
                </div>
                <div className="data-request-content__data-request-fields__field__value">
                  {dataRequest.imagingSystem.name}
                </div>
              </div>
            )}
            {dataRequest.expectedPullListVolume !== null &&
              dataRequest.isRequireCodeAssistance && (
                <div className="data-request-content__data-request-fields__field">
                  <div className="ry-label">
                    {getTextToDisplay(
                      'dataRequest.dataFields.expectedPullListVolume'
                    )}
                  </div>
                  <div className="data-request-content__data-request-fields__field__value">
                    {dataRequest.expectedPullListVolume}
                  </div>
                </div>
              )}
            {dataRequest.numberOfInvoicesToBePulled !== null &&
              dataRequest.isRequireCodeAssistance && (
                <div className="data-request-content__data-request-fields__field">
                  <div className="ry-label">
                    {getTextToDisplay(
                      'dataRequest.dataFields.numberOfInvoices'
                    )}
                  </div>
                  <div className="data-request-content__data-request-fields__field__value">
                    {dataRequest.numberOfInvoicesToBePulled}
                  </div>
                </div>
              )}
          </div>
        </>
      )}
      {isDataRequestsUpdateVisible && (
        <div className="data-request-content__data-request-fields">
          {isERPType && dataRequest.erpSystem && (
            <div className="data-request-content__data-request-fields__field">
              <div className="ry-label">
                {getTextToDisplay('dataRequest.dataFields.erpSystem')}
              </div>
              <div className="data-request-content__data-request-fields__field__value">
                {dataRequest.erpSystem.name}
              </div>
            </div>
          )}
          {isERPType && dataRequest.taxEngine && (
            <div className="data-request-content__data-request-fields__field">
              <div className="ry-label">
                {getTextToDisplay('dataRequest.dataFields.taxEngine')}
              </div>
              <div className="data-request-content__data-request-fields__field__value">
                {dataRequest.taxEngine.name}
              </div>
            </div>
          )}
          {!isDocumentImagesType && dataRequest.dateRange && (
            <div className="data-request-content__data-request-fields__field">
              <div className="ry-label">
                {getTextToDisplay('dataRequest.dataFields.dateRanges')}
              </div>
              <div className="data-request-content__data-request-fields__field__value-array">
                {formatRecords(
                  dataRequest.dateRange as TDateRange[],
                  handleDateRangesModalOpen
                )}
              </div>
            </div>
          )}
          {!dataRequest.isRequireCodeAssistance &&
            dataRequest.jurisdictions && (
              <div className="data-request-content__data-request-fields__field">
                <div className="ry-label">
                  {getTextToDisplay('dataRequest.dataFields.jurisdictions')}
                </div>
                <div className="data-request-content__data-request-fields__field__value-array">
                  {formatRecords(
                    dataRequest.jurisdictions.map(
                      jurisdiction => jurisdiction.name
                    ),
                    handleJurisdictionsModalOpen
                  )}
                </div>
              </div>
            )}
        </div>
      )}
      {dataRequest.description && (
        <>
          <label className="ry-label">{getTextToDisplay('Description')}</label>
          <Paragraphs text={dataRequest.description} />
        </>
      )}
      {dataRequest.dataSpecs && (
        <>
          <label className="ry-label">{getTextToDisplay('Data Specs')}</label>
          <Paragraphs text={dataRequest.dataSpecs} />
        </>
      )}
      {isDataRequestsUpdateVisible &&
        isDocumentImagesType &&
        dataRequest.descriptionOfIssue && (
          <>
            <label className="ry-label">
              {getTextToDisplay('dataRequest.dataFields.descriptionOfIssue')}
            </label>
            <Paragraphs text={dataRequest.descriptionOfIssue} />
          </>
        )}
      {isDataRequestsUpdateVisible && isERPType && dataRequest.fileOrigin && (
        <>
          <label className="ry-label">
            {getTextToDisplay('dataRequest.dataFields.fileOrigin')}
          </label>
          <Paragraphs text={dataRequest.fileOrigin} />
        </>
      )}
      {isDataRequestsUpdateVisible &&
        isERPType &&
        permissionService.isRyan() &&
        dataRequest.justification &&
        !dataRequest.isRequireCodeAssistance && (
          <>
            <label className="ry-label">
              <Icon name="user-profile-check" />
              {getTextToDisplay('dataRequest.dataFields.justification')}
            </label>
            <Paragraphs text={dataRequest.justification} />
          </>
        )}
      <Attachments
        attachments={dataRequest.attachments}
        disableDownload={
          dataRequest.isUserGhosted || activeView.isExecutiveView
        }
        engagementGuid={dataRequest.engagementGuid}
      />
    </div>
  );
};

export default withUser(DataRequestFields);
