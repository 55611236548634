import {
  ICustomViewCreateType,
  ICustomViewSummary,
  ICustomViewType
} from 'interfaces';
import history from 'services/history';
import { escapeRegexCharacters } from 'utils/escapeRegexCharacters';
import { stringReplaceJsx } from 'utils/stringReplaceJsx';

import classnames from 'classnames';
import React, { FunctionComponent, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button, DropdownMenu, Icon, Tooltip } from '@ryan/components';

import CustomViewDeleteModal from './CustomViewDeleteModal';

import './SwitcherCustomViews.scss';

interface Props {
  searchQuery: string;
  customViews: ICustomViewSummary[];
  selectedCustomView: ICustomViewSummary | null;
  onCustomViewSelected: (view: ICustomViewSummary) => void;
  onCustomViewDeleted: (view: ICustomViewSummary) => void;
  onCloseSwitcher: () => void;
}

export const SwitcherCustomViews: FunctionComponent<Props> = ({
  searchQuery,
  customViews,
  selectedCustomView,
  onCustomViewSelected,
  onCustomViewDeleted,
  onCloseSwitcher
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  const savedCustomViews = useMemo(
    () =>
      customViews.filter(
        view => view.createType === ICustomViewCreateType.CustomSaved
      ),
    [customViews]
  );

  const filteredCustomViews = useMemo(() => {
    const re = new RegExp(escapeRegexCharacters(searchQuery), 'i');
    return savedCustomViews.filter(view => re.test(view.name));
  }, [searchQuery, savedCustomViews]);

  const [customViewToDelete, setCustomViewToDelete] =
    useState<ICustomViewSummary | null>();

  function onDeleteCustomView(view: ICustomViewSummary) {
    setCustomViewToDelete(view);
  }

  function onCloseCustomViewDelete(wasDeleted = false) {
    if (customViewToDelete && wasDeleted) {
      onCustomViewDeleted(customViewToDelete);
    }

    setCustomViewToDelete(null);
  }

  return (
    <div className="switcher-custom-views">
      <table>
        <tbody>
          {filteredCustomViews.map(view => {
            const isSelected =
              view.customViewGuid === selectedCustomView?.customViewGuid;
            const isDynamic = view.type === ICustomViewType.Dynamic;
            const isExecutive = view.isExecutiveView;

            return (
              <tr
                className={classnames({
                  'switcher-custom-views__view': true,
                  'switcher-custom-views__view--selected': isSelected
                })}
                key={view.customViewGuid}
              >
                <td className="switcher-custom-views__view-name">
                  <button
                    onClick={() => onCustomViewSelected(view)}
                    type="button"
                  >
                    {isSelected && (
                      <Icon
                        className="switcher-custom-views__view-name-check"
                        name="success-filled"
                      />
                    )}
                    {stringReplaceJsx(view.name, searchQuery, (match, i) => (
                      <b key={i}>{match}</b>
                    ))}
                    {isExecutive && (
                      <Tooltip
                        content={t('ExecutiveCustomViewToolTip')}
                        placement="top"
                        renderTarget={({ open, ...props }) => (
                          <div
                            aria-expanded={open}
                            aria-haspopup="true"
                            {...props}
                          >
                            <Icon
                              className="switcher-custom-views__view-name-lock"
                              name="lock"
                            />
                          </div>
                        )}
                      />
                    )}
                  </button>
                </td>
                <td className="switcher-custom-views__view-count">
                  {view.projectCount}
                </td>
                <td className="switcher-custom-views__view-type">
                  <Tooltip
                    content={
                      isDynamic
                        ? t('customView.viewType.dynamic.description')
                        : t('customView.viewType.static.description')
                    }
                    placement="top"
                    renderTarget={({ open, ...props }) => (
                      <button
                        aria-expanded={open}
                        aria-haspopup="true"
                        type="button"
                        {...props}
                      >
                        <Icon name={isDynamic ? 'venn-diagram' : 'picklist'} />
                      </button>
                    )}
                  />
                </td>
                <td className="switcher-custom-views__view-actions">
                  <DropdownMenu
                    className="switcher-custom-views__dropdown"
                    options={[
                      {
                        label: t('Edit'),
                        icon: 'pencil',
                        onClick: () => {
                          onCloseSwitcher();
                          history.push({
                            pathname: `/app/custom-view/edit/${view.customViewGuid}`,
                            state: { redirectTo: location.pathname }
                          });
                        }
                      },
                      {
                        label: t('Delete'),
                        icon: 'trash',
                        negative: true,
                        onClick: () => onDeleteCustomView(view)
                      }
                    ]}
                    placement="top"
                    renderTarget={({ ref, open, onClick }) => (
                      <button
                        aria-expanded={open}
                        aria-haspopup="menu"
                        onClick={onClick}
                        ref={ref}
                      >
                        <Icon name="more-outline" />
                      </button>
                    )}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="switcher-custom-views__footer">
        {/**
         * <div> is always rendered so that button is shown right
         */}
        <div>
          {savedCustomViews.length === 0 && <i>{t('customView.empty')}</i>}
        </div>
        <Button
          icon="plus"
          onClick={() => {
            onCloseSwitcher();
            history.push({
              pathname: '/app/custom-view',
              state: { redirectTo: location.pathname }
            });
          }}
          size="sm"
          text={t('customView.title')}
          variant="text"
        />
      </div>

      {customViewToDelete &&
        ReactDOM.createPortal(
          <CustomViewDeleteModal
            customView={customViewToDelete}
            onClose={onCloseCustomViewDelete}
            open={Boolean(customViewToDelete)}
          />,
          document.body
        )}
    </div>
  );
};
