import { IPermission, IRole, IRolePermission, UserType } from 'interfaces';
import ApiService from 'services/ApiService';
import {
  FormikProps,
  WithFormikConfig,
  formikFieldProps,
  withFormik,
  yup
} from 'utils/forms';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { ChangeEvent, Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import { Button, Dropdown, TextInput, pushToast } from '@ryan/components';

import RolePermissions from './RolePermissions';
import RolePermissionsLegend from './RolePermissionsLegend';

import './CreateRole.scss';

interface ICreateRoleValues {
  name: string;
  userTypes: string;
  permissions: IRolePermission[];
}

/**
 * Inner Form
 */

function filterPermissionsForUserTypes(
  permissions: IPermission[],
  userTypes: number
) {
  return permissions
    .filter(p => p.userTypes & userTypes)
    .map(p => ({
      ...p,
      isGranted: false,
      isLocked: false
    }));
}

interface IRoleInnerFormProps
  extends ICreateRoleProps,
    FormikProps<ICreateRoleValues> {
  // ...
}

interface IRoleInnerFormState {
  loading: boolean;
  allPermissions: IPermission[] | null;
}

class RoleInnerForm extends Component<
  IRoleInnerFormProps,
  IRoleInnerFormState
> {
  readonly state: IRoleInnerFormState = {
    loading: true,
    allPermissions: null
  };

  componentDidMount() {
    this.fetchPermissions();
  }

  async fetchPermissions() {
    const { values, setFieldValue } = this.props;
    this.setState({ loading: true });
    try {
      const response = await ApiService.getPermissions();
      const allPermissions = response.data;

      this.setState({ allPermissions });

      setFieldValue(
        'permissions',
        filterPermissionsForUserTypes(
          allPermissions,
          parseFloat(values.userTypes)
        )
      );
    } catch {
      pushServerErrorToast();
    }
    this.setState({ loading: false });
  }

  getUserTypeOptions() {
    const { t } = this.props;
    return [
      {
        label: t('manageRoles.selectUserType'),
        value: '',
        disabled: true
      },
      ...[
        UserType.Ryan,
        UserType.Client,
        UserType.ClientRepresentativeAppraiser,
        UserType.ClientRepresentativeAttorney,
        UserType.TaxAgencyRepresentative
      ].map(userTypes => ({
        label: t(`userTypes.${userTypes}`),
        value: `${userTypes}`
      }))
    ];
  }

  handleUserTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { values, setValues } = this.props;
    const { allPermissions } = this.state;
    const userTypes = e.target.value;
    if (allPermissions) {
      setValues({
        ...values,
        userTypes,
        permissions: filterPermissionsForUserTypes(
          allPermissions,
          parseFloat(userTypes)
        )
      });
    }
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push('/app/roles');
  };

  render() {
    const { t, values, setFieldValue, handleSubmit } = this.props;
    const { loading } = this.state;
    return (
      <div className="create-role">
        <h3 className="ry-h3">{t('manageRoles.create.title')}</h3>
        <p>{t('manageRoles.create.description')}</p>

        <form autoComplete="off" onSubmit={handleSubmit}>
          <TextInput
            {...formikFieldProps('name', this.props)}
            label={t('manageRoles.create.nameLabel')}
          />
          <Dropdown
            {...formikFieldProps('userTypes', this.props)}
            disabled={loading}
            label={t('manageRoles.create.userTypesLabel')}
            onChange={this.handleUserTypeChange}
            options={this.getUserTypeOptions()}
          />
          <div className="row">
            <div className="col-md-8">
              <RolePermissions
                loading={loading}
                onChange={permissions =>
                  setFieldValue('permissions', permissions)
                }
                permissions={values.permissions}
              />
              {values.permissions.length === 0 && (
                <div className="create-role__na">
                  {t('manageRoles.create.noPermissionsApplicable')}
                </div>
              )}
            </div>
            <div className="col-md-4">
              <RolePermissionsLegend t={t} />
            </div>
          </div>

          <div className="create-role__actions">
            <Button onClick={this.handleCancel} size="lg" text={t('Cancel')} />
            <Button
              size="lg"
              text={t('manageRoles.create.confirm')}
              type="submit"
              variant="primary"
            />
          </div>
        </form>
      </div>
    );
  }
}

/**
 * Formik Wrapper
 */

interface ICreateRoleProps extends WithTranslation, RouteComponentProps {
  onRoleCreated: (role: IRole) => void;
}

const config: WithFormikConfig<ICreateRoleProps, ICreateRoleValues> = {
  mapPropsToValues: () => ({
    name: '',
    userTypes: '1',
    permissions: []
  }),

  validationSchema: ({ t }: ICreateRoleProps) =>
    yup.object({
      name: yup.string().required(t('manageRoles.create.nameRequired')),
      userTypes: yup
        .string()
        .required(t('manageRoles.create.userTypesRequired')),
      permissions: yup.array(yup.object())
    }),

  handleSubmit: async (values, { props }) => {
    const { t, history, onRoleCreated } = props;
    try {
      const response = await ApiService.createRole({
        name: values.name,
        userTypes: parseFloat(values.userTypes),
        permissions: values.permissions
      });

      const role = response.data;

      onRoleCreated(role);

      pushToast({
        type: 'success',
        title: t('manageRoles.create.successToastTitle'),
        content: t('manageRoles.create.successToastContent', {
          role: role.roleName
        })
      });

      history.push('/app/roles');
    } catch {
      pushServerErrorToast();
    }
  }
};

export default withTranslation()(
  withFormik<ICreateRoleProps, ICreateRoleValues>(config)(RoleInnerForm)
);
