import { getDateRange, setDateRange } from './dateRange.localStorage';
import Datepicker from 'components/Datepicker';
import { WithUser, withUser } from 'contexts/UserContext';
import {
  IEngagement,
  ISavingsSummaryRollup,
  ISummaryPerCurrency,
  Permission
} from 'interfaces';
import AmplitudeApiService from 'services/AmplitudeApiService';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import { formatDate } from 'utils/formatDate';
import { formikDatepickerProps, formikFieldProps } from 'utils/forms';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import { AxiosResponse } from 'axios';
import { isSameDay } from 'date-fns';
import { FormikProps } from 'formik';
import React, { Component } from 'react';
import { TFunction, WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button, Icon, Popover } from '@ryan/components';

import UnsavedChangesContext from '../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import {
  AmplitudeActionType,
  getAmplitudeLocation
} from '../../utils/amplitudeUtils/amplitudeUtils';
import Empty from '../Empty';
import { RadioButton, RadioButtonGroup } from '../RadioButton';
import CurrencyCarousel from './CurrencyCarousel/CurrencyCarousel';
import ISavingsSummaryCardFilterValues from './ISavingsSummaryCardFilterValues';
import SavingsFilterType from './SavingsFilterType';
import SavingsSummaryDataViz from './SavingsSummaryDataViz';
import getDefaultCurrency, {
  orderCurrenciesForDisplay
} from './getDefaultCurrency';

import './SavingsSummaryCardContent.scss';

function isLastEntryToday(engagement: IEngagement) {
  return (
    engagement.lastSavingsSummaryAsOfDate !== null &&
    isSameDay(new Date(), new Date(engagement.lastSavingsSummaryAsOfDate))
  );
}

interface ISavingsSummaryCardContentProps
  extends WithTranslation,
    WithUser,
    RouteComponentProps {
  engagement?: IEngagement;
  customViewGuid?: string;
  formik: FormikProps<ISavingsSummaryCardFilterValues>;
  onSavingsEmpty: () => void;
}

interface ISavingsSummaryCardContentState {
  // Whether we're currently loading
  loading: boolean;

  // Results of the first call
  totals: ISummaryPerCurrency[] | null;
  totalsEmpty: boolean;

  // Results currently shown
  activeResults: {
    filterType: SavingsFilterType;
    startDate: Date | null;
    endDate: Date | null;
    filteredTotals: ISummaryPerCurrency[];
    activeCurrency: string | null;
  } | null;
}

class SavingsSummaryCardContent extends Component<
  ISavingsSummaryCardContentProps,
  ISavingsSummaryCardContentState
> {
  private _isMounted = false;
  private getEngagementSavingsSummaryRollupCancelToken?: CancelTokenSource;
  private getCustomViewSavingsSummaryRollupCancelToken?: CancelTokenSource;

  readonly state: ISavingsSummaryCardContentState = {
    loading: true,
    totals: null,
    totalsEmpty: false,
    activeResults: null
  };

  static contextType = UnsavedChangesContext;
  context!: React.ContextType<typeof UnsavedChangesContext>;

  componentDidMount() {
    this._isMounted = true;

    this.fetchTotals();

    // formik@2.x.x has initialTouched as an alternative to this
    this.props.formik.setTouched({
      startDate: true,
      endDate: true
    });
  }

  async componentDidUpdate(prevProps: ISavingsSummaryCardContentProps) {
    const { customViewGuid, engagement } = this.props;
    const { values } = this.props.formik;

    // If the entity's guid has changed, we need to reset and fetch grand totals.
    if (
      prevProps.customViewGuid !== customViewGuid ||
      prevProps.engagement !== engagement
    ) {
      this.fetchTotals();
      return;
    }
    // If dates changed, cache them.
    const prevStartDate = prevProps.formik.values.startDate;
    const prevEndDate = prevProps.formik.values.endDate;
    const prevFilterType = prevProps.formik.values.filterType;
    const startDate = this.props.formik.values.startDate;
    const endDate = this.props.formik.values.endDate;
    const filterType = this.props.formik.values.filterType;

    if (
      prevStartDate !== startDate ||
      prevEndDate !== endDate ||
      prevFilterType !== filterType
    ) {
      setDateRange(startDate, endDate, filterType);
    }

    // If the values are updated, we need to wait on validation.
    // If no errors, we can call API.
    if (prevProps.formik.values !== values) {
      // We should be able to use formik.isValidating, but it's been turned off.
      // https://github.com/jaredpalmer/formik/issues/1624
      // This timeout flushes errors.
      setTimeout(() => {
        // Important that errors are referenced here, inside the setTimeout!
        // Do not move to top of function!
        const { errors } = this.props.formik;
        if (Object.keys(errors).length === 0) {
          this.fetchFilteredTotals();
        }
      }, 0);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.getCustomViewSavingsSummaryRollupCancelToken?.cancel();
    this.getEngagementSavingsSummaryRollupCancelToken?.cancel();
  }

  /**
   * The first call captures the sum of all entries as of today.
   * We cache this first result so that we know if this card has no data,
   * and how many currencies in total (in the case of account level card).
   */
  async fetchTotals() {
    const { i18n } = this.props;
    const { activeResults: prevActiveResults } = this.state;
    const [cachedStartDate, cachedEndDate, cachedFilterType] = getDateRange();
    const startDate =
      cachedFilterType === SavingsFilterType.Range ? cachedStartDate : null;
    const endDate = cachedEndDate ?? new Date();

    const response = await this.fetch(startDate, endDate);

    if (response) {
      // Since we are querying for all time, this lets us know if
      // this entity (account or engagement) has ANY savings.
      const totalsEmpty =
        response.status === 204 ||
        response.data.summariesPerCurrency.every(
          currency => currency.totals.summary === 0
        );

      // Cache these totals. We use these to fill in the blanks.
      const totals = totalsEmpty ? null : response.data.summariesPerCurrency;

      // reorder filtered totals for display
      const filteredTotals = totals
        ? orderCurrenciesForDisplay(totals, i18n.language)
        : [];

      if (this._isMounted) {
        this.setState({
          totals,
          totalsEmpty,
          activeResults: totalsEmpty
            ? null
            : {
                filterType:
                  cachedFilterType === SavingsFilterType.AsOf
                    ? SavingsFilterType.AsOf
                    : SavingsFilterType.Range,
                startDate,
                endDate,
                filteredTotals,
                activeCurrency: getDefaultCurrency(
                  totals,
                  prevActiveResults?.activeCurrency
                )
              }
        });
      }

      // Callback to hide this card if empty and client user.
      if (totalsEmpty) {
        this.props.onSavingsEmpty();
      }
    }
  }

  async fetchFilteredTotals() {
    const { formik, i18n } = this.props;
    const { values } = formik;
    const {
      activeResults: prevActiveResults,
      totals,
      totalsEmpty
    } = this.state;

    const startDate =
      values.filterType === SavingsFilterType.Range ? values.startDate : null;
    const { endDate } = values;

    if (!totalsEmpty && totals && endDate) {
      const response = await this.fetch(startDate, endDate);
      if (response) {
        const filteredTotals = orderCurrenciesForDisplay(
          totals.map(et => {
            if (response.status !== 204) {
              const match = response.data.summariesPerCurrency.find(
                rt => rt.currencyCode === et.currencyCode
              );
              if (match) {
                return match;
              }
            }

            return {
              currencyCode: et.currencyCode,
              totals: {
                approved: 0,
                potential: 0,
                received: 0,
                submitted: 0,
                summary: 0
              }
            };
          }),
          i18n.language
        );

        this.setState({
          activeResults: {
            filterType: values.filterType,
            startDate,
            endDate,
            filteredTotals,
            activeCurrency: getDefaultCurrency(
              filteredTotals,
              prevActiveResults?.activeCurrency
            )
          }
        });
      }
    }
  }

  /**
   * Used by fetchTotals and fetchFilteredTotals.
   */
  async fetch(startDate: Date | null, endDate: Date | null) {
    const { engagement, customViewGuid } = this.props;

    let request: Promise<AxiosResponse<ISavingsSummaryRollup>> | undefined;

    if (engagement) {
      // refresh cancel tokens
      this.getEngagementSavingsSummaryRollupCancelToken?.cancel();
      this.getEngagementSavingsSummaryRollupCancelToken =
        ApiService.CancelToken.source();

      request = ApiService.getEngagementSavingsSummaryRollup(
        engagement.engagementGuid,
        startDate || undefined,
        endDate || undefined,
        this.getEngagementSavingsSummaryRollupCancelToken.token
      );
    } else if (customViewGuid) {
      // refresh cancel tokens
      this.getCustomViewSavingsSummaryRollupCancelToken?.cancel();
      this.getCustomViewSavingsSummaryRollupCancelToken =
        ApiService.CancelToken.source();

      request = ApiService.getCustomViewSavingsSummaryRollup(
        customViewGuid,
        startDate || undefined,
        endDate || undefined,
        this.getCustomViewSavingsSummaryRollupCancelToken.token
      );
    }

    if (!request) {
      throw new Error(
        'SavingsSummaryCardContent must be given either `customViewGuid` or `engagementGuid`.'
      );
    }

    this.setState({ loading: true });

    try {
      const response = await request;
      return response;
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushServerErrorToast();
      }
    } finally {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }
  }

  handleViewHistory = () => {
    const { engagement, history } = this.props;
    const { isUnsavedChanges, setBlockNavigation, setTargetUrl } = this.context;

    if (isUnsavedChanges) {
      setBlockNavigation(true);
      setTargetUrl(
        engagement
          ? `/app/project/${engagement.engagementGuid}/savings-history`
          : history.location.pathname
      );
    } else if (engagement) {
      history.push(`/app/project/${engagement.engagementGuid}/savings-history`);
    }
  };

  handleViewProjectSavings = () => {
    const { customViewGuid, history } = this.props;
    const location = getAmplitudeLocation();
    if (customViewGuid) {
      history.push(`/app/projects/savings-summary`);
    }
    if (location === 'dashboard') {
      AmplitudeApiService.logEvent(
        `${AmplitudeActionType.CLICK}-view-project-savings-${location}`
      );
    }
  };

  handleUpdateSavings = () => {
    const { engagement, history } = this.props;
    const { isUnsavedChanges, setBlockNavigation, setTargetUrl } = this.context;

    if (isUnsavedChanges) {
      setBlockNavigation(true);
      setTargetUrl(
        engagement
          ? `/app/project/${engagement.engagementGuid}/savings-summary-entry/${
              isLastEntryToday(engagement) ? 'update' : 'create'
            }`
          : history.location.pathname
      );
    } else if (engagement) {
      history.push(
        `/app/project/${engagement.engagementGuid}/savings-summary-entry/${
          isLastEntryToday(engagement) ? 'update' : 'create'
        }`
      );
    }
  };

  handleSelectedCurrency = (activeCurrency: string) => {
    this.setState(({ activeResults }) =>
      activeResults
        ? { activeResults: { ...activeResults, activeCurrency } }
        : null
    );
  };

  getActiveSummary(currencySummaries: ISummaryPerCurrency[]) {
    const { customViewGuid } = this.props;
    const { activeResults } = this.state;
    if (customViewGuid && activeResults && activeResults.activeCurrency) {
      const activeSummary = currencySummaries.find(
        summary => summary.currencyCode === activeResults.activeCurrency
      );

      if (activeSummary) {
        return activeSummary;
      }
    }

    return currencySummaries[0];
  }

  renderDateFilter(
    formikHandle: FormikProps<ISavingsSummaryCardFilterValues>,
    getTextToDisplayCallback: TFunction
  ) {
    const ROOT_TO_TEXT = 'savingsSummaryCard';

    const isDateRangeFilter =
      formikHandle.values.filterType === SavingsFilterType.Range;

    const datePickerAmplitudeProps = {
      amplitudeAdditionalSelector: 'savings-summary',
      amplitudeEventDetails: {
        'timeframe-type': getTextToDisplayCallback(
          `${ROOT_TO_TEXT}.${isDateRangeFilter ? 'dateRange' : 'totalAsOf'}`
        )
      },
      isAmplitudeTracking: true
    };

    return (
      <div className="savings-summary-card-content__filter">
        <RadioButtonGroup {...formikFieldProps('filterType', formikHandle)}>
          <RadioButton
            label={getTextToDisplayCallback(`${ROOT_TO_TEXT}.totalAsOf`)}
            value={SavingsFilterType.AsOf}
          />
          <RadioButton
            label={getTextToDisplayCallback(`${ROOT_TO_TEXT}.dateRange`)}
            value={SavingsFilterType.Range}
          />
        </RadioButtonGroup>

        <div className="savings-summary-card-content__filter--fields row">
          {isDateRangeFilter && (
            <div className="col-md-6">
              <Datepicker
                {...datePickerAmplitudeProps}
                {...formikDatepickerProps('startDate', formikHandle)}
                label={getTextToDisplayCallback(`${ROOT_TO_TEXT}.startDate`)}
              />
            </div>
          )}
          <div className="col-md-6">
            <Datepicker
              {...datePickerAmplitudeProps}
              {...formikDatepickerProps('endDate', formikHandle)}
              label={getTextToDisplayCallback(
                `${ROOT_TO_TEXT}.${isDateRangeFilter ? 'endDate' : 'asOf'}`
              )}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      t,
      isAppReadOnly,
      permissionService: ps,
      formik,
      customViewGuid,
      engagement
    } = this.props;

    const { loading, totalsEmpty, activeResults } = this.state;
    const canEdit = ps.hasPermission(Permission.SavingsSummaryEdit);
    const hasErrors = Object.keys(formik.errors).length !== 0;
    const isReadOnly = !!engagement?.isReadOnly || isAppReadOnly;

    return (
      <div className="savings-summary-card-content">
        {/* Date Filter */}
        {!totalsEmpty && (
          <Popover
            className="savings-summary-card-content__popover"
            placement="left"
            popperModifiers={[
              {
                name: 'preventOverflow',
                options: {
                  altAxis: true
                }
              }
            ]}
            renderContent={() => this.renderDateFilter(formik, t)}
            renderTarget={({ ref, onClick }) => (
              <Button
                className="savings-summary-card-content__popover-target"
                icon="calendar-time"
                innerRef={ref}
                onClick={onClick}
                size="lg"
                variant="text"
              />
            )}
          />
        )}

        {/* Loading */}
        {loading && (
          <div className="savings-summary-card-content__loading">
            <Icon className="loading-spin" name="loading" />
          </div>
        )}

        {!loading && (
          <>
            {/* Subtitle */}
            {activeResults && (
              <p className="savings-summary-card-content__date-range">
                {activeResults.filterType === SavingsFilterType.AsOf &&
                  activeResults.endDate &&
                  t('savingsSummaryWidget.totalAsOf', {
                    date: formatDate(activeResults.endDate)
                  })}

                {activeResults.filterType === SavingsFilterType.Range &&
                  activeResults.startDate &&
                  activeResults.endDate &&
                  t('savingsSummaryWidget.duringWithDates', {
                    startDate: formatDate(activeResults.startDate),
                    endDate: formatDate(activeResults.endDate)
                  })}
              </p>
            )}

            {/* Error State */}
            {hasErrors && (
              <div className="savings-summary-card-content__error-state">
                <Icon name="calendar" />
                <p>{t('savingsSummaryWidget.error')}</p>
              </div>
            )}

            {/* Empty */}
            {!hasErrors && totalsEmpty && (
              <Empty icon="calculator">{t('savingsSummaryWidget.empty')}</Empty>
            )}

            {!hasErrors && !totalsEmpty && activeResults && (
              <SavingsSummaryDataViz
                filterType={activeResults.filterType}
                savingsSummary={this.getActiveSummary(
                  activeResults.filteredTotals
                )}
                t={t}
              />
            )}
          </>
        )}

        {activeResults && activeResults.filteredTotals.length > 1 && (
          <CurrencyCarousel
            currencySummaries={activeResults.filteredTotals}
            onCurrencyChange={index => {
              this.handleSelectedCurrency(
                activeResults.filteredTotals[index].currencyCode
              );
            }}
          />
        )}

        {/* Buttons */}
        <div className="savings-summary-card-content__buttons">
          {/* Engagement buttons */}
          {engagement && (
            <>
              {/**
               * If Engagement has no savings data, but the user has permission to
               * create savings entries, then show them a button (on small breakpoints)
               * to create the first entry.
               */}
              {totalsEmpty && canEdit && (
                <Button
                  block
                  // Cannot create savings on small breakpoints.
                  className="d-none d-lg-block"
                  disabled={isReadOnly}
                  onClick={this.handleUpdateSavings}
                  text={t('Add Savings Summary')}
                  variant="primary"
                />
              )}

              {/**
               * If Engagement has savings data, they can view Savings History.
               * Also, if the user has permission to create savings entries,
               * then show them a button (on small breakpoints) to create a new entry.
               */}
              {!totalsEmpty && (
                <>
                  <Button
                    block
                    onClick={this.handleViewHistory}
                    text={t('savingsSummaryWidget.buttons.viewHistory')}
                    variant="primary"
                  />
                  {canEdit && (
                    <Button
                      // Cannot create savings on small breakpoints.
                      block
                      className="d-none d-lg-block"
                      disabled={isReadOnly}
                      onClick={this.handleUpdateSavings}
                      text={
                        isLastEntryToday(engagement)
                          ? t('savings.editTodaysEntry')
                          : t('Update')
                      }
                    />
                  )}
                </>
              )}
            </>
          )}

          {/* Custom View buttons */}
          {customViewGuid && (
            <Button
              block
              onClick={this.handleViewProjectSavings}
              text={t('savingsSummaryWidget.buttons.viewProjectSavings')}
              variant="primary"
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(
  withUser(withRouter(SavingsSummaryCardContent))
);
