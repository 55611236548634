import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Button, Icon } from '@ryan/components';

import { useAppReadOnly } from '../../contexts/UserContext';
import { IEngagement } from '../../interfaces';
import ApiService from '../../services/ApiService';
import {
  IRenderEngagementsMap,
  IUserDetailsProps,
  TMappedEngagement
} from './utils';

import './UserDetails.scss';

const UserDetails: React.FunctionComponent<IUserDetailsProps> = ({
  customViewGuid,
  onViewAll,
  userData
}) => {
  const isAppReadOnly = useAppReadOnly();

  const { t: getTextToDisplay } = useTranslation();
  const [engagementData, setEngagementData] = useState<
    TMappedEngagement[] | null
  >(null);

  const fetchEngagements = useCallback(
    async (guids: { customViewGuid?: string; userMemberGuid: string }) => {
      try {
        const response = await ApiService.getEngagementsForUser(
          guids.userMemberGuid,
          {
            customViewGuid: guids.customViewGuid,
            itemsPerPage: 5,
            pageNumber: 1,
            sort: 'engagementDisplayNameShort'
          }
        );

        const mappedEngagements = response.data.results.map(
          ({ engagementGuid, engagementDisplayNameLong }: IEngagement) => ({
            engagementDisplayNameLong,
            engagementGuid
          })
        );

        setEngagementData(mappedEngagements);
      } catch {}
    },
    []
  );

  useEffect(() => {
    fetchEngagements({ customViewGuid, userMemberGuid: userData.memberGuid });
  }, [customViewGuid, fetchEngagements, userData.memberGuid]);

  const renderEngagementsMap: IRenderEngagementsMap = engagementsToMap => (
    <ul>
      {engagementsToMap.map(engagement => (
        <li key={engagement.engagementGuid}>
          <NavLink
            className="ry-link"
            to={`/app/project/${engagement.engagementGuid}`}
          >
            {engagement.engagementDisplayNameLong}
          </NavLink>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="user-details row">
      <div className="col-6">
        <b>
          {getTextToDisplay(
            'contactInformation.sharedProjects',
            isAppReadOnly ? { context: 'readonly' } : {}
          )}
        </b>

        {!engagementData &&
          Array.from({ length: 5 }, (_, index) => (
            <div className="ry-skeleton" key={index} />
          ))}

        {engagementData &&
          engagementData.length > 0 &&
          renderEngagementsMap(engagementData)}

        <Button onClick={onViewAll} size="sm" variant="text">
          {getTextToDisplay('contactInformation.viewAll')}
        </Button>
      </div>
      <div className="col-6">
        <b>{getTextToDisplay('contactInformation.information')}</b>
        <ul>
          <li>
            <a className="ry-link" href={`mailto:${userData.email}`}>
              <Icon name="mail" />
              {userData.email}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserDetails;
