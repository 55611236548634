import React, { FunctionComponent } from 'react';

/**
 * Wraps text in a paragraph tag.
 * Useful for user-generated content. Preserves newlines.
 * Also gets overflow-wrap from global.
 */
const Paragraphs: FunctionComponent<{ text: string | null }> = ({
  text,
  ...rest
}) =>
  text !== null ? (
    <p style={{ whiteSpace: 'pre-line' }} {...rest}>
      {text.replace(/(^\s*|\s*$)/g, '')}
    </p>
  ) : null;

export default Paragraphs;
