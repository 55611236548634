import { Formik } from 'formik';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  Checkbox,
  Modal,
  pushToast
} from '@ryan/components';

import { UserType } from '../../../interfaces';
import ApiService from '../../../services/ApiService';
import { MessageTypeEnums } from '../../../utils/enums/MessageTypeEnums';
import { formikCheckboxProps } from '../../../utils/forms';
import pushServerErrorToast from '../../../utils/pushServerErrorToast';
import {
  IHandleFormikSubmit,
  IRenderCheckbox,
  ThirdPartyFileAccessModalEnums,
  ThirdPartyFileAccessModalProps
} from './utils';

import './ThirdPartyFileAccessModal.scss';

const ThirdPartyFileAccessModal: React.FC<ThirdPartyFileAccessModalProps> = ({
  currentUserTypeVisibility,
  fileName,
  guids,
  onClose,
  onUpdate
}) => {
  const { t: textToDisplay } = useTranslation();

  const {
    IS_VISIBLE_TO_CLIENT_REPRESENTATIVE_APPRAISER,
    IS_VISIBLE_TO_CLIENT_REPRESENTATIVE_ATTORNEY,
    IS_VISIBLE_TO_TAX_AGENCY_REPRESENTATIVE,
    ROOT_TO_TEXT,
    ROOT_TO_USER_TYPES
  } = ThirdPartyFileAccessModalEnums;
  const {
    ClientRepresentativeAppraiser: UserTypeClientRepresentativeAppraiser,
    ClientRepresentativeAttorney: UserTypeClientRepresentativeAttorney,
    TaxAgencyRepresentative: UserTypeTaxAgencyRepresentative
  } = UserType;

  const handleFormikSubmit: IHandleFormikSubmit = async ({
    callbacks,
    documentGuid,
    engagementGuid,
    formikValues,
    successToast,
    thirdPartyUserTypeOrder,
    thirdPartyUserTypes
  }) => {
    const minimumUserTypeVisibility = UserType.Ryan | UserType.Client;
    const userTypeVisibility =
      minimumUserTypeVisibility |
      (formikValues[thirdPartyUserTypeOrder[0]]
        ? thirdPartyUserTypes.UserTypeClientRepresentativeAppraiser
        : 0) |
      (formikValues[thirdPartyUserTypeOrder[1]]
        ? thirdPartyUserTypes.UserTypeClientRepresentativeAttorney
        : 0) |
      (formikValues[thirdPartyUserTypeOrder[2]]
        ? thirdPartyUserTypes.UserTypeTaxAgencyRepresentative
        : 0);

    try {
      await ApiService.updateFileVisibility(
        [documentGuid],
        userTypeVisibility,
        engagementGuid
      );

      pushToast(successToast);

      callbacks.onUpdateCallback();
      callbacks.onCloseCallback();
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushServerErrorToast();
        callbacks.onCloseCallback();
      }
    }
  };

  const renderCheckbox: IRenderCheckbox = ({
    formik,
    name,
    ...passthroughProps
  }) => {
    return (
      <Checkbox {...formikCheckboxProps(name, formik)} {...passthroughProps} />
    );
  };

  return (
    <Modal
      className="third-party-file-access-modal"
      onClose={onClose}
      open
      title={textToDisplay(`${ROOT_TO_TEXT}.title`)}
    >
      <p>{textToDisplay(`${ROOT_TO_TEXT}.fileVisibilityNotice`)}</p>
      <p>{textToDisplay(`${ROOT_TO_TEXT}.changeVisibilityPrompt`)}</p>

      <Formik
        initialValues={{
          [IS_VISIBLE_TO_CLIENT_REPRESENTATIVE_APPRAISER]:
            (currentUserTypeVisibility &
              UserTypeClientRepresentativeAppraiser) >
            0,
          [IS_VISIBLE_TO_CLIENT_REPRESENTATIVE_ATTORNEY]:
            (currentUserTypeVisibility & UserTypeClientRepresentativeAttorney) >
            0,
          [IS_VISIBLE_TO_TAX_AGENCY_REPRESENTATIVE]:
            (currentUserTypeVisibility & UserTypeTaxAgencyRepresentative) > 0
        }}
        onSubmit={(formikValues: { [key: string]: boolean }) =>
          handleFormikSubmit({
            callbacks: {
              onCloseCallback: onClose,
              onUpdateCallback: onUpdate
            },
            documentGuid: guids.documentGuid,
            engagementGuid: guids.engagementGuid,
            formikValues,
            successToast: {
              content: (
                <Trans
                  i18nKey={`${ROOT_TO_TEXT}.successToast.visibilityUpdated`}
                >
                  <b>{{ fileName }}</b>
                </Trans>
              ),
              title: textToDisplay(`${ROOT_TO_TEXT}.successToast.title`),
              type: MessageTypeEnums.SUCCESS
            },
            thirdPartyUserTypeOrder: [
              IS_VISIBLE_TO_CLIENT_REPRESENTATIVE_APPRAISER,
              IS_VISIBLE_TO_CLIENT_REPRESENTATIVE_ATTORNEY,
              IS_VISIBLE_TO_TAX_AGENCY_REPRESENTATIVE
            ],
            thirdPartyUserTypes: {
              UserTypeClientRepresentativeAppraiser,
              UserTypeClientRepresentativeAttorney,
              UserTypeTaxAgencyRepresentative
            }
          })
        }
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <div className="well">
              <label className="ry-label">
                {textToDisplay(`${ROOT_TO_TEXT}.makeVisibleTo`)}
              </label>

              <div className="third-party-file-access-modal__selections">
                <div>
                  {renderCheckbox({
                    formik,
                    label: textToDisplay(
                      `${ROOT_TO_USER_TYPES}.${UserTypeClientRepresentativeAppraiser}`
                    ),
                    name: IS_VISIBLE_TO_CLIENT_REPRESENTATIVE_APPRAISER,
                    value: UserTypeClientRepresentativeAppraiser.toString()
                  })}
                </div>
                <div>
                  {renderCheckbox({
                    formik,
                    label: textToDisplay(
                      `${ROOT_TO_USER_TYPES}.${UserTypeClientRepresentativeAttorney}`
                    ),
                    name: IS_VISIBLE_TO_CLIENT_REPRESENTATIVE_ATTORNEY,
                    value: UserTypeClientRepresentativeAttorney.toString()
                  })}
                </div>
                <div>
                  {renderCheckbox({
                    formik,
                    label: textToDisplay(
                      `${ROOT_TO_USER_TYPES}.${UserTypeTaxAgencyRepresentative}`
                    ),
                    name: IS_VISIBLE_TO_TAX_AGENCY_REPRESENTATIVE,
                    value: UserTypeTaxAgencyRepresentative.toString()
                  })}
                </div>
              </div>
            </div>

            <ButtonGroup>
              <Button
                onClick={onClose}
                text={textToDisplay(`${ROOT_TO_TEXT}.cancel`)}
              />
              <Button
                disabled={!formik.dirty}
                text={textToDisplay(`${ROOT_TO_TEXT}.done`)}
                type="submit"
                variant="primary"
              />
            </ButtonGroup>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default ThirdPartyFileAccessModal;
