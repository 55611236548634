import { TFunction } from 'i18next';

export default function useHelpSubjectOptions(t: TFunction) {
  const subjectOptions = [
    'Data and Files',
    'Profile',
    'Reporting',
    'Ryan Contracts/Invoices',
    'Security'
  ];

  subjectOptions.sort().push('Other');

  return [
    {
      value: '',
      label: t('helpForm.fields.subject.placeholder'),
      disabled: true
    },
    ...subjectOptions.map(value => ({
      value,
      label: t(`helpForm.fields.subject.options.${value}`)
    }))
  ];
}
