import { useUser } from 'hooks';
import qs from 'query-string';
import { getUserManager } from 'services/UserManager';

import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Banner, Button } from '@ryan/components';

import './DebugBanner.scss';

export const DebugBanner: React.FC = () => {
  const location = useLocation();
  const { permissionService } = useUser();
  const hash = qs.parse(location.hash);
  const enableDebug: boolean =
    typeof hash.debug !== 'undefined' && permissionService.isRyan();

  // user session expiration time in seconds
  const [expirationTime, setExpirationTime] = useState<number | null>(null);

  /**
   * Gets the time remaining in the active user session.
   */
  const getExpirationTime = useCallback(async () => {
    const user = await getUserManager().getUser();
    const expiresIn = user?.expires_in;

    if (typeof expiresIn === 'number') {
      setExpirationTime(expiresIn);
    }
  }, []);

  // initialize user session expiration status
  useEffect(() => {
    let refreshInterval: NodeJS.Timeout;
    const initExpirationInterval = async () => {
      getExpirationTime();
      refreshInterval = setInterval(getExpirationTime, 60000);
    };
    initExpirationInterval();

    return () => {
      clearInterval(refreshInterval);
    };
  });

  return !enableDebug ? null : (
    <Banner id="debug-banner">
      <div className="ry-h4">DEBUG</div>
      <div>
        {expirationTime !== null &&
          `active session expires in ${Math.floor(
            expirationTime / 60
          )} minutes`}
      </div>
      <Button
        onClick={async () => {
          await getUserManager().signinSilent();
          getExpirationTime();
        }}
        variant="link"
      >
        Renew Now
      </Button>
    </Banner>
  );
};
