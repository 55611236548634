import { TermsOfUseType } from 'interfaces';
// Routes
import NewPassword from 'routes/PasswordManagement/NewPassword';
import PracticeAreaConfiguration from 'routes/Superadmin/PracticeAreaConfiguration';
import TermsOfUse, { ViewTermsOfUse } from 'routes/TermsOfUse';

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

/**
 * A router for pages that can only be viewed behind user-authentication.
 */
const ProtectedRouter: React.FC = () => (
  <Switch>
    <Route component={TermsOfUse} path="/go-to/accept-terms" />
    <Route component={NewPassword} path="/go-to/new-password" />
    <Route component={PracticeAreaConfiguration} path="/superadmin/dashboard" />
    <Route
      path="/go-to/privacy"
      render={() => (
        <ViewTermsOfUse termsOfUseType={TermsOfUseType.PrivacyPolicy} />
      )}
    />
    <Route
      path="/go-to/terms"
      render={() => (
        <ViewTermsOfUse termsOfUseType={TermsOfUseType.TermsOfUse} />
      )}
    />
    <Redirect to="/" />
  </Switch>
);

export default ProtectedRouter;
