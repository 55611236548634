import React, { ComponentProps } from 'react';

import { Button, DropdownMenu, IDropdownMenuOption } from '@ryan/components';

import { UserType } from '../../../interfaces';
import ApiService from '../../../services/ApiService';
import history from '../../../services/history';
import { getIsDeferredActivation } from '../../../utils/';
import { NewUserRequestStatusEnums } from '../../../utils/enums/NewUserRequestStatusEnums';
import {
  IOverflowMenuProps,
  formatToOverflowMenu,
  getOverflowOptionForNonRyanUser,
  getOverflowOptionsForRyanUser
} from './utils';

const OverflowMenu: React.FC<IOverflowMenuProps> = ({
  dropdownMenuButtonSize,
  handleGrantUserAccess,
  handleRemoveUserConfirm,
  handleUserGhosting,
  isActiveUserWithManageEngagementAccess,
  isOverflowDisabled,
  permissionService,
  textToDisplay,
  user
}) => {
  const {
    isActiveUserWithEditAccess,
    isActiveUserWithNewUserApprovalAccess,
    isUserAssociationEditable,
    isUserBigFour,
    isUserGhosted,
    userActivateDate,
    userDeactivateDate,
    userMemberGuid,
    userGuid,
    userTypeId
  } = formatToOverflowMenu(user);
  const isActiveUserSuperAdmin = permissionService.isSuperAdmin();
  const isUserActiveUser = permissionService.isUser(userGuid);
  const isActiveUserBigFourOrExecutiveAssistant =
    permissionService.isBig4OrExecutiveAssistant();

  if (isUserActiveUser && !isActiveUserSuperAdmin) {
    return null;
  }

  const isUserTypeRyan = userTypeId === UserType.Ryan;
  const options: ComponentProps<typeof DropdownMenu>['options'] = [];

  if (
    handleRemoveUserConfirm &&
    handleUserGhosting &&
    isActiveUserWithManageEngagementAccess &&
    isUserTypeRyan
  ) {
    const overFlowOptions = getOverflowOptionsForRyanUser({
      isUserAssociationEditable,
      isUserBigFour,
      isUserGhosted,
      isActiveUserBigFourOrExecutiveAssistant,
      openModalCallback: () => {
        handleRemoveUserConfirm(user);
      },
      textToDisplay,
      toggleGhostingCallback: () => {
        handleUserGhosting(user, !isUserGhosted);
      }
    });

    overFlowOptions.forEach(overflowOption => {
      options.push({
        ...overflowOption,
        disabled: isOverflowDisabled
      } as any as IDropdownMenuOption);
    });
  }

  const isActiveUserTypeRyan = permissionService.isRyan();

  if (!isUserTypeRyan && isActiveUserTypeRyan) {
    const overFlowOption = getOverflowOptionForNonRyanUser({
      editUserAccessCallback: async () => {
        const response = await ApiService.getNewUserRequestDetails(userGuid);

        if (
          response.data &&
          response.data.status === NewUserRequestStatusEnums.PENDING
        ) {
          history.push(
            `/app/team/review-new-user/${response.data.queueItemGuid}`
          );
        } else {
          history.push(`/app/team/edit-user/${userGuid}`, {
            previousLocationPathname: history.location.pathname
          });
        }
      },
      isActiveUserWithEditAccess,
      isActiveUserWithNewUserApprovalAccess,
      isDeferredActivation: getIsDeferredActivation(
        userActivateDate,
        userDeactivateDate
      ),
      newUserRequestStatus: user.newUserStatus as any as string,
      openModalCallback: () => {
        handleGrantUserAccess(user);
      },
      textToDisplay,
      viewUserProfileCallback: () => {
        history.push(`/app/personal-information/${userMemberGuid}`);
      }
    });

    if (overFlowOption) {
      options.push({
        ...overFlowOption,
        disabled: isOverflowDisabled
      } as any as IDropdownMenuOption);
    }
  }

  return options.length > 0 ? (
    <DropdownMenu
      options={options}
      placement="top"
      renderTarget={({ onClick, open, ref }) => (
        <Button
          ariaExpanded={open}
          ariaHaspopup="menu"
          className="additional-options"
          icon="more-outline"
          innerRef={ref}
          onClick={onClick}
          size={dropdownMenuButtonSize as any as 'sm' | 'md' | 'lg'}
          variant="text"
        />
      )}
    />
  ) : null;
};

export default OverflowMenu;
