import classNames from 'classnames';
import { TFunction } from 'i18next';
import memoizeOne from 'memoize-one';
import React, { Component, ComponentProps } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  Dropdown,
  Message,
  TextInput,
  pushToast
} from '@ryan/components';

import {
  ContractFileStatus,
  ContractFileType,
  IButtonLoadingState,
  IContractFile
} from '../../interfaces';
import ApiService from '../../services/ApiService';
import {
  Formik,
  FormikHelpers,
  formikDatepickerProps,
  formikFieldProps,
  yup
} from '../../utils/forms';
import Modal from '..//Modal';
import Datepicker from '../Datepicker';

import './ContractFileEditPublishModal.scss';

/**
 * Helpers
 */

const getContractFileTypeOptions = memoizeOne<
  (t: TFunction) => ComponentProps<typeof Dropdown>['options']
>(t => [
  {
    value: '',
    label: t('contracts.modal.selectFileName'),
    disabled: true
  },
  ...Object.keys(ContractFileType)
    .map(key => parseInt(key, 10))
    .filter(i => !isNaN(i) && i !== ContractFileType.Unknown)
    .map(value => ({
      value: value.toString(),
      label: t(`contracts.type.${value}`)
    }))
]);

const getSchema = (t: TFunction) =>
  yup.object({
    typeId: yup.string().required(t('contracts.modal.required.type')),
    name: yup
      .string()
      .test(
        'identifierRequired',
        t('contracts.modal.required.identifier'),
        function (value: string) {
          const typeId = this.resolve(yup.ref('typeId'));
          return !(typeId === ContractFileType.Other.toString() && !value);
        }
      ),
    executedDate: yup
      .date()
      .nullable()
      .required(t('contracts.modal.required.executeddate'))
  });

const getInitialValues = (contractFile: IContractFile) => ({
  name: contractFile.originalName ? contractFile.name : '',
  typeId:
    contractFile.typeId === ContractFileType.Unknown
      ? ''
      : contractFile.typeId.toString(),
  executedDate: contractFile.executedDate ? contractFile.executedDate : null
});

/**
 * Component
 */

interface IContractFileModalProps extends WithTranslation {
  open: boolean;
  contractFile: IContractFile;
  onClose: (contractFile?: IContractFile) => void;
  contractStatus?: ContractFileStatus | null;
}

interface IContractFileModalState extends IButtonLoadingState {
  error?: React.ReactNode;
}

export class ContractFileEditPublishModal extends Component<
  IContractFileModalProps,
  IContractFileModalState
> {
  schema = getSchema(this.props.t);

  state: IContractFileModalState = { loading: null };

  getDisplayName(typeId: string, name: string) {
    const { t } = this.props;
    if (typeId === ContractFileType.Other.toString()) {
      return name;
    }
    if (typeId !== '') {
      return `${t(`contracts.type.${typeId}`)} ${name}`;
    }
  }

  resetState() {
    this.setState({ error: null, loading: null });
  }

  handleClose = () => {
    this.resetState();
    this.props.onClose();
  };

  handleSubmit = (values: any, { resetForm }: FormikHelpers<any>) => {
    const { contractFile, contractStatus } = this.props;

    const contractFileFromValues: IContractFile = {
      ...values,
      status:
        contractFile.statusId === ContractFileStatus.Published
          ? contractFile.status
          : contractStatus,
      engagementGuid: contractFile.engagementGuid
    };

    const promise = ApiService.upsertContractFile(
      contractFileFromValues.engagementGuid,
      contractFile.engagementContractDocumentGuid,
      contractFileFromValues
    )
      .then(({ data: updatedContractFile }) => {
        const { t } = this.props;
        const { typeId, name } = updatedContractFile;
        const displayName = this.getDisplayName(typeId.toString(), name);

        pushToast({
          type: 'success',
          title: t(
            contractStatus === ContractFileStatus.Published
              ? 'contracts.modal.publish.success.title'
              : 'contracts.modal.edit.success.title'
          ),
          content: (
            <Trans
              i18nKey={
                contractStatus === ContractFileStatus.Published
                  ? 'contracts.modal.publish.success.content'
                  : 'contracts.modal.edit.success.content'
              }
            >
              <b>{{ displayName }}</b> has been successfully updated.
            </Trans>
          )
        });

        resetForm();
        this.resetState();
        this.props.onClose(updatedContractFile);
      })
      .catch(() => {
        const { t } = this.props;
        this.setState({
          error: (
            <Message title={t('serverError.title')} type="error">
              {t('serverError.content')}
            </Message>
          )
        });
      });

    this.setState({ loading: promise });
  };

  render() {
    const { t, open, contractFile, contractStatus } = this.props;
    const { loading, error } = this.state;
    const isPublish = contractStatus === ContractFileStatus.Published;

    return (
      <Modal
        className="contract-file-edit-publish-modal"
        onClose={this.handleClose}
        open={open}
        title={t(
          isPublish
            ? 'contracts.modal.publish.title'
            : 'contracts.modal.edit.title'
        )}
      >
        {error}
        <Formik
          initialValues={getInitialValues(contractFile)}
          onSubmit={this.handleSubmit}
          validationSchema={this.schema}
        >
          {formik => {
            const { typeId, name, executedDate } = formik.values;
            const displayName = this.getDisplayName(typeId, name);

            return (
              <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <p
                  className={classNames([
                    'contract-file-edit-publish-modal__publish-description',
                    !isPublish && 'contract-file-edit-publish-modal--hidden'
                  ])}
                >
                  {t('contracts.modal.publishDescription')}
                </p>
                <Dropdown
                  {...formikFieldProps('typeId', formik)}
                  label={t('contracts.modal.type')}
                  options={getContractFileTypeOptions(t)}
                />
                <TextInput
                  {...formikFieldProps('name', formik)}
                  helperText={t('contracts.modal.identifierHelperText', {
                    filename: contractFile.originalName || contractFile.name
                  })}
                  label={t('contracts.modal.identifier', {
                    context:
                      typeId !== ContractFileType.Other.toString()
                        ? 'optional'
                        : undefined
                  })}
                />
                <Datepicker
                  {...formikDatepickerProps('executedDate', formik)}
                  label={t('contracts.modal.executeddate')}
                  maxDate={new Date()}
                />
                <p
                  className={classNames([
                    !Boolean(displayName) &&
                      'contract-file-edit-publish-modal--hidden'
                  ])}
                >
                  {t('contracts.modal.display', { displayName })}
                </p>
                <ButtonGroup>
                  <Button
                    disabled={
                      displayName === '' ||
                      executedDate === null ||
                      !formik.dirty
                    }
                    loading={loading}
                    text={t(
                      isPublish
                        ? 'contracts.modal.updateandpublish'
                        : 'contracts.modal.rename'
                    )}
                    type="submit"
                    variant="primary"
                  />
                  <Button
                    disabled={loading !== null}
                    onClick={this.handleClose}
                    text={t('Cancel')}
                  />
                </ButtonGroup>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default withTranslation()(ContractFileEditPublishModal);
