import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ryan/components';

import {
  DataRequestProgressTrackerEnums,
  IDataRequestProgressTracker
} from './utils';

import './DataRequestProgressTracker.scss';

export const DataRequestProgressTracker: IDataRequestProgressTracker = ({
  activeStep
}) => {
  const { t: getTextToDisplay } = useTranslation();
  const steps = [
    DataRequestProgressTrackerEnums.CREATED,
    DataRequestProgressTrackerEnums.READY_FOR_ANALYSIS,
    DataRequestProgressTrackerEnums.RECONCILIATION,
    DataRequestProgressTrackerEnums.DPA_AND_SITUSING,
    DataRequestProgressTrackerEnums.COMPLETE
  ];

  const activeStepIndex = steps.indexOf(
    DataRequestProgressTrackerEnums[
      activeStep
        .split(' ')
        .join('_')
        .split('&')
        .join('and')
        .toLocaleUpperCase() as keyof typeof DataRequestProgressTrackerEnums
    ]
  );

  return (
    <ul className="progress-tracker">
      {steps.map((step, index) => {
        const isStepBeforeActiveStep = index < activeStepIndex;
        const isCurrentStep = index === activeStepIndex;
        return (
          <li
            className={classNames('progress-tracker__step', {
              'progress-tracker__step--is-active': isStepBeforeActiveStep,
              'progress-tracker__step--is-current': isCurrentStep
            })}
            key={index}
          >
            <Icon
              className={classNames('progress-tracker__step__icon', {
                'progress-tracker__step__icon--filled':
                  isStepBeforeActiveStep || isCurrentStep
              })}
              name="success-filled"
            />
            <div className="progress-tracker__step__text">
              {getTextToDisplay(step)}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default DataRequestProgressTracker;
