import { differenceInCalendarDays, format, parseISO } from 'date-fns';
import { TFunction } from 'i18next';

import { getLocale } from '../../../../utils/i18n';
import { ICodeNotesGroupedByDate, IGroupCodeNotesByDate } from '../ICodeNotes';

const formatDateLabel = (
  date: Date,
  getTextToDisplay: TFunction,
  language: string
) => {
  const diff = differenceInCalendarDays(new Date(), date);
  switch (diff) {
    case 0:
      return getTextToDisplay('Today');
    case 1:
      return getTextToDisplay('Yesterday');
    default:
      return format(date, 'EEEE, MMMM d', { locale: getLocale(language) });
  }
};

export const groupCodeNotesByDate: IGroupCodeNotesByDate = ({
  getTextToDisplay,
  language = 'en-US',
  notes
}) => {
  const groupedComments = notes.reduce((days, codeNote) => {
    const date = parseISO(codeNote.createDate);
    const key = format(date, 'yyyyMMdd');
    if (!days[key]) {
      days[key] = {
        key,
        label: formatDateLabel(date, getTextToDisplay, language),
        codeNotes: []
      };
    }
    days[key].codeNotes.push(codeNote);
    return days;
  }, {} as Record<string, ICodeNotesGroupedByDate>);

  return Object.keys(groupedComments)
    .sort()
    .map(key => {
      groupedComments[key].codeNotes.sort(
        (a, b) =>
          parseISO(a.createDate).getTime() - parseISO(b.createDate).getTime()
      );
      return groupedComments[key];
    });
};
