import AccountHierarchy from 'components/AccountHierarchy/AccountHierarchy';
import AdvancedFilterModal from 'components/AdvancedFilterModal/AdvancedFilterModal';
import {
  DXP_PROJECT_FILTERS,
  EngagementPersonnelFilter,
  FilterOptions,
  JurisdictionFilter,
  PracticeAreaFilter,
  ProjectDataType,
  ProjectFilter
} from 'components/AdvancedFilterModal/interfaces';
import Currency from 'components/Currency/Currency';
import DataRequestModal from 'components/DataRequestModal/DataRequestModal';
import DataRequestStatusSummaryCard from 'components/DataRequestStatusSummaryCard/DataRequestStatusSummaryCard';
import Empty from 'components/Empty';
import RecentActivityCard from 'components/RecentActivityCard/RecentActivityCard';
import Table from 'components/Table';
import TableEmpty from 'components/TableEmpty/TableEmpty';
import TaskDataVizCard from 'components/TaskDataViz/TaskDataVizCard';
import TimelinesCard from 'components/TimelinesCard/TimelinesCard';
import { WithUser, withUser } from 'contexts/UserContext';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import debouncedSearch from 'utils/debouncedSearch';
import getSortParam from 'utils/getSortParm';
import {
  joinAndRemoveDuplicateEngagementPersonnel,
  joinAndRemoveDuplicateJurisdictions,
  joinAndRemoveDuplicatePracticeAreas
} from 'utils/joinAndRemoveDuplicates';
import pushServerErrorToast from 'utils/pushServerErrorToast';
import switcherDidUpdate from 'utils/switcherDidUpdate';

import classNames from 'classnames';
import React, { Component, Key } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import {
  Button,
  Dropdown,
  IOption,
  ITableColumn,
  Icon,
  Tooltip
} from '@ryan/components';

import ProjectSnapshot from '../../../components/Table/ProjectSnapshot/ProjectSnapshot';
import {
  EngagementStatus,
  Feature,
  IDataRequest,
  IEngagement,
  IProjectOverviewState,
  IProjectOverviewTableSearch,
  Permission,
  UserType
} from '../../../interfaces';
import redirectToDecisionsApp from '../../../utils/redirectToDecisions';

import './Overview.scss';

interface IProjectsOverviewProps
  extends WithUser,
    RouteComponentProps,
    WithTranslation {
  // ...
}

export class Overview extends Component<
  IProjectsOverviewProps,
  IProjectOverviewState
> {
  private _isMounted = false;

  private engagementsForCustomViewByUserCancelToken?: CancelTokenSource;
  private customViewDataRequestCountCancelToken?: CancelTokenSource;

  private columns: ITableColumn<IEngagement>[] = [];
  private filterOptions: IOption[] = [];

  private defaultFilterState: FilterOptions = {
    accountHandlers: [],
    engagementManagers: [],
    engagementPrincipals: [],
    jurisdictions: [],
    practiceAreas: []
  };

  constructor(props: IProjectsOverviewProps) {
    super(props);

    this.state = {
      availableFilters: this.defaultFilterState,
      dataRequests: {
        toDo: 0,
        inProgress: 0,
        complete: 0
      },
      engagements: [],
      expanded: {},
      filtered: {
        status: [`${EngagementStatus.Active}`],
        type: '',
        service: ''
      },
      isDataRequestModalOpen: false,
      isFilterModalOpen: false,
      loading: false,
      page: 1,
      pageSize: 10,

      searchQuery: '',
      sorted: {
        id: 'engagementDisplayNameLong',
        desc: false
      },
      selectedFilters: this.defaultFilterState,
      totalCount: 0
    };

    const { t, isAppReadOnly, permissionService: ps } = props;
    const canViewSavings = ps.hasPermission(Permission.SavingsSummaryView);
    const columns: ITableColumn<IEngagement>[] = [];

    // project status dropdown options
    const filterOptions: IOption[] = [
      {
        label: t('All'),
        value: ''
      },
      {
        label: t('Active'),
        value: `${EngagementStatus.Active}`
      },
      {
        label: t('Inactive'),
        value: `${EngagementStatus.InActive}`
      }
    ];

    this.filterOptions = filterOptions;

    columns.push({
      id: 'hierarchy',
      label: '',
      align: 'center',
      width: '3rem',
      render: (row: IEngagement) => (
        <AccountHierarchy hierarchy={row.accountHierarchy} />
      )
    });

    columns.push({
      id: 'engagementDisplayNameLong',
      label: t('projectOverview.columns.project'),
      sortable: true,
      render: (row: IEngagement) => (
        <Link className="bs bs--link" to={`/app/project/${row.engagementGuid}`}>
          <b>{row.engagementDisplayNameLong}</b>
          <small>{row.accountName}</small>
        </Link>
      )
    });

    if (ps.isRyan()) {
      columns.push({
        id: 'engagementId',
        label: t('projectOverview.columns.code'),
        sortable: true,
        render: row => (
          <span className="ry-table__td__engagement-id">
            {row.engagementId}
          </span>
        )
      });
    }

    columns.push({
      id: 'jurisdictionDisplayName',
      label: t('projectOverview.columns.jurisdiction'),
      sortable: true,
      render: (row: IEngagement) => row.jurisdictionDisplayName || '-'
    });

    columns.push({
      id: 'isActive',
      label: t('projectOverview.columns.isActive'),
      sortable: true,
      render: (row: IEngagement) => (
        <div className="projects-overview__pills">
          <div className={`pill ${row.isActive ? 'active' : 'inactive'}`}>
            {t(row.isActive ? 'Active' : 'Inactive')}
          </div>
          {(row.isUserGhosted || isAppReadOnly) && (
            <Tooltip
              content={t(
                'projectDetails.readonly',
                isAppReadOnly ? { context: 'executiveView' } : {}
              )}
              placement="top-start"
              renderTarget={({ open, ...props }) => (
                <button className="pill ghosted" type="button" {...props}>
                  <Icon name="hide" />
                </button>
              )}
            />
          )}
        </div>
      )
    });

    if (canViewSavings) {
      columns.push({
        id: 'lastSavingsSummarySavingsTotal',
        label: t('projectOverview.columns.totalSavings'),
        sortable: true,
        align: 'right',
        render: (row: IEngagement) =>
          row.projectCurrency ? (
            <Currency
              currencyCode={row.projectCurrency}
              value={
                row.lastSavingsSummarySavingsTotal
                  ? row.lastSavingsSummarySavingsTotal
                  : 0.0
              }
            />
          ) : (
            '–'
          )
      });
    }

    this.columns = columns;
  }

  handleCreateDataRequestClose = (dataRequest?: IDataRequest) => {
    if (dataRequest) {
      this.fetchDataRequestStatusCount();
    }
    this.setState({ isDataRequestModalOpen: false });
  };

  private lastRequest: Promise<any> | null = null;

  fetchAvailableProjectFilters = () => {
    const {
      activeView: { customViewGuid }
    } = this.props;
    ApiService.fetchFilterOptions(customViewGuid).then(({ data }) => {
      this.setState({
        availableFilters: data
      });
    });
  };

  fetchSavedProjectFilters = () => {
    const {
      activeView: { customViewGuid }
    } = this.props;
    const filtersKeyForCustomView = `${DXP_PROJECT_FILTERS}${customViewGuid}`;
    const selectedFilters = window.sessionStorage.getItem(
      filtersKeyForCustomView
    );
    const parsed =
      selectedFilters !== null
        ? JSON.parse(selectedFilters)
        : this.defaultFilterState;
    // Fetch each list of filters.
    this.setState({ selectedFilters: parsed });
  };

  fetchProjects(updates?: Partial<IProjectOverviewState>) {
    const { user, activeView } = this.props;

    this.setState({ ...(updates as any), loading: true }, () => {
      const { searchQuery, filtered, sorted, page, pageSize, selectedFilters } =
        this.state;

      const params: IProjectOverviewTableSearch = {
        isActive: filtered!.status.length === 1 ? filtered!.status[0] : '',
        itemsPerPage: pageSize,
        pageNumber: page,
        searchTerm: searchQuery,
        selectedFilters: JSON.stringify(selectedFilters),
        engagementSort: getSortParam(sorted)
      };

      // refresh cancel token
      this.engagementsForCustomViewByUserCancelToken?.cancel();
      this.engagementsForCustomViewByUserCancelToken =
        ApiService.CancelToken.source();

      const request = ApiService.getEngagementsForCustomViewByUserWithFilters(
        activeView.customViewGuid,
        user.profile.userGuid,
        params
      )
        .then(response => {
          return response.data;
        })
        .catch(error => {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
          }
          return { results: [], totalResults: 0 };
        })
        .then(data => {
          if (request === this.lastRequest && this._isMounted) {
            this.setState({
              loading: false,
              engagements: data.results,
              totalCount: data.totalResults
            });
          }
        });

      this.lastRequest = request;
    });
  }

  async fetchDataRequestStatusCount() {
    const {
      activeView: { customViewGuid },
      permissionService: ps
    } = this.props;

    if (ps.hasPermission(Permission.DataRequestsView)) {
      try {
        // refresh cancel token
        this.customViewDataRequestCountCancelToken?.cancel();
        this.customViewDataRequestCountCancelToken =
          ApiService.CancelToken.source();

        const response = await ApiService.getCustomViewDataRequestCount(
          customViewGuid,
          this.customViewDataRequestCountCancelToken.token
        );
        this.setState({ dataRequests: response.data });
      } catch {
        // ...
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchProjects();
    this.fetchDataRequestStatusCount();

    this.fetchAvailableProjectFilters();
    this.fetchSavedProjectFilters();
  }

  componentDidUpdate(prevProps: IProjectsOverviewProps) {
    if (switcherDidUpdate(prevProps, this.props)) {
      this.fetchProjects({ page: 1 });
      this.fetchDataRequestStatusCount();
      this.fetchAvailableProjectFilters();
      this.fetchSavedProjectFilters();
    }
  }

  componentWillUnmount() {
    this.customViewDataRequestCountCancelToken?.cancel();
    this.engagementsForCustomViewByUserCancelToken?.cancel();
    this._isMounted = false;
  }

  onSort = (sorted: any) => {
    this.fetchProjects({ sorted });
  };

  onPage = (page: number, pageSize: number) => {
    this.fetchProjects({ page, pageSize });
  };

  onSearch = debouncedSearch(
    (searchQuery: string) => {
      this.setState({ searchQuery });
    },
    (searchQuery: string) => {
      this.fetchProjects({ page: 1, searchQuery });
    }
  );

  onToggleExpansion = (expanded: boolean, _row: IEngagement, rowKey: Key) => {
    this.setState(state => ({
      expanded: { ...state.expanded, [rowKey]: expanded }
    }));
  };

  onFilterChange = (value: IOption) => {
    this.setState(
      {
        filtered: {
          ...this.state.filtered,
          status: [`${value}`]
        }
      },
      () => {
        this.fetchProjects({ page: 1, filtered: this.state.filtered });
      }
    );
  };

  getRowClassName = (row: IEngagement) => {
    switch (row.accountHierarchy.length) {
      case 1:
        return 'level-1';
      case 2:
        return 'level-2';
      default:
        return 'level-3-or-more';
    }
  };

  onFilterPressed = () => {
    this.setState({
      isFilterModalOpen: true
    });
  };

  // Practice Areas

  selectPracticeArea = (practiceArea: PracticeAreaFilter) => {
    const { selectedFilters } = this.state;
    const { practiceAreas } = selectedFilters;
    if (
      !practiceAreas.some(
        pa => pa.practiceAreaGuid === practiceArea.practiceAreaGuid
      )
    ) {
      this.setState(prevState => ({
        selectedFilters: {
          ...prevState.selectedFilters,
          practiceAreas: [
            practiceArea,
            ...prevState.selectedFilters.practiceAreas
          ]
        }
      }));
    } else {
      this.setState(prevState => ({
        selectedFilters: {
          ...prevState.selectedFilters,
          practiceAreas: prevState.selectedFilters.practiceAreas.filter(
            pa => pa.practiceAreaGuid !== practiceArea.practiceAreaGuid
          )
        }
      }));
    }
  };

  // Jurisdictions

  selectJurisdiction = (jurisdiction: JurisdictionFilter) => {
    const {
      selectedFilters: { jurisdictions }
    } = this.state;
    if (
      !jurisdictions.some(
        j => j.jurisdictionGuid === jurisdiction.jurisdictionGuid
      )
    ) {
      this.setState(prevState => ({
        selectedFilters: {
          ...prevState.selectedFilters,
          jurisdictions: [
            jurisdiction,
            ...prevState.selectedFilters.jurisdictions
          ]
        }
      }));
    } else {
      this.setState(prevState => ({
        selectedFilters: {
          ...prevState.selectedFilters,
          jurisdictions: prevState.selectedFilters.jurisdictions.filter(
            j => j.jurisdictionGuid !== jurisdiction.jurisdictionGuid
          )
        }
      }));
    }
  };

  // Account Handler

  selectAccountHandler = (accountHandler: EngagementPersonnelFilter) => {
    const {
      selectedFilters: { accountHandlers }
    } = this.state;
    if (!accountHandlers.some(eh => eh.userGuid === accountHandler.userGuid)) {
      this.setState(prevState => ({
        selectedFilters: {
          ...prevState.selectedFilters,
          accountHandlers: [
            accountHandler,
            ...prevState.selectedFilters.accountHandlers
          ]
        }
      }));
    } else {
      this.setState(prevState => ({
        selectedFilters: {
          ...prevState.selectedFilters,
          accountHandlers: prevState.selectedFilters.accountHandlers.filter(
            eh => eh.userGuid !== accountHandler.userGuid
          )
        }
      }));
    }
  };

  // Engagement Manager

  selectEngagementManager = (engagementManager: EngagementPersonnelFilter) => {
    const {
      selectedFilters: { engagementManagers }
    } = this.state;
    if (
      !engagementManagers.some(em => em.userGuid === engagementManager.userGuid)
    ) {
      this.setState(prevState => ({
        selectedFilters: {
          ...prevState.selectedFilters,
          engagementManagers: [
            engagementManager,
            ...prevState.selectedFilters.engagementManagers
          ]
        }
      }));
    } else {
      this.setState(prevState => ({
        selectedFilters: {
          ...prevState.selectedFilters,
          engagementManagers:
            prevState.selectedFilters.engagementManagers.filter(
              em => em.userGuid !== engagementManager.userGuid
            )
        }
      }));
    }
  };

  // Engagement Principal

  selectEngagementPrincipal = (
    engagementPrincipal: EngagementPersonnelFilter
  ) => {
    const {
      selectedFilters: { engagementPrincipals }
    } = this.state;
    if (
      !engagementPrincipals.some(
        ep => ep.userGuid === engagementPrincipal.userGuid
      )
    ) {
      this.setState(prevState => ({
        selectedFilters: {
          ...prevState.selectedFilters,
          engagementPrincipals: [
            engagementPrincipal,
            ...prevState.selectedFilters.engagementPrincipals
          ]
        }
      }));
    } else {
      this.setState(prevState => ({
        selectedFilters: {
          ...prevState.selectedFilters,
          engagementPrincipals:
            prevState.selectedFilters.engagementPrincipals.filter(
              ep => ep.userGuid !== engagementPrincipal.userGuid
            )
        }
      }));
    }
  };

  // Filter Utilities

  clearAllFor = (dataType: ProjectDataType) => {
    switch (dataType) {
      case ProjectDataType.PracticeArea:
        this.setState(prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            practiceAreas: []
          }
        }));
        return;
      case ProjectDataType.AccountHandler:
        this.setState(prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            accountHandlers: []
          }
        }));
        return;
      case ProjectDataType.EngagementManager:
        this.setState(prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            engagementManagers: []
          }
        }));
        return;
      case ProjectDataType.EngagementPrincipal:
        this.setState(prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            engagementPrincipals: []
          }
        }));
        return;
      case ProjectDataType.Jurisdiction:
        this.setState(prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            jurisdictions: []
          }
        }));
        return;
    }
  };

  clearAll = () => {
    this.setState({
      selectedFilters: {
        accountHandlers: [],
        engagementManagers: [],
        engagementPrincipals: [],
        jurisdictions: [],
        practiceAreas: []
      }
    });
  };

  removeFilterFor = (filterGuid: string, filterType: ProjectDataType) => {
    switch (filterType) {
      case ProjectDataType.AccountHandler:
        this.setState(prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            accountHandlers: prevState.selectedFilters.accountHandlers.filter(
              eh => eh.userGuid !== filterGuid
            )
          }
        }));
        return;
      case ProjectDataType.EngagementManager:
        this.setState(prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            engagementManagers:
              prevState.selectedFilters.engagementManagers.filter(
                em => em.userGuid !== filterGuid
              )
          }
        }));
        return;
      case ProjectDataType.EngagementPrincipal:
        this.setState(prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            engagementPrincipals:
              prevState.selectedFilters.engagementPrincipals.filter(
                ep => ep.userGuid !== filterGuid
              )
          }
        }));
        return;
      case ProjectDataType.Jurisdiction:
        this.setState(prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            jurisdictions: prevState.selectedFilters.jurisdictions.filter(
              j => j.jurisdictionGuid !== filterGuid
            )
          }
        }));
        return;
      case ProjectDataType.PracticeArea:
        this.setState(prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            practiceAreas: prevState.selectedFilters.practiceAreas.filter(
              pa => pa.practiceAreaGuid !== filterGuid
            )
          }
        }));
        return;
    }
  };

  selectFilterFor = (filter: ProjectFilter, dataType: ProjectDataType) => {
    switch (dataType) {
      case ProjectDataType.AccountHandler:
        const accountHandler: EngagementPersonnelFilter =
          filter as EngagementPersonnelFilter;
        this.selectAccountHandler(accountHandler);
        return;
      case ProjectDataType.EngagementManager:
        const engagementManager: EngagementPersonnelFilter =
          filter as EngagementPersonnelFilter;
        this.selectEngagementManager(engagementManager);
        return;
      case ProjectDataType.EngagementPrincipal:
        const engagementPrincipal = filter as EngagementPersonnelFilter;
        this.selectEngagementPrincipal(engagementPrincipal);
        return;
      case ProjectDataType.Jurisdiction:
        const jurisdiction = filter as JurisdictionFilter;
        this.selectJurisdiction(jurisdiction);
        return;
      case ProjectDataType.PracticeArea:
        const practiceArea: PracticeAreaFilter = filter as PracticeAreaFilter;
        this.selectPracticeArea(practiceArea);
        return;
    }
  };

  selectMultipleFiltersFor = (
    filters: ProjectFilter[],
    dataType: ProjectDataType
  ) => {
    switch (dataType) {
      case ProjectDataType.AccountHandler:
        const handlers: EngagementPersonnelFilter[] =
          filters as EngagementPersonnelFilter[];
        this.setState(prevState => {
          return {
            selectedFilters: {
              ...prevState.selectedFilters,
              accountHandlers: [
                ...joinAndRemoveDuplicateEngagementPersonnel(
                  handlers,
                  prevState.selectedFilters.accountHandlers
                )
              ]
            }
          };
        });
        return;
      case ProjectDataType.EngagementManager:
        const managers: EngagementPersonnelFilter[] =
          filters as EngagementPersonnelFilter[];
        this.setState(prevState => {
          return {
            selectedFilters: {
              ...prevState.selectedFilters,
              engagementManagers: [
                ...joinAndRemoveDuplicateEngagementPersonnel(
                  managers,
                  prevState.selectedFilters.engagementManagers
                )
              ]
            }
          };
        });
        return;
      case ProjectDataType.EngagementPrincipal:
        const principals: EngagementPersonnelFilter[] =
          filters as EngagementPersonnelFilter[];
        this.setState(prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            engagementPrincipals: [
              ...joinAndRemoveDuplicateEngagementPersonnel(
                principals,
                prevState.selectedFilters.engagementPrincipals
              )
            ]
          }
        }));
        return;
      case ProjectDataType.Jurisdiction:
        const jurisdictions: JurisdictionFilter[] =
          filters as JurisdictionFilter[];
        this.setState(prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            jurisdictions: [
              ...joinAndRemoveDuplicateJurisdictions(
                jurisdictions,
                prevState.selectedFilters.jurisdictions
              )
            ]
          }
        }));
        return;
      case ProjectDataType.PracticeArea:
        const practiceAreas: PracticeAreaFilter[] =
          filters as PracticeAreaFilter[];
        this.setState(prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            practiceAreas: [
              ...joinAndRemoveDuplicatePracticeAreas(
                practiceAreas,
                prevState.selectedFilters.practiceAreas
              )
            ]
          }
        }));
        return;
    }
  };

  deselectMultipleFiltersFor = (
    filters: ProjectFilter[],
    dataType: ProjectDataType
  ) => {
    switch (dataType) {
      case ProjectDataType.AccountHandler:
        const handlers: EngagementPersonnelFilter[] =
          filters as EngagementPersonnelFilter[];
        this.setState(prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            accountHandlers: prevState.selectedFilters.accountHandlers.filter(
              eh => !handlers.some(i => i.userGuid === eh.userGuid)
            )
          }
        }));
        return;
      case ProjectDataType.EngagementManager:
        const managers: EngagementPersonnelFilter[] =
          filters as EngagementPersonnelFilter[];
        this.setState(prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            engagementManagers:
              prevState.selectedFilters.engagementManagers.filter(
                em => !managers.some(i => i.userGuid === em.userGuid)
              )
          }
        }));
        return;
      case ProjectDataType.EngagementPrincipal:
        const principals: EngagementPersonnelFilter[] =
          filters as EngagementPersonnelFilter[];
        this.setState(prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            engagementPrincipals:
              prevState.selectedFilters.engagementPrincipals.filter(
                ep => !principals.some(i => i.userGuid === ep.userGuid)
              )
          }
        }));
        return;
      case ProjectDataType.Jurisdiction:
        const jurisdictions: JurisdictionFilter[] =
          filters as JurisdictionFilter[];
        this.setState(prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            jurisdictions: prevState.selectedFilters.jurisdictions.filter(
              j =>
                !jurisdictions.some(
                  i => i.jurisdictionGuid === j.jurisdictionGuid
                )
            )
          }
        }));
        return;
      case ProjectDataType.PracticeArea:
        const practiceAreas: PracticeAreaFilter[] =
          filters as PracticeAreaFilter[];
        this.setState(prevState => ({
          selectedFilters: {
            ...prevState.selectedFilters,
            practiceAreas: prevState.selectedFilters.practiceAreas.filter(
              pa =>
                !practiceAreas.some(
                  i => i.practiceAreaGuid === pa.practiceAreaGuid
                )
            )
          }
        }));
        return;
    }
  };

  onFilterClosed = () => {
    this.setState({ isFilterModalOpen: false });
    this.fetchSavedProjectFilters();
  };

  renderFilterOptions = () => {
    const { t, user } = this.props;
    const { selectedFilters } = this.state;
    const {
      accountHandlers,
      engagementManagers,
      engagementPrincipals,
      jurisdictions,
      practiceAreas
    } = selectedFilters;
    const totalFilters =
      accountHandlers.length +
      engagementManagers.length +
      engagementPrincipals.length +
      jurisdictions.length +
      practiceAreas.length;

    if ((user.profile.userTypeId & UserType.ThirdParty) > 0) {
      return null;
    }

    return (
      <Button
        icon="filter"
        onClick={() => this.onFilterPressed()}
        size="sm"
        text={t('advancedFilterModal.numberOfActiveFilters', {
          numFilters: totalFilters
        })}
        variant="text"
      />
    );
  };

  render() {
    const {
      activeView: { customViewGuid, name },
      isFeatureToggled,
      permissionService,
      t: getTextToDisplay
    } = this.props;

    const {
      availableFilters,
      dataRequests,
      engagements,
      expanded,
      filtered,
      isDataRequestModalOpen,
      isFilterModalOpen,
      loading,
      page,
      pageSize,
      searchQuery,
      selectedFilters,
      sorted,
      totalCount
    } = this.state;

    // Check if user has read-only access to engagements
    const isUserReadOnly: boolean =
      engagements.length > 0 &&
      engagements.filter(engagement => !engagement.isUserGhosted).length === 0;

    const isDataRequestsUpdateVisible = isFeatureToggled(
      Feature.RWMDataRequests
    );

    return (
      <div
        className={classNames('projects-overview', {
          'projects-overview--is-ryan': permissionService.isRyan()
        })}
      >
        <Table<IEngagement>
          {...((permissionService.isRyan() || permissionService.isClient()) && {
            expanded
          })}
          columns={this.columns}
          data={engagements}
          filtered={filtered}
          loading={loading}
          onFilterChange={this.onFilterChange}
          onPageChange={this.onPage}
          onSearchChange={this.onSearch}
          onSortChange={this.onSort}
          onToggleExpansion={this.onToggleExpansion}
          page={page}
          pageSize={pageSize}
          renderActionPlacement={0}
          renderActions={() => (
            <div className="filter">
              <div className="filter-action-container">
                <Dropdown
                  name="filterBy"
                  onChange={e => {
                    this.onFilterChange(e.target.value as IOption);
                  }}
                  options={this.filterOptions}
                  value={filtered?.status[0]}
                />
                {this.renderFilterOptions()}
              </div>
            </div>
          )}
          renderEmpty={() => (
            <TableEmpty searchQuery={searchQuery}>
              <Empty icon="search">
                {getTextToDisplay('projectOverview.empty', { name })}
              </Empty>
            </TableEmpty>
          )}
          renderExpandedRow={engagement => (
            <ProjectSnapshot engagement={engagement} />
          )}
          rowClassName={this.getRowClassName}
          rowId="engagementGuid"
          searchQuery={searchQuery}
          sorted={sorted}
          title={getTextToDisplay('projectOverview.title')}
          totalCount={totalCount}
        />
        <div className="row">
          <div className="col-12 col-lg-8">
            {permissionService.hasPermission(Permission.ActivityRead) && (
              <RecentActivityCard />
            )}
            {permissionService.hasPermission(Permission.TimelinesView) && (
              <TimelinesCard customViewGuid={customViewGuid} />
            )}
          </div>
          <div className="col-12 col-lg-4">
            {permissionService.hasPermission(Permission.DataRequestsView) && (
              <DataRequestStatusSummaryCard
                isEngagementReadOnly={isUserReadOnly}
                onCreateClick={() => {
                  if (isDataRequestsUpdateVisible) {
                    redirectToDecisionsApp();
                  } else if (
                    permissionService.hasPermission(Permission.DataRequestsEdit)
                  ) {
                    this.setState({ isDataRequestModalOpen: true });
                  }
                }}
                values={dataRequests}
              />
            )}
            {permissionService.hasPermission(Permission.TasksView) && (
              <TaskDataVizCard
                customViewGuid={customViewGuid}
                isEngagementReadOnly={isUserReadOnly}
              />
            )}
          </div>
        </div>
        {permissionService.hasPermission(Permission.DataRequestsEdit) && (
          <DataRequestModal
            onClose={this.handleCreateDataRequestClose}
            open={isDataRequestModalOpen}
          />
        )}
        <AdvancedFilterModal
          availableFilters={availableFilters}
          clearSelection={this.removeFilterFor}
          onApply={() => {
            const filtersJSON = JSON.stringify(selectedFilters);
            const filtersKeyForCustomView = `${DXP_PROJECT_FILTERS}${customViewGuid}`;
            window.sessionStorage.setItem(filtersKeyForCustomView, filtersJSON);
            this.fetchProjects();
            this.onFilterClosed();
          }}
          onClear={dataType => this.clearAllFor(dataType)}
          onClearAll={this.clearAll}
          onClose={this.onFilterClosed}
          onDeselectAll={this.deselectMultipleFiltersFor}
          onFilterOptionSelect={this.selectFilterFor}
          open={isFilterModalOpen}
          selectedFilters={selectedFilters}
          toggleFilters={this.selectMultipleFiltersFor}
        />
      </div>
    );
  }
}

export default withUser(withTranslation()(Overview));
