import classNames from 'classnames';
import { addDays } from 'date-fns';
import ENV from 'env';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dropdown, Switch } from '@ryan/components';

import { useUnsavedChanges } from '../../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import { getCurrencySymbol } from '../../../utils/getCurrencySymbol';
import Datepicker from '../../Datepicker';
import InfoTooltip from '../../InfoTooltip';
import EditCurrencyModal from '../../Modal/EditCurrencyModal/EditCurrencyModal';
import {
  IHandleOnChange,
  IRenderBaselineSwitch,
  ISavingsEntryDateAndCurrencyProps
} from './utils';

import './SavingsEntryDateAndCurrency.scss';

const SavingsEntryDateAndCurrency: React.FC<
  ISavingsEntryDateAndCurrencyProps
> = ({
  entryISOCurrencyCode,
  handleCreateEntry,
  handleUpdateEntry,
  isEditCurrencyEnabled,
  isoCurrencyCodeOptions,
  isSubmitEnabled,
  latestEntryAsOfDate,
  minimumEntryDate
}) => {
  const { t: getTextToDisplay } = useTranslation();
  const { setIsUnsavedChanges } = useUnsavedChanges();

  const [asOfDate, setAsOfDate] = useState<Date | null>(
    latestEntryAsOfDate ? new Date(latestEntryAsOfDate) : null
  );
  const [isBaseline, setIsBaseline] = useState(false);
  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false);
  const [isoCurrencyCode, setISOCurrencyCode] = useState(
    entryISOCurrencyCode || ''
  );

  const ROOT_TO_TEXT = 'savingsSummary.savingsEntryDateAndCurrency';

  const handleOnChange: IHandleOnChange = ({
    handleUpdateEntryCallback,
    key,
    setCallback,
    value
  }) => {
    setCallback(value);

    if (handleUpdateEntryCallback) {
      handleUpdateEntryCallback({ [key]: value });
    }
  };
  const renderBaselineSwitchProps = {
    label: getTextToDisplay(`${ROOT_TO_TEXT}.baselineLabel`),
    switchProps: {
      checked: isBaseline,
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        handleOnChange({
          ...(!isSubmitEnabled && {
            handleUpdateEntryCallback: handleUpdateEntry
          }),
          key: 'isBaseline',
          setCallback: setIsBaseline,
          value: event.target.checked
        });
      }
    },
    tooltip: getTextToDisplay(`${ROOT_TO_TEXT}.baselineTooltip`, {
      ryanPlatform: ENV.RYAN_PLATFORM
    })
  };

  const renderBaselineSwitch: IRenderBaselineSwitch = ({
    label,
    switchProps,
    tooltip
  }) => {
    return (
      <div className="baseline-switch">
        <span>{label}</span>
        <InfoTooltip>{tooltip}</InfoTooltip>

        <Switch {...switchProps} />
      </div>
    );
  };

  useEffect(() => {
    if (isSubmitEnabled) {
      setIsUnsavedChanges(
        (Boolean(asOfDate) && isSubmitEnabled) ||
          isoCurrencyCode !== entryISOCurrencyCode
      );
    }
  });

  return (
    <div className="savings-entry-date-and-currency">
      <div
        className={classNames('savings-entry-date-and-currency__form', {
          'savings-entry-date-and-currency__form--short': !isSubmitEnabled
        })}
      >
        <Datepicker
          {...(minimumEntryDate && {
            minDate: addDays(minimumEntryDate, 1)
          })}
          label={getTextToDisplay(
            `${ROOT_TO_TEXT}.${isBaseline ? 'baselineAsOf' : 'savingsAsOf'}`
          )}
          maxDate={new Date()}
          onChange={(...event) => {
            handleOnChange({
              ...(!isSubmitEnabled && {
                handleUpdateEntryCallback: handleUpdateEntry
              }),
              key: 'asOfDate',
              setCallback: setAsOfDate,
              value: event[1]!
            });
          }}
          value={asOfDate}
        />

        {isSubmitEnabled && (
          <section>
            {isEditCurrencyEnabled && (
              <Dropdown
                // TODO: Update components library to allow JSX if this pattern continues
                label={
                  (
                    <>
                      {getTextToDisplay(`${ROOT_TO_TEXT}.projectCurrency`)}
                      <InfoTooltip>
                        {getTextToDisplay(
                          `${ROOT_TO_TEXT}.projectCurrencyToolTip`
                        )}
                      </InfoTooltip>
                    </>
                  ) as any
                }
                onChange={event => {
                  setISOCurrencyCode(event.target.value);
                }}
                options={[
                  ...(!entryISOCurrencyCode
                    ? [
                        {
                          label: `- ${getTextToDisplay('Select')} -`,
                          value: ''
                        }
                      ]
                    : []),
                  ...isoCurrencyCodeOptions.map(option => ({
                    label: `${option.isoAlphaCode} - ${option.ryanCurrencyName}`,
                    value: option.isoAlphaCode
                  }))
                ]}
                value={isoCurrencyCode}
              />
            )}

            <Button
              disabled={!Boolean(asOfDate) || !Boolean(isoCurrencyCode)}
              onClick={() =>
                handleCreateEntry(asOfDate as Date, isBaseline, isoCurrencyCode)
              }
              text={getTextToDisplay('Create')}
              variant="primary"
            />
          </section>
        )}

        {!isSubmitEnabled &&
          !minimumEntryDate &&
          renderBaselineSwitch(renderBaselineSwitchProps)}

        {!isSubmitEnabled && (
          <div className="well">
            <ul className="labeled-list">
              <li>
                <label>{getTextToDisplay('Currency')}</label>
                {`${isoCurrencyCode.toUpperCase()} (${getCurrencySymbol(
                  isoCurrencyCode
                )})`}
                {isEditCurrencyEnabled && (
                  <Button
                    icon="pencil"
                    onClick={() => setIsCurrencyModalOpen(true)}
                    size="sm"
                    variant="text"
                  />
                )}
              </li>
            </ul>
          </div>
        )}
      </div>

      {isSubmitEnabled &&
        !minimumEntryDate &&
        renderBaselineSwitch(renderBaselineSwitchProps)}

      {isCurrencyModalOpen && (
        <EditCurrencyModal
          isoCurrencyCode={isoCurrencyCode}
          isoCurrencyCodeOptions={isoCurrencyCodeOptions}
          onClose={() => setIsCurrencyModalOpen(false)}
          onSubmit={updatedISOCurrencyCode => {
            setISOCurrencyCode(updatedISOCurrencyCode);
            setIsCurrencyModalOpen(false);
            handleUpdateEntry({ isoCurrencyCode: updatedISOCurrencyCode });
          }}
        />
      )}
    </div>
  );
};

export default SavingsEntryDateAndCurrency;
