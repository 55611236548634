import Modal from 'components/Modal';
import EngagementContext from 'contexts/EngagementContext';
import { ITask } from 'interfaces';
import ApiService from 'services/ApiService';

import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import { Button, ButtonGroup, pushToast } from '@ryan/components';

interface IDeleteTaskModalProps extends WithTranslation {
  task: ITask;
  open?: boolean;
  onClose: (deleted: boolean) => void;
}

interface IDeleteTaskModalState {
  submitPromise: Promise<any> | null;
}

export class DeleteTaskModal extends Component<
  IDeleteTaskModalProps,
  IDeleteTaskModalState
> {
  static contextType = EngagementContext;
  context!: React.ContextType<typeof EngagementContext>;

  readonly state: IDeleteTaskModalState = {
    submitPromise: null
  };

  handleDelete = async () => {
    const {
      t,
      task: { title, engagementGuid, queueItemGuid },
      onClose
    } = this.props;

    try {
      const deleteTaskPromise = ApiService.deleteTask(
        engagementGuid,
        queueItemGuid
      );

      this.setState({ submitPromise: deleteTaskPromise });
      await deleteTaskPromise;

      pushToast({
        type: 'success',
        title: t('taskActions.deleteModal.successToast.title'),
        content: (
          <Trans i18nKey="taskActions.deleteModal.successToast.content">
            <b>{{ title }}</b> is now completed.
          </Trans>
        )
      });
    } catch (error) {
      pushToast({
        type: 'error',
        title: t('serverError.title'),
        content: t('serverError.content')
      });
    } finally {
      this.setState({ submitPromise: null });
      onClose(true);
    }
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose(false);
  };

  render() {
    const { open = true, t } = this.props;
    const { submitPromise } = this.state;

    return (
      <Modal
        onClose={this.handleClose}
        open={open}
        title={t('taskActions.deleteModal.title')}
      >
        <p>{t('taskActions.deleteModal.content')}</p>
        <ButtonGroup>
          <Button
            loading={submitPromise}
            negative
            onClick={this.handleDelete}
            text={t('Yes, Delete')}
          />
          <Button
            disabled={submitPromise !== null}
            onClick={this.handleClose}
            text={t('No, Cancel')}
            variant="secondary"
          />
        </ButtonGroup>
      </Modal>
    );
  }
}

export default withTranslation()(DeleteTaskModal);
