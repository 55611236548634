import AccountHierarchy from 'components/AccountHierarchy/AccountHierarchy';
import Breadcrumb from 'components/Breadcrumbs/Breadcrumb';
import DocumentTitle from 'components/DocumentTitle';
import Table from 'components/Table';
import {
  getEditUserAccessOptions,
  getOverflowOptionForNonRyanUser
} from 'components/Table/OverflowMenu/utils/getOverflowOptions/getOverflowOptions';
import UserConversionModal from 'components/UserConversionModal/UserConversionModal';
import UserInfoCard from 'components/UserInfoCard/UserInfoCard';
import PathHistoryContext from 'contexts/PathHistoryContext/PathHistoryContext';
import { WithUser, withUser } from 'contexts/UserContext';
import {
  IBreadcrumbRoute,
  IEngagement,
  ITableState,
  IUser,
  IUserEngagementsTableSearch,
  UserType
} from 'interfaces';
import AmplitudeApiService from 'services/AmplitudeApiService';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import debouncedSearch from 'utils/debouncedSearch';
import { NewUserRequestStatusEnums } from 'utils/enums/NewUserRequestStatusEnums';
import { formatDate } from 'utils/formatDate';
import getSortParam from 'utils/getSortParm';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import { Button, ITableColumn, pushToast } from '@ryan/components';

import WelcomeEmailCard from '../../components/PersonalInformation/WelcomeEmailCard/WelcomeEmailCard';
import { getIsDeferredActivation } from '../../utils/';

import './PersonalInformationPage.scss';

interface IPersonalInformationProps
  extends RouteComponentProps<{ userGuid: string }>,
    WithTranslation,
    WithUser {}

interface IPersonalInformationState extends ITableState {
  canEditUser: boolean | null;
  engagements: IEngagement[];
  isUserConversionModalOpen: boolean;
  user: IUser | null;
}

const defaultState = {
  canEditUser: null,
  engagements: [],
  isUserConversionModalOpen: false,
  loading: false,
  page: 1,
  pageSize: 10,
  searchQuery: '',
  sorted: {
    desc: false,
    id: 'accountName'
  },
  totalCount: 0,
  user: null
};

export class PersonalInformationPage extends Component<
  IPersonalInformationProps,
  IPersonalInformationState
> {
  private columns: ITableColumn<IEngagement>[];

  private _isMounted = false;

  private source?: CancelTokenSource;

  private engagementsSource?: CancelTokenSource;

  static contextType = PathHistoryContext;

  constructor(props: IPersonalInformationProps) {
    super(props);

    const { t } = this.props;

    this.state = defaultState;
    this.columns = [
      {
        id: 'hierarchy',
        label: '',
        align: 'center',
        width: '3rem',
        render: (row: IEngagement) => (
          <AccountHierarchy
            hierarchy={row.accountHierarchy}
            isPublished={row.isPublished}
          />
        )
      },
      {
        id: 'accountName',
        label: t('Company'),
        sortable: true,
        render: 'accountName'
      },
      {
        id: 'engagementDisplayNameLong',
        label: t('Project Name'),
        sortable: true,
        render: 'engagementDisplayNameLong'
      }
    ];
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchUser();
  }

  componentDidUpdate(prevProps: IPersonalInformationProps) {
    const { userGuid } = this.props.match.params;
    const { userGuid: prevUserGuid } = prevProps.match.params;
    if (userGuid !== prevUserGuid) {
      this.fetchUser();
    }
  }

  componentWillUnmount() {
    this.source?.cancel();
    this.engagementsSource?.cancel();
    this._isMounted = false;
  }

  fetchUser() {
    const {
      t,
      permissionService: ps,
      match: {
        params: { userGuid }
      }
    } = this.props;

    // Reset state
    this.setState(defaultState);

    // Get the user information
    this.source?.cancel();
    this.source = ApiService.CancelToken.source();
    ApiService.getUserInfoForProfilePage(userGuid, this.source.token)
      .then(({ data }) => {
        const userInfo = {
          ...data.memberProfile,
          canCurrentUserApproveNurConversion:
            data.canCurrentUserApproveNurConversion,
          canCurrentUserEdit: data.canCurrentUserEdit,
          currentUserIsGhosted: data.currentUserIsGhosted,
          emailExpirationDateTimestamp: data.expirationDate,
          emailScheduledDateTimestamp: data.emailScheduledDate,
          emailSentDateTimestamp: data.emailSentDate,
          newUserStatus: data.newUserRequestStatus?.generalStatus,
          queueItemGuid: data.queueItemGuid
        };

        this.setState({ user: userInfo });
        return ps.canEditUserProjects(userInfo) || ps.canEditUser(userInfo);
      })
      .then(canEditUser => {
        this.setState({ canEditUser });
      })
      .catch(error => {
        if (!ApiService.isCancel(error)) {
          pushToast({
            type: 'error',
            title: t('editUser.errorToast.findExistingUser')
          });
        }
      });

    // Get the user's projects
    this.fetchUserEngagements();
  }

  fetchUserEngagements(updates?: Partial<IPersonalInformationState>) {
    const { userGuid } = this.props.match.params;

    this.setState(
      { ...(updates as IPersonalInformationState), loading: true },
      async () => {
        const { page, searchQuery, pageSize, sorted } = this.state;
        const params: IUserEngagementsTableSearch = {
          pageNumber: page,
          searchTerm: searchQuery,
          itemsPerPage: pageSize,
          sort: getSortParam(sorted)
        };
        this.engagementsSource?.cancel();
        this.engagementsSource = ApiService.CancelToken.source();

        try {
          const {
            data: { results, totalResults }
          } = await ApiService.getEngagementsForUser(
            userGuid,
            params,
            this.engagementsSource.token
          );

          this.setState({
            engagements: results,
            totalCount: totalResults
          });
        } catch (error) {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
          }
        } finally {
          if (this._isMounted) {
            this.setState({ loading: false });
          }
        }
      }
    );
  }

  getRowClassName = (row: IEngagement) => {
    switch (row.accountHierarchy.length) {
      case 1:
        return 'level-1';
      case 2:
        return 'level-2';
      default:
        return 'level-3-or-more';
    }
  };

  onFilter = (filtered: Record<string, unknown>) => {
    this.fetchUserEngagements({ page: 1, filtered });
  };

  onSearch = debouncedSearch(
    (searchQuery: string) => {
      this.setState({ searchQuery });
    },
    (searchQuery: string) => {
      this.fetchUserEngagements({ page: 1, searchQuery });
    }
  );

  onSort = (sorted: any) => {
    this.fetchUserEngagements({ sorted });
  };

  onPage = (page: number, pageSize: number) => {
    this.fetchUserEngagements({ page, pageSize });
  };

  handleUserConversionModalClose = (isUpdateRequired: boolean) => {
    if (isUpdateRequired) {
      this.fetchUser();
    }

    this.setState({ isUserConversionModalOpen: false });
  };

  renderBreadcrumbs({ fullName, userGuid }: IUser) {
    const { t } = this.props;
    const locationState = this.props.location.state as
      | { pushFrom?: IBreadcrumbRoute[] }
      | undefined;

    if (locationState && locationState.pushFrom) {
      return (
        <>
          {locationState.pushFrom.map((breadcrumb, i) => (
            <Breadcrumb
              key={i}
              label={t(breadcrumb.title)}
              to={breadcrumb.route}
            />
          ))}
          <Breadcrumb
            label={t('contactInformation.personsProfile', { fullName })}
            to={`/app/personal-information/${userGuid}`}
          />
        </>
      );
    }
    return (
      <>
        <Breadcrumb label={t('team.title')} to="/app/team/" />
        <Breadcrumb
          label={t('contactInformation.personsProfile', { fullName })}
          to={`/app/personal-information/${userGuid}`}
        />
      </>
    );
  }

  render() {
    const { isAppReadOnly, permissionService: ps, t } = this.props;
    const { user } = this.state;

    if (user === null) {
      return `${t('Loading')}...`;
    }

    const {
      loading,
      engagements,
      searchQuery,
      sorted,
      page,
      pageSize,
      totalCount,
      filtered
    } = this.state;

    const isApprovedNURWithDeactivateDate =
      user.newUserStatus === NewUserRequestStatusEnums.APPROVED &&
      user.deactivateDate &&
      (user.activateDate == null || user.deactivateDate > user.activateDate);

    const isUserSinceLastActiveDisplayed =
      user.userTypeId === UserType.Ryan ||
      user.acceptedTermsOfUseDate != null ||
      user.newUserStatus === NewUserRequestStatusEnums.DENIED ||
      isApprovedNURWithDeactivateDate;

    return (
      <div className="personal-information-page">
        <DocumentTitle title="Personal Information" />
        {this.renderBreadcrumbs(user)}
        <div className="row">
          <div className="col-lg-4">
            <UserInfoCard isIncludeEditButton user={user} />

            {ps.isRyan() && !isUserSinceLastActiveDisplayed && (
              <WelcomeEmailCard
                isCurrentUserGhosted={user.currentUserIsGhosted!}
                mailToDetails={{
                  company: user.company,
                  fullName:
                    user.fullName || `${user.firstName} ${user.lastName}`.trim()
                }}
                queueItemGuid={user.queueItemGuid || null}
                timestamps={{
                  emailExpirationTimestamp:
                    user.emailExpirationDateTimestamp || null,
                  emailScheduledTimestamp:
                    user.emailScheduledDateTimestamp || null,
                  emailSentTimestamp: user.emailSentDateTimestamp || null
                }}
              />
            )}

            {ps.isRyan() && isUserSinceLastActiveDisplayed && (
              <div className="well">
                <ul className="row labeled-list">
                  <li className="col">
                    <label>{t('User Since')}</label>
                    {user.acceptedTermsOfUseDate
                      ? formatDate(user.acceptedTermsOfUseDate)
                      : '–'}
                  </li>
                  <li className="col">
                    <label>{t('Last Active')}</label>
                    {user.loginDate ? formatDate(user.loginDate, true) : '–'}
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="col-lg-8">
            <Table<IEngagement>
              columns={this.columns}
              data={engagements}
              filtered={filtered}
              loading={loading}
              onFilterChange={this.onFilter}
              onPageChange={this.onPage}
              onSearchChange={this.onSearch}
              onSortChange={this.onSort}
              page={page}
              pageSize={pageSize}
              renderActionPlacement={1}
              renderActions={this.renderActions}
              rowClassName={this.getRowClassName}
              rowId="engagementGuid"
              searchQuery={searchQuery}
              sorted={sorted}
              title={
                user.userGuid === this.props.user.profile.userGuid
                  ? t('contactInformation.myProjects')
                  : t(
                      'contactInformation.sharedProjects',
                      isAppReadOnly ? { context: 'readonly' } : {}
                    )
              }
              totalCount={totalCount}
            />
          </div>
        </div>

        {this.state.isUserConversionModalOpen && (
          <UserConversionModal
            accountGuid={user.accountGuid as any as string}
            onClose={this.handleUserConversionModalClose as any}
            user={user}
          />
        )}
      </div>
    );
  }

  recentlyVisited = (path: string): boolean => {
    const pathHistory = this.context.pathHistory as string[];

    let found = false;

    if (pathHistory.length > 0) {
      const result = pathHistory.slice(-5).find(element => {
        const elementWithoutGuids = element.replace(
          /\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g,
          ''
        );
        return elementWithoutGuids.includes(path);
      });

      found = !!result;
    }

    return found;
  };

  renderActions = () => {
    const { permissionService } = this.props;
    const { canEditUser, user } = this.state;

    const pathHistory = this.context.pathHistory as string[];

    if (!user || !permissionService.isRyan()) {
      return null;
    }

    const { isAppReadOnly, history, t: textToDisplay } = this.props;
    const {
      activateDate: userActivateDate,
      canCurrentUserApproveNurConversion: isActiveUserWithNewUserApprovalAccess,
      currentUserIsGhosted: isActiveUserGhostedOnAllSharedProjects,
      deactivateDate: userDeactivateDate,
      newUserStatus: userNewUserStatus,
      userGuid,
      userTypeId,
      canCurrentUserEdit
    } = user;

    const isActiveUserWithEditAccess =
      (canEditUser && canCurrentUserEdit) || false;

    const editUserAccessCallback = () => {
      history.push(`/app/team/edit-user/${userGuid}`, {
        isFromPersonalInformationPage: true
      });
    };

    let actionOption = null;

    if (userTypeId === UserType.Ryan) {
      if (isActiveUserWithEditAccess) {
        actionOption = getEditUserAccessOptions(
          editUserAccessCallback,
          textToDisplay
        );
      }
    } else {
      actionOption = getOverflowOptionForNonRyanUser({
        editUserAccessCallback,
        isActiveUserWithEditAccess,
        isActiveUserWithNewUserApprovalAccess:
          isActiveUserWithNewUserApprovalAccess || false,
        isDeferredActivation: getIsDeferredActivation(
          userActivateDate,
          userDeactivateDate
        ),
        newUserRequestStatus:
          userNewUserStatus || NewUserRequestStatusEnums.NULL,
        openModalCallback: () => {
          if (
            isActiveUserWithNewUserApprovalAccess &&
            (userNewUserStatus === undefined ||
              userNewUserStatus === NewUserRequestStatusEnums.PENDING ||
              userNewUserStatus === NewUserRequestStatusEnums.NULL) &&
            pathHistory.length > 0
          ) {
            let grantUserAccessPath: string | null = null;

            if (
              this.recentlyVisited('/app/project/team') &&
              this.recentlyVisited('/app/team/manage/client')
            ) {
              grantUserAccessPath = 'profile - manage single project team';
            } else if (
              this.recentlyVisited('/app/project/') &&
              this.recentlyVisited('/overview')
            ) {
              grantUserAccessPath = 'profile - single project team page';
            } else if (this.recentlyVisited('/app/team/manage/client')) {
              grantUserAccessPath = 'profile - manage big team';
            } else if (this.recentlyVisited('/app/team/client')) {
              grantUserAccessPath = 'profile - big team page';
            }

            grantUserAccessPath &&
              AmplitudeApiService.logEvent('grant-user-access', {
                'grant-access-path': grantUserAccessPath
              });
          }
          this.setState({ isUserConversionModalOpen: true });
        },
        textToDisplay
      });
    }

    return actionOption ? (
      <Button
        disabled={isAppReadOnly || isActiveUserGhostedOnAllSharedProjects}
        icon={actionOption.icon}
        onClick={actionOption.onClick}
        text={actionOption.label}
        variant="text"
      />
    ) : null;
  };
}

export default withTranslation()(withUser(PersonalInformationPage));
