import { TDataRequestActionsProps } from './ActionsByType.interfaces';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button, SplitButton } from '@ryan/components';

import { useAppReadOnly, useUser } from '../../../hooks';
import { Permission } from '../../../interfaces';
import { ButtonSizeEnums } from '../../../utils/enums/ButtonPropsEnums';
import { IconEnums } from '../../../utils/enums/IconEnums';

const DataRequestActions = ({
  isEngagementReadOnly,
  onOpenCompleteModal,
  onOpenDeleteModal,
  onOpenEditModal,
  queueItemGuid
}: TDataRequestActionsProps) => {
  const ROOT_TO_TEXT = 'worklistPage.openTab.actionsByType.dataRequest';

  const isAppReadOnly = useAppReadOnly();
  const history = useHistory();
  const { t: getTextToDisplay } = useTranslation();
  const { permissionService } = useUser();

  const defaultProps = {
    block: true,
    onClick: () => {
      history.push(`/app/data-request/${queueItemGuid}`);
    },
    size: ButtonSizeEnums.SMALL
  };
  const isDisabledSplitButtonOptions = isAppReadOnly || isEngagementReadOnly;

  const splitButtonOptions = [
    {
      disabled: isDisabledSplitButtonOptions,
      icon: IconEnums.PENCIL,
      label: getTextToDisplay(`${ROOT_TO_TEXT}.edit`),
      onClick: onOpenEditModal
    },
    {
      disabled: isDisabledSplitButtonOptions,
      icon: IconEnums.APPROVE,
      label: getTextToDisplay(`${ROOT_TO_TEXT}.complete`),
      onClick: onOpenCompleteModal
    },
    {
      disabled: isDisabledSplitButtonOptions,
      icon: IconEnums.TRASH,
      label: getTextToDisplay(`${ROOT_TO_TEXT}.delete`),
      negative: true,
      onClick: onOpenDeleteModal
    }
  ];

  return permissionService.hasPermission(Permission.DataRequestsEdit) ? (
    <SplitButton {...defaultProps} options={splitButtonOptions}>
      {getTextToDisplay(`${ROOT_TO_TEXT}.review`)}
    </SplitButton>
  ) : (
    <Button {...defaultProps}>
      {getTextToDisplay(
        `${ROOT_TO_TEXT}.${
          permissionService.isClient() ? 'transferData' : 'view'
        }`
      )}
    </Button>
  );
};

export default DataRequestActions;
