import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteChildrenProps } from 'react-router';

import { Button } from '@ryan/components';

export interface IResetPasswordSuccessProps
  extends RouteChildrenProps,
    WithTranslation {
  /**
   * Indicates a new user has successfully created their password. Updates
   * component verbiage.
   */
  newUser?: boolean;
}

/**
 * Renders after successful password reset from `ResetPassword` component.
 */
const ResetPasswordSuccess: React.FunctionComponent<IResetPasswordSuccessProps> =
  ({ history, newUser = false, t }) => (
    <>
      <p style={{ marginBottom: '2rem' }}>
        {t('resetPasswordSuccess.text', newUser ? { context: 'newUser' } : {})}
      </p>
      <Button
        onClick={() => history.push('/app')}
        role="link"
        size="lg"
        text={t('resetPasswordSuccess.button')}
        type="button"
        variant="primary"
      />
    </>
  );

export default withTranslation()(ResetPasswordSuccess);
