import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonGroup } from '@ryan/components';

import { useUser } from '../../../contexts/UserContext';
import { Feature } from '../../../interfaces';
import ApiService from '../../../services/ApiService';
import Modal from '../../Modal';
import { FileBatch } from '../utils/FileBatch';
import { getFolderAndFileActionStatusTitle } from '../utils/getFolderAndFileActionStatusTitle';

interface IFileBatchDeleteModalProps {
  engagementGuid: string;
  files: FileBatch | null;
  loading: Promise<any> | null;
  onSubmit: () => void;
  onCancel: () => void;
}

export const FileBatchDeleteModal: FunctionComponent<
  IFileBatchDeleteModalProps
> = ({
  engagementGuid: currentEngagementGuid,
  files,
  loading,
  onSubmit,
  onCancel
}) => {
  const { t: getTextToDisplay } = useTranslation();
  const { isFeatureToggled } = useUser();
  const isLockedFilesFunctionalityVisible = isFeatureToggled(
    Feature.LockedFiles
  );
  const [
    numberOfLinksForCurrentEngagement,
    setNumberOfLinksForCurrentEngagement
  ] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const areLockedFilesInsideFolder =
    files?.folders.some(folder => folder.lockedFilesCount > 0) || false;

  const fileCount = (files?.files || []).length;
  const folderCount = (files?.folders || []).length;
  const lockedFolderCount =
    files?.folders.filter(
      folder => folder.lockedFilesCount && folder.lockedFilesCount > 0
    ).length || 0;

  const isSingleFile = fileCount === 1;
  const isMultipleFiles = fileCount > 1;
  const isSingleFolderWithLock = lockedFolderCount === 1;
  const isMultipleFoldersWithLock = lockedFolderCount > 1;
  const hasOtherFolders = folderCount > lockedFolderCount;

  let modalContent;

  if (isLockedFilesFunctionalityVisible) {
    if (isMultipleFiles && isMultipleFoldersWithLock && !hasOtherFolders) {
      modalContent = (
        <p>
          {getTextToDisplay(
            'file.batchDeleteModal.lockedFiles.confirmationModalDescriptionPlural'
          )}
        </p>
      );
    } else if (isSingleFile || isMultipleFiles) {
      if (
        (isSingleFolderWithLock || isMultipleFoldersWithLock) &&
        hasOtherFolders
      ) {
        modalContent = (
          <p>
            {getTextToDisplay(
              'file.batchDeleteModal.lockedFiles.confirmationModalFilesAndFoldersDescription'
            )}
          </p>
        );
      } else if (isMultipleFoldersWithLock) {
        modalContent = (
          <p>
            {getTextToDisplay(
              'file.batchDeleteModal.lockedFiles.confirmationModalDescriptionPlural'
            )}
          </p>
        );
      } else if (isSingleFolderWithLock) {
        modalContent = (
          <p>
            {getTextToDisplay(
              'file.batchDeleteModal.lockedFiles.confirmationModalDescription'
            )}
          </p>
        );
      }
    } else if (isSingleFolderWithLock) {
      modalContent = (
        <p>
          {getTextToDisplay(
            'file.batchDeleteModal.lockedFiles.confirmationModalDescription'
          )}
        </p>
      );
    } else if (isMultipleFoldersWithLock && !isSingleFile && !isMultipleFiles) {
      modalContent = (
        <p>
          {getTextToDisplay(
            'file.batchDeleteModal.lockedFiles.confirmationModalDescriptionPlural'
          )}
        </p>
      );
    }
  }
  // Will fall to this condition if none of the conditions related to folders with locked files matched.
  if (!modalContent) {
    if (files?.files.length === 1) {
      // If only one file
      modalContent =
        numberOfLinksForCurrentEngagement > 1 ? (
          // There is only one file, and that file contains a link
          <>
            <p>
              {getTextToDisplay('file.batchDeleteModal.linkContent', {
                count: numberOfLinksForCurrentEngagement
              })}
            </p>
            <p>
              {getTextToDisplay('file.batchDeleteModal.contentWithLinks', {
                count: files.filesCount
              })}
            </p>
          </>
        ) : (
          // The one file does not contain any links
          <p>
            {getTextToDisplay('file.batchDeleteModal.contentNoLinks', {
              count: files.filesCount
            })}
          </p>
        );
    } else if (files?.files.some(f => f.linkCount > 0)) {
      // The user has selected more than one file and they contain links
      modalContent = (
        <>
          <p>{getTextToDisplay('file.batchDeleteModal.linkContentBatch')}</p>
          <p>
            {getTextToDisplay('file.batchDeleteModal.contentWithLinks', {
              count: files.filesCount
            })}
          </p>
        </>
      );
    } else {
      modalContent = (
        // The files selected do not contains links
        <p>
          {getTextToDisplay('file.batchDeleteModal.contentNoLinks', {
            count: files?.filesCount
          })}
        </p>
      );
    }
  }

  useEffect(() => {
    const fetchLinksForFile = async (
      engagementGuid: string,
      documentGuid: string
    ) => {
      ApiService.getFileLinks({
        engagementGuid,
        documentGuid
      })
        .then(({ data }) => {
          const { results } = data;
          const count = results.filter(
            item => item.engagementGuid === currentEngagementGuid
          ).length;
          setNumberOfLinksForCurrentEngagement(count);
        })
        .finally(() => {
          setLoaded(true);
        });
    };

    if (files && files.files.length === 1) {
      if (files.files[0].linkCount > 1) {
        const documentGuid = files.files[0].documentGuid;
        const engagementGuid = files.files[0].engagementGuid;
        fetchLinksForFile(engagementGuid, documentGuid);
      } else if (files.files[0].linkCount === 1) {
        setNumberOfLinksForCurrentEngagement(1);
        setLoaded(true);
      }
    } else {
      setLoaded(true);
    }
  }, [files, currentEngagementGuid]);

  if (files && loaded) {
    const title =
      isLockedFilesFunctionalityVisible && areLockedFilesInsideFolder
        ? getTextToDisplay(
            'file.batchDeleteModal.lockedFiles.confirmationModalTitle'
          )
        : getTextToDisplay(
            ...getFolderAndFileActionStatusTitle({
              basePathToTitle: 'file.batchDeleteModal.title',
              files
            })
          );

    return (
      <Modal onClose={onCancel} open={loaded} title={title}>
        {modalContent}
        <ButtonGroup>
          <Button
            loading={loading}
            negative
            onClick={onSubmit}
            text={
              isLockedFilesFunctionalityVisible && areLockedFilesInsideFolder
                ? getTextToDisplay(
                    'file.batchDeleteModal.lockedFiles.confirmationModalSubmitAction'
                  )
                : getTextToDisplay('Yes, Delete')
            }
          />
          <Button
            disabled={loading !== null}
            onClick={onCancel}
            text={getTextToDisplay('No, Cancel')}
            variant="secondary"
          />
        </ButtonGroup>
      </Modal>
    );
  }

  return null;
};

export default FileBatchDeleteModal;
