import Modal from 'components/Modal';
import UploadModalWarning from 'components/UploadModalWarning/UploadModalWarning';
import { useEngagement } from 'contexts/EngagementContext';
import { WithUser, withUser } from 'contexts/UserContext';
import { IDataRequest } from 'interfaces';
import { useDSSManager } from 'utils/DSS';

import React, { FunctionComponent, useCallback } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import DataRequestForm from './DataRequestForm';

import './DataRequestModal.scss';

interface IDataRequestModalProps extends WithTranslation, WithUser {
  open?: boolean;
  dataRequest?: IDataRequest;
  engagementGuid?: string;
  onClose: (dataRequest?: IDataRequest) => void;
}

export const DataRequestModal: FunctionComponent<IDataRequestModalProps> = ({
  t,
  open = true,
  dataRequest,
  engagementGuid,
  activeView: { customViewGuid },
  onClose: onCloseProp
}) => {
  /**
   * Hook gives us an instance, and forces re-render when DSSManager is updated.
   * Like how class components use `new DSSManager({ onChange: () => this.forceUpdate() })`
   * @todo DSSManager is not idomatic and should be refactored.
   */
  const dss = useDSSManager();
  const { refreshUpdateDate } = useEngagement();

  // Clear uploads on close.
  const onClose = useCallback(() => {
    onCloseProp();
    dss.clearUploads();
  }, [dss, onCloseProp]);

  function handleSubmitted(dataRequest?: IDataRequest) {
    onCloseProp(dataRequest);
    refreshUpdateDate?.(dataRequest?.engagementGuid);
    dss.clearUploads();
  }

  return (
    <UploadModalWarning dss={dss} onClose={onClose}>
      {({ dss, warning, onEscape, onCancel }) => (
        <Modal
          className="data-request-modal"
          onClose={onEscape}
          open={open}
          title={t(
            dataRequest
              ? 'dataRequest.modal.edit.title'
              : 'dataRequest.modal.new.title'
          )}
        >
          {warning}
          <DataRequestForm
            customViewGuid={customViewGuid}
            dataRequest={dataRequest}
            dss={dss}
            engagementGuid={engagementGuid}
            onCancel={onCancel}
            onSubmitted={handleSubmitted}
          />
        </Modal>
      )}
    </UploadModalWarning>
  );
};

export default withTranslation()(withUser(DataRequestModal));
