import Modal from 'components/Modal';
import EngagementContext from 'contexts/EngagementContext';
import { ILearning } from 'interfaces';
import ApiService from 'services/ApiService';

import React, { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import { Button, ButtonGroup, pushToast } from '@ryan/components';

interface ILearningDeleteModalProps extends WithTranslation {
  learning: ILearning;
  open: boolean;
  onClose: (deleted: boolean) => void;
}

export class LearningDeleteModal extends Component<ILearningDeleteModalProps> {
  static contextType = EngagementContext;
  context!: React.ContextType<typeof EngagementContext>;

  handleCancel = () => {
    this.props.onClose(false);
  };

  handleDelete = async () => {
    const {
      t,
      learning: { title, engagementGuid, learningGuid },
      onClose
    } = this.props;
    try {
      await ApiService.deleteLearning(engagementGuid, learningGuid);
      pushToast({
        type: 'success',
        title: t('learning.deleteModal.success.title'),
        content: (
          <Trans i18nKey="learning.deleteModal.success.content">
            <b>{{ title }}</b> has been deleted.
          </Trans>
        )
      });
      onClose(true);
    } catch (error) {
      pushToast({
        type: 'error',
        title: t('serverError.title'),
        content: t('serverError.content')
      });
      onClose(false);
    }
  };

  render() {
    const { t, open } = this.props;
    return (
      <Modal
        onClose={this.handleCancel}
        open={open}
        title={t('learning.deleteModal.title')}
      >
        <p>{t('learning.deleteModal.content')}</p>
        <ButtonGroup>
          <Button
            negative
            onClick={this.handleDelete}
            text={t('Yes, Delete')}
          />
          <Button
            onClick={this.handleCancel}
            text={t('No, Cancel')}
            variant="secondary"
          />
        </ButtonGroup>
      </Modal>
    );
  }
}

export default withTranslation()(LearningDeleteModal);
