import {
  IGetCommentButtonAttributes,
  IMapAssignmentToUpdateTaskApi,
  IMapResultsToWorklist
} from './WorklistOpen.interfaces';

import { EntityType, Permission } from '../../../interfaces';
import {
  ButtonSizeEnums,
  ButtonVariantEnums
} from '../../../utils/enums/ButtonPropsEnums';
import { IconEnums } from '../../../utils/enums/IconEnums';

export enum WorklistTableEnums {
  ACTION = 'action',
  ASSIGNED_TO = 'assignedTo',
  COMMENT = 'comment',
  DUE_DATE = 'dueDate',
  PROJECT = 'engagementDisplayNameShort',
  STATUS = 'status',
  TITLE = 'title',
  TYPE = 'entityType'
}

export const getCommentButtonAttributes: IGetCommentButtonAttributes = ({
  commentCount,
  confirmPermission,
  isAppReadOnly,
  isEngagementReadOnly,
  onDataRequestCommentClick,
  onTaskCommentClick,
  typeId
}) => {
  const hasComments = commentCount > 0;
  const isReadyByState = isAppReadOnly || isEngagementReadOnly;
  const isReadOnlyByPermissions =
    (typeId === EntityType.DataRequest &&
      !confirmPermission(Permission.DataRequestsContribute)) ||
    (typeId === EntityType.Task &&
      !confirmPermission(Permission.TasksContribute));

  let textPath =
    isReadyByState || isReadOnlyByPermissions ? 'viewCommentsWithCount' : '';
  if (!textPath) {
    textPath = hasComments ? 'commentsWithCount' : 'addAComment';
  }

  let onClick = () => {};
  switch (typeId) {
    case EntityType.DataRequest:
      onClick = onDataRequestCommentClick;
      break;
    case EntityType.Task:
      onClick = onTaskCommentClick;
      break;
    default:
  }

  return {
    defaultProps: {
      icon: hasComments ? IconEnums.COMMENT_ALERT : IconEnums.COMMENT,
      onClick,
      size: ButtonSizeEnums.SMALL,
      variant: ButtonVariantEnums.TEXT
    },
    textPath
  };
};

export const mapAssignmentToUpdateTaskApi: IMapAssignmentToUpdateTaskApi = ({
  assignmentAssignedTo,
  assignmentDescription,
  assignmentDueDate,
  assignmentStatusId,
  assignmentTitle,
  engagementGuid,
  engagementMilestoneGuid,
  isAssignmentCurrentlyWatched,
  queueItemGuid
}) => ({
  assignedToUserGuid: assignmentAssignedTo.userGuid,
  description: assignmentDescription,
  dueDate: new Date(assignmentDueDate),
  engagementGuid,
  engagementMilestoneGuid,
  isCurrentUserWatching: isAssignmentCurrentlyWatched,
  queueItemGuid,
  statusId: assignmentStatusId,
  title: assignmentTitle
});

export const mapResultsToWorklist: IMapResultsToWorklist = results => {
  return results.map(
    ({
      accountName,
      assignedToName,
      assignedToUserGuid,
      attachments,
      commentsCount,
      createDate,
      createdBy,
      createdByName,
      createdByUserType,
      dataSpecs,
      defaultFolderGuid,
      description,
      dueDate,
      engagementDisplayNameShort,
      engagementGuid,
      engagementId,
      engagementMilestoneGuid,
      engagementMilestoneTitle,
      entityType,
      isCurrentUserWatching,
      isEngagementReadOnly,
      queueItemGuid,
      status,
      title,
      transferredFilesCount,
      watcherCount
    }) => ({
      ...(dataSpecs !== null && {
        assignmentDataSpecs: dataSpecs
      }),
      ...(engagementMilestoneGuid !== null && {
        engagementMilestoneGuid
      }),
      ...(engagementMilestoneTitle !== null && {
        engagementMilestoneTitle
      }),
      ...(transferredFilesCount !== null && {
        assignmentTransferredFilesCount: transferredFilesCount
      }),
      accountName,
      assignmentAssignedTo: {
        name: assignedToName,
        userGuid: assignedToUserGuid
      },
      assignmentAttachments: attachments || [],
      assignmentCommentsCount: commentsCount,
      assignmentCreatedBy: {
        name: createdByName,
        userGuid: createdBy,
        userType: createdByUserType
      },
      assignmentCreatedDate: createDate,
      assignmentDescription: description,
      assignmentDueDate: dueDate,
      assignmentFollowerCount: watcherCount,
      assignmentStatusId: status,
      assignmentTitle: title,
      assignmentTypeId: entityType,
      defaultFolderGuid,
      engagementDisplayNameShort,
      engagementGuid,
      engagementId,
      isAssignmentCurrentlyWatched: isCurrentUserWatching,
      isEngagementReadOnly,
      queueItemGuid
    })
  );
  // TODO: Not used
  // isEngagementActive: true
  // watcherGhostedCount: 0
};
