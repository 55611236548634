import { WithUser, withUser } from 'contexts/UserContext';

import classnames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Tooltip } from '@ryan/components';

import './AccountHierarchy.scss';

interface IAccountSummary {
  accountGuid: string;
  name: string;
}

interface IAccountHierarchyProps extends WithUser {
  hierarchy: IAccountSummary[];
  isPublished?: boolean;
}

export const AccountHierarchy: FunctionComponent<IAccountHierarchyProps> = ({
  permissionService: ps,
  hierarchy
}) => {
  const expandedView = ps.isRyan();
  const { t } = useTranslation();

  const hierarchyNodes = (
    <div className="account-hierarchy__accounts">
      {hierarchy.map((account: IAccountSummary, i: number) => (
        <div className="account-hierarchy__account" key={account.accountGuid}>
          {i !== 0 && <Icon name="arrow-nested" />}
          {account.name}
        </div>
      ))}
    </div>
  );

  return (
    <Tooltip
      className={classnames({
        'account-hierarchy': true,
        'tooltip-list': expandedView
      })}
      content={
        expandedView ? (
          <ul>
            <li>
              <label>{t('projects.accountHierarchy')}</label>
              {hierarchyNodes}
            </li>
          </ul>
        ) : (
          hierarchyNodes
        )
      }
      placement="top"
      renderTarget={({ open, ...props }) => (
        <button className="account-hierarchy-target" type="button" {...props}>
          <span className="ry-sr-only">
            {t('projects.projectLevel', { level: hierarchy.length })}
          </span>
          <Icon name={`stacks-${Math.min(hierarchy.length, 9)}`} />
        </button>
      )}
    />
  );
};

export default withUser(AccountHierarchy);
