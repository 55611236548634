import {
  IHandleOpenDataRequestDrawer,
  IHandleOpenTaskDrawer,
  IHandleToggleFollow,
  TWorklistAssignment
} from './WorklistOpen.interfaces';
import {
  WorklistTableEnums,
  getCommentButtonAttributes,
  mapAssignmentToUpdateTaskApi,
  mapResultsToWorklist
} from './WorklistOpen.utils';
import Empty from 'components/Empty';

import { CancelToken } from 'axios';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  EMessageTypes,
  ITableColumn,
  Table,
  Tooltip,
  pushToast
} from '@ryan/components';

import FollowersModal from '../../../components/FollowersModal/FollowersModal';
import StatusIcon from '../../../components/StatusIcon/StatusIcon';
import TableEmpty from '../../../components/TableEmpty/TableEmpty';
import { useDataRequestDrawer } from '../../../contexts/DataRequestDrawerContext';
import { useTaskDrawer } from '../../../contexts/TaskDrawerContext';
import { useAppReadOnly, useUser } from '../../../hooks';
import {
  EntityType,
  IDataRequest,
  ITableState,
  ITask,
  Status,
  UserType
} from '../../../interfaces';
import ApiService, { CancelTokenSource } from '../../../services/ApiService';
import debouncedSearch from '../../../utils/debouncedSearch';
import { formatDate } from '../../../utils/formatDate';
import getSortParam from '../../../utils/getSortParm';
import { isPastDue } from '../../../utils/isPastDue';
import pushServerErrorToast from '../../../utils/pushServerErrorToast';
import DataRequestActions from '../ActionsByType/DataRequestActions';
import TaskActions from '../ActionsByType/TaskActions';
import DataRequestModals from '../DataRequestModals/DataRequestModals';
import { DataRequestModalActionEnums } from '../DataRequestModals/DataRequestModals.utils';
import DataRequestDetails from '../DetailsByType/DataRequestDetails';
import TaskDetails from '../DetailsByType/TaskDetails';
import TaskModals from '../TaskModals/TaskModals';
import { TaskModalActionEnums } from '../TaskModals/TaskModals.utils';

import './WorklistOpen.scss';

const WorklistOpen = () => {
  const ROOT_TO_TEXT = 'worklistPage.openTab';

  const isAppReadOnly = useAppReadOnly();
  const {
    dataRequestDrawerEvents,
    onDataRequestDrawerOpenComments,
    onDataRequestDrawerOpenHistory
  } = useDataRequestDrawer();
  const { onTaskDrawerOpen, taskDrawerEvents } = useTaskDrawer();
  const { t: getTextToDisplay } = useTranslation();
  const {
    permissionService,
    user: { profile: activeUserProfile }
  } = useUser();

  const sourceRef = useRef<CancelTokenSource>();
  const [assignmentToComplete, setAssignmentToComplete] =
    useState<TWorklistAssignment | null>(null);
  const [assignmentToDelete, setAssignmentToDelete] =
    useState<TWorklistAssignment | null>(null);
  const [assignmentToEdit, setAssignmentToEdit] =
    useState<TWorklistAssignment | null>(null);
  const [assignmentToReassign, setAssignmentToReassign] =
    useState<TWorklistAssignment | null>(null);
  const [assignmentToUpdateFollowers, setAssignmentToUpdateFollowers] =
    useState<TWorklistAssignment | null>(null);
  const [expandedRows, setExpandedRows] = useState<{
    [rowId: string]: boolean;
  }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingFollowStatus, setIsUpdatingFollowStatus] = useState(false);
  const [pageView, setPageView] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [searchQuery, setSearchQuery] = useState('');
  const [sorted, setSorted] = useState<ITableState['sorted']>({
    id: WorklistTableEnums.DUE_DATE,
    desc: false
  });
  const [totalResults, setTotalResults] = useState(0);
  const [worklist, setWorklist] = useState<TWorklistAssignment[]>([]);

  const columns = useMemo(
    (): ITableColumn<TWorklistAssignment>[] => [
      {
        id: WorklistTableEnums.TITLE,
        label: getTextToDisplay(
          `${ROOT_TO_TEXT}.columns.${WorklistTableEnums.TITLE}`
        ),
        render: ({ accountName, assignmentTitle }) => (
          <div className="column-title">
            <span>{assignmentTitle}</span>
            <small>{accountName}</small>
          </div>
        ),
        sortable: true
      },
      {
        id: WorklistTableEnums.PROJECT,
        label: getTextToDisplay(
          `${ROOT_TO_TEXT}.columns.${WorklistTableEnums.PROJECT}`
        ),
        render: ({ engagementDisplayNameShort, engagementId }) => (
          <div className="column-project">
            <span>{engagementDisplayNameShort}</span>
            {permissionService.isRyan() && <small>{engagementId}</small>}
          </div>
        ),
        sortable: true
      },
      {
        id: WorklistTableEnums.TYPE,
        label: getTextToDisplay(
          `${ROOT_TO_TEXT}.columns.${WorklistTableEnums.TYPE}`
        ),
        render: ({ assignmentTypeId }) =>
          getTextToDisplay(`${ROOT_TO_TEXT}.assignmentTypes`, {
            context: Object.values(EntityType)[assignmentTypeId - 1]
          }),
        sortable: true
      },
      {
        id: WorklistTableEnums.STATUS,
        label: getTextToDisplay(
          `${ROOT_TO_TEXT}.columns.${WorklistTableEnums.STATUS}`
        ),
        render: ({ assignmentStatusId }) => (
          <div className="column-status">
            <StatusIcon status={assignmentStatusId} />
          </div>
        ),
        sortable: true
      },
      {
        id: WorklistTableEnums.ASSIGNED_TO,
        label: getTextToDisplay(
          `${ROOT_TO_TEXT}.columns.${WorklistTableEnums.ASSIGNED_TO}`
        ),
        render: ({ assignmentAssignedTo }) =>
          assignmentAssignedTo.userGuid === activeUserProfile.memberGuid
            ? getTextToDisplay(`${ROOT_TO_TEXT}.assignedToYou`)
            : assignmentAssignedTo.name,
        sortable: true
      },
      {
        id: WorklistTableEnums.DUE_DATE,
        label: getTextToDisplay(
          `${ROOT_TO_TEXT}.columns.${WorklistTableEnums.DUE_DATE}`
        ),
        render: ({ assignmentDueDate }) => (
          <span className="column-due-date">
            {formatDate(assignmentDueDate)}
          </span>
        ),
        sortable: true
      },
      {
        id: WorklistTableEnums.COMMENT,
        label: '',
        render: row => {
          const { defaultProps, textPath } = getCommentButtonAttributes({
            commentCount: row.assignmentCommentsCount,
            confirmPermission: permission =>
              permissionService.hasPermission(permission),
            isAppReadOnly,
            isEngagementReadOnly: row.isEngagementReadOnly,
            onDataRequestCommentClick: () => {
              handleOpenDataRequestDrawer(row, onDataRequestDrawerOpenComments);
            },
            onTaskCommentClick: () => {
              handleOpenTaskDrawer(row, onTaskDrawerOpen);
            },
            typeId: row.assignmentTypeId
          });

          return (
            <Tooltip
              content={getTextToDisplay(`${ROOT_TO_TEXT}.${textPath}`, {
                count: row.assignmentCommentsCount
              })}
              placement="top"
              renderTarget={({ open, ref, ...props }) => (
                <Button
                  {...props}
                  {...defaultProps}
                  aria-expanded={open}
                  aria-haspopup="true"
                  className="comment-button"
                  innerRef={ref}
                />
              )}
            />
          );
        }
      },
      {
        id: WorklistTableEnums.ACTION,
        label: '',
        render: row => {
          const {
            assignmentCreatedBy,
            assignmentStatusId,
            assignmentTypeId,
            isEngagementReadOnly,
            queueItemGuid
          } = row;
          let ActionElement = null;

          const defaultProps = {
            isEngagementReadOnly,
            onOpenCompleteModal: () => {
              setAssignmentToComplete(row);
            },
            onOpenDeleteModal: () => {
              setAssignmentToDelete(row);
            },
            onOpenEditModal: () => {
              setAssignmentToEdit(row);
            }
          };

          const onMarkTaskInProgress = async () => {
            try {
              const { data } = await ApiService.updateTask(
                mapAssignmentToUpdateTaskApi(row) as ITask,
                { statusId: Status.InProgress }
              );
              pushToast({
                content: getTextToDisplay(
                  `${ROOT_TO_TEXT}.markInProgressSucessToastContent`,
                  { title: data.title }
                ),
                title: getTextToDisplay(
                  `${ROOT_TO_TEXT}.markInProgressSucessToastTitle`
                ),
                type: EMessageTypes.SUCCESS
              });

              setIsLoading(true);
            } catch {
              pushServerErrorToast();
            }
          };

          switch (assignmentTypeId) {
            case EntityType.DataRequest:
              ActionElement = (
                <DataRequestActions
                  {...defaultProps}
                  queueItemGuid={queueItemGuid}
                />
              );
              break;
            case EntityType.Task:
              ActionElement = (
                <TaskActions
                  {...defaultProps}
                  createdByUserType={assignmentCreatedBy.userType as UserType}
                  onMarkInProgress={onMarkTaskInProgress}
                  onOpenReassignModal={() => {
                    setAssignmentToReassign(row);
                  }}
                  statusId={assignmentStatusId}
                />
              );
              break;
            default:
          }

          return ActionElement;
        }
      }
    ],
    [
      activeUserProfile.memberGuid,
      isAppReadOnly,
      getTextToDisplay,
      onDataRequestDrawerOpenComments,
      onTaskDrawerOpen,
      permissionService
    ]
  );

  const getWorklistAssignments = useCallback(
    async (cancelToken: CancelToken) => {
      const { data } = await ApiService.getWorklistAssignments(cancelToken, {
        itemsPerPage: pageSize,
        pageNumber: pageView,
        searchTerm: searchQuery,
        sort: getSortParam(sorted)
      });

      const mappedWorklist = mapResultsToWorklist(data.results);
      setTotalResults(data.totalResults);
      setWorklist(mappedWorklist);
      setIsUpdatingFollowStatus(false);
      setIsLoading(false);
    },
    [pageSize, pageView, searchQuery, setIsLoading, setWorklist, sorted]
  );

  const onSearch = useMemo(
    () =>
      debouncedSearch(
        (searchQuery: string) => {
          setSearchQuery(searchQuery);
        },
        (searchQuery: string) => {
          setSearchQuery(searchQuery);
          setPageView(1);
          setIsLoading(true);
        }
      ),
    []
  );

  useEffect(() => {
    const refreshData = () => {
      setIsLoading(true);
    };

    dataRequestDrawerEvents.addListener('commentAdded', refreshData);
    dataRequestDrawerEvents.addListener('commentEdited', refreshData);
    dataRequestDrawerEvents.addListener('commentRemoved', refreshData);
    taskDrawerEvents.addListener('commentAdded', refreshData);
    taskDrawerEvents.addListener('commentEdited', refreshData);
    taskDrawerEvents.addListener('commentRemoved', refreshData);
  }, [dataRequestDrawerEvents, taskDrawerEvents]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    sourceRef.current = ApiService.CancelToken.source();
    getWorklistAssignments(sourceRef.current.token);

    return () => {
      sourceRef.current?.cancel();
    };
  }, [isLoading, getWorklistAssignments, sourceRef]);

  const handleOpenDataRequestDrawer: IHandleOpenDataRequestDrawer = (
    worklistAssignment,
    onDataRequestDrawerOpenCallback
  ) => {
    const dataRequest = {
      engagementGuid: worklistAssignment.engagementGuid,
      engagementDisplayNameShort: worklistAssignment.engagementDisplayNameShort,
      isEngagementReadOnly: worklistAssignment.isEngagementReadOnly,
      queueItemGuid: worklistAssignment.queueItemGuid,
      title: worklistAssignment.assignmentTitle
    } as IDataRequest;

    onDataRequestDrawerOpenCallback(dataRequest);
  };

  const handleOpenTaskDrawer: IHandleOpenTaskDrawer = (
    worklistAssignment,
    onTaskDrawerOpenCallback
  ) => {
    const modifiedTask = {
      engagementGuid: worklistAssignment.engagementGuid,
      engagementDisplayNameShort: worklistAssignment.engagementDisplayNameShort,
      isEngagementReadOnly: worklistAssignment.isEngagementReadOnly,
      queueItemGuid: worklistAssignment.queueItemGuid,
      title: worklistAssignment.assignmentTitle
    } as ITask;

    onTaskDrawerOpenCallback(modifiedTask);
  };

  const handleToggleFollow: IHandleToggleFollow = async (
    { engagementGuid, isAssignmentCurrentlyWatched, queueItemGuid },
    callbacks
  ) => {
    try {
      callbacks.setIsUpdatingFollowStatus(true);

      await ApiService.addWatcherToQueue(
        engagementGuid,
        !isAssignmentCurrentlyWatched,
        queueItemGuid
      );

      callbacks.setIsLoading(true);
    } catch {
      pushServerErrorToast();
    }
  };

  return (
    <div className="worklist-open">
      <Table<TWorklistAssignment>
        columns={columns}
        data={worklist}
        expanded={expandedRows}
        loading={isLoading}
        onPageChange={(page: number, pageSize: number) => {
          setPageView(page);
          setPageSize(pageSize);
          setIsLoading(true);
        }}
        onSearchChange={onSearch}
        onSortChange={(sorted: any) => {
          setSorted(sorted);
          setIsLoading(true);
        }}
        onToggleExpansion={(isRowExpanded, row, rowId) => {
          setExpandedRows({
            ...expandedRows,
            [rowId]: isRowExpanded
          });
        }}
        page={pageView}
        pageSize={pageSize}
        pageSizeOptions={[10, 25, 50, 100]}
        renderEmpty={() => (
          <TableEmpty searchQuery={searchQuery}>
            <Empty icon="clipboard">
              {getTextToDisplay(`${ROOT_TO_TEXT}.emptyTable`)}
            </Empty>
          </TableEmpty>
        )}
        renderExpandedRow={row => {
          const { defaultProps: commentButtonProps, textPath } =
            getCommentButtonAttributes({
              commentCount: row.assignmentCommentsCount,
              confirmPermission: permission =>
                permissionService.hasPermission(permission),
              isAppReadOnly,
              isEngagementReadOnly: row.isEngagementReadOnly,
              onDataRequestCommentClick: () => {
                handleOpenDataRequestDrawer(
                  row,
                  onDataRequestDrawerOpenComments
                );
              },
              onTaskCommentClick: () => {
                handleOpenTaskDrawer(row, onTaskDrawerOpen);
              },
              typeId: row.assignmentTypeId
            });

          const defaultDetailsProps = {
            assignmentAttachments: row.assignmentAttachments,
            assignmentCreatedBy: row.assignmentCreatedBy,
            assignmentCreatedDate: row.assignmentCreatedDate,
            assignmentDescription: row.assignmentDescription,
            assignmentFollowerCount: row.assignmentFollowerCount,
            assignmentStatusId: row.assignmentStatusId,
            assignmentTitle: row.assignmentTitle,
            commentButtonDisplayTextPath: getTextToDisplay(
              `${ROOT_TO_TEXT}.${textPath}`,
              { count: row.assignmentCommentsCount }
            ),
            commentButtonProps,
            engagementDisplayNameShort: row.engagementDisplayNameShort,
            engagementGuid: row.engagementGuid,
            isAssignmentCurrentlyWatched: row.isAssignmentCurrentlyWatched,
            isFollowButtonDisabled: isUpdatingFollowStatus,
            isPastDue: isPastDue(new Date(row.assignmentDueDate), new Date()),
            onOpenFollowersModal: () => {
              setAssignmentToUpdateFollowers(row);
            },
            onToggleFollow: () => {
              handleToggleFollow(row, {
                setIsUpdatingFollowStatus,
                setIsLoading
              });
            }
          };

          const { assignmentTypeId } = row;
          switch (assignmentTypeId) {
            case EntityType.DataRequest:
              return (
                <DataRequestDetails
                  {...defaultDetailsProps}
                  assignmentAssignedTo={row.assignmentAssignedTo}
                  assignmentDataSpecs={row.assignmentDataSpecs}
                  assignmentDueDate={row.assignmentDueDate}
                  assignmentTransferredFilesCount={
                    row.assignmentTransferredFilesCount
                  }
                  onOpenDataRequestHistory={() => {
                    handleOpenDataRequestDrawer(
                      row,
                      onDataRequestDrawerOpenHistory
                    );
                  }}
                />
              );
            case EntityType.Task:
              return (
                <TaskDetails
                  {...defaultDetailsProps}
                  engagementMilestoneGuid={row.engagementMilestoneGuid}
                  engagementMilestoneTitle={row.engagementMilestoneTitle}
                  onLinkToMilestone={() => {
                    setAssignmentToEdit(row);
                  }}
                />
              );
            default:
              return null;
          }
        }}
        rowClassName={row =>
          isPastDue(new Date(row.assignmentDueDate), new Date())
            ? 'past-due-assignment'
            : ''
        }
        rowId="queueItemGuid"
        searchQuery={searchQuery}
        sorted={sorted}
        totalCount={totalResults}
      />

      <DataRequestModals
        assignmentToComplete={
          assignmentToComplete &&
          assignmentToComplete.assignmentTypeId === EntityType.DataRequest
            ? assignmentToComplete
            : null
        }
        assignmentToDelete={
          assignmentToDelete &&
          assignmentToDelete.assignmentTypeId === EntityType.DataRequest
            ? assignmentToDelete
            : null
        }
        assignmentToEdit={
          assignmentToEdit &&
          assignmentToEdit.assignmentTypeId === EntityType.DataRequest
            ? assignmentToEdit
            : null
        }
        onClose={(
          editedDataRequest: IDataRequest | undefined,
          modalAction: DataRequestModalActionEnums
        ) => {
          if (editedDataRequest) {
            setIsLoading(true);
          }

          switch (modalAction) {
            case DataRequestModalActionEnums.COMPLETE:
              setAssignmentToComplete(null);
              break;
            case DataRequestModalActionEnums.DELETE:
              setAssignmentToDelete(null);
              break;
            case DataRequestModalActionEnums.EDIT:
              setAssignmentToEdit(null);
              break;
            default:
          }
        }}
      />

      <TaskModals
        assignmentToComplete={
          assignmentToComplete &&
          assignmentToComplete.assignmentTypeId === EntityType.Task
            ? assignmentToComplete
            : null
        }
        assignmentToDelete={
          assignmentToDelete &&
          assignmentToDelete.assignmentTypeId === EntityType.Task
            ? assignmentToDelete
            : null
        }
        assignmentToEdit={
          assignmentToEdit &&
          assignmentToEdit.assignmentTypeId === EntityType.Task
            ? assignmentToEdit
            : null
        }
        assignmentToReassign={
          assignmentToReassign &&
          assignmentToReassign.assignmentTypeId === EntityType.Task
            ? assignmentToReassign
            : null
        }
        onClose={(
          editedTask: ITask | undefined,
          modalAction: TaskModalActionEnums
        ) => {
          if (editedTask) {
            setIsLoading(true);
          }

          switch (modalAction) {
            case TaskModalActionEnums.COMPLETE:
              setAssignmentToComplete(null);
              break;
            case TaskModalActionEnums.DELETE:
              setAssignmentToDelete(null);
              break;
            case TaskModalActionEnums.EDIT:
              setAssignmentToEdit(null);
              break;
            case TaskModalActionEnums.REASSIGN:
              setAssignmentToReassign(null);
              break;
            default:
          }
        }}
      />

      {assignmentToUpdateFollowers && (
        <FollowersModal
          engagementGuid={assignmentToUpdateFollowers.engagementGuid}
          instructions={getTextToDisplay('followersModal.instructions', {
            context:
              Object.values(EntityType)[
                assignmentToUpdateFollowers.assignmentTypeId - 1
              ]
          })}
          onClose={() => {
            setAssignmentToUpdateFollowers(null);
          }}
          onUpdate={() => {
            setAssignmentToUpdateFollowers(null);
            setIsLoading(true);
          }}
          open
          queueItemGuid={assignmentToUpdateFollowers.queueItemGuid}
          title={getTextToDisplay('followersModal.title', {
            context:
              Object.values(EntityType)[
                assignmentToUpdateFollowers.assignmentTypeId - 1
              ]
          })}
          userTypeId={activeUserProfile.userTypeId}
        />
      )}
    </div>
  );
};

export default WorklistOpen;
