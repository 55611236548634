import { RadioButtonCard, RadioButtonGroup } from 'components/RadioButton';
import { ICustomViewType } from 'interfaces';

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonGroup } from '@ryan/components';

import CustomViewContext, { CustomViewRole } from './CustomViewContext';

interface ICustomViewInit {
  disableNext?: boolean;
  onCancel: () => void;
  onNext: () => void;
}

const CustomViewInit: React.FC<ICustomViewInit> = ({
  disableNext = false,
  onCancel,
  onNext
}) => {
  const { t } = useTranslation();
  const { hasExecutiveAccess, viewType, setViewType, role, setRole } =
    useContext(CustomViewContext);

  return (
    <div className="custom-view-init">
      <label className="ry-label">{t('customView.viewType.label')}</label>
      <RadioButtonGroup
        name={t('customView.viewType.label')}
        onChange={(e, value) => setViewType(Number(value))}
        value={viewType !== null ? `${viewType}` : ''}
      >
        <RadioButtonCard
          icon="venn-diagram"
          label={t('customView.viewType.dynamic.label')}
          value={`${ICustomViewType.Dynamic}`}
        >
          {t('customView.viewType.dynamic.description')}
        </RadioButtonCard>
        <RadioButtonCard
          icon="picklist"
          label={t('customView.viewType.static.label')}
          value={`${ICustomViewType.Static}`}
        >
          {t('customView.viewType.static.description')}
        </RadioButtonCard>
      </RadioButtonGroup>
      {hasExecutiveAccess && (
        <>
          <label className="ry-label">{t('customView.role.label')}</label>
          <RadioButtonGroup
            name={t('customView.role.label')}
            onChange={(e, value) => setRole(value as CustomViewRole)}
            value={role !== null ? `${role}` : ''}
          >
            <RadioButtonCard
              label={t('customView.role.personal.label')}
              value={CustomViewRole.Personal}
            >
              {t('customView.role.personal.description')}
            </RadioButtonCard>
            <RadioButtonCard
              label={t('customView.role.executive.label')}
              value={CustomViewRole.Executive}
            >
              {t('customView.role.executive.description')}
            </RadioButtonCard>
          </RadioButtonGroup>
        </>
      )}
      <ButtonGroup>
        <Button
          className="custom-view-init__next"
          disabled={disableNext}
          onClick={onNext}
          text={t('Next')}
          type="button"
          variant="primary"
        />
        <Button onClick={onCancel} text={t('Cancel')} type="button" />
      </ButtonGroup>
    </div>
  );
};

export default CustomViewInit;
