import Password, { UserInfo } from 'components/Password';
import { INewPasswordForm, UserType } from 'interfaces';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import { Formik, formikFieldProps, yup } from 'utils/forms';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserData } from 'react-oidc';

import { Button, Message, TextInput } from '@ryan/components';

import './NewPassword.scss';

const initialValues: INewPasswordForm = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
};

/**
 * Renders the "New Password" page for existing users to reset their password
 * (assuming they know their current password).
 *
 * Not to be confused with the "New User Password" page for first time users or
 * the "Password Reset" page for users who have forgotten their password.
 *
 * NOTE: This page is meant to also be used by non-DXP Ryan employees to change
 * their password, so page content should be kept as generic as possible and
 * should not link to app specific pages.
 */
const NewPassword: React.FC = () => {
  const { t } = useTranslation();

  // user information to match against password requirements
  // NOTE: getting user directly from auth token instead of UserProvider as the
  // provider requires users to have accepted the DXP terms of use, which does
  // not apply on this page meant for non-DXP users as well
  const { user } = useContext(UserData);
  const [userInfo, setUserInfo] = useState<UserInfo>({
    firstName: '',
    lastName: '',
    userTypeId: UserType.Ryan
  });

  // error message if user enters an incorrect password
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>(null);

  // indicates password update status
  const [isPasswordUpdateSuccessful, setIsPasswordUpdateSuccessful] =
    useState(false);

  // toggle display status of user's current password
  const [maskCurrentPassword, setMaskCurrentPassword] = useState(true);

  // toggles display status of user's re-entering of new password
  const [maskConfirmPassword, setMaskConfirmPassword] = useState(true);

  // Promise for the form submit request
  const [requestPromise, setRequestPromise] = useState<Promise<any> | null>(
    null
  );

  // fetch user information required to enforce password restrictions
  useEffect(() => {
    // initialize cancel token source
    const source: CancelTokenSource = ApiService.CancelToken.source();
    const fetchUserInfo = async () => {
      try {
        const response = await ApiService.getUserInfo(source.token);
        const {
          userFirstName: firstName,
          userLastName: lastName,
          type: userTypeId
        } = response.data;
        setUserInfo({
          firstName,
          lastName,
          userTypeId
        });
      } catch (error) {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast(
            `could not fetch user info to enforce new password restrictions - ${error}`
          );
        }
      }
    };

    fetchUserInfo();

    return () => {
      // cancel ongoing request on cleanup
      source.cancel();
    };
  }, [user]);

  return (
    <Formik<INewPasswordForm>
      initialValues={initialValues}
      onSubmit={async (values, { validateForm }) => {
        try {
          const request = ApiService.validateCredentialsAndResetPassword(
            values.oldPassword,
            values.confirmPassword
          );

          setRequestPromise(request);
          await request;
          setIsPasswordUpdateSuccessful(true);
        } catch (error: any) {
          switch (error.response.status) {
            case 500:
              pushServerErrorToast();
              break;
            case 400:
              setErrorMessage(t('security.resetPassword.incorrectPassword'));
              validateForm();
              break;
          }

          setIsPasswordUpdateSuccessful(false);
        } finally {
          setRequestPromise(null);
        }
      }}
      validateOnMount
      validationSchema={yup.object({
        oldPassword: yup
          .string()
          .required(
            t('security.resetPassword.current', { context: 'required' })
          )
          .notOneOf(
            [yup.ref('newPassword')],
            t('security.resetPassword.oldMustNotMatchNew')
          ),
        newPassword: yup.string(),
        confirmPassword: yup
          .string()
          .equalTo(
            yup.ref('newPassword'),
            t('security.resetPassword.confirmMustMatchNew')
          )
      })}
    >
      {formik =>
        isPasswordUpdateSuccessful ? (
          <p>{t('security.resetPassword.success')}</p>
        ) : (
          <form className="new-password-form" onSubmit={formik.handleSubmit}>
            <p>{t('security.resetPassword.description')}</p>
            {errorMessage && <Message type="error">{errorMessage}</Message>}
            <TextInput
              {...formikFieldProps('oldPassword', formik)}
              autoComplete="current-password"
              icon={maskCurrentPassword ? 'show' : 'hide'}
              label={t('security.resetPassword.current')}
              onIconClick={() =>
                setMaskCurrentPassword(
                  prevMaskCurrentPassword => !prevMaskCurrentPassword
                )
              }
              type={maskCurrentPassword ? 'password' : 'text'}
            />
            <Password
              label={t('security.resetPassword.new')}
              name="newPassword"
              userInfo={userInfo}
            />
            <TextInput
              {...formikFieldProps('confirmPassword', formik)}
              autoComplete="new-password"
              icon={maskConfirmPassword ? 'show' : 'hide'}
              label={t('security.resetPassword.confirm')}
              onIconClick={() =>
                setMaskConfirmPassword(
                  prevMaskConfirmPassword => !prevMaskConfirmPassword
                )
              }
              type={maskConfirmPassword ? 'password' : 'text'}
            />
            <Button loading={requestPromise} type="submit" variant="primary">
              {t('Submit')}
            </Button>
          </form>
        )
      }
    </Formik>
  );
};

export default NewPassword;
