import DocumentTitle from 'components/DocumentTitle';
import TabsAndRoutes from 'components/Tabs/TabsAndRoutes';

import React, { FunctionComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import PracticeAreaConfiguration from './PracticeAreaConfiguration';
import TestRoute from './TestRoute';

interface SuperadminProps extends WithTranslation, RouteComponentProps {}

const Superadmin: FunctionComponent<SuperadminProps> = ({ t }) => {
  return (
    <div>
      <DocumentTitle title={t('Superadmin Dashboard')} />
      <h1 className="ry-h1">{t('Superadmin Dashboard')}</h1>
      <TabsAndRoutes
        tabs={[
          {
            label: t('Practice Areas'),
            path: '/app/superadmin/dashboard/practice-areas',
            component: PracticeAreaConfiguration,
            enabled: true
          },
          {
            label: 'Test Route',
            path: '/app/superadmin/dashboard/test',
            component: TestRoute,
            enabled: true
          }
        ]}
      />
    </div>
  );
};

export default withTranslation()(Superadmin);
