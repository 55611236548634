import { IUserSummary } from 'interfaces';

import React, {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import {
  ITextareaMentionsProps,
  ITextareaMentionsRef,
  MentionUser,
  TextareaMentions
} from '@ryan/components';

import ApiService from '../../../services/ApiService';

export interface ICommentInputProps
  extends Omit<ITextareaMentionsProps, 'onBlur' | 'onSuggestionsRequested'> {
  boundingParentRef: MutableRefObject<HTMLDivElement | HTMLFormElement | null>;
  engagementGuid?: string;
  makeOnSuggestionsRequestedCallback?: any;
}

/**
 * Returns `onSuggestionsRequested` handler for `TextareaMentions` component.
 * Breaking out into this factory function for unit testing purposes.
 *
 * @ignore
 */
export const makeOnSuggestionsRequested =
  (
    engagementGuid: string,
    userFilter?: (user: IUserSummary) => boolean | null
  ) =>
  (query: string): Promise<MentionUser[]> =>
    ApiService.engagementMembersAutocomplete(engagementGuid, query).then(
      response => {
        const filteredData = userFilter
          ? response.data.filter(userFilter)
          : response.data;
        return filteredData.map(user => ({
          id: user.userGuid,
          display: user.fullName
        }));
      }
    );

/**
 * Renders a textarea component with support for user mentions of users
 * associated with the engagement specified by `engagementGuid`.
 */
const CommentInput = React.forwardRef<ITextareaMentionsRef, ICommentInputProps>(
  (
    {
      boundingParentRef,
      engagementGuid,
      makeOnSuggestionsRequestedCallback,
      ...props
    },
    ref
  ) => {
    const commentInputRef = useRef<HTMLDivElement | null>(null);
    const [boundingParentElement, setBoundingParentElement] =
      useState<HTMLElement | null>(null);

    useEffect(() => {
      if (!boundingParentRef?.current) {
        return;
      }

      setBoundingParentElement(boundingParentRef.current);
    }, [boundingParentRef]);

    useEffect(() => {
      if (!boundingParentElement || !commentInputRef?.current) {
        return;
      }

      const observer = new ResizeObserver(resizeObserverEntries =>
        setCommentInputWidth(
          resizeObserverEntries[0].target,
          (commentInputRef as MutableRefObject<HTMLDivElement>).current
        )
      );
      observer.observe(boundingParentElement);

      return () => {
        observer.unobserve(boundingParentElement);
      };
    }, [boundingParentElement, commentInputRef]);

    /**
     * Fetches a list of users associated with the engagement specified by
     * `engagementGuid`.
     */
    const onSuggestionsRequested: ITextareaMentionsProps['onSuggestionsRequested'] =
      useMemo(
        makeOnSuggestionsRequestedCallback ||
          (() => makeOnSuggestionsRequested(engagementGuid as string)),
        [engagementGuid, makeOnSuggestionsRequestedCallback]
      );

    const setCommentInputWidth = (
      parentElement: Element,
      commentInputElement: HTMLDivElement
    ) => {
      if (!commentInputElement) {
        return;
      }
      commentInputElement.setAttribute(
        'style',
        `width: ${parentElement.clientWidth}px`
      );
    };

    return (
      <div ref={commentInputRef}>
        <TextareaMentions
          {...props}
          onBlur={undefined}
          onSuggestionsRequested={onSuggestionsRequested}
          ref={ref}
        />
      </div>
    );
  }
);

export default CommentInput;
