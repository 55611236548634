import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EMessageTypes,
  IModalProps,
  pushToast
} from '@ryan/components';

import Modal from '../../../components/Modal';
import SelectFolder from '../../../components/SelectFolder/SelectFolder';
import { WithUser, withUser } from '../../../contexts/UserContext';
import {
  Feature,
  FolderSelection,
  IEngagement,
  IFolder,
  IFolderTree
} from '../../../interfaces';
import ApiService from '../../../services/ApiService';
import { ApiErrorResponseEnums } from '../../../utils/enums/ApiErrorResponseEnums';
import pushServerErrorToast from '../../../utils/pushServerErrorToast';
import { RYAN_INTERNAL } from '../utils/FileDirectoryEnums';

export interface INewFolderModalProps
  extends Omit<IModalProps, 'onClose'>,
    WithTranslation,
    WithUser {
  engagement: IEngagement;

  // loading if null
  folders: IFolderTree[] | null;

  // name of "folder" that holds prop `folders`
  // if selected, selected folder would return as null in callback
  rootName: string;

  // the folder to start
  defaultFolder: FolderSelection;

  // callback to close
  onClose: (newFolder?: IFolder) => void;
}

interface INewFolderModalState {
  createNewFolderLoading: Promise<any> | null;
  folder: FolderSelection;
  folderError: string | null;
}

const maxLengthName = 255;

export class NewFolderModal extends Component<
  INewFolderModalProps,
  INewFolderModalState
> {
  private _isMounted = false;

  constructor(props: INewFolderModalProps) {
    super(props);

    const { t, defaultFolder } = this.props;

    this.state = {
      createNewFolderLoading: null,
      folder: {
        parentFolderGuid: defaultFolder ? defaultFolder.folderGuid : null,
        folderGuid: null,
        folderName: t('New Folder'),
        folderVisibleToUserTypes:
          defaultFolder?.folderVisibleToUserTypes || null
      },
      folderError: null
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (folder: FolderSelection) => {
    const { t } = this.props;

    this.setState({ folder });

    const folderLengthError =
      folder && folder.folderName?.length > maxLengthName
        ? t('folder.renameModal.folderName.maxLength', {
            length: maxLengthName
          })
        : null;

    this.setState({ folderError: folderLengthError });
  };

  handleSave = async () => {
    const { engagement, onClose, t } = this.props;
    const { folder } = this.state;

    if (!engagement || folder === null || folder.folderGuid !== null) {
      return;
    }

    try {
      const createEngagementFolderPromise = ApiService.createEngagementFolder(
        engagement.engagementGuid,
        {
          parentFolderGuid: folder.parentFolderGuid,
          folderName: folder.folderName
        }
      );

      this.setState({ createNewFolderLoading: createEngagementFolderPromise });

      const { data } = await createEngagementFolderPromise;

      pushToast({
        title: t('dataAndFiles.folderCreated'),
        type: EMessageTypes.SUCCESS
      });

      onClose(data);
    } catch (error: any) {
      const { data, status } = error.response || {};

      let folderNameError: string | null = null;

      if (status === ApiErrorResponseEnums.BAD_REQUEST && Array.isArray(data)) {
        data.forEach(error => {
          if (error.propertyName === 'FolderName') {
            folderNameError = error.errorMessage;
          }
        });
      }

      if (folderNameError) {
        this.setState({ folderError: folderNameError });
        return;
      }

      pushServerErrorToast();
      onClose();
    } finally {
      if (this._isMounted) {
        this.setState({ createNewFolderLoading: null });
      }
    }
  };

  render() {
    const {
      defaultFolder,
      engagement,
      folders,
      i18n,
      isFeatureToggled,
      rootName,
      tReady,
      onClose,
      t,
      ...rest
    } = this.props;
    const { createNewFolderLoading, folder, folderError } = this.state;
    const isInternalFilesFunctionalityVisible = isFeatureToggled(
      Feature.InternalFiles
    );
    const foldersWithoutInternal = (folders || []).filter(
      folder => folder.folderName !== RYAN_INTERNAL
    );
    return (
      <Modal
        className="file-move-modal"
        onClose={() => onClose()}
        open
        title={t('Create A New Folder')}
        {...rest}
      >
        {folders ? (
          <SelectFolder
            createMode="always"
            feedback={folderError}
            folders={
              !isInternalFilesFunctionalityVisible
                ? foldersWithoutInternal
                : folders
            }
            invalid={folderError !== null}
            moveFileCount={2}
            onChange={this.handleChange}
            rootName={rootName}
            value={folder} // Just passing a number above 1 so it grabs the plural translation
          />
        ) : (
          <>
            <div className="ry-skeleton sk-field-label" />
            <div className="ry-skeleton sk-field" />
          </>
        )}
        <ButtonGroup>
          <Button
            disabled={folderError !== null}
            loading={createNewFolderLoading}
            onClick={this.handleSave}
            text={t('Done')}
            type="submit"
            variant="primary"
          />
          <Button
            disabled={createNewFolderLoading !== null}
            onClick={() => onClose()}
            text={t('Cancel')}
            variant="secondary"
          />
        </ButtonGroup>
      </Modal>
    );
  }
}

export default withTranslation()(withUser(NewFolderModal));
