import { useAmplitude } from 'contexts/AmplitudeContext/AmplitudeConsumer';
import qs from 'query-string';
import { parse } from 'query-string';
import { amplitudeEventDetail } from 'utils/amplitudeUtils/amplitudeUtils';

import classnames from 'classnames';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Button,
  ITableColumn,
  makeTableCheckboxFilter
} from '@ryan/components';

import Currency from '../../../components/Currency/Currency';
import Empty from '../../../components/Empty';
import Table from '../../../components/Table';
import TableEmpty from '../../../components/TableEmpty/TableEmpty';
import { useDownload, useUser } from '../../../hooks';
import { IInvoice, InvoiceStatus } from '../../../interfaces';
import ApiService, { CancelTokenSource } from '../../../services/ApiService';
import { ButtonSizeEnums } from '../../../utils/enums/ButtonSizeEnums';
import { formatDate } from '../../../utils/formatDate';
import getSortParam from '../../../utils/getSortParm';
import isTableFilterActive from '../../../utils/isTableFilterActive';
import pushServerErrorToast from '../../../utils/pushServerErrorToast';
import useTable, {
  TableHookInitialState,
  TableHookOnFetch
} from '../../../utils/useTable';
import InvoiceEngagementCard from './InvoiceEngagementCard';

import './Invoices.scss';

const tableHookInitialState: TableHookInitialState<IInvoice> = {
  query: {
    filtered: { status: [] },
    page: 1,
    pageSize: 10,
    searchQuery: '',
    sorted: {
      id: 'invoiceDate',
      desc: true
    }
  }
};

// Assumes Permission.InvoicesView
const Invoices: FunctionComponent = () => {
  const { triggerAmplitudeEvent } = useAmplitude();
  const { onDownloadInvoice } = useDownload();
  const history = useHistory();
  const location = useLocation();
  const { t: getTextToDisplay } = useTranslation();
  const { activeView, isAppReadOnly, isEngagementInView } = useUser();
  const sourceRef = useRef<CancelTokenSource>();

  const onTableFetch = useCallback<TableHookOnFetch<IInvoice>>(
    query => {
      // refresh cancel token
      sourceRef.current?.cancel();
      sourceRef.current = ApiService.CancelToken.source();
      return ApiService.getInvoicesByCustomView(
        activeView.customViewGuid,
        {
          pageNumber: query.page,
          searchTerm: query.searchQuery,
          sort: getSortParam(query.sorted),
          itemsPerPage: query.pageSize,
          status: query.filtered.status.join(',')
        },
        sourceRef.current.token
      )
        .then(response => ({
          data: response.data.results,
          totalCount: response.data.totalResults
        }))
        .catch(error => {
          if (!ApiService.isCancel(error)) {
            pushServerErrorToast();
          }

          throw error;
        });
    },
    [activeView.customViewGuid]
  );
  const [table, fetchInvoices] = useTable<IInvoice>(
    tableHookInitialState,
    onTableFetch
  );

  const columns: ITableColumn<IInvoice>[] = useMemo(
    () => [
      {
        id: 'name',
        label: getTextToDisplay('invoices.columns.name'),
        render: row => (
          <div className="bs">
            <b>{row.name}</b>
            {row.replaceInvoices && (
              <small>
                {getTextToDisplay('invoices.replaces', {
                  count: row.replaceInvoices.split(',').length,
                  numbers: row.replaceInvoices.replace(/,/g, ', ')
                })}
              </small>
            )}
          </div>
        ),
        sortable: true,
        width: '50%'
      },
      {
        id: 'invoiceDate',
        label: getTextToDisplay('invoices.columns.invoiceDate'),
        render: row => formatDate(row.invoiceDate),
        sortable: true
      },
      {
        id: 'paymentTerms',
        label: getTextToDisplay('invoices.columns.paymentTerms'),
        render: row => row.paymentTerms
      },
      {
        filter: makeTableCheckboxFilter([
          {
            label: getTextToDisplay('invoices.label.outstanding'),
            value: InvoiceStatus.Outstanding.toString()
          },
          {
            label: getTextToDisplay('invoices.label.closed'),
            value: InvoiceStatus.Closed.toString()
          },
          {
            label: getTextToDisplay('invoices.label.pastDue'),
            value: InvoiceStatus.PastDue.toString()
          }
        ]),
        filterActive: (value: string[]) => value.length > 0,
        id: 'status',
        label: getTextToDisplay('invoices.columns.status'),
        render: row => (
          <div
            className={classnames(
              'invoices-status',
              row.status.toLocaleLowerCase()
            )}
          >
            {getTextToDisplay(`invoices.status.${row.statusId}`)}
          </div>
        )
      },
      {
        id: 'dueDate',
        label: getTextToDisplay('invoices.columns.dueDate'),
        render: row => (row.dueDate ? formatDate(row.dueDate) : '–'),
        sortable: true
      },
      {
        align: 'right',
        id: 'balance',
        label: getTextToDisplay('invoices.columns.balance'),
        render: row => (
          <Currency
            currencyCode={row.isoCurrencyCode}
            includeCreditFormat
            value={row.balance}
          />
        ),
        sortable: true
      },
      {
        align: 'right',
        id: 'amountDue',
        label: getTextToDisplay('invoices.columns.amountDue'),
        render: row => (
          <Currency
            currencyCode={row.isoCurrencyCode}
            includeCreditFormat
            value={row.amountDue}
          />
        ),
        sortable: true
      },
      {
        id: 'invoiceNumber',
        label: getTextToDisplay('invoices.columns.invoiceNumber'),
        render: 'invoiceNumber',
        sortable: true
      },
      {
        align: 'center',
        id: 'download',
        label: '',
        render: row => {
          const isReadOnlyByUserState =
            isAppReadOnly || activeView.isExecutiveView;

          return (
            <Button
              className="invoices-page__download"
              // NOTE: Unclear why invoiceEngagements is an array in search endpoint.
              //    Using first invoice invoiceEngagement of the array.
              disabled={
                isReadOnlyByUserState || row.invoiceEngagements[0].isGhosted
              }
              icon="download"
              onClick={() => {
                triggerAmplitudeEvent({
                  amplitudeEventAction:
                    amplitudeEventDetail.ryanInvoices.downloadEventName,
                  amplitudeEventName:
                    amplitudeEventDetail.ryanInvoices.eventName,
                  amplitudeEventProperty:
                    amplitudeEventDetail.ryanInvoices.downloadPropertyOptions
                      .dataAndFiles
                });

                onDownloadInvoice(row);
              }}
              size={ButtonSizeEnums.SMALL}
              variant="text"
            />
          );
        }
      }
    ],
    [
      activeView.isExecutiveView,
      getTextToDisplay,
      isAppReadOnly,
      onDownloadInvoice,
      triggerAmplitudeEvent
    ]
  );

  const getRowClassName = useCallback((row: IInvoice) => {
    switch (row.statusId) {
      case InvoiceStatus.PastDue:
        return 'pastdue';
      case InvoiceStatus.Closed:
        return 'closed';
      default:
        return '';
    }
  }, []);

  // cancel ongoing requests
  useEffect(
    () => () => {
      sourceRef.current?.cancel();
    },
    []
  );

  // if directed here via invoice activity link, verify the engagement for the
  // activity is part of the current view and update view if not
  useEffect(() => {
    const verifyEngagementInView = async () => {
      const { accountGuid, engagementGuid } = qs.parse(location.hash);

      if (
        typeof accountGuid === 'string' &&
        accountGuid &&
        typeof engagementGuid === 'string' &&
        engagementGuid
      ) {
        let isInView: boolean;

        try {
          isInView = await isEngagementInView(engagementGuid);
        } catch {
          isInView = false;
        }

        // update view to single account view for invoice's account if current
        // view does not contain engagement; leverages AccountRedirect component
        if (!isInView) {
          history.replace(`${location.pathname}?account=${accountGuid}`);
        } else {
          // clear hash
          history.replace({ ...location, hash: '' });
        }
      }
    };

    verifyEngagementInView();
  }, [history, location, isEngagementInView]);

  useEffect(() => {
    fetchInvoices({ page: 1 });
  }, [fetchInvoices]);

  const tableRef = useRef(table);
  useEffect(() => {
    const { invoice: invoiceGuid } = parse(history.location.search);

    if (invoiceGuid) {
      const invoice = table.state.data.find(
        invoice => invoice.invoiceGuid === invoiceGuid
      );
      if (invoice) {
        tableRef.current.onToggleExpansion(true, invoice, invoice.invoiceId);
        history.replace({ ...history.location, search: '' });
      }
    }
  }, [history, table.state.data]);

  return (
    <div className="invoices-page">
      <Table<IInvoice, number>
        columns={columns}
        // rows
        data={table.state.data}
        expanded={table.state.expanded}
        filtered={table.state.query.filtered}
        groupBy="statusId"
        groupByClassName={(statusId: number) => InvoiceStatus[statusId]}
        loading={table.state.loading}
        // data
        onFilterChange={table.onFilterChange}
        onPageChange={table.onPageChange}
        onSearchChange={table.onSearchChange}
        // query
        onSortChange={table.onSortChange}
        onToggleExpansion={table.onToggleExpansion}
        page={table.state.query.page}
        pageSize={table.state.query.pageSize}
        renderEmpty={() => (
          <TableEmpty
            filterActive={isTableFilterActive(
              columns,
              table.state.query.filtered
            )}
            searchQuery={table.state.query.searchQuery}
          >
            <Empty icon="invoice">{getTextToDisplay('invoices.empty')}</Empty>
          </TableEmpty>
        )}
        // empty
        renderExpandedRow={row => (
          <div className="row">
            {row.invoiceEngagements.map(invoiceEngagement => (
              <div
                className="col-6 col-lg-4"
                key={invoiceEngagement.engagementGuid}
              >
                <InvoiceEngagementCard
                  currencyCode={row.isoCurrencyCode}
                  invoiceEngagement={invoiceEngagement}
                />
              </div>
            ))}
          </div>
        )}
        // expand
        renderGroupHeader={(statusId: number) =>
          getTextToDisplay(`invoices.status.${statusId}`)
        }
        rowClassName={getRowClassName}
        rowId="invoiceId"
        // handlers
        searchQuery={table.state.query.searchQuery}
        sorted={table.state.query.sorted}
        subheader={getTextToDisplay('Payment processing')}
        title={getTextToDisplay('invoices.title')}
        totalCount={table.state.totalCount}
      />
    </div>
  );
};

export default Invoices;
