import { AttachmentLink } from 'components/FileLink';
import TooltipList from 'components/TooltipList/TooltipList';
import { useAppReadOnly } from 'contexts/UserContext';
import { DSSDocument, IFile } from 'interfaces';
import { formatDate } from 'utils/formatDate';

import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@ryan/components';

import isDSSDocument from './isDSSDocument';

import './Attachments.scss';

export interface IAttachmentsProps {
  // a list of existing attachments to display
  attachments: (IFile | DSSDocument)[];

  // adds a download link for files, only applies to IFile file types
  disableDownload?: boolean;

  // optional if file is directly associated to an engagement
  engagementGuid: string | null;

  // adds a trash button to remove files
  onDelete?: (file: IFile | DSSDocument) => void;
}

/**
 * Displays a list of file attachments.
 */
const Attachments: FunctionComponent<IAttachmentsProps> = ({
  attachments,
  disableDownload,
  onDelete,
  engagementGuid
}) => {
  const { t } = useTranslation();
  const isAppReadOnly = useAppReadOnly();

  if (attachments.length === 0) {
    return null;
  }

  return (
    <div className="attachments">
      <h3 className="ry-label">{t('Attachments')}</h3>
      <div className="attachments__well">
        <table className="attachments__list">
          <tbody>
            {attachments.map(attachment => {
              const isDSSDoc = isDSSDocument(attachment);

              let fileName: string;
              let updatedByName: string;
              let createDate: Date;

              if (isDSSDoc) {
                // if the attachment is a DSS document type, it has just been
                // uploaded by the user
                fileName = attachment.documentName;
                updatedByName = t('You');
                createDate = new Date();
              } else {
                const file = attachment as IFile;
                fileName = file.displayName;
                updatedByName = file.updatedByName || '–';
                createDate = file.createDate;
              }

              return (
                <tr className="attachments__item" key={attachment.documentGuid}>
                  <td className="attachments__item-icon">
                    <Icon name="paperclip" />
                  </td>

                  {/**
                   * Attachment name.
                   * Download link if uploaded file.
                   */}
                  <td className="attachments__item-name">
                    {isDSSDoc || disableDownload ? (
                      fileName
                    ) : (
                      <AttachmentLink
                        engagementGuid={engagementGuid}
                        file={attachment as IFile}
                      />
                    )}
                  </td>

                  {/**
                   * Tooltip
                   */}
                  <td className="attachments__item-controls">
                    <TooltipList
                      ariaLabel={t('File Information')}
                      list={[
                        {
                          label: t('file.originalName'),
                          value: attachment.documentName
                        },
                        {
                          label: t('Uploaded By'),
                          value: updatedByName
                        },
                        {
                          label: t('Date Uploaded'),
                          value: formatDate(createDate)
                        }
                      ]}
                      placement="top"
                    />

                    {/**
                     * Delete
                     */}
                    {onDelete && !isAppReadOnly && (
                      <Button
                        ariaLabel={t('Delete Attachment')}
                        className="attachments__item-delete"
                        icon="trash"
                        onClick={() => onDelete(attachment)}
                        variant="text"
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Attachments;
