import { IFile } from 'interfaces';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, FileStatus, Icon } from '@ryan/components';

interface ISelectedFileProps {
  file: IFile;
  onFileTrashed?: (file: IFile) => void;
}

/**
 * Renders an already uploaded file that has been selected to be attached to an
 * item. Mimics the visual of an uploaded file.
 * @todo: Componentize in @ryan/components
 */
const SelectedFile: React.FC<ISelectedFileProps> = ({
  file,
  onFileTrashed
}) => {
  const { t } = useTranslation();
  return (
    <li
      className={`ry-file-upload__file ry-file-upload__file--${FileStatus.Success}`}
    >
      <div className="ry-file-upload__file-icon">
        <Icon name="success-filled" />
      </div>
      <div className="ry-file-upload__file-content">
        {file.displayName}
        <div className="ry-file-upload__progress">
          <div
            className="ry-file-upload__progress-determinate-bar"
            style={{ width: '100%' }}
          />
        </div>
      </div>
      <div className="ry-file-upload__file-actions">
        <Button
          ariaLabel={t('Delete Attachment')}
          icon="trash"
          size="sm"
          variant="text"
          {...(typeof onFileTrashed === 'function'
            ? { onClick: () => onFileTrashed(file) }
            : {})}
        />
      </div>
    </li>
  );
};

export default SelectedFile;
