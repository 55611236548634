import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Button, SplitButton } from '@ryan/components';

import EngagementContext from '../../contexts/EngagementContext';
import { WithUser, withUser } from '../../contexts/UserContext';
import { Feature, IDataRequest, Status } from '../../interfaces';
import { RedirectToDecisionUrlEnum } from '../../utils/enums/RedirectToDecisionsEnum';
import redirectToDecisionsApp from '../../utils/redirectToDecisions';
import DataRequestModal from '../DataRequestModal/DataRequestModal';
import DataRequestCompleteModal from './DataRequestCompleteModal';
import DataRequestDeleteModal from './DataRequestDeleteModal';
import DataRequestReopenModal from './DataRequestReopenModal';
import { IGetOptions, IHandleDeleteModalClose } from './utils';

import './DataRequestActions.scss';

interface IDataRequestActionsProps
  extends RouteComponentProps,
    WithTranslation,
    WithUser {
  dataRequest: IDataRequest;
  isOnDetailPage?: boolean;
  onUpdate: () => void;
  size: 'sm' | 'md' | 'lg';
}

interface IDataRequestActionsState {
  isCompleteModalOpen: boolean;
  isDeleteModalOpen: boolean;
  isEditModalOpen: boolean;
  isReopenModalOpen: boolean;
}

// Assumes Permission.DataRequestsEdit
// Otherwise this component should not be rendered.
export class DataRequestActions extends PureComponent<
  IDataRequestActionsProps,
  IDataRequestActionsState
> {
  static contextType = EngagementContext;
  context!: React.ContextType<typeof EngagementContext>;

  readonly state = {
    isCompleteModalOpen: false,
    isDeleteModalOpen: false,
    isEditModalOpen: false,
    isReopenModalOpen: false
  };

  handleDeleteModalClose = ({
    history,
    isDeleted,
    isOnDetailPage,
    onUpdate
  }: IHandleDeleteModalClose) => {
    this.setState({ isDeleteModalOpen: false }, () => {
      if (isDeleted) {
        isOnDetailPage
          ? history.push('/app/data-and-files/data-requests')
          : onUpdate();
      }
    });
  };

  getOptions({
    dataRequest,
    isAppReadOnly,
    isOnDetailPage,
    t: getTextToDisplay
  }: IGetOptions) {
    const disabled = dataRequest.isEngagementReadOnly || isAppReadOnly;
    const options = [];

    options.push({
      disabled,
      icon: 'pencil',
      label: getTextToDisplay('Edit'),
      onClick: () => this.setState({ isEditModalOpen: true })
    });

    // If not already marked Complete
    // and we are on the "detail" page (otherwise, the main SplitButton action
    // will be "Complete", so it doesn't need to be in the dropdown options).
    if (dataRequest.statusId !== Status.Complete && !isOnDetailPage) {
      options.push({
        disabled,
        icon: 'approve',
        label: getTextToDisplay('Complete'),
        onClick: () => this.setState({ isCompleteModalOpen: true })
      });
    }

    // If marked Complete
    if (dataRequest.statusId === Status.Complete) {
      options.push({
        disabled,
        icon: 'file-refresh',
        label: getTextToDisplay('Reopen'),
        onClick: () => this.setState({ isReopenModalOpen: true })
      });
    }

    options.push({
      disabled,
      icon: 'trash',
      label: getTextToDisplay('Delete'),
      negative: true,
      onClick: () => this.setState({ isDeleteModalOpen: true })
    });

    return options;
  }

  render() {
    const {
      dataRequest,
      isAppReadOnly,
      isFeatureToggled,
      isOnDetailPage,
      history,
      onUpdate,
      size,
      t: getTextToDisplay
    } = this.props;
    const isComplete = dataRequest.statusId === Status.Complete;
    const isDataRequestsUpdateVisible = isFeatureToggled(
      Feature.RWMDataRequests
    );
    const decisionsRedirectUrl =
      RedirectToDecisionUrlEnum.REVIEW_DATA_REQUEST +
      dataRequest.decisionsEntityId;

    return (
      <>
        {isDataRequestsUpdateVisible ? (
          <Button
            block
            disabled={
              isOnDetailPage &&
              (isComplete ||
                dataRequest.isEngagementReadOnly ||
                isAppReadOnly ||
                dataRequest.isUserGhosted)
            }
            onClick={
              isOnDetailPage
                ? () => this.setState({ isCompleteModalOpen: true })
                : () => redirectToDecisionsApp(decisionsRedirectUrl)
            }
            size={size}
            text={getTextToDisplay(
              isOnDetailPage ? 'Complete' : isComplete ? 'View' : 'Review'
            )}
          />
        ) : (
          <SplitButton
            block
            disabled={
              isOnDetailPage &&
              (isComplete || dataRequest.isEngagementReadOnly || isAppReadOnly)
            }
            onClick={() => {
              if (isOnDetailPage) {
                this.setState({ isCompleteModalOpen: true });
              } else {
                history.push(`/app/data-request/${dataRequest.queueItemGuid}`);
              }
            }}
            options={this.getOptions({
              dataRequest,
              isAppReadOnly,
              isOnDetailPage,
              t: getTextToDisplay
            })}
            size={size}
            text={getTextToDisplay(
              isOnDetailPage ? 'Complete' : isComplete ? 'View' : 'Review'
            )}
          />
        )}
        {this.state.isCompleteModalOpen && (
          <DataRequestCompleteModal
            dataRequest={dataRequest}
            onClose={(updatedDataRequest?: IDataRequest) => {
              if (updatedDataRequest) {
                onUpdate();
                this.context.refreshUpdateDate?.(
                  this.context.engagement?.engagementGuid
                );
              }

              this.setState({ isCompleteModalOpen: false });
            }}
            open
          />
        )}
        {this.state.isDeleteModalOpen && (
          <DataRequestDeleteModal
            dataRequest={dataRequest}
            onClose={(isDeleted: boolean) =>
              this.handleDeleteModalClose({
                history,
                isDeleted,
                isOnDetailPage,
                onUpdate
              })
            }
            open
          />
        )}
        {this.state.isEditModalOpen && (
          <DataRequestModal
            dataRequest={dataRequest}
            onClose={(updatedDataRequest?: IDataRequest) => {
              if (updatedDataRequest) {
                onUpdate();
              }
              this.setState({ isEditModalOpen: false });
            }}
            open
          />
        )}
        {this.state.isReopenModalOpen && (
          <DataRequestReopenModal
            dataRequest={dataRequest}
            onClose={(updatedDataRequest?: IDataRequest) => {
              if (updatedDataRequest) {
                this.props.onUpdate();
                this.context.refreshUpdateDate?.(
                  this.context.engagement?.engagementGuid
                );
              }

              this.setState({ isReopenModalOpen: false });
            }}
            open
          />
        )}
      </>
    );
  }
}

export default withRouter(withUser(withTranslation()(DataRequestActions)));
