import { DSSDocument } from 'interfaces';
import { getAuthorizationRequestHeader } from 'services/ApiService';
import { expiresWithin, getUserManager } from 'services/UserManager';
import { createLogger } from 'services/logger';

import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource
} from 'axios';
import ENV from 'env';

import { transformResponse } from '../transformResponse';
import Chunks, { Chunk, ChunkStatus } from './Chunks';
import compress from './compress';

// initialize file upload logger
const logger = createLogger('DSSUploaderLogger', { level: 'debug' });

const optionsDefaults = {
  chunkSize: 5000000, // 5mb
  chunksInParallel: 5,
  data: {}
};

/**
 * Provided a file or blob, returns the formdata that DSS accepts.
 * Optionally does not send the file, for initial and finalizing chunked uploads.
 */
const getFormData = (
  file: Blob,
  filename: string,
  data: { [key: string]: any } = {},
  sendFile = true
) => {
  const formData = new FormData();

  // the file
  if (sendFile !== false) {
    formData.append('file', file, filename);
  }

  // file data
  formData.append('DocumentName', filename);
  formData.append('DocName', filename);
  formData.append('DocTypeName', 'DXP Upload');
  formData.append('MimeType', file.type || 'application/octet-stream');

  // additional data
  Object.keys(data).forEach(key => {
    if (data[key] !== undefined) {
      formData.append(key, data[key]);
    }
  });

  return formData;
};

export enum DSSUploaderStatus {
  Uploading = 'uploading', // file is uploading
  Uploaded = 'uploaded', // file successfully uploaded
  Canceled = 'canceled', // file was canceled before upload finished
  Error = 'errored' // file upload failed
}

interface IDSSUploaderOptions {
  // size of a single chunk
  // files greater than this size will be chunked
  chunkSize?: number;

  // number of chunks to upload at a time; must be greater than 0
  chunksInParallel?: number;

  // additional data to send to DSS
  data?: Record<string, unknown>;

  onError?: (uploader: DSSUploader, error: AxiosError) => void;

  onProgress?: (uploader: DSSUploader, percent: number) => void;

  onSuccess?: (
    uploader: DSSUploader,
    response: AxiosResponse<DSSDocument>
  ) => void;
}

export class DSSUploader {
  private chunkedUploadDocumentGuid?: string;
  public dssDocument?: DSSDocument;
  public file: File;
  public options: IDSSUploaderOptions;
  public progress = 0;
  private source?: CancelTokenSource;
  public status: DSSUploaderStatus = DSSUploaderStatus.Uploading;

  /**
   * Tracks the number failed user session renewal attempts. Will stop renewal
   * attempts after a set number sttempts to prevent renewal loops during
   * chunk uploads.
   */
  private renewAttempts = 0;

  /**
   * Tracks an ongoing renewal attempt.
   */
  private renewPromise: Promise<void> | null = null;

  constructor(file: File, options?: IDSSUploaderOptions) {
    this.file = file;
    this.options = { ...optionsDefaults, ...options };
    this.start();
  }

  /**
   * Re-authenticates the active user session to refresh their expiration timeout.
   */
  private async renew(): Promise<void> {
    const renewSession = async () => {
      // increment attempt counter
      this.renewAttempts += 1;

      if (await this.shouldRenew()) {
        try {
          logger.debug(
            'initiating refresh of user session during chunked file upload'
          );
          await getUserManager().signinSilent();

          // reset renewal attempts on successful renewal
          if (!(await this.shouldRenew())) {
            this.renewAttempts = 0;
          }
        } catch (error: any) {
          logger.warn(
            `refresh of user session during chunked file upload has failed ${
              this.renewAttempts
            } time(s) - ${error?.message || error}`
          );
        }
      }

      this.renewPromise = null;
    };

    // cache renew promise to prevent making multiple concurrent requests
    this.renewPromise = this.renewPromise || renewSession();
    return this.renewPromise;
  }

  /**
   * Returns `true` if the uploader should attempt to renew the active user
   * session during to prevent session expiration during upload.
   */
  private async shouldRenew(): Promise<boolean> {
    return this.renewAttempts <= 5 && (await expiresWithin(30 * 60));
  }

  private start() {
    const { file, options } = this;
    this.source = axios.CancelToken.source();

    if (file.size > options.chunkSize!) {
      this.startChunkUpload();
    } else {
      this.startSingleUpload();
    }
  }

  public cancel() {
    if (this.status === DSSUploaderStatus.Uploading) {
      logger.debug(
        `${
          this.chunkedUploadDocumentGuid
            ? `[${this.chunkedUploadDocumentGuid}] `
            : ''
        }cancelling upload in progress`
      );
      this.source?.cancel();
      delete this.source;
      this.status = DSSUploaderStatus.Canceled;
    }
  }

  private async startSingleUpload() {
    const { file, options } = this;
    this.status = DSSUploaderStatus.Uploading;

    try {
      const { blob, compressed } = await compress(file);
      const formData = getFormData(blob, file.name, {
        ...options.data,
        // Must tell DSS if this file is compressed and how.
        // dss-v1 - pako zlib
        Compression: compressed ? 'dss-v1' : undefined
      });
      logger.debug(
        `initiating single file ${
          compressed ? 'compressed' : 'uncompressed'
        } upload`
      );
      const response = await this.send(formData, {
        onUploadProgress: (e: ProgressEvent) => {
          this.progress = Math.floor((e.loaded / e.total) * 100);

          if (typeof options.onProgress === 'function') {
            options.onProgress(this, this.progress);
          }
        }
      });
      this.handleSuccess(response);
      return response;
    } catch (error: any) {
      if (!axios.isCancel(error)) {
        this.handleError(error);
      }
    }
  }

  private async startChunkUpload() {
    const { file, options } = this;

    //
    // Chunk the file.
    //
    const chunks = new Chunks(file, options.chunkSize!);

    //
    // Begin the 3-step upload process
    //
    try {
      this.status = DSSUploaderStatus.Uploading;

      // Step 1 - get documentGuid
      logger.debug('retrieving document GUID to begin chunked file upload');
      const {
        data: { documentGuid: DocumentGuid }
      } = await this.send(
        getFormData(
          file,
          file.name,
          { ...options.data, ChunkCount: chunks.length },
          false
        )
      );
      this.chunkedUploadDocumentGuid = DocumentGuid;

      // Step 2 - send chunks in parallel
      logger.debug(`[${DocumentGuid}] initiating chunked file upload`);
      await this.sendChunksInParallel(chunks, {
        ...options.data,
        DocumentGuid
      });

      // Step 3 - all chunks successfully sent, finalize the upload
      logger.debug(
        `[${DocumentGuid}] completed chunked file upload, sending final completion request`
      );
      const finalResponse = await this.send(
        getFormData(
          file,
          file.name,
          {
            ...options.data,
            DocumentGuid,
            ChunkCount: chunks.length,
            ChunkUploadComplete: true
          },
          false
        )
      );
      this.handleSuccess(finalResponse);
      return finalResponse;
    } catch (error: any) {
      this.handleError(error);
    }
  }

  private sendChunksInParallel(
    chunks: Chunks,
    data: Record<string, unknown>
  ): Promise<void> {
    // create a new promise that will eventually resolve when all chunks have
    // succeeded, or reject when a chunk has failed 3 times
    return new Promise((resolve, reject) => {
      const { chunksInParallel: chunksInParallelOption, onProgress } =
        this.options;
      const chunksInParallel =
        chunksInParallelOption && chunksInParallelOption > 0
          ? chunksInParallelOption
          : optionsDefaults.chunksInParallel;
      let stopThreads = false;

      /**
       * Manages a single chunk upload.
       */
      const thread = async () => {
        while (this.status === DSSUploaderStatus.Uploading && !stopThreads) {
          // if our session expires soon, renew session
          if (await this.shouldRenew()) {
            await this.renew();
          }

          const chunk = chunks.next;

          if (!chunk) {
            // no more chunks, close thread
            logger.debug(
              `[${this.chunkedUploadDocumentGuid}] no more available chunks, closing thread`
            );
            break;
          } else if (chunk.tries < 5) {
            logger.debug(
              `[${this.chunkedUploadDocumentGuid}] sending chunk ${
                chunk.index
              } (attempt ${chunk.tries + 1})`
            );
            await this.sendChunk(chunk, chunks.length, data, () => {
              const { percent } = chunks.progress;
              this.progress = percent;

              if (typeof onProgress === 'function') {
                onProgress(this, percent);
              }
            });
          } else {
            // if chunk has failed too many times, reject
            stopThreads = true;
            this.cancel();
            reject(`sending chunk ${chunk.index} has failed too many times`);
            break;
          }
        }

        // if all chunks complete, resolve
        if (chunks.complete) {
          stopThreads = true;
          await this.renew();
          resolve();
        }
      };

      /**
       * Initiates uploading the next set of available chunks.
       */
      const startThreads = async () => {
        logger.debug(
          `[${this.chunkedUploadDocumentGuid}] starting ${chunksInParallel} chunked file upload thread(s)`
        );
        await this.renew();

        // initiate parallel requests
        for (let i = 0; i < chunksInParallel; i++) {
          thread();
        }
      };

      startThreads();
    });
  }

  private async sendChunk(
    chunk: Chunk,
    ChunkCount: number,
    data: Record<string, unknown>,
    onProgress: (e: ProgressEvent) => void
  ) {
    chunk.status = ChunkStatus.Uploading;
    chunk.tries++;

    try {
      const { blob, compressed } = await compress(chunk.blob);
      chunk.bytesTotal = blob.size;
      await this.send(
        getFormData(blob, this.file.name, {
          ...data,
          ChunkCount,
          CurrentChunk: chunk.index,
          // Must tell DSS if this chunk is compressed and how.
          // - dss-v1 - pako zlib per chunk
          Compression: compressed ? 'dss-v1' : undefined
        }),
        {
          onUploadProgress: (e: ProgressEvent) => {
            // Update bytesTotal with e.total because it includes possibly
            // compressed blob + rest of formdata.
            chunk.bytesTotal = e.total;
            chunk.bytesLoaded = e.loaded;
            onProgress(e);
          }
        }
      );
      chunk.status = ChunkStatus.Success;
    } catch (error: any) {
      chunk.status = ChunkStatus.Ready;

      if (!axios.isCancel(error)) {
        logger.warn(
          `[${
            this.chunkedUploadDocumentGuid
          }] an error occurred while sending chunk ${chunk.index} - ${
            error?.message || error
          }`
        );
      }
    }
  }

  private async send(
    formData: FormData,
    config?: AxiosRequestConfig & { documentGuid?: string }
  ) {
    const documentGuid = formData.get('DocumentGuid');
    const requestConfig: AxiosRequestConfig & { documentGuid?: string } = {
      ...config,
      cancelToken: this.source?.token,
      timeout: 600000, // 10 minutes
      transformResponse,
      withCredentials: true
    };

    // apply auth header if unavailable
    if (!requestConfig.headers?.Authorization) {
      requestConfig.headers = requestConfig.headers || {};
      requestConfig.headers.Authorization = getAuthorizationRequestHeader();
    }

    return axios.post<DSSDocument>(
      `${ENV.API_ROOT}/api/document/${documentGuid || ''}`,
      formData,
      requestConfig
    );
  }

  private handleSuccess = (response: AxiosResponse<DSSDocument>) => {
    this.status = DSSUploaderStatus.Uploaded;
    delete this.source;
    this.dssDocument = { ...response.data, fileSize: this.file.size };
    logger.debug(
      `[${this.dssDocument.documentGuid}] file has successfully uploaded`
    );

    if (typeof this.options.onSuccess === 'function') {
      this.options.onSuccess(this, response);
    }
  };

  private handleError = (error: AxiosError) => {
    this.status = DSSUploaderStatus.Error;
    delete this.source;
    logger.error(
      `${
        this.chunkedUploadDocumentGuid
          ? `[${this.chunkedUploadDocumentGuid}] `
          : ''
      }a file upload error has occurred - ${error?.message || error}`
    );

    if (typeof this.options.onError === 'function') {
      this.options.onError(this, error);
    }
  };
}
