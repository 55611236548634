import { CommentDeleteModal } from 'components/Comments';
import DataRequestDrawer from 'components/DataRequestDrawer';
import { EventEmitter } from 'events';
import { IActivity, IComment, IDataRequest, Permission } from 'interfaces';
import ApiService from 'services/ApiService';
import history from 'services/history';
import getMentionValue from 'utils/getMentionValue';
import pushServerErrorToast from 'utils/pushServerErrorToast';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { MentionsValue } from '@ryan/components';

import { WithUser, withUser } from '../UserContext';
import { DataRequestDrawerContext } from './DataRequestDrawerContext';

const EMPTY_COMMENT = new MentionsValue();

interface IDataRequestDrawerProviderProps extends WithTranslation, WithUser {
  children: React.ReactNode;
}

interface IDataRequestDrawerProviderState {
  dataRequest: IDataRequest | null;
  tab: 'history' | 'comments';
  history: IActivity[] | null;
  historyLoading: boolean;
  comments: IComment[] | null;
  commentsLoading: boolean;
  editComment: IComment | null;
  editCommentText: MentionsValue;
  editCommentLoading: Promise<any> | null;
  deleteComment: IComment | null;
  deleteCommentLoading: Promise<any> | null;
  newCommentText: MentionsValue;
  newCommentLoading: Promise<any> | null;
}

export class DataRequestDrawerProvider extends Component<
  IDataRequestDrawerProviderProps,
  IDataRequestDrawerProviderState
> {
  private events = new EventEmitter();

  readonly state: IDataRequestDrawerProviderState = {
    dataRequest: null,
    tab: 'history' as const,
    history: null,
    historyLoading: false,
    comments: null,
    commentsLoading: false,
    editComment: null,
    editCommentText: EMPTY_COMMENT,
    editCommentLoading: null,
    deleteComment: null,
    deleteCommentLoading: null,
    newCommentText: EMPTY_COMMENT,
    newCommentLoading: null
  };

  get canView() {
    const { permissionService: ps } = this.props;
    return ps.hasPermission(Permission.DataRequestsView);
  }

  get canComment() {
    const { isAppReadOnly, permissionService: ps } = this.props;
    const { dataRequest } = this.state;
    return (
      dataRequest !== null &&
      !dataRequest.isEngagementReadOnly &&
      !isAppReadOnly &&
      ps.hasPermission(Permission.DataRequestsContribute)
    );
  }

  handleOpenHistory = (dataRequest: IDataRequest) => {
    this.changeTab('history', dataRequest);
  };

  handleOpenComments = (dataRequest: IDataRequest) => {
    this.changeTab('comments', dataRequest);
  };

  handleTabChange = (activeTabKey: 'history' | 'comments') => {
    const { dataRequest } = this.state;
    if (dataRequest) {
      this.changeTab(activeTabKey, dataRequest);
    }
  };

  changeTab(tab: 'history' | 'comments', dataRequest: IDataRequest) {
    const { history, historyLoading, comments, commentsLoading } = this.state;

    this.setState({ tab, dataRequest });

    if (!history && !historyLoading) {
      this.fetchHistory(dataRequest);
    }

    if (!comments && !commentsLoading) {
      this.fetchComments(dataRequest);
    }
  }

  fetchHistory = async (dataRequest: IDataRequest) => {
    if (this.canView) {
      this.setState({ historyLoading: true });
      try {
        const { permissionService: ps } = this.props;
        const allowedToViewActivityFeed = ps.hasPermission(
          Permission.ActivityRead
        );

        const response = allowedToViewActivityFeed
          ? await ApiService.getDataRequestActivity(
              dataRequest.engagementGuid,
              dataRequest.queueItemGuid
            )
          : await ApiService.getDataRequestActivityRestricted(
              dataRequest.engagementGuid,
              dataRequest.queueItemGuid
            );
        this.setState({ history: response.data });
      } catch {
        pushServerErrorToast();
      }
      this.setState({ historyLoading: false });
    }
  };

  fetchComments = async (dataRequest: IDataRequest) => {
    if (this.canView) {
      this.setState({ commentsLoading: true });
      try {
        const response = await ApiService.getDataRequestComments(
          dataRequest.engagementGuid,
          dataRequest.queueItemGuid
        );
        this.setState({ comments: response.data });
      } catch {
        // this.pushServerErrorToast();
      }
      this.setState({ commentsLoading: false });
    }
  };

  handleClose = () => {
    const { editCommentLoading, newCommentLoading, deleteComment } = this.state;
    if (!editCommentLoading && !newCommentLoading && !deleteComment) {
      history.replace({
        ...history.location,
        hash: ''
      });

      this.setState({
        dataRequest: null,
        history: null,
        comments: null,
        editComment: null,
        editCommentText: EMPTY_COMMENT,
        deleteComment: null,
        newCommentText: EMPTY_COMMENT,
        newCommentLoading: null
      });
    }
  };

  /**
   * Edit a comment.
   */

  handleEditComment = (editComment: IComment) => {
    if (this.canComment) {
      this.setState({
        editComment,
        editCommentText: getMentionValue(editComment)
      });
    }
  };

  handleEditCommentChange = (editCommentText: MentionsValue) => {
    if (this.canComment) {
      this.setState({ editCommentText });
    }
  };

  handleEditCommentSave = async (dataRequest: IDataRequest) => {
    const { editComment, editCommentText } = this.state;
    if (this.canComment && editComment) {
      const { text } = editCommentText.toJSON();

      // if empty string, delete instead of edit
      if (text.length === 0) {
        this.handleDeleteCommentRequest(editComment);
        return;
      }

      const editCommentLoading = ApiService.updateDataRequestComment(
        dataRequest.engagementGuid,
        editComment.commentGuid,
        text
      );

      this.setState({ editCommentLoading });

      try {
        const { data: updatedComment } = await editCommentLoading;
        this.setState(({ comments }) => ({
          comments:
            comments &&
            comments.map(c =>
              c.commentGuid === updatedComment.commentGuid ? updatedComment : c
            ),
          editComment: null,
          editCommentText: EMPTY_COMMENT
        }));
        this.events.emit('commentEdited', {
          engagementGuid: dataRequest.engagementGuid
        });
      } catch {
        // this.pushServerErrorToast();
      }

      this.setState({ editCommentLoading: null });
    }
  };

  handleEditCommentCancel = () => {
    this.setState({ editComment: null, editCommentText: EMPTY_COMMENT });
  };

  /**
   * Delete a comment.
   */

  handleDeleteCommentRequest = (deleteComment: IComment) => {
    if (this.canComment) {
      this.setState({ deleteComment });
    }
  };

  handleDeleteCommentConfirm = async (dataRequest: IDataRequest) => {
    const { deleteComment } = this.state;
    if (this.canComment && deleteComment) {
      const deleteCommentLoading = ApiService.deleteDataRequestComment(
        dataRequest.engagementGuid,
        deleteComment.commentGuid
      );
      this.setState({ deleteCommentLoading });

      try {
        await deleteCommentLoading;
        this.setState(({ comments, editComment }) => {
          const state: any = {
            comments:
              comments &&
              comments.filter(c => c.commentGuid !== deleteComment.commentGuid),
            deleteComment: null
          };

          if (
            editComment &&
            editComment.commentGuid === deleteComment.commentGuid
          ) {
            state.editComment = null;
            state.editCommentText = '';
          }

          return state;
        });
        this.events.emit('commentRemoved', {
          engagementGuid: dataRequest.engagementGuid
        });
      } catch {
        pushServerErrorToast();
      }

      this.setState({ deleteCommentLoading: null });
    }
  };

  handleDeleteCommentCancel = () => {
    this.setState({ deleteComment: null });
  };

  /**
   * Create a new comment.
   */

  handleNewCommentChange = (newCommentText: MentionsValue) => {
    if (this.canComment) {
      this.setState({ newCommentText });
    }
  };

  handleNewCommentSave = async () => {
    const { dataRequest, newCommentText } = this.state;
    const { text } = newCommentText.toJSON();

    if (this.canComment && dataRequest && text.length > 0) {
      const newCommentLoading = ApiService.createDataRequestComment(
        dataRequest.engagementGuid,
        dataRequest.queueItemGuid,
        text
      );

      this.setState({ newCommentLoading });

      try {
        const response = await newCommentLoading;
        this.setState(({ comments }) => ({
          comments: comments && comments.concat([response.data]),
          newCommentText: EMPTY_COMMENT
        }));
        this.events.emit('commentAdded', {
          engagementGuid: dataRequest.engagementGuid
        });
      } catch {
        pushServerErrorToast();
      }

      this.setState({ newCommentLoading: null });
    }
  };

  render() {
    const { t, children } = this.props;

    const {
      dataRequest,
      tab,
      history,
      historyLoading,
      comments,
      commentsLoading,
      editComment,
      editCommentText,
      editCommentLoading,
      deleteComment,
      deleteCommentLoading,
      newCommentText,
      newCommentLoading
    } = this.state;

    return (
      <>
        <DataRequestDrawerContext.Provider
          value={{
            dataRequestDrawerEvents: this.events,
            onDataRequestDrawerOpenHistory: this.handleOpenHistory,
            onDataRequestDrawerOpenComments: this.handleOpenComments
          }}
        >
          {children}
        </DataRequestDrawerContext.Provider>

        {this.canView && (
          <>
            <DataRequestDrawer
              canComment={this.canComment}
              comments={comments}
              commentsLoading={commentsLoading}
              dataRequest={dataRequest}
              editComment={editComment}
              editCommentLoading={editCommentLoading}
              editCommentText={editCommentText}
              history={history}
              historyLoading={historyLoading}
              newCommentLoading={newCommentLoading}
              newCommentText={newCommentText}
              onClose={this.handleClose}
              onDeleteComment={this.handleDeleteCommentRequest}
              onEditComment={this.handleEditComment}
              onEditCommentCancel={this.handleEditCommentCancel}
              onEditCommentChange={this.handleEditCommentChange}
              onEditCommentSave={() => this.handleEditCommentSave(dataRequest!)}
              onNewCommentChange={this.handleNewCommentChange}
              onNewCommentSave={this.handleNewCommentSave}
              onTabChange={this.handleTabChange}
              t={t}
              tabKey={tab}
            />

            <CommentDeleteModal
              loading={deleteCommentLoading}
              onCancel={this.handleDeleteCommentCancel}
              onConfirm={() => this.handleDeleteCommentConfirm(dataRequest!)}
              open={deleteComment !== null}
              t={t}
            />
          </>
        )}
      </>
    );
  }
}

export default withTranslation()(withUser(DataRequestDrawerProvider));
