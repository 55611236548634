import ENV from '../env';

/**
 * Redirects user to RWM/Decisions App.
 */
export default function redirectToDecisionsApp(redirectUrl?: string) {
  const returnUrl = `&Return=${window.location.href}`;
  const STATIC_PATH =
    '/Primary/H/?FolderId=08db87b7-d2e4-d23f-e16f-131a9006b45b&pageName=Data%20Request%20Create%20Request%20Page&chrome=off';
  const path = redirectUrl ? redirectUrl : STATIC_PATH;

  window.open(ENV.DECISIONS_BASE_URL + path + returnUrl, '_self');
}

export function getRedirectToDecisionsAppUrl(redirectUrl?: string) {
  const returnUrl = `&Return=${window.location.href}`;
  const STATIC_PATH =
    '/Primary/H/?FolderId=08db87b7-d2e4-d23f-e16f-131a9006b45b&pageName=Data%20Request%20Create%20Request%20Page&chrome=off';
  const path = redirectUrl ? redirectUrl : STATIC_PATH;

  return ENV.DECISIONS_BASE_URL + path + returnUrl;
}
