import env from 'env';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, GlobalFooter } from '@ryan/components';

import { useAmplitude } from '../../contexts/AmplitudeContext/AmplitudeConsumer';
import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import { useUser } from '../../contexts/UserContext';
import { Feature } from '../../interfaces';
import ApiService from '../../services/ApiService';
import {
  AmplitudeActionType,
  amplitudeEventDetail
} from '../../utils/amplitudeUtils/amplitudeUtils';
import pushServerErrorToast from '../../utils/pushServerErrorToast';
import HelpModal from '../HelpModal/HelpModal';
import ReleaseNotesModal from './ReleaseNotesModal/ReleaseNotesModal';
import { IRenderHyperLink } from './utils/';

import './AppFooter.scss';

export const AppFooter: React.FunctionComponent = () => {
  const { triggerAmplitudeEvent } = useAmplitude();
  const { t: textToDisplay } = useTranslation();
  const { isFeatureToggled, permissionService } = useUser();
  const { isUnsavedChanges, setBlockNavigation, setTargetUrl } =
    useUnsavedChanges();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isReleaseNotesFeatureToggled] = useState(
    isFeatureToggled(Feature.ReleaseNotes)
  );
  const [isReleaseNotesModalOpen, setIsReleaseNotesModalOpen] =
    useState<boolean>(false);

  const trainingUrl =
    (permissionService.isRyan() && env.URL_LEARN_RYAN) ||
    (permissionService.isClient() && env.URL_LEARN_CLIENT);

  const renderHyperLink: IRenderHyperLink = (href, text) => {
    return (
      <>
        <span />
        <a
          href={href}
          onClick={handleNavigation(href)}
          rel="noopener noreferrer"
          target="_blank"
        >
          {text}
        </a>
      </>
    );
  };

  const fetchShouldOpenNewModal = useCallback(async () => {
    try {
      const response = await ApiService.getShouldOpenNewModal();

      if (response && response.data !== undefined) {
        setIsReleaseNotesModalOpen(response.data);
      }
    } catch (error) {
      pushServerErrorToast();
    }
  }, []);

  const handleNavigation = (url: string) => (event: React.MouseEvent) => {
    if (isUnsavedChanges) {
      setBlockNavigation(true);
      setTargetUrl(url);
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (isReleaseNotesFeatureToggled) {
      fetchShouldOpenNewModal();
    }
  }, [fetchShouldOpenNewModal, isReleaseNotesFeatureToggled]);

  return (
    <>
      <GlobalFooter copyrightText="">
        <Link onClick={handleNavigation('/go-to/privacy')} to="/go-to/privacy">
          {textToDisplay('appFooter.privacyPolicy')}
        </Link>
        <span />
        <Link onClick={handleNavigation('/go-to/terms')} to="/go-to/terms">
          {textToDisplay('appFooter.termsOfUse')}
        </Link>
        {renderHyperLink(
          'https://ryancx.co1.qualtrics.com/jfe/form/SV_8wwCGoA3E4vOPLT',
          textToDisplay('appFooter.giveFeedback')
        )}
        {trainingUrl && (
          <>
            <span />
            <a
              href={trainingUrl}
              onClick={() => {
                triggerAmplitudeEvent({
                  amplitudeEventAction: AmplitudeActionType.CLICK,
                  amplitudeEventLocation: 'footer',
                  amplitudeEventName:
                    amplitudeEventDetail.global.footerLearnLink
                });
                handleNavigation(trainingUrl);
              }}
              rel="noopener noreferrer"
              target="_blank"
            >
              {textToDisplay('appFooter.learn')}
            </a>
          </>
        )}
        <span />
        <Button onClick={() => setIsHelpModalOpen(true)} variant="link">
          {textToDisplay('appFooter.help')}
        </Button>
        {isReleaseNotesFeatureToggled && (
          <>
            <span />
            <Button
              onClick={() => setIsReleaseNotesModalOpen(true)}
              variant="link"
            >
              {textToDisplay('whatsNew')}
            </Button>
          </>
        )}
      </GlobalFooter>

      <HelpModal
        onClose={() => setIsHelpModalOpen(false)}
        open={isHelpModalOpen}
      />

      {isReleaseNotesModalOpen && (
        <ReleaseNotesModal
          onClose={() => {
            setIsReleaseNotesModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default AppFooter;
