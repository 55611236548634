import { IMapAssignmentToVariousTaskModals } from './TaskModals.interfaces';
import { UserType } from 'interfaces';

export enum TaskModalActionEnums {
  COMPLETE = 'complete',
  DELETE = 'delete',
  EDIT = 'edit',
  REASSIGN = 'reassign'
}

export const mapAssignmentToCompleteTask: IMapAssignmentToVariousTaskModals = ({
  assignmentAssignedTo,
  assignmentDescription,
  assignmentDueDate,
  assignmentStatusId,
  assignmentTitle,
  isAssignmentCurrentlyWatched,
  engagementGuid,
  engagementMilestoneGuid,
  queueItemGuid
}) => ({
  assignedToUserGuid: assignmentAssignedTo.userGuid,
  description: assignmentDescription,
  dueDate: new Date(assignmentDueDate),
  engagementGuid,
  engagementMilestoneGuid,
  isCurrentUserWatching: isAssignmentCurrentlyWatched,
  queueItemGuid,
  statusId: assignmentStatusId,
  title: assignmentTitle
});

export const mapAssignmentToDeleteTask: IMapAssignmentToVariousTaskModals = ({
  assignmentTitle,
  engagementGuid,
  queueItemGuid
}) => ({
  engagementGuid,
  queueItemGuid,
  title: assignmentTitle
});

export const mapAssignmentToEditTask: IMapAssignmentToVariousTaskModals = ({
  assignmentAssignedTo,
  assignmentAttachments,
  assignmentCreatedBy,
  assignmentDescription,
  assignmentDueDate,
  assignmentStatusId,
  assignmentTitle,
  isAssignmentCurrentlyWatched,
  engagementDisplayNameShort,
  engagementGuid,
  engagementMilestoneGuid,
  queueItemGuid
}) => ({
  assignedToUserGuid: assignmentAssignedTo.userGuid,
  attachments: assignmentAttachments || [],
  createdByUserType: assignmentCreatedBy.userType as UserType,
  description: assignmentDescription,
  dueDate: new Date(assignmentDueDate),
  engagementDisplayNameShort,
  engagementGuid,
  engagementMilestoneGuid,
  isCurrentUserWatching: isAssignmentCurrentlyWatched,
  queueItemGuid,
  statusId: assignmentStatusId,
  title: assignmentTitle
});

export const mapAssignmentToReasignTask: IMapAssignmentToVariousTaskModals = ({
  assignmentAssignedTo,
  assignmentDescription,
  assignmentDueDate,
  assignmentStatusId,
  assignmentTitle,
  engagementDisplayNameShort,
  engagementGuid,
  engagementMilestoneGuid,
  isAssignmentCurrentlyWatched,
  queueItemGuid
}) => ({
  assignedToName: assignmentAssignedTo.name,
  assignedToUserGuid: assignmentAssignedTo.userGuid,
  description: assignmentDescription,
  dueDate: new Date(assignmentDueDate),
  engagementDisplayNameShort,
  engagementGuid,
  engagementMilestoneGuid,
  isCurrentUserWatching: isAssignmentCurrentlyWatched,
  queueItemGuid,
  statusId: assignmentStatusId,
  title: assignmentTitle
});
