import DataRequestModal from 'components/DataRequestModal/DataRequestModal';
import DataRequestStatusSummaryCard from 'components/DataRequestStatusSummaryCard/DataRequestStatusSummaryCard';
import DataRequestUpcomingSummaryCard from 'components/DataRequestUpcomingSummaryCard';
import RecentActivityCard from 'components/RecentActivityCard/RecentActivityCard';
import RecentDataRequestsCard from 'components/RecentDataRequestsCard/RecentDataRequestsCard';
import { WithUser, withUser } from 'contexts/UserContext';
import ApiService, { CancelTokenSource } from 'services/ApiService';
import pushServerErrorToast from 'utils/pushServerErrorToast';
import switcherDidUpdate from 'utils/switcherDidUpdate';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  Feature,
  IDataRequest,
  IDataRequestsCountByStatus,
  IUpcomingDataRequests,
  Permission
} from '../../../interfaces';
import redirectToDecisionsApp from '../../../utils/redirectToDecisions';

import './Overview.scss';

interface IDataAndFilesOverviewProps
  extends WithUser,
    RouteComponentProps,
    WithTranslation {}

interface IDataAndFilesOverviewState {
  createButtonDisabled: boolean;
  dataRequestsUpcoming: IUpcomingDataRequests;
  dataRequestsUpcomingLoading: boolean;
  dataRequestsStatus: IDataRequestsCountByStatus | null;
  openDataRequestModal: boolean;
}

export class Overview extends Component<
  IDataAndFilesOverviewProps,
  IDataAndFilesOverviewState
> {
  private _isMounted = false;
  private upcomingDataRequestsCancelToken?: CancelTokenSource;
  private statusCountCancelToken?: CancelTokenSource;

  readonly state = {
    // Default numbers to 0
    createButtonDisabled: true,
    dataRequestsUpcoming: {
      overDue: 0,
      dueTwoWeeks: 0,
      dueOneMonth: 0
    },
    dataRequestsUpcomingLoading: false,
    dataRequestsStatus: null,
    openDataRequestModal: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchCounts();
  }

  componentDidUpdate(prevProps: IDataAndFilesOverviewProps) {
    if (switcherDidUpdate(prevProps, this.props)) {
      this.fetchCounts();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.upcomingDataRequestsCancelToken?.cancel();
    this.statusCountCancelToken?.cancel();
  }

  fetchCounts() {
    this.getUpcomingDataRequestsForCustomView();
    this.getDataRequestStatusCount();
    this.getEngagementsForCustomView();
  }

  async getUpcomingDataRequestsForCustomView() {
    const {
      activeView: { customViewGuid },
      permissionService: ps
    } = this.props;

    if (ps.hasPermission(Permission.DataRequestsView)) {
      this.setState({ dataRequestsUpcomingLoading: true });

      try {
        // refresh cancel token
        this.upcomingDataRequestsCancelToken?.cancel();
        this.upcomingDataRequestsCancelToken = ApiService.CancelToken.source();

        // This call will return all data requests in an account that are not yet complete
        const response = await ApiService.getUpcomingDataRequestsForCustomView(
          customViewGuid,
          this.upcomingDataRequestsCancelToken.token
        );

        this.setState({ dataRequestsUpcoming: response.data });
      } catch (error) {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();
        }
      } finally {
        if (this._isMounted) {
          this.setState({ dataRequestsUpcomingLoading: false });
        }
      }
    }
  }

  async getDataRequestStatusCount() {
    const {
      activeView: { customViewGuid },
      permissionService: ps
    } = this.props;

    if (ps.hasPermission(Permission.DataRequestsView)) {
      try {
        // refresh cancel token
        this.statusCountCancelToken?.cancel();
        this.statusCountCancelToken = ApiService.CancelToken.source();

        const response = await ApiService.getCustomViewDataRequestCount(
          customViewGuid,
          this.statusCountCancelToken.token
        );
        this.setState({ dataRequestsStatus: response.data });
      } catch (error) {
        if (!ApiService.isCancel(error)) {
          pushServerErrorToast();

          if (this._isMounted) {
            this.setState({
              dataRequestsStatus: {
                complete: 0,
                inProgress: 0,
                toDo: 0
              }
            });
          }
        }
      }
    }
  }

  async getEngagementsForCustomView() {
    const {
      activeView: { customViewGuid },
      user: {
        profile: { userGuid }
      }
    } = this.props;

    if (userGuid && customViewGuid) {
      ApiService.getEngagementsForCustomViewByUser(
        customViewGuid,
        userGuid,
        {}
      ).then(({ data }) => {
        const results = data.results;
        const isUserGhosted =
          results.filter(engagement => !engagement.isUserGhosted).length === 0;
        this.setState({ createButtonDisabled: isUserGhosted });
      });
    }
  }

  handleCreateDataRequestClose = (dataRequest?: IDataRequest) => {
    if (dataRequest) {
      this.getUpcomingDataRequestsForCustomView();
      this.getDataRequestStatusCount();
    }

    if (this._isMounted) {
      this.setState({ openDataRequestModal: false });
    }
  };

  render() {
    const {
      isFeatureToggled,
      permissionService,
      t: getTextToDisplay
    } = this.props;
    const {
      createButtonDisabled,
      dataRequestsUpcoming,
      dataRequestsUpcomingLoading,
      dataRequestsStatus,
      openDataRequestModal
    } = this.state;

    const isDataRequestsUpdateVisible = isFeatureToggled(
      Feature.RWMDataRequests
    );

    return (
      <>
        <div className="data-and-files-overview row">
          <div className="col-12 col-lg-4">
            <div className="row">
              {permissionService.hasPermission(Permission.DataRequestsView) && (
                <div className="col-md-6 col-lg-12">
                  <DataRequestUpcomingSummaryCard
                    loading={dataRequestsUpcomingLoading}
                    t={getTextToDisplay}
                    values={dataRequestsUpcoming}
                  />
                </div>
              )}
              {permissionService.hasPermission(Permission.DataRequestsView) && (
                <div className="col-md-6 col-lg-12">
                  <DataRequestStatusSummaryCard
                    isEngagementReadOnly={createButtonDisabled}
                    onCreateClick={() => {
                      if (isDataRequestsUpdateVisible) {
                        redirectToDecisionsApp();
                      } else if (
                        permissionService.hasPermission(
                          Permission.DataRequestsEdit
                        )
                      ) {
                        this.setState({ openDataRequestModal: true });
                      }
                    }}
                    values={dataRequestsStatus}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-8 order-lg-first">
            {permissionService.hasPermission(Permission.ActivityRead) && (
              <RecentActivityCard />
            )}
            {permissionService.hasPermission(Permission.DataRequestsView) && (
              <RecentDataRequestsCard />
            )}
          </div>
        </div>

        {permissionService.hasPermission(Permission.DataRequestsEdit) && (
          <DataRequestModal
            onClose={this.handleCreateDataRequestClose}
            open={openDataRequestModal}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(withUser(withRouter(Overview)));
