import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonGroup } from '@ryan/components';

import Modal from '../../../components/Modal';
import { ICodeNoteDeleteModalProps } from './utils';

const CodeNoteDeleteModal: FunctionComponent<ICodeNoteDeleteModalProps> = ({
  onCancel,
  onConfirm
}) => {
  const { t } = useTranslation();
  return (
    <Modal onClose={onCancel} open title={t('codeNotes.deleteModal.title')}>
      <p>{t('codeNotes.deleteModal.content')}</p>
      <ButtonGroup>
        <Button negative onClick={onConfirm} text={t('Yes, Delete')} />
        <Button onClick={onCancel} text={t('No, Cancel')} variant="secondary" />
      </ButtonGroup>
    </Modal>
  );
};

export default CodeNoteDeleteModal;
