import React, { FunctionComponent } from 'react';

const TestRoute: FunctionComponent = () => {
  return (
    <div>
      <h1>Test Route</h1>
    </div>
  );
};

export default TestRoute;
