import Table from 'components/Table';
import { WithUser, withUser } from 'contexts/UserContext';
import {
  IRole,
  IRolePermission,
  IUserPermission,
  Permission
} from 'interfaces';

import React, { ChangeEvent, Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  Checkbox,
  Dropdown,
  Icon,
  Tooltip,
  pushToast
} from '@ryan/components';

import './RoleAndPermissions.scss';

const noop = () => {};

export interface IRoleAndPermissionsProps {
  activeUserPermissions?: IUserPermission[];
  canEditUserPermissions: boolean;
  customPermissions: IUserPermission[];
  isThirdPartyUser: boolean;
  onChange: (
    selectedRoleGuid: string,
    customPermissions: IUserPermission[]
  ) => void;
  roles: IRole[] | null;
  selectedRoleGuid: string;
}

interface IRoleAndPermissionsState {
  isCustomizing: boolean;
}

export class RoleAndPermissions extends Component<
  IRoleAndPermissionsProps & WithUser & WithTranslation,
  IRoleAndPermissionsState
> {
  private columns: any[];

  constructor(props: IRoleAndPermissionsProps & WithUser & WithTranslation) {
    super(props);
    const { t } = props;

    this.state = {
      isCustomizing: false
    };

    this.columns = [
      {
        id: 'permissionName',
        label: t('Permissions'),
        render: this.renderPermissionNameAndDescription,
        width: '100%'
      },
      {
        id: 'isGranted',
        label: t('Granted'),
        align: 'center',
        render: this.renderPermissionCheckbox
      }
    ];
  }

  handleRoleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { onChange } = this.props;
    onChange(e.target.value, []);
  };

  handleCustomize = () => {
    this.setState({ isCustomizing: true });
  };

  handlePermissionChange = (
    isChecked: boolean,
    rolePermission: IRolePermission
  ) => {
    const {
      activeUserPermissions,
      customPermissions,
      onChange,
      selectedRoleGuid
    } = this.props;

    const permissionGroupDependency: {
      [key: string]: { disabled?: string[]; enabled?: string[] };
    } = {
      [Permission.DataRequestsContribute]: {
        enabled: [
          Permission.DataRequestsView,
          Permission.FilesRead,
          Permission.FilesContribute
        ]
      },
      [Permission.DataRequestsEdit]: {
        enabled: [
          Permission.DataRequestsView,
          Permission.FilesRead,
          Permission.FilesContribute
        ]
      },
      [Permission.DataRequestsView]: {
        disabled: [
          Permission.DataRequestsContribute,
          Permission.DataRequestsEdit
        ],
        enabled: [Permission.FilesRead, Permission.FilesContribute]
      },
      [Permission.FilesContribute]: {
        disabled: [
          Permission.DataRequestsView,
          Permission.DataRequestsEdit,
          Permission.DataRequestsContribute
        ],
        enabled: [Permission.FilesRead]
      },
      [Permission.FilesRead]: {
        disabled: [
          Permission.FilesContribute,
          Permission.FilesWrite,
          Permission.DataRequestsView,
          Permission.DataRequestsEdit,
          Permission.DataRequestsContribute
        ]
      },
      [Permission.FilesWrite]: { enabled: [Permission.FilesRead] },
      [Permission.LearningsContribute]: { enabled: [Permission.LearningsView] },
      [Permission.LearningsEdit]: { enabled: [Permission.LearningsView] },
      [Permission.LearningsView]: {
        disabled: [Permission.LearningsContribute, Permission.LearningsEdit]
      },
      [Permission.TasksContribute]: { enabled: [Permission.TasksView] },
      [Permission.TasksEdit]: { enabled: [Permission.TasksView] },
      [Permission.TasksView]: {
        disabled: [Permission.TasksContribute, Permission.TasksEdit]
      },
      [Permission.TimelinesContribute]: { enabled: [Permission.TimelinesView] },
      [Permission.TimelinesEdit]: { enabled: [Permission.TimelinesView] },
      [Permission.TimelinesView]: {
        disabled: [Permission.TimelinesContribute, Permission.TimelinesEdit]
      },
      [Permission.ReportsLogos]: { enabled: [Permission.ReportsView] },
      [Permission.ReportsView]: { disabled: [Permission.ReportsLogos] },
      [Permission.SavingsSummaryEdit]: {
        enabled: [Permission.SavingsSummaryView]
      },
      [Permission.SavingsSummaryView]: {
        disabled: [Permission.SavingsSummaryEdit]
      },
      [Permission.SchedulingAdmin]: { enabled: [Permission.SchedulingBasic] },
      [Permission.SchedulingBasic]: { disabled: [Permission.SchedulingAdmin] }
    };

    const permissionDependency =
      permissionGroupDependency[
        rolePermission.permissionGuid as keyof typeof permissionGroupDependency
      ];
    const dependencyGuids =
      (permissionDependency &&
        permissionDependency[isChecked ? 'enabled' : 'disabled']) ||
      [];

    let updatedCustomPermissions = [...customPermissions];

    [rolePermission.permissionGuid, ...dependencyGuids].forEach(
      dependencyGuid => {
        const permission = (
          activeUserPermissions ||
          (this.getSelectedRole() as IRole).rolePermissions
        ).find(
          userPermission => userPermission.permissionGuid === dependencyGuid
        );

        if (!permission) {
          return;
        }

        const {
          isGranted,
          permissionDescription,
          permissionGroup,
          permissionGroupId,
          permissionGuid,
          permissionName,
          userTypes
        } = permission;

        updatedCustomPermissions = updatedCustomPermissions.filter(
          customPermission => customPermission.permissionGuid !== dependencyGuid
        );

        updatedCustomPermissions.push({
          isGranted: isChecked,
          permissionDescription,
          permissionGroup,
          permissionGroupId,
          permissionGuid,
          permissionName,
          userTypes
        });
      }
    );

    onChange(selectedRoleGuid, updatedCustomPermissions);
  };

  handleReset = () => {
    const { t, onChange } = this.props;
    const selectedRole = this.getSelectedRole();

    if (selectedRole) {
      onChange(selectedRole.roleGuid, []);
      pushToast({
        type: 'info',
        title: t('roleAndPermissions.revertToastTitle'),
        content: t('roleAndPermissions.revertToastContent')
      });
    }
  };

  getRoleOptions() {
    const { t, roles } = this.props;
    return [
      {
        value: '',
        label: t('roleAndPermissions.selectRole'),
        disabled: true
      },
      ...(roles || []).map((role: IRole) => ({
        value: role.roleGuid,
        label: role.roleName
      }))
    ];
  }

  getSelectedRole() {
    const { roles, selectedRoleGuid } = this.props;
    if (roles) {
      const role = roles.find(r => r.roleGuid === selectedRoleGuid);
      if (role) {
        return role;
      }
    }
    return null;
  }

  render() {
    const {
      canEditUserPermissions,
      customPermissions,
      isThirdPartyUser,
      permissionService: ps,
      roles,
      selectedRoleGuid,
      t
    } = this.props;
    const { isCustomizing } = this.state;

    const role = this.getSelectedRole();
    let permissions: IRolePermission[] | null = null;

    if (role) {
      // Executive Access is given per user by SuperAdmins.
      // Remove it from list.
      permissions = role.rolePermissions.filter(
        p => p.permissionGuid !== Permission.ExecutiveAccess
      );
    }

    const roleHasPermissions: boolean = !!permissions && permissions.length > 0;

    return (
      <div className="role-and-permissions">
        <div className="role-and-permissions__role">
          <Dropdown
            disabled={!roles || roles.length === 0}
            label={t('Role')}
            name="Role"
            onChange={this.handleRoleChange}
            options={this.getRoleOptions()}
            value={selectedRoleGuid}
          />
          {/**
           * If we have permission to edit, and the selected role has permissions
           * to customize OR user is SuperAdmin, then show the button.
           */}
          {!isThirdPartyUser &&
            (canEditUserPermissions || ps.isSuperAdmin()) &&
            ps.isRyan() && (
              <Button
                disabled={!roleHasPermissions || isCustomizing}
                onClick={this.handleCustomize}
                text={t('Customize')}
                variant="text"
              />
            )}
        </div>
        {/**
         * If we have permission to edit, and the selected role has permissions
         * to customize, and we are customizing (someone hit the button).
         */}
        {canEditUserPermissions &&
          !!role &&
          !!permissions &&
          roleHasPermissions &&
          isCustomizing && (
            <div className="role-and-permissions__permissions">
              <h3 className="ry-h3">
                {t('roleAndPermissions.customizeTitle', {
                  roleName: role.roleName
                })}
              </h3>
              <Table<IRolePermission>
                columns={this.columns}
                data={permissions}
                groupBy="permissionGroupId"
                renderGroupHeader={permissionGroupId =>
                  t(`manageRoles.permissionGroup.${permissionGroupId}`)
                }
                rowId="permissionGuid"
              />
              <div className="role-and-permissions__permissions-footer">
                <Tooltip
                  content={
                    <div className="role-and-permissions__permissions-legend">
                      <Checkbox
                        checked
                        label={t('roleAndPermissions.legend.default')}
                        onChange={noop}
                        value="selected-default"
                        variant="secondary"
                      />
                      <Checkbox
                        checked
                        label={t('roleAndPermissions.legend.custom')}
                        onChange={noop}
                        value="selected-custom"
                      />
                      <Checkbox
                        checked
                        disabled
                        label={t('roleAndPermissions.legend.selectedDisabled')}
                        onChange={noop}
                        value="selected-disabled"
                      />
                      <Checkbox
                        disabled
                        label={t(
                          'roleAndPermissions.legend.notSelectedDisabled'
                        )}
                        onChange={noop}
                        value="disabled"
                      />
                    </div>
                  }
                  placement="top"
                  renderTarget={({ open, ...props }) => (
                    <span
                      aria-expanded={open}
                      aria-haspopup="true"
                      className="role-and-permissions__permissions-legend-target"
                      tabIndex={0}
                      {...props}
                    >
                      <Icon name="information" />
                      {t('roleAndPermissions.legend.title')}
                    </span>
                  )}
                />
                <Button
                  disabled={customPermissions.length === 0}
                  onClick={this.handleReset}
                  variant="text"
                >
                  {t('roleAndPermissions.revert')}
                </Button>
              </div>
            </div>
          )}
      </div>
    );
  }

  renderPermissionNameAndDescription = ({
    permissionDescription,
    permissionGuid,
    permissionName
  }: IRolePermission) => {
    const { i18n, t } = this.props;
    return (
      <>
        <b>
          {i18n.exists(`manageRoles.permission.${permissionGuid}.name`)
            ? t(`manageRoles.permission.${permissionGuid}.name`)
            : permissionName}
        </b>
        <p>
          {i18n.exists(`manageRoles.permission.${permissionGuid}.description`)
            ? t(`manageRoles.permission.${permissionGuid}.description`)
            : permissionDescription}
        </p>
      </>
    );
  };

  renderPermissionCheckbox = (row: IRolePermission) => {
    const { customPermissions } = this.props;
    const customPermission = customPermissions.find(
      p => p.permissionGuid === row.permissionGuid
    );
    return (
      <Checkbox
        checked={
          customPermission !== undefined
            ? customPermission.isGranted
            : row.isGranted
        }
        disabled={row.isLocked}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          this.handlePermissionChange(e.target.checked, row);
        }}
        value={row.permissionGuid}
        variant={row.isGranted ? 'secondary' : 'primary'}
      />
    );
  };
}

export default withTranslation()(withUser(RoleAndPermissions));
