import Drawer from 'components/Drawer';
import { useAppReadOnly } from 'contexts/UserContext';
import { INotification } from 'interfaces';

import { TFunction } from 'i18next';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef
} from 'react';
import { useInView } from 'react-intersection-observer';

import { Button, Icon, Tooltip } from '@ryan/components';

import {
  NotificationFeed,
  NotificationFeedSkeleton
} from '../NotificationFeed';
import { NotificationHookResponse } from './useNotifications';

import './NotificationDrawer.scss';

interface INotificationDrawerProps {
  notifications: INotification[] | null;
  notificationsItemsPerPage: number;
  notificationsLoading: boolean;
  notificationsTotalResults: number;
  onClose: () => void;
  onDismissNotifications: NotificationHookResponse[1]['dismissNotifications'];
  onFetchNext: () => void;
  onFetchNotifications: () => void;
  onTrackDismissAllNotifications: () => void;
  onTrackDismissNotifications: (totalResults: number) => void;
  open: boolean;
  t: TFunction;
}

export const NotificationDrawer: FunctionComponent<
  INotificationDrawerProps
> = props => {
  const {
    notifications,
    notificationsItemsPerPage,
    notificationsLoading,
    notificationsTotalResults,
    onClose,
    onDismissNotifications,
    onFetchNext,
    onFetchNotifications,
    onTrackDismissAllNotifications,
    onTrackDismissNotifications,
    open,
    t
  } = props;

  const isAppReadOnly = useAppReadOnly();
  const infiniteScrollRootRef = useRef<HTMLDivElement | null>(null);

  const hasUserClearedAllLoadedNotifications =
    notificationsTotalResults > 0 && notifications?.length === 0;
  const isLoadingNotifications = notificationsLoading;

  const [inViewRef, isInView] = useInView({
    root: infiniteScrollRootRef.current,
    rootMargin: '0px 0px 200px'
  });

  const infiniteScrollTargetRef = useCallback(
    (node: Element | null) => {
      if (node) {
        inViewRef(node);
      }
    },
    [inViewRef]
  );

  useEffect(() => {
    if (isInView) {
      onFetchNext();
    }
  }, [onFetchNext, isInView]);

  useEffect(() => {
    if (!isLoadingNotifications && hasUserClearedAllLoadedNotifications) {
      onFetchNotifications();
    }
  }, [
    hasUserClearedAllLoadedNotifications,
    isLoadingNotifications,
    onFetchNotifications
  ]);

  return (
    <Drawer className="notification-drawer" onClose={onClose} open={open}>
      <Drawer.Header>
        <div className="notification-drawer__header">
          <h2 className="ry-h2">{t('activity.notifications.title')}</h2>
          <Tooltip
            content={t('activity.notifications.tooltip')}
            placement="bottom"
            renderTarget={({ open, ...props }) => (
              <div
                aria-expanded={open}
                aria-haspopup="true"
                className="notification-drawer__tooltip"
                {...props}
              >
                <Icon name="information" />
              </div>
            )}
          />
          {isAppReadOnly && (
            <Tooltip
              content={t('activity.notifications.executiveTooltip')}
              placement="bottom"
              renderTarget={({ open, ...props }) => (
                <div
                  aria-expanded={open}
                  aria-haspopup="true"
                  className="notification-drawer__tooltip"
                  {...props}
                >
                  <Icon name="warning-outline" />
                </div>
              )}
            />
          )}
          <Button
            disabled={
              !notifications ||
              notifications.length === 0 ||
              notificationsLoading
            }
            onClick={() => {
              onDismissNotifications(notifications!);
              onTrackDismissAllNotifications();
            }}
            text={`${t(
              'activity.notifications.clearAll'
            )} (${notificationsTotalResults})`}
            variant="text"
          />
        </div>
      </Drawer.Header>
      <Drawer.Body ref={infiniteScrollRootRef}>
        {isLoadingNotifications && (
          <NotificationFeedSkeleton count={notificationsItemsPerPage} />
        )}

        {!isLoadingNotifications && !hasUserClearedAllLoadedNotifications && (
          <>
            <NotificationFeed
              notifications={notifications || []}
              notificationsLoading={notificationsLoading}
              onCloseNotificationDrawer={onClose}
              onDismissNotifications={onDismissNotifications}
              onTrackDismissNotifications={onTrackDismissNotifications}
            />

            <div ref={infiniteScrollTargetRef} />
          </>
        )}
      </Drawer.Body>
    </Drawer>
  );
};

export default NotificationDrawer;
