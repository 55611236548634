import Modal from 'components/Modal';

import React, { FunctionComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button, ButtonGroup } from '@ryan/components';

interface IFileDeleteModalProps extends WithTranslation {
  open: boolean;
  multiple?: boolean;
  loading: Promise<any> | null;
  onSubmit: () => void;
  onCancel: () => void;
}

export const FileDeleteModal: FunctionComponent<IFileDeleteModalProps> =
  props => {
    const { t, open, multiple, loading, onSubmit, onCancel } = props;
    return (
      <Modal
        onClose={onCancel}
        open={open}
        title={t('file.deleteModal.title', { count: multiple ? 2 : 1 })}
      >
        <p>
          {t('file.deleteModal.content1')}
          <br />
          {t('file.deleteModal.content2')}
        </p>
        <ButtonGroup>
          <Button
            loading={loading}
            negative
            onClick={onSubmit}
            text={t('Yes, Delete')}
          />
          <Button
            disabled={loading !== null}
            onClick={onCancel}
            text={t('No, Cancel')}
            variant="secondary"
          />
        </ButtonGroup>
      </Modal>
    );
  };

export default withTranslation()(FileDeleteModal);
