import Screen from 'components/Screen';
import { AppLayout, PublicLayout, PublicLayoutToBeProtected } from 'layouts';
import { UserManager } from 'oidc-client';
import { trackPageView } from 'services/Analytics';
import history from 'services/history';
import { logger } from 'services/logger';
import ScrollToTop from 'utils/ScrollToTop';
import { scrubSensitiveDataFromURL } from 'utils/scrubSensitiveDataFromURL';

import React, { Suspense, useEffect } from 'react';
import { Callback, makeAuthenticator } from 'react-oidc';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { ToastContainer } from '@ryan/components';

import AmplitudeProvider from './contexts/AmplitudeContext/AmplitudeProvider';
import UnsavedChangesProvider from './contexts/UnsavedChangesContext/UnsavedChangesProvider';

const App: React.FC<{ userManager: UserManager }> = ({ userManager }) => {
  const authenticatorParams = {
    placeholderComponent: <Screen />,
    signinArgs: {
      state: {
        path:
          // `path` value dictates where user is redirected to on successful
          // login; if user originated in public route before logging in
          // (ex. /public/reset-password-success or /public/logout), redirect
          // user to base '/app' route post-login instead of back to the public
          // route
          window.location.pathname.indexOf('/public') === 0
            ? '/app'
            : `${window.location.pathname}${window.location.search}${window.location.hash}`
      }
    },
    userManager
  };
  const ProtectedAppLayout = makeAuthenticator(authenticatorParams)(AppLayout);
  const ProtectedPublicLayout = makeAuthenticator(authenticatorParams)(
    PublicLayoutToBeProtected
  );

  // track initial page view
  useEffect(() => {
    trackPageView(
      scrubSensitiveDataFromURL(
        window.location.pathname + window.location.search + window.location.hash
      )
    );
  }, []);

  return (
    <Suspense fallback={<Screen />}>
      <ToastContainer />
      <Router history={history}>
        <ScrollToTop />
        <AmplitudeProvider>
          <UnsavedChangesProvider>
            <Switch>
              <Route
                path="/authentication/callback"
                render={() => (
                  <>
                    <Screen />
                    <Callback
                      onError={error => {
                        logger.error(
                          `authentication callback has failed with "${error.message}"`
                        );
                        history.push('/app');
                      }}
                      onSuccess={user => {
                        const redirectTo: unknown = user?.state?.path;
                        history.push(
                          typeof redirectTo === 'string' &&
                            redirectTo.indexOf('/authentication/callback') ===
                              -1
                            ? redirectTo
                            : '/app'
                        );
                      }}
                      userManager={userManager}
                    />
                  </>
                )}
              />
              <Route
                path="/public"
                render={() => <PublicLayout userManager={userManager} />}
              />
              <Route component={ProtectedPublicLayout} path="/go-to" />
              <Route component={ProtectedAppLayout} path="/app" />
              <Redirect to="/app" />
            </Switch>
          </UnsavedChangesProvider>
        </AmplitudeProvider>
      </Router>
    </Suspense>
  );
};

export default App;
