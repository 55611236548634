import { IMapAssignmentToVariousDataRequestModals } from './DataRequestModals.interfaces';

export enum DataRequestModalActionEnums {
  COMPLETE = 'complete',
  DELETE = 'delete',
  EDIT = 'edit'
}

export const mapAssignmentToCompleteDataRequest: IMapAssignmentToVariousDataRequestModals =
  ({
    assignmentAssignedTo,
    assignmentDataSpecs,
    assignmentDescription,
    assignmentDueDate,
    assignmentStatusId,
    assignmentTitle,
    engagementGuid,
    isAssignmentCurrentlyWatched,
    queueItemGuid
  }) => ({
    assignedToUserGuid: assignmentAssignedTo.userGuid,
    dataSpecs: assignmentDataSpecs || '',
    description: assignmentDescription,
    dueDate: assignmentDueDate,
    engagementGuid,
    isCurrentUserWatching: isAssignmentCurrentlyWatched,
    queueItemGuid,
    statusId: assignmentStatusId,
    title: assignmentTitle
  });

export const mapAssignmentToDeleteDataRequest: IMapAssignmentToVariousDataRequestModals =
  ({ assignmentTitle, engagementGuid, queueItemGuid }) => ({
    engagementGuid,
    queueItemGuid,
    title: assignmentTitle
  });

export const mapAssignmentToEditDataRequest: IMapAssignmentToVariousDataRequestModals =
  ({
    assignmentAssignedTo,
    assignmentAttachments,
    assignmentDataSpecs,
    assignmentDescription,
    assignmentDueDate,
    assignmentStatusId,
    assignmentTitle,
    defaultFolderGuid,
    engagementDisplayNameShort,
    engagementGuid,
    isAssignmentCurrentlyWatched,
    queueItemGuid
  }) => ({
    assignedToUserGuid: assignmentAssignedTo.userGuid,
    attachments: assignmentAttachments || [],
    dataSpecs: assignmentDataSpecs || '',
    defaultFolderGuid: defaultFolderGuid,
    description: assignmentDescription,
    dueDate: assignmentDueDate,
    engagementDisplayNameShort,
    engagementGuid,
    isCurrentUserWatching: isAssignmentCurrentlyWatched,
    queueItemGuid,
    statusId: assignmentStatusId,
    title: assignmentTitle
  });
