import { useAmplitude } from 'contexts/AmplitudeContext/AmplitudeConsumer';

import React, { FunctionComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button, Card, DonutChart, Icon, Tooltip } from '@ryan/components';

import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import { WithUser, withUser } from '../../contexts/UserContext';
import { IDataRequestsCountByStatus, Permission } from '../../interfaces';
import history from '../../services/history';
import {
  AmplitudeActionType,
  amplitudeEventDetail
} from '../../utils/amplitudeUtils/amplitudeUtils';
import Empty from '../Empty';

import './DataRequestStatusSummaryCard.scss';

interface IDataRequestStatusSummaryCardProps extends WithTranslation, WithUser {
  values: IDataRequestsCountByStatus | null;
  isEngagementReadOnly?: boolean;
  engagementGuid?: string;
  onCreateClick: () => void;
}

// Assumes Permission.DataRequestsView
export const DataRequestStatusSummaryCard: FunctionComponent<
  IDataRequestStatusSummaryCardProps
> = ({
  engagementGuid,
  isAppReadOnly,
  isEngagementReadOnly,
  onCreateClick,
  permissionService: ps,
  t,
  values
}) => {
  const { initializeEventToTrack, triggerAmplitudeEvent } = useAmplitude();
  const location = useLocation();
  const { isUnsavedChanges, setBlockNavigation, setTargetUrl } =
    useUnsavedChanges();

  const isEmpty =
    values !== null &&
    values.complete === 0 &&
    values.inProgress === 0 &&
    values.toDo === 0;

  const handleButtonClick = (url: string) => {
    if (isUnsavedChanges) {
      setBlockNavigation(true);
      setTargetUrl(url);
    } else {
      history.push(url);
    }
  };

  return (
    <Card
      className="dr-status-summary-card"
      role="region"
      title={t('Data Requests')}
    >
      <Tooltip
        content={t(
          engagementGuid
            ? 'dataRequest.statusCard.engagementTooltip'
            : 'dataRequest.statusCard.accountTooltip'
        )}
        placement="top"
        renderTarget={({ open, ...props }) => (
          <div
            aria-expanded={open}
            aria-haspopup="true"
            className="dr-upcoming-summary-card__tooltip"
            {...props}
          >
            <Icon name="information" />
          </div>
        )}
      />

      {/* Loading */}
      {values === null && (
        <div className="center-icon">
          <Icon className="loading-spin" name="loading" />
        </div>
      )}

      {/* Empty */}
      {isEmpty && (
        <Empty icon="file">
          {t(
            ps.isRyan()
              ? 'dataRequest.statusCard.empty.ryan'
              : 'dataRequest.statusCard.empty.notRyan'
          )}
        </Empty>
      )}

      {/* Content */}
      {values !== null && !isEmpty && (
        <DonutChart
          data={[
            {
              color: '#EFEFF3',
              description: t('dataRequest.legendStatus.1'),
              inverseLegendColor: true,
              value: values.toDo
            },
            {
              color: '#0E247E',
              description: t('dataRequest.legendStatus.3'),
              value: values.complete
            },
            {
              color: '#A6CAFF',
              description: t('dataRequest.legendStatus.2'),
              inverseLegendColor: true,
              value: values.inProgress
            }
          ]}
          dataDescription={t('dataRequest.requests')}
        />
      )}

      <div className="dr-status-summary-card__buttons">
        <Button
          block
          onClick={() => {
            if (location.pathname === '/app') {
              initializeEventToTrack({
                eventAction: AmplitudeActionType.CLICK,
                eventName:
                  amplitudeEventDetail.dashboard.viewDataRequestsEventName,
                isTrackingOrigin: true
              });
            }

            handleButtonClick(
              engagementGuid
                ? `/app/project/${engagementGuid}/data-requests`
                : `/app/data-and-files/data-requests`
            );
          }}
          variant="primary"
        >
          {t('dataRequest.view')}
        </Button>

        {ps.hasPermission(Permission.DataRequestsEdit) && (
          <Button
            disabled={isEngagementReadOnly || isAppReadOnly}
            icon="plus"
            onClick={() => {
              if (location.pathname === '/app') {
                triggerAmplitudeEvent({
                  amplitudeEventAction: AmplitudeActionType.CLICK,
                  amplitudeEventLocation: location.pathname,
                  amplitudeEventName:
                    amplitudeEventDetail.dashboard.createDataRequestEventName
                });
              }

              onCreateClick();
            }}
            variant="secondary"
          />
        )}
      </div>
    </Card>
  );
};

DataRequestStatusSummaryCard.defaultProps = {
  isEngagementReadOnly: false
};

export default withTranslation()(withUser(DataRequestStatusSummaryCard));
