import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  Dropdown,
  TextInput,
  Textarea
} from '@ryan/components';

import { MilestoneDrawerConsumer } from '../../contexts/MilestoneDrawerContext';
import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext/UnsavedChangesContext';
import { WithUser, withUser } from '../../contexts/UserContext';
import {
  IMilestone,
  IMilestoneFormValues,
  IMilestoneRequest,
  IPagedDataResponse,
  ITask,
  MilestoneType,
  Permission,
  Status
} from '../../interfaces';
import { ButtonSizeEnums } from '../../utils/enums/ButtonSizeEnums';
import { ButtonVariantEnums } from '../../utils/enums/ButtonVariantEnums';
import {
  Formik,
  formikDatepickerProps,
  formikFieldProps,
  yup
} from '../../utils/forms';
import getCommentButtonProps from '../../utils/getCommentButtonProps';
import Datepicker from '../Datepicker';
import MilestoneHeader from './MilestoneHeader';
import MilestoneTasks from './MilestoneTasks';

export interface IMilestoneFormProps {
  milestone: IMilestone | IMilestoneRequest;
  milestones: IMilestone[];
  engagementKickoffDate: Date | null;
  engagementProjectedEndDate: Date | null;
  submitPromise: Promise<any> | null;
  onSubmit: (values: IMilestoneRequest) => void;
  onCancel: () => void;
  onDelete?: () => void;

  tasks: IPagedDataResponse<ITask> | null;
  onCreateTask: () => void;
}

const MilestoneForm: FunctionComponent<IMilestoneFormProps & WithUser> = ({
  engagementKickoffDate,
  engagementProjectedEndDate,
  isAppReadOnly,
  milestone,
  milestones,
  onCancel,
  onCreateTask,
  onDelete,
  onSubmit,
  permissionService,
  submitPromise,
  tasks
}) => {
  const { t: getTextToDisplay } = useTranslation();
  const { setIsUnsavedChanges } = useUnsavedChanges();
  const schema = useMemo(
    () =>
      yup.object({
        title: yup
          .string()
          .trim()
          .required(
            getTextToDisplay('milestones.milestoneContent.validations.title')
          )
          .max(
            80,
            getTextToDisplay(
              'milestones.milestoneContent.validations.maxLength'
            )
          ),
        statusId: yup
          .string()
          .required(
            getTextToDisplay('milestones.milestoneContent.validations.status')
          ),
        startDate: yup
          .date()
          .nullable()
          .required(
            getTextToDisplay(
              'milestones.milestoneContent.validations.startDate'
            )
          )
          .test(
            'is-kickoff-before-projected-end',
            getTextToDisplay(
              'milestones.milestoneContent.validations.dateBoundaries.kickoff'
            ),
            function (startDate: Date | null) {
              return !(
                milestone.milestoneTypeId === MilestoneType.Kickoff &&
                startDate !== null &&
                engagementProjectedEndDate !== null &&
                startDate > engagementProjectedEndDate
              );
            }
          )
          .test(
            'is-interim-after-kickoff',
            getTextToDisplay(
              'milestones.milestoneContent.validations.dateBoundaries.minDate'
            ),
            function (startDate: Date | null) {
              const startDay = new Date(startDate as Date).setHours(0, 0, 0, 0);
              const kickoffDay = new Date(
                engagementKickoffDate as Date
              ).setHours(0, 0, 0, 0);
              return !(
                milestone.milestoneTypeId === MilestoneType.Interim &&
                startDate !== null &&
                engagementKickoffDate !== null &&
                startDay < kickoffDay
              );
            }
          )
          .test(
            'is-interim-start-before-end',
            getTextToDisplay(
              'milestones.milestoneContent.validations.dateBoundaries.startDate'
            ),
            function (startDate: Date | null) {
              const endDate = this.resolve(yup.ref('endDate'));
              return !(
                milestone.milestoneTypeId === MilestoneType.Interim &&
                startDate !== null &&
                endDate !== null &&
                startDate > endDate
              );
            }
          )
          .test(
            'is-kickoff-after-milestone-date',
            getTextToDisplay(
              'milestones.milestoneContent.validations.dateBoundaries.milestoneDate'
            ),
            function (startDate: Date | null) {
              if (
                milestone.milestoneTypeId !== MilestoneType.Kickoff ||
                startDate === null
              ) {
                return true;
              }

              const startDay = new Date(startDate).setHours(0, 0, 0, 0);

              return !milestones.some(milestone => {
                if (
                  milestone.milestoneTypeId === MilestoneType.Interim &&
                  milestone.startDate
                ) {
                  const interimStartDay = new Date(
                    milestone.startDate
                  ).setHours(0, 0, 0, 0);
                  return interimStartDay < startDay;
                }
                return false;
              });
            }
          ),
        endDate: yup
          .date()
          .nullable()
          .required(
            getTextToDisplay('milestones.milestoneContent.validations.endDate')
          )
          .test(
            'is-interim-end-after-start',
            getTextToDisplay(
              'milestones.milestoneContent.validations.dateBoundaries.endDate'
            ),
            function (endDate: Date | null) {
              const startDate = this.resolve(yup.ref('startDate'));
              return !(
                milestone.milestoneTypeId === MilestoneType.Interim &&
                endDate !== null &&
                startDate !== null &&
                endDate < startDate
              );
            }
          )
          .test(
            'is-interim-before-projected-end',
            getTextToDisplay(
              'milestones.milestoneContent.validations.dateBoundaries.maxDate'
            ),
            function (endDate: Date | null) {
              return !(
                milestone.milestoneTypeId === MilestoneType.Interim &&
                endDate !== null &&
                engagementProjectedEndDate !== null &&
                endDate > engagementProjectedEndDate
              );
            }
          )
          .test(
            'is-projected-end-after-kickoff',
            getTextToDisplay(
              'milestones.milestoneContent.validations.dateBoundaries.estimatedEnd'
            ),
            function (endDate: Date | null) {
              return !(
                milestone.milestoneTypeId === MilestoneType.End &&
                endDate !== null &&
                engagementKickoffDate !== null &&
                endDate < engagementKickoffDate
              );
            }
          )
          .test(
            'is-projected-end-after-milestone-date',
            getTextToDisplay(
              'milestones.milestoneContent.validations.dateBoundaries.milestoneEndDate'
            ),
            function (endDate: Date | null) {
              return !(
                milestone.milestoneTypeId === MilestoneType.End &&
                endDate !== null &&
                milestones.some(
                  milestone =>
                    milestone.milestoneTypeId === MilestoneType.Interim &&
                    milestone.endDate > endDate
                )
              );
            }
          ),
        detail: yup
          .string()
          .max(
            2048,
            getTextToDisplay(
              'milestones.milestoneContent.validations.detailMaxLength'
            )
          )
      }),
    [
      engagementKickoffDate,
      engagementProjectedEndDate,
      getTextToDisplay,
      milestone.milestoneTypeId,
      milestones
    ]
  );

  useEffect(
    () => () => {
      // NOTE: Unsafe removal. Currently not able to know if value is being used by other components.
      setIsUnsavedChanges(false);
    },
    [setIsUnsavedChanges]
  );

  return (
    <Formik<IMilestoneFormValues>
      initialValues={{
        title: milestone.title,
        statusId: milestone.statusId.toString(),
        startDate: milestone.startDate,
        endDate: milestone.endDate,
        detail: milestone.detail
      }}
      onSubmit={values => {
        onSubmit({
          ...{
            milestoneTypeId: milestone.milestoneTypeId,
            title: values.title,
            detail: values.detail,
            statusId: parseFloat(values.statusId),
            /**
             * Kickoff and Estimated End are effectively one date,
             * but since backend still accepts both for no good reason,
             * we make them the same in those cases.
             */
            startDate:
              milestone.milestoneTypeId === MilestoneType.End
                ? values.endDate
                : values.startDate,
            endDate:
              milestone.milestoneTypeId === MilestoneType.Kickoff
                ? values.startDate
                : values.endDate
          }
        });
      }}
      validationSchema={schema}
    >
      {formik => {
        const canEditMilestones = permissionService.hasPermission(
          Permission.TimelinesEdit
        );
        const isSavedMilestone = 'engagementMilestoneGuid' in milestone;
        const isInterimMilestone =
          milestone.milestoneTypeId === MilestoneType.Interim;
        setIsUnsavedChanges(formik.dirty);

        return (
          <form className="milestone-form" onSubmit={formik.handleSubmit}>
            <MilestoneHeader
              isActive
              milestone={{
                milestoneTypeId: milestone.milestoneTypeId,
                ...formik.values,
                statusId: parseFloat(formik.values.statusId)
              }}
            />
            <div className="milestone-form__container">
              <div className="row">
                <div className="col-lg-6">
                  {isInterimMilestone && (
                    <TextInput
                      {...formikFieldProps('title', formik)}
                      label={getTextToDisplay('Title')}
                      placeholder={getTextToDisplay('New Milestone')}
                    />
                  )}
                  <Dropdown
                    {...formikFieldProps('statusId', formik)}
                    label={getTextToDisplay('Status')}
                    options={[
                      Status.Todo,
                      Status.InProgress,
                      Status.Complete
                    ].map(value => ({
                      label: getTextToDisplay(`milestones.dropdown.${value}`),
                      value: value.toString()
                    }))}
                  />
                  <div className={isInterimMilestone ? 'row' : ''}>
                    {milestone.milestoneTypeId !== MilestoneType.End && (
                      <div className={isInterimMilestone ? 'col' : ''}>
                        <Datepicker
                          {...formikDatepickerProps('startDate', formik)}
                          label={getTextToDisplay('Start Date')}
                        />
                      </div>
                    )}
                    {milestone.milestoneTypeId !== MilestoneType.Kickoff && (
                      <div className={isInterimMilestone ? 'col' : ''}>
                        <Datepicker
                          {...formikDatepickerProps('endDate', formik)}
                          label={getTextToDisplay('End Date')}
                          {...(milestone.milestoneTypeId !== MilestoneType.End
                            ? {
                                // adjust placement of datepicker of both start
                                // and end date fields will be displayed
                                popperPlacement: 'bottom-end'
                              }
                            : {})}
                        />
                      </div>
                    )}
                  </div>
                  <Textarea
                    {...formikFieldProps('detail', formik)}
                    label={getTextToDisplay('Detail (Optional)')}
                    maxLength={2048}
                  />
                </div>
                <div className="col-lg-6">
                  <MilestoneTasks
                    milestone={milestone}
                    onCreateTask={onCreateTask}
                    tasks={tasks}
                  />
                </div>
              </div>
              <div className="milestone-form__buttons">
                {/**
                 * UPDATE and CANCEL
                 */}
                <ButtonGroup>
                  <Button
                    disabled={!formik.dirty}
                    loading={submitPromise}
                    size={ButtonSizeEnums.LARGE}
                    text={getTextToDisplay('Update')}
                    type="submit"
                    variant={ButtonVariantEnums.PRIMARY}
                  />
                  <Button
                    disabled={submitPromise !== null}
                    onClick={onCancel}
                    size={ButtonSizeEnums.LARGE}
                    text={getTextToDisplay('Cancel')}
                  />
                </ButtonGroup>

                {/**
                 * DELETE MILESTONE
                 * Saved, Interim, Editable (not from external sources) Milestones
                 * can be deleted when editing if you have permission.
                 */}
                {canEditMilestones &&
                  isInterimMilestone &&
                  isSavedMilestone &&
                  (milestone as IMilestone).isEditable &&
                  onDelete && (
                    <Button
                      icon="trash"
                      negative
                      onClick={onDelete}
                      size={ButtonSizeEnums.SMALL}
                      text={getTextToDisplay('Delete')}
                      variant={ButtonVariantEnums.TEXT}
                    />
                  )}

                {/**
                 * OPEN COMMENTS
                 * Only saved Milestones can have comments.
                 */}
                {isSavedMilestone && (
                  <MilestoneDrawerConsumer>
                    {({ onMilestoneDrawerOpen }) => (
                      <Button
                        {...getCommentButtonProps(
                          getTextToDisplay,
                          permissionService.hasPermission(
                            Permission.TimelinesContribute
                          ),
                          milestone as IMilestone,
                          isAppReadOnly
                        )}
                        onClick={() =>
                          onMilestoneDrawerOpen(milestone as IMilestone)
                        }
                        size={ButtonSizeEnums.SMALL}
                        variant={ButtonVariantEnums.TEXT}
                      />
                    )}
                  </MilestoneDrawerConsumer>
                )}
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default withUser(MilestoneForm);
