import { IExecutiveAccessToggle } from 'interfaces';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox } from '@ryan/components';

export type ExecutiveAccessSelectionStatus = {
  [toggleGuid: string]: boolean;
};

function makeSelectionsHash(
  items: IExecutiveAccessToggle[],
  setter: (item: IExecutiveAccessToggle) => boolean = item => item.isSelected
): ExecutiveAccessSelectionStatus {
  return items.reduce(
    (hash, item) => ({ ...hash, [item.toggleGuid]: setter(item) }),
    {}
  );
}

interface IExecutiveAccessToggleGroupProps {
  typeName: string;
  items: IExecutiveAccessToggle[];
  onChange: (selections: ExecutiveAccessSelectionStatus) => void;
}

/**
 * Collection of table rows for a group of toggle type options to be rendered by
 * `ExecutiveAccessControl` component.
 */
const ExecutiveAccessToggleGroup: React.FC<IExecutiveAccessToggleGroupProps> =
  ({ items, onChange, typeName }) => {
    const { t } = useTranslation();

    // toggles visibility of items within this toggle type group
    const [showItems, setShowItems] = useState<boolean>(false);

    const hasSomeSelected = items.some(t => t.isSelected);
    const hasSomeUnselected = items.some(t => !t.isSelected);
    const hasAllSelected = !hasSomeUnselected;

    /**
     * Handle selection of all items in group. Default to disabling all items if
     * if a subset has already been selected.
     */
    const handleGroupToggle = () => {
      onChange(makeSelectionsHash(items, () => !hasSomeSelected));
    };

    return (
      <>
        <tr className="executive-access-control__group-head ry-table__tr ry-table__tr--even">
          <td className="ry-table__td">
            <Button
              ariaExpanded={showItems}
              icon={showItems ? 'chevron-up' : 'chevron-down'}
              onClick={() => setShowItems(prevShowItems => !prevShowItems)}
              variant="text"
            >
              {typeName}
            </Button>
          </td>
          <td className="ry-table__td ry-table__td--align-right">
            <Checkbox
              checked={hasAllSelected}
              indeterminate={hasSomeSelected && hasSomeUnselected}
              label={t('Select All')}
              onChange={handleGroupToggle}
              value=""
            />
          </td>
        </tr>
        {showItems &&
          items.map((item, i) => {
            return (
              <tr
                className={`executive-access-control__group-item ry-table__tr ry-table__tr--${
                  i % 2 === 0 ? 'even' : 'odd'
                }`}
                key={item.toggleGuid}
              >
                <td className="ry-table__td">
                  <label htmlFor={`select-${item.toggleGuid}`}>
                    {item.name}
                  </label>
                </td>
                <td className="ry-table__td ry-table__td--align-right">
                  <Checkbox
                    checked={item.isSelected}
                    id={`select-${item.toggleGuid}`}
                    onChange={() => {
                      onChange(
                        makeSelectionsHash(items, i =>
                          i.toggleGuid === item.toggleGuid
                            ? !i.isSelected
                            : i.isSelected
                        )
                      );
                    }}
                    value={item.toggleGuid}
                  />
                </td>
              </tr>
            );
          })}
      </>
    );
  };

export default ExecutiveAccessToggleGroup;
