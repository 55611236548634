import {
  DSSDocument,
  IActivity,
  IDataRequest,
  IDataRequestComment,
  IDataRequestRequest,
  IDataRequestSearchParams,
  IDataRequestUploadFilesOnBehalfOfUserRequest,
  IDataRequestsCountByStatus,
  IPagedDataResponse,
  IUpcomingDataRequests,
  IUserSummary
} from 'interfaces';
import { unwrapCommandResponse } from 'utils/unwrapCommandResponse';

import { CancelToken } from 'axios';

import ApiBase from './ApiBase';

//
//
// Data Requests
//
//

/**
 * GET Data Requests for a custom view
 */
export async function getCustomViewDataRequests(
  this: ApiBase,
  customViewGuid: string,
  params?: IDataRequestSearchParams,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IDataRequest>>(
    `/api/customViews/${customViewGuid}/datarequests/search`,
    { cancelToken, params }
  );
}

/**
 * GET an Engagement's Data Requests
 */
export async function getEngagementDataRequests(
  this: ApiBase,
  engagementGuid: string,
  params?: IDataRequestSearchParams,
  cancelToken?: CancelToken
) {
  return this.server.get<IPagedDataResponse<IDataRequest>>(
    `/api/engagements/${engagementGuid}/datarequests/search`,
    { cancelToken, params }
  );
}

/**
 * GET assignable users for Data Requests on an Engagement
 */
export async function getEngagementDataRequestAssignableUsers(
  this: ApiBase,
  engagementGuid: string,
  query: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IUserSummary[]>(
    `/api/engagements/${engagementGuid}/datarequests/assignableusers`,
    { cancelToken, params: { searchTerm: query } }
  );
}

/**
 * GET a Data Request
 */
export async function getDataRequest(
  this: ApiBase,
  dataRequestGuid: string,
  cancelToken?: CancelToken
) {
  // Not adding engagements to this route since we have an adjoining call once we get the response back that grabs the engagement itself.
  return this.server.get<IDataRequest>(`/api/datarequests/${dataRequestGuid}`, {
    cancelToken
  });
}

/**
 * GET a Data Request's Activity
 */
export async function getDataRequestActivity(
  this: ApiBase,
  engagementGuid: string,
  dataRequestGuid: string
) {
  return this.server.get<IActivity[]>(
    `/api/engagements/${engagementGuid}/datarequests/${dataRequestGuid}/activity`
  );
}

/**
 * GET a Data Request's Activity
 */
export async function getDataRequestActivityRestricted(
  this: ApiBase,
  engagementGuid: string,
  dataRequestGuid: string
) {
  return this.server.get<IActivity[]>(
    `/api/engagements/${engagementGuid}/datarequests/${dataRequestGuid}/activity/restricted`
  );
}

/**
 * Create a new Data Request
 */
export async function createDataRequest(
  this: ApiBase,
  dataRequest: IDataRequestRequest
) {
  return unwrapCommandResponse<IDataRequest>(
    this.server.post(
      `/api/engagements/${dataRequest.engagementGuid}/datarequests`,
      dataRequest
    )
  );
}

/**
 * Update a Data Request
 */
export async function updateDataRequest(
  this: ApiBase,
  dataRequest: IDataRequest,
  updates: IDataRequestRequest
) {
  return unwrapCommandResponse<IDataRequest>(
    this.server.put(
      `/api/engagements/${dataRequest.engagementGuid}/datarequests/${dataRequest.queueItemGuid}`,
      {
        title: dataRequest.title,
        description: dataRequest.description,
        assignedToUserGuid: dataRequest.assignedToUserGuid,
        dueDate: dataRequest.dueDate,
        dataSpecs: dataRequest.dataSpecs,
        statusId: dataRequest.statusId,
        isCurrentUserWatching: dataRequest.isCurrentUserWatching,
        ...updates
      }
    )
  );
}

/**
 * DELETE a Data Request
 */
export async function deleteDataRequest(
  this: ApiBase,
  engagementGuid: string,
  dataRequestGuid: string,
  deleteFiles?: boolean
) {
  return unwrapCommandResponse<IDataRequest>(
    this.server.delete(
      `/api/engagements/${engagementGuid}/datarequests/${dataRequestGuid}`,
      {
        params: {
          deleteFiles: deleteFiles ? 1 : undefined
        }
      }
    )
  );
}

//
//
// Upload Files
//
//

export async function uploadDataRequestFiles(
  this: ApiBase,
  engagementGuid: string,
  dataRequestGuid: string,
  documents: DSSDocument[]
) {
  return this.server.post(
    `/api/engagements/${engagementGuid}/datarequests/${dataRequestGuid}/documents`,
    { documents }
  );
}

export async function uploadDataRequestFilesOnBehalfOfUser(
  this: ApiBase,
  engagementGuid: string,
  dataRequestGuid: string,
  request: IDataRequestUploadFilesOnBehalfOfUserRequest
) {
  return unwrapCommandResponse(
    this.server.post(
      `/api/engagements/${engagementGuid}/datarequests/${dataRequestGuid}/documents/internal`,
      request
    )
  );
}

//
//
// Ad Hoc Data Request
//
//

export async function uploadAdHocFiles(
  this: ApiBase,
  engagementGuid: string,
  request: {
    title: string;
    description: string;
    comment: string;
    documents: DSSDocument[];
  }
) {
  return this.server.post<IDataRequest>(
    `/api/engagements/${engagementGuid}/datarequests/adhoc`,
    request
  );
}

//
//
// Data Request Comments
//
//

/**
 * Create a Data Request Comment
 */
export async function createDataRequestComment(
  this: ApiBase,
  engagementGuid: string,
  dataRequestGuid: string,
  comment: string
) {
  return unwrapCommandResponse<IDataRequestComment>(
    this.server.post(
      `/api/engagements/${engagementGuid}/datarequests/${dataRequestGuid}/comments`,
      {
        comment
      }
    )
  );
}

/**
 * GET a Data Request's Comments
 */
export async function getDataRequestComments(
  this: ApiBase,
  engagementGuid: string,
  dataRequestGuid: string
) {
  return this.server.get<IDataRequestComment[]>(
    `/api/engagements/${engagementGuid}/datarequests/${dataRequestGuid}/comments`
  );
}

/**
 * Update a Data Request Comment
 */
export async function updateDataRequestComment(
  this: ApiBase,
  engagementGuid: string,
  queueItemCommentGuid: string,
  commentText: string
) {
  return unwrapCommandResponse<IDataRequestComment>(
    this.server.put(
      `/api/engagements/${engagementGuid}/datarequests/comments/${queueItemCommentGuid}`,
      {
        comment: commentText
      }
    )
  );
}

/**
 * DELETE a Data Request Comment
 */
export async function deleteDataRequestComment(
  this: ApiBase,
  engagementGuid: string,
  queueItemCommentGuid: string
) {
  return unwrapCommandResponse<IDataRequestComment>(
    this.server.delete(
      `/api/engagements/${engagementGuid}/datarequests/comments/${queueItemCommentGuid}`
    )
  );
}

//
//
// Dashboards
//
//

export async function getUpcomingDataRequestsForCustomView(
  this: ApiBase,
  customViewGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IUpcomingDataRequests>(
    `/api/customViews/${customViewGuid}/datarequests/getupcoming`,
    { cancelToken }
  );
}

export async function getCustomViewDataRequestCount(
  this: ApiBase,
  customViewGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IDataRequestsCountByStatus>(
    `/api/customViews/${customViewGuid}/datarequests/findcountbystatus`,
    { cancelToken }
  );
}

export async function getEngagementDataRequestCount(
  this: ApiBase,
  engagementGuid: string,
  cancelToken?: CancelToken
) {
  return this.server.get<IDataRequestsCountByStatus>(
    `/api/engagements/${engagementGuid}/datarequests/findcountbystatus`,
    { cancelToken }
  );
}
