import Modal from 'components/Modal';
import { ITask, Status } from 'interfaces';
import ApiService from 'services/ApiService';

import React, { Component, createRef } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  MentionsValue,
  pushToast
} from '@ryan/components';

import CommentInput from '../../Comments/CommentInput';

interface ICompleteTaskModalProps extends WithTranslation {
  task: ITask;
  open?: boolean;
  onClose: (updatedTask?: ITask) => void;
}

interface ICompleteTaskModalState {
  comment: MentionsValue;
  isValid: boolean;
}

const maxLengthComment = 250;

export class CompleteTaskModal extends Component<
  ICompleteTaskModalProps,
  ICompleteTaskModalState
> {
  private modalContentRef = createRef<HTMLDivElement>();

  readonly state = {
    comment: new MentionsValue(),
    isValid: true
  };

  handleChange = (comment: MentionsValue) => {
    const isValid = comment.length <= maxLengthComment;
    this.setState({ comment, isValid });
  };

  handleComplete = async () => {
    const { t, task, onClose } = this.props;
    const { comment, isValid } = this.state;

    if (!isValid) return;

    try {
      const { data: updatedTask } = await ApiService.updateTask(task, {
        statusId: Status.Complete,
        isTaskComplete: true,
        comment: comment.toJSON().text
      });
      const { title } = updatedTask;
      pushToast({
        type: 'success',
        title: t('taskActions.completeModal.successToast.title'),
        content: (
          <Trans i18nKey="taskActions.completeModal.successToast.content">
            <b>{{ title }}</b> is now completed.
          </Trans>
        )
      });
      onClose(updatedTask);
    } catch (error) {
      pushToast({
        type: 'error',
        title: t('serverError.title'),
        content: t('serverError.content')
      });
      onClose();
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { t, open = true, task } = this.props;
    const { comment, isValid } = this.state;
    const validationProps = {
      feedback: t('taskActions.completeModal.comment.maxLength', {
        length: maxLengthComment
      }),
      invalid: !isValid
    };

    return (
      <Modal
        onClose={this.handleClose}
        open={open}
        title={t('taskActions.completeModal.title')}
      >
        <div ref={this.modalContentRef}>
          <CommentInput
            {...(!isValid ? validationProps : {})}
            boundingParentRef={this.modalContentRef}
            engagementGuid={task.engagementGuid}
            label={t('taskActions.completeModal.comment.label')}
            onChange={this.handleChange}
            value={comment}
          />
          <ButtonGroup>
            <Button
              disabled={!isValid}
              onClick={this.handleComplete}
              text={t('taskActions.completeModal.submit')}
              variant="primary"
            />
            <Button
              onClick={this.handleClose}
              text={t('Cancel')}
              variant="secondary"
            />
          </ButtonGroup>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(CompleteTaskModal);
